import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Heading,
  Stack,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Checkbox,
  VStack,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import { IoPencilSharp } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { MdAdd } from "react-icons/md";
import moment from "moment-timezone";
import { listSupportsByProduct, createSupport, updateSupport } from '../../actions/supportActions';
import { SUPPORT_UPDATE_RESET } from '../../constants/supportConstants';
import { getProductReasons } from '../../actions/reasonsActions';
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const SupervisorSupport = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const product_id = userInfo?.product_id || '';
  
  const { supports, loading, error } = useSelector((state) => state.supportProductList);
  const { reasons } = useSelector((state) => state.productReasons);

  const { success: createSuccess } = useSelector((state) => state.supportCreate);

  const { success: updateSuccess } = useSelector((state) => state.supportUpdate);   
  
  const [editSupport, setEditSupport] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [support, setSupport] = useState({
    name: "",
    email: "",
    phone: "",
    product_id: product_id,
    reasons: [],
    password: "", 
  });

  const supportsPerPage = 10;
  const indexOfLastSupport = currentPage * supportsPerPage;
  const indexOfFirstSupport = indexOfLastSupport - supportsPerPage;
  const currentSupports = supports?.slice(indexOfFirstSupport, indexOfLastSupport);

  useEffect(() => {
    if (userInfo) {
      dispatch(listSupportsByProduct(product_id, searchQuery)); 
    }
  }, [dispatch, userInfo, product_id, searchQuery, createSuccess, updateSuccess]);

  const { isOpen } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      dispatch(getProductReasons(product_id, searchQuery));
    }
  }, [isOpen, dispatch, product_id, searchQuery]);

  const handleEdit = (support) => {
    setEditSupport(support);
    setModalOpen(true);
    setSupport({
      name: support.name,
      email: support.email,
      phone: support.phone,
      product_id: support.product_id,
      reasons: support.reasons,
      password: "", 
    });
  };

  // const handleDelete = (id) => {
  //   if (window.confirm("Are you sure you want to delete this support entry?")) {
  //     dispatch(deleteSupport(id))
  //       .then(() => {
  //         dispatch(listSupportsByProduct(product_id, searchQuery));
  //       });
  //   }
  // };
  
  const handleCloseModal = () => {
    setEditSupport(null);
    setModalOpen(false);
    setSupport({
      name: "",
      email: "",
      phone: "",
      product_id: product_id,
      reasons: [],
      password: "",
    });
  };

  const handleSaveEdit = () => {
    if (editSupport) {
      dispatch(updateSupport(editSupport.id, support))
        .then(() => {
          dispatch({ type: SUPPORT_UPDATE_RESET });
          dispatch(listSupportsByProduct(product_id, searchQuery));
          setModalOpen(false);
          setEditSupport(null);
          setSupport({
            name: "",
            email: "",
            phone: "",
            product_id: product_id,
            reasons: [],
            password: "",
          });
        });
    }
  };

  const handleCreateSupport = () => {
    dispatch(createSupport(support))
      .then(() => {
        dispatch(listSupportsByProduct(product_id, searchQuery));
        setModalOpen(false);
        setSupport({
          name: "",
          email: "",
          phone: "",
          product_id: product_id,
          reasons: [],
          password: "",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Support
        </Heading>

        <Button
          align="center"
          color="blue.500"
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          padding="4"
          textAlign="center"
          bgColor="white"
          m={4}
          _hover={{ bgColor: 'blue.500', color: 'white', borderWidth:"2px", borderColor:"white" }}
          cursor="pointer"
          variant="outline"
          onClick={() => {
            setSupport({
              name: "",
              email: "",
              phone: "",
              product_id: product_id,
              reasons: [],
              password: "",
            });
            setModalOpen(true);
          }}
        >
          <Icon as={MdAdd} boxSize={6} mr={2} />
          Add Support
        </Button>
      </Flex>
      <Flex justifyContent="space-between" mt="2">
        <Box mb="5" ml={8} mt={4}>
          <Text>Show entries</Text>
        </Box>
        <Box display="flex" alignItems="center" m={4}>
          <Text fontSize="md" mr={2}>
            Search:
          </Text>
          <Input
            type="text"
            placeholder="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            bgColor="white"
            borderRadius="lg"
            borderWidth="2px"
            borderColor="blue.500"
            padding="2"
            _hover={{ borderColor: "white" }}
          />
        </Box>
      </Flex>    
        
      <Stack spacing={4} overflowX="auto">
        <Table variant="striped" colorScheme="whiteAlpha" bg='white'>
          <Thead>
            <Tr>
              <Th borderBottom="1px" borderColor="gray.300">No.</Th>
              <Th borderBottom="1px" borderColor="gray.300">Name</Th>
              <Th borderBottom="1px" borderColor="gray.300">Email</Th>
              <Th borderBottom="1px" borderColor="gray.300">Date Created</Th>
              <Th borderBottom="1px" borderColor="gray.300">Action</Th>
            </Tr>
          </Thead>
          <Tbody>              
            {loading ? (
              <Tr>
                <Td colSpan={5}>
                  <Loader />
                </Td>
              </Tr>
            ) : error ? (
              <Tr>
                <Td colSpan={5}>
                  <Message status="error">{error}</Message>
                </Td>
              </Tr>
            ) : (
              currentSupports.map((support, index) => (
                <Tr key={support.id}>
                  <Td>{index + 1}</Td>
                  <Td>{support.name}</Td>
                  <Td>{support.email}</Td>
                  <Td>{moment(support.created_at).tz("Africa/Nairobi").format("dddd, MMMM Do YYYY, h:mm:ss a")}</Td>
                  <Td>
                    <Flex>
                      <Button
                        colorScheme="teal"
                        bg='blue.500'
                        mr="2"
                        onClick={() => handleEdit(support)}
                      >
                        <Icon as={IoPencilSharp} />
                      </Button>
                      {/* <Button
                        colorScheme="red"
                        onClick={() => handleDelete(support.id)}
                      >
                        <Icon as={IoTrashBinSharp} />
                      </Button> */}
                    </Flex>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
        {/* Pagination */}
        <Flex justify="center">
          <Button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Text mx={2}>
            Page {currentPage}
          </Text>
          <Button
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastSupport >= supports?.length}
          >
            Next
          </Button>
        </Flex>
      </Stack>      

      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editSupport ? "Edit Support" : "Add Support"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={support.name}
                onChange={(e) => setSupport({ ...support, name: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={support.email}
                onChange={(e) => setSupport({ ...support, email: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                type="text"
                name="phone"
                value={support.phone}
                onChange={(e) => setSupport({ ...support, phone: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={support.password}
                  onChange={(e) => setSupport({ ...support, password: e.target.value })}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleTogglePassword}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <VStack align="flex-start" spacing={4}>
              <Text>Reasons:</Text>
              {reasons?.data?.map((reason) => (
                <Checkbox
                  key={reason.id}
                  isChecked={support.reasons.includes(reason.id)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setSupport((prevSupport) => ({
                      ...prevSupport,
                      reasons: checked
                        ? [...prevSupport.reasons, reason.id]
                        : prevSupport.reasons.filter((id) => id !== reason.id),
                    }));
                  }}
                >
                  {reason.reason}
                </Checkbox>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button colorScheme="green" onClick={editSupport ? handleSaveEdit : handleCreateSupport}>
              {editSupport ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SupervisorSupport;

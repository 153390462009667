
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Heading,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";

import { FiSend } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { sendHaziBulkEmail, listContactGroups } from "../../actions/haziSocialsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziBulkMailScreen = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [groupId, setGroupId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, error, groups } = useSelector((state) => state.haziContactGroupsList);
  const { success, error: sendError } = useSelector((state) => state.haziBulkEmailSend);

  useEffect(() => {
    if (userInfo) {
      dispatch(listContactGroups());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (success) {
      toast({
        title: "Email Sent",
        description: "Your bulk email was sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (sendError) {
      toast({
        title: "Error",
        description: "Failed to send email. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [success, sendError, toast]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("group_id", groupId);
    formData.append("subject", subject);
    formData.append("message", message);
    if (attachment) {
      formData.append("attachment", attachment);
    }
    dispatch(sendHaziBulkEmail(formData));
  };

  return (
    <Box p={6} bg="gray.50" minH="100vh">
      <Heading as="h1" fontSize="2xl" mb={6}>
        Bulk Emails
      </Heading>

      <Box
        p={8}
        bg="white"
        boxShadow="lg"
        rounded="lg"
        maxW="600px"
      >
        <Stack spacing={4}>
          {loading && <Loader />}
          {error && <Message variant="error">{error}</Message>}

          <FormControl isRequired>
            <FormLabel>Contact Group</FormLabel>
            <Select
              placeholder="Select a group"
              value={groupId}
              onChange={(e) => setGroupId(e.target.value)}
            >
              {groups?.data?.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Subject</FormLabel>
            <Input
              placeholder="Enter email subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea
              placeholder="Enter your email body"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Attachment</FormLabel>
            <Input
              type="file"
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </FormControl>

          <Button
            colorScheme="blue"
            leftIcon={<FiSend />}
            onClick={handleSubmit}
            isDisabled={!groupId || !subject || !message}
          >
            Send Email
          </Button>

          {success && (
            <Alert status="success" rounded="md">
              <AlertIcon />
              <AlertTitle>Email Sent!</AlertTitle>
              <AlertDescription>
                Your email was sent to the selected group.
              </AlertDescription>
            </Alert>
          )}

          {sendError && (
            <Alert status="error" rounded="md">
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Failed to send the email. Please try again.
              </AlertDescription>
            </Alert>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default HaziBulkMailScreen;

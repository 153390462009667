import axios from 'axios';

import {
	USER_DELETE_FAIL,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DETAILS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_RESET,
	USER_DETAILS_SUCCESS,
	USER_LIST_FAIL,
	USER_LIST_REQUEST,
	USER_LIST_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAIL,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_UPDATE_FAIL,
	USER_UPDATE_PROFILE_FAIL,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_RESET,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_FORGOT_PASSWORD_FAIL,
    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_SUCCESS,
} from '../constants/userConstants';

export const login = (email, password) => async (dispatch) => {
	try {
		dispatch({ type: USER_LOGIN_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.post(
			`/api/users/login`,
			{ email, password },
			config
		);

		dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (err) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const logout = () => async (dispatch) => {
	localStorage.removeItem('userInfo');
	dispatch({ type: USER_LOGOUT });
	dispatch({ type: USER_UPDATE_PROFILE_RESET });
	dispatch({ type: USER_DETAILS_RESET });
};

export const register = (name, email, phone, role, password, product_id) => async (dispatch) => {
	try {
	  dispatch({ type: USER_REGISTER_REQUEST });
  
	  const config = {
		headers: {
		  'Content-Type': 'application/json',
		},
	  };
  
	  const { data } = await axios.post(
		`/api/users`,
		{ name, email, phone, role, password, product_id },
		config
	  );
  
	  dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
	} catch (err) {
	  dispatch({
		type: USER_REGISTER_FAIL,
		payload:
		  err.response && err.response.data.message
			? err.response.data.message
			: err.message,
	  });
	}
  };

export const getUserDetails = () => async (dispatch, getState) => {
	try {
	  console.log('Fetching user details...');
	  dispatch({ type: USER_DETAILS_REQUEST });
  
	  const {
		userLogin: { userInfo },
	  } = getState();
  
	  const config = {
		headers: {
		  Authorization: `Bearer ${userInfo.token}`,
		},
	  };
  
	  const { data } = await axios.get(`/api/users/profile`, config);
  
	  console.log('User details:', data);
  
	  dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
	} catch (err) {
	  console.error('Error fetching user details:', err);
	  dispatch({
		type: USER_DETAILS_FAIL,
		payload:
		  err.response && err.response.data.message
			? err.response.data.message
			: err.message,
	  });
	}
  };
 
export const updateUserProfile = (user) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.put(`/api/users/profile`, user, config);

		dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
		dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
	} catch (err) {
		dispatch({
			type: USER_UPDATE_PROFILE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const listUsers = (searchQuery = '') => async (dispatch, getState) => {
	try {
	  dispatch({ type: USER_LIST_REQUEST });
  
	  const {
		userLogin: { userInfo },
	  } = getState();
  
	  const config = {
		headers: {
		  Authorization: `Bearer ${userInfo.token}`,
		},
	  };
  
	  const { data } = await axios.get(`/api/users?search=${searchQuery}`, config);

		dispatch({ type: USER_LIST_SUCCESS, payload: data });
	} catch (err) {
	  dispatch({
		type: USER_LIST_FAIL,
		payload:
		  err.response && err.response.data.message
			? err.response.data.message
			: err.message,
	  });
	}
  };  

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: USER_DELETE_REQUEST });

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		await axios.delete(`/api/users/${id}`, config);

		dispatch({ type: USER_DELETE_SUCCESS });
	} catch (err) {
		dispatch({
			type: USER_DELETE_FAIL,
			payload:
				err.response && err.response.data.message
					? err.response.data.message
					: err.message,
		});
	}
};

export const updateUser = (id, user) => async (dispatch, getState) => {
	try {
	  dispatch({ type: USER_UPDATE_REQUEST });
  
	  const {
		userLogin: { userInfo },
	  } = getState();
  
	  const config = {
		headers: {
		  Authorization: `Bearer ${userInfo.token}`,
		  'Content-Type': 'application/json',
		},
	  };
  
	  const { data } = await axios.put(`/api/users/${id}`, user, config);  
  
	  dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
	} catch (err) {
	  console.error('Update User Error:', err);
  
	  dispatch({
		type: USER_UPDATE_FAIL,
		payload:
		  err.response && err.response.data.message
			? err.response.data.message
			: err.message,
	  });
	}
  };  

  export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: USER_FORGOT_PASSWORD_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(
            `/api/users/forgot-password`,
            { email },
            config
        );

        dispatch({ type: USER_FORGOT_PASSWORD_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: USER_FORGOT_PASSWORD_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};
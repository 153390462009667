import { Divider, Box } from '@chakra-ui/react';

import Header from "./components/Header";
import Homescreen from "./components/Homescreen";
import Gridicon from "./components/Gridicon";
import Features from "./components/Featues";
import Footer from "./components/Footer";
import AboutUsSection from "./components/AboutUsSection";
import ServicesSection from "./components/ServicesSection";
import Contact from "./components/Contact";
import Countries from './components/Countries';

const Landing = () => {
  return (
    <Box flex='1' mt={6} overflow='auto'>
      <Header />

      <Homescreen />
      <Gridicon />
      <Features />
      <AboutUsSection/>
      <Countries/>       
      <ServicesSection/>
      <Contact/>

      <Divider mt="12" />
      <Footer />
    </Box>
  );
}

export default Landing;

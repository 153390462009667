import React, { useEffect } from 'react';
import { Box, Text, Flex, Heading, Icon } from '@chakra-ui/react';
import { FaPhone, FaPhoneSlash, FaClock, FaRegBookmark } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";

import { getAdminInboundStats, getAdminOutboundStats } from '../../actions/statsActions';

const StatCard = ({ bgColor, icon, label, value }) => (
  <Flex
    bg="white"
    p="4"
    borderWidth="3px"
    borderColor="grey.100"
    borderRadius="lg"
    boxShadow="md"
    m={2}
    width={{ base: '100%', md: '30%' }}
    align="center"
    mb={{ base: '2', md: '0' }}
    mx="auto"
  >
    <Box
      bgColor={bgColor}
      m={2}
      h="40px"
      w="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icon as={icon} color="white" fontSize="2.0em" />
    </Box>
    <Box>
      <Text>{`${label}: ${value}`}</Text>
    </Box>
  </Flex>
);

const HomeAdmin = () => {
  const dispatch = useDispatch();

  const adminInboundStats = useSelector((state) => state.adminInboundStats);  
  console.log('Admin Inbound Stats', adminInboundStats);

  const { 
    allTimeStats: {
      connectCount = 0,
      abandonCount = 0,
      ringNoAnswerCount = 0,
    } = {},
    dailyStats: {
      answeredCount: dailyConnectCount = 0,
      noAnswerCount: dailyRingNoAnswerCount = 0,
    } = {}
  } = useSelector((state) => state.adminInboundStats); 

  const {
    allTimeStats: {
      answeredCount: outboundAnsweredCount = 0,
      noAnswerCount: outboundNoAnswerCount = 0,
      totalTalkTime: outboundTalkTime = 0,
      avgCallDuration: outboundAht = 0,
    } = {},
    dailyStats: {
      answeredCount: dailyOutboundAnsweredCount = 0,
      noAnswerCount: dailyOutboundNoAnswerCount = 0,
    } = {}
  } = useSelector((state) => state.adminOutboundStats);

  useEffect(() => {
    dispatch(getAdminInboundStats());
    dispatch(getAdminOutboundStats());
  }, [dispatch]);

  return (
    <Flex direction="column" p="4" borderRadius="md" m={4}>
      <Heading as="h2" size="md" mb="4">
        Dashboard
      </Heading>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Inbound Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="green" icon={FaPhone} label="Answered Calls" value={connectCount} />
          <StatCard bgColor="red" icon={FaPhoneSlash} label="Abandoned Calls" value={abandonCount} />
          <StatCard bgColor="orange" icon={FaPhone} label="Ring No Answer Calls" value={ringNoAnswerCount} />
        </Flex>
      </Box>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Outbound Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="teal" icon={FaPhone} label="Answered Calls" value={outboundAnsweredCount} />
          <StatCard bgColor="orange" icon={FaPhoneSlash} label="Not Answered Calls" value={outboundNoAnswerCount} />
          <StatCard bgColor="green" icon={FaClock} label="Talk Time (Mins)" value={outboundTalkTime} />
          <StatCard bgColor="red" icon={FaRegBookmark} label="AHT (Mins)" value={outboundAht} />
        </Flex>
      </Box>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Inbound Stats Today
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="teal" icon={FaPhone} label="Answered Calls" value={dailyConnectCount} />
          <StatCard bgColor="orange" icon={FaPhoneSlash} label="Not Answered Calls" value={dailyRingNoAnswerCount} />
        </Flex>
      </Box>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Outbound Stats Today
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="teal" icon={FaPhone} label="Answered Calls" value={dailyOutboundAnsweredCount} />
          <StatCard bgColor="orange" icon={FaPhoneSlash} label="Not Answered Calls" value={dailyOutboundNoAnswerCount} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default HomeAdmin;

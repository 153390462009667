import { useState, useEffect, useRef } from "react";
import {
  HStack,
  VStack,
  Box,
  Icon,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { FaPhone, FaCogs, FaHeadphones, FaTimes } from "react-icons/fa";

const steps = [
  {
    icon: FaPhone,
    title: "Ringing",
    description: "+254769******",
    color: "green",
  },
  {
    icon: FaCogs,
    title: "In Queue",
    description: "User waiting in queue",
    color: "orange.400",
  },
  {
    icon: FaHeadphones,
    title: "Answered",
    description: "Answered by Mark",
    color: "blue.400",
  },
  {
    icon: FaTimes,
    title: "Hung Up",
    description: "Missed call",
    color: "red.300",
  },
];

const ringAnimation = keyframes`
  0% { transform: rotate(0); }
  25% { transform: rotate(15deg); }
  50% { transform: rotate(0); }
  75% { transform: rotate(-15deg); }
  100% { transform: rotate(0); }
`;

const CallProgress = () => {
  const [callState, setCallState] = useState("idle");
  const audioRef = useRef(null);

  useEffect(() => {
    if (callState === "ringing" && audioRef.current) {
      audioRef.current.play();
    } else if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [callState]);

  
  return (
    <Box width={["90%", "80%", "40%"]} border="2px solid" borderColor="#E0E0E0">
      <audio ref={audioRef} src="/ringtone.mpeg" loop />
      <HStack spacing={2}>
        {steps.map((step, index) => (
          <HStack
            key={index}
            spacing={2}
            align="center"
            borderRadius="md"
            borderRight="1px solid"
            borderColor="#E0E0E0"
            w="100%"
            bg={step.title.toLowerCase() === callState ? "gray.100" : "white"} // Highlight current step
          >
            <Box
              display="inline-block"
              animation={
                step.title.toLowerCase() === "ringing" &&
                callState === "ringing"
                  ? `${ringAnimation} 1s infinite`
                  : "none"
              }
            >
              <Icon as={step.icon} boxSize={6} color={step.color} />
            </Box>
            <VStack align="start">
              <Text fontWeight="bold">{step.title}</Text>
              <Text fontSize="sm" color="gray.500">
                {step.description}
              </Text>
            </VStack>
          </HStack>
        ))}
      </HStack>
      {/* <HStack spacing={4}>
        <Button onClick={() => setCallState("ringing")} colorScheme="green">
          Start Ringing
        </Button>

        <Button onClick={() => setCallState("hungup")} colorScheme="red">
          End Call
        </Button>
      </HStack> */}
    </Box>
  );
};

export default CallProgress;

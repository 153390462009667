import {
    Box,
    Flex,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Select,
    Input,
    Stack,
    Button,
  } from '@chakra-ui/react';
  
  const SocialEscalations = () => {
    return (
      <Box p="4" bg="white"  borderRadius="md" m={2}>
           <Box m={6} mt={8}>
              <Text fontSize="xl" fontWeight="bold" mb="4">
                  Social Escalations
              </Text>
          </Box>      
          <Flex  flexWrap="wrap" justify="space-around">
          {/* Entries per page dropdown */}
              <Box >
                  <label htmlFor="entries">Show</label>
              <Select id="entries" variant="outline" size="sm" ml="2">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </Select>
              <span>entries</span>
              </Box>
  
            {/* Search input */}
            <Box>
                <label htmlFor="search">Search:</label>
                <Input type="text" id="search" variant="outline" size="sm" ml="2" borderWidth="2px" />
            </Box>
          
          </Flex>
  
        {/* Table */}
        <Table>
          <TableCaption>Showing 1 to 1 of 2 entries</TableCaption>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Source</Th>
              <Th>Customer</Th>
              <Th>Phone Number</Th>
              <Th>Agent</Th>
              <Th>Reason</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
  
        {/* Pagination */}
        <Stack mt="4" direction="row" align="center" spacing="4" justify="flex-end">
          <Button colorScheme="blue" size="sm">
            Previous
          </Button>
          <span>1</span>
          <Button colorScheme="blue" size="sm">
            Next
          </Button>
        </Stack>
      </Box>
    );
  };
  
  export default SocialEscalations;
  
import React from 'react';
import {  
  Box, 
  Flex, 
  Heading,
  Image, 
  Stack,
} from '@chakra-ui/react';

import keImg1 from "./ke.png";
import TZmg1 from "./tz.png";
import ugmg1 from "./ug.png";
import rdcmg1 from "./rdc.png";

const Countries = () => {
  return (
    <Box position="relative" backgroundColor="#00669E"> 
     <Box className="section-header" >
      <br></br>
          <Heading mx={1} color={'#FFFFFF'}>Zuridesk Product Footprint</Heading>
        </Box>
      <Flex 
        maxWidth="xlg" 
        mx="auto"       
        p={6}
      >    
      
        <Flex mx='auto'>
          {/* Cards */}
          <Box gridTemplateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }}>
            <Stack  
              maxWidth="xlg" 
              mx="auto"
              direction="row"
              justify="center"                    
              spacing={2}
              wrap="wrap"
              align="center"
            >                
              <Box
                maxW="md"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                shadow="md"
                border Color="blue.500"
                boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                height="100px"
                width={['250px']} 
                _hover={{
                  transform: 'scale(1.05)',
                }}
              >
                <Image
                  bo
                  alt={"Zuridesk Tanzania"}
                  fit={"cover"}
                  w={"100%"}
                  h={"100%"}
                  src={TZmg1}
                />
              </Box>

              <Box
                maxW="md"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                shadow="md"
                border Color="blue.500"
                boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                height="100px"
                width={['250px']} 
                _hover={{
                  transform: 'scale(1.05)',
                }}
              >
                <Image
                  bo
                  alt={"Zuridesk Tanzania"}
                  fit={"cover"}
                  w={"100%"}
                  h={"100%"}
                  src={keImg1}
                />
              </Box>

              <Box
                maxW="md"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                shadow="md"
                border Color="blue.500"
                boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                height="100px"
                width={['250px']} 
                _hover={{
                  transform: 'scale(1.05)',
                }}
              >
                <Image
                  bo
                  alt={"Zuridesk Tanzania"}
                  fit={"cover"}
                  w={"100%"}
                  h={"100%"}
                  src={ugmg1}
                />
              </Box>

              <Box
                maxW="md"
                borderWidth="2px"
                borderRadius="lg"
                overflow="hidden"
                shadow="md"
                border Color="blue.500"
                boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                height="100px"
                width={['250px']} 
                _hover={{
                  transform: 'scale(1.05)',
                }}
              >
                <Image
                  bo
                  alt={"Zuridesk Tanzania"}
                  fit={"cover"}
                  w={"100%"}
                  h={"100%"}
                  src={rdcmg1}
                />
              </Box>                   
            </Stack>
          </Box>
        </Flex>
      </Flex> 
    </Box>
  );
}

export default Countries;

import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  Flex, 
  Heading, 
  Spacer, 
  Text, 
  Input, 
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FaTrash } from "react-icons/fa";
import { bulkDeleteClientContacts } from "../../actions/clientContactsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const OutboundBulkContacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bulkContactsDelete = useSelector((state) => state.bulkContactsDelete);
  const { loading, success, error } = bulkContactsDelete;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const toast = useToast();
  
  const handleBulkDelete = () => {

    if (!startDate || !endDate) {
      toast({
        title: "Error",
        description: "Start date and end date must be provided.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    dispatch(bulkDeleteClientContacts(startDate, endDate));
  };
  
  return (
    <Box bg='white' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Filter 
        </Heading>
      </Flex>
      <Spacer h="2" bg='blackAlpha.50'/>

      <Flex
        flexWrap="wrap"
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        mr={8}
        ml={8}
        mt={8}
      >
        <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }}>
          <Text style={{ fontWeight: "bold" }}>From Date</Text>
          <Input 
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Flex>

        <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }}>
          <Text style={{ fontWeight: "bold" }}>To Date</Text>
          <Input 
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Flex>
      </Flex>

      <Spacer h="2" bg='blackAlpha.50'/>

      {loading && <Loader />}
      {error && <Message type="error">{error}</Message>}
      {success && <Message type="success">Bulk deletion successful</Message>}

      <Flex justify="center" mt={4}>
        <Button 
          colorScheme="gray" 
          bg='white'
          onClick={handleBulkDelete} 
          isLoading={loading} 
          color='red' 
          borderColor='red.500' 
          rounded='lg' 
          borderWidth='2px'
          >
          <FaTrash mr={2} /> 
          <Text ml={2}>Bulk Delete</Text>
        </Button>
      </Flex>

      <Spacer h="5" />
    </Box>
  );
};

export default OutboundBulkContacts;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  Textarea,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { GoSingleSelect } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment-timezone";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  getAgentClientContacts,
  updateClientContact,
  getClientPrefixByAgent,
  fetchCustomTags,
} from "../../actions/clientContactsActions";

const OutboundAgentContacts = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [agentDetails, setAgentDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedStatusInCampaign, setSelectedStatusInCampaign] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [description, setDescription] = useState("");
  const [client_name, setClientName] = useState("");
  const [customer_feedback, setCustomerFeedback] = useState("");
  const [location, setLocation] = useState("");
  const [callTag1, setCallTag1] = useState("");
  const [callTag2, setCallTag2] = useState("");
  const [callTag3, setCallTag3] = useState("");
  const [callTag4, setCallTag4] = useState("");
  const [callTag5, setCallTag5] = useState("");
  const [callTag6, setCallTag6] = useState("");
  const [customCallTags, setCustomCallTags] = useState({
    call_tag_1: "Call Tag 1",
    call_tag_2: "Call Tag 2",
    call_tag_3: "Call Tag 3",
    call_tag_4: "Call Tag 4",
    call_tag_5: "Call Tag 5",
    call_tag_6: "Call Tag 6",
    col_1: "Column 1",
    col_2: "Column 2",
    col_3: "Column 3",
    col_4: "Column 4",
    col_5: "Column 5",
    col_6: "Column 6",
    col_7: "Column 7",
    col_8: "Column 8",
    col_9: "Column 9",
  });

  const [searchTerm, setSearchTerm] = useState("");

  const agentClientContacts = useSelector((state) => state.agentClientContacts);
  const { loading: contactsLoading, error: contactsError, contacts } =
    agentClientContacts;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [selectedContact, setSelectedContact] = useState(null);
  const [newStatus, setNewStatus] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const { isOpen: isMoreOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const fetchAgentDetails = async () => {
      try {
        const { data } = await axios.get(
          `/api/admin/agents/details/${userInfo.id}`
        );
        setAgentDetails(data.data);
        const agentId = data.data.id;
        dispatch(getAgentClientContacts(agentId));
      } catch (error) {
        console.log(error);
      }
    };

    if (userInfo && userInfo.id) {
      fetchAgentDetails();
    }
  }, [dispatch, userInfo]);

  const clientPrefixByAgent = useSelector((state) => state.clientPrefixByAgent);
  const { clientsPrefix } = clientPrefixByAgent;

  const prefix =
    clientsPrefix && clientsPrefix[0] ? clientsPrefix[0].prefix : "";

  // Fetch custom tags when a campaign is selected in the "Filter by Campaign" tab
  useEffect(() => {
    if (userInfo && selectedCampaign) {
      const campaignId = contacts.find(
        (contact) => contact.campaign_name === selectedCampaign
      )?.campaign_id;
      if (campaignId) {
        dispatch(fetchCustomTags(userInfo.product_id, campaignId));
      }
    }
  }, [dispatch, userInfo, selectedCampaign, contacts]);

  // Update custom call tags when customTags state updates
  const customTagsState = useSelector((state) => state.customTagsFetch);
  const { customTags } = customTagsState;

  useEffect(() => {
    if (customTags && customTags.data && customTags.data.length > 0) {
      setCustomCallTags((prevTags) => ({
        ...prevTags,
        ...customTags.data[0],
      }));
    }
  }, [customTags]);

  const handleEdit = async (contact, newStatus) => {
    try {
      const agentId = agentDetails.id;
      const updatedContact = { ...contact, status: newStatus };

      if (newStatus === "Answered") {
        const updatedContactWithDescription = {
          ...updatedContact,
          description: description,
          client_name: client_name,
          customer_feedback: customer_feedback,
          location: location,
          callTag1: callTag1,
          callTag2: callTag2,
          callTag3: callTag3,
          callTag4: callTag4,
          callTag5: callTag5,
          callTag6: callTag6,
        };
        dispatch(updateClientContact(contact.id, updatedContactWithDescription))
          .then(() => {
            dispatch(getAgentClientContacts(agentId));
          });
      } else {
        dispatch(updateClientContact(contact.id, updatedContact)).then(() => {
          dispatch(getAgentClientContacts(agentId));
        });
      }

      setModalOpen(false);
    } catch (error) {
      console.error("Error updating contact:", error);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const product_id = userInfo?.product_id || "";
    const queue_id = product_id;
    dispatch(getClientPrefixByAgent(queue_id));
  }, [dispatch, userInfo]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    const phoneStr = String(phone);
    if (!phoneStr.trim()) return "";
    const fullPrefix = `${prefix}`;
    if (!phoneStr.startsWith(fullPrefix)) {
      return `${fullPrefix}${phoneStr}`;
    }
    return phoneStr;
  };

  const openStatusModal = (contact) => {
    setSelectedContact(contact);
    setNewStatus(contact.status);
    setDescription(contact.description || "");
    setClientName(contact.client_name || "");
    setCustomerFeedback(contact.customer_feedback || "");
    setLocation(contact.location || "");
    setCallTag1(contact.callTag1 || "");
    setCallTag2(contact.callTag2 || "");
    setCallTag3(contact.callTag3 || "");
    setCallTag4(contact.callTag4 || "");
    setCallTag5(contact.callTag5 || "");
    setCallTag6(contact.callTag6 || "");

    // Fetch custom tags for the selected campaign when action button is clicked
    const campaignId = contact.campaign_id; // Assuming `campaign_id` is part of the contact object
    if (campaignId) {
      dispatch(fetchCustomTags(userInfo.product_id, campaignId));
    }

    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const filterContactsByTab = () => {
    if (!contacts) return [];

    let filteredContacts = [];

    switch (activeTab) {
      case 1:
        filteredContacts = contacts.filter((contact) => {
          return (
            contact.campaign_name === selectedCampaign &&
            (!selectedStatusInCampaign ||
              contact.status === selectedStatusInCampaign)
          );
        });
        break;
      case 2:
        filteredContacts = contacts.filter(
          (contact) => contact.status === selectedStatus
        );
        break;
      default:
        filteredContacts = contacts;
        break;
    }

    // Apply search filter
    if (searchTerm.trim()) {
      filteredContacts = filteredContacts.filter((contact) =>
        String(contact.col_1)
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      );
    }

    // Sort the contacts to show "Not Called" first in the "All Contacts" tab
    if (activeTab === 0) {
      filteredContacts.sort((a, b) => {
        if (a.status === "Not Called" && b.status !== "Not Called") return -1;
        if (a.status !== "Not Called" && b.status === "Not Called") return 1;
        return 0;
      });
    }

    return filteredContacts;
  };

  const filteredContacts = filterContactsByTab();
  const currentContacts = filteredContacts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    // Reapply selected campaign and status after data is refetched
    setSelectedCampaign((prevCampaign) =>
      contacts.find((contact) => contact.campaign_name === prevCampaign)
        ? prevCampaign
        : ""
    );
    setSelectedStatusInCampaign((prevStatus) =>
      contacts.find(
        (contact) =>
          contact.status === prevStatus &&
          contact.campaign_name === selectedCampaign
      )
        ? prevStatus
        : ""
    );
    setSelectedStatus((prevStatus) =>
      contacts.find((contact) => contact.status === prevStatus)
        ? prevStatus
        : ""
    );
  }, [contacts, selectedCampaign]);

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize="lg" mb="5" ml={8} mt={4}>
          Available Contacts
        </Heading>
        <Input
          placeholder="Search by Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          maxWidth="300px"
          borderColor="blue.400"
          borderWidth="2px"
          mr={8}
        />
      </Flex>

      <Tabs index={activeTab} onChange={setActiveTab}>
        <TabList>
          <Tab>All Contacts</Tab>
          <Tab>Filter by Campaign</Tab>
          <Tab>Filter by Status</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* No additional filter needed */}
          </TabPanel>

          <TabPanel>
            <Flex m={4} alignItems="center">
              <Select
                placeholder="Select Campaign"
                value={selectedCampaign}
                onChange={(e) => setSelectedCampaign(e.target.value)}
                ml={8}
                maxWidth="300px"
                borderColor="blue.400"
                borderWidth="2px"
              >
                {[...new Set(contacts?.map((contact) => contact.campaign_name))]
                  .map((campaign, index) => (
                    <option key={index} value={campaign}>
                      {campaign}
                    </option>
                  ))}
              </Select>
              <Select
                placeholder="Select Status"
                value={selectedStatusInCampaign}
                onChange={(e) => setSelectedStatusInCampaign(e.target.value)}
                ml={4}
                maxWidth="300px"
                borderColor="blue.400"
                borderWidth="2px"
                isDisabled={!selectedCampaign}
              >
                <option value="Not Called">Not Called</option>
                <option value="Not Answered">Not Answered</option>
                <option value="Answered">Answered</option>
                <option value="Restricted">Restricted</option>
                <option value="Unreachable">Unreachable</option>
                <option value="Not Exist">Not Exist</option>
                <option value="Busy">Busy</option>
                <option value="Not In Service">Not In Service</option>
              </Select>
            </Flex>
          </TabPanel>

          <TabPanel>
            <Flex m={4}>
              <Select
                placeholder="Select Status"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                ml={8}
                maxWidth="300px"
                borderColor="blue.400"
                borderWidth="2px"
              >
                <option value="Not Called">Not Called</option>
                <option value="Not Answered">Not Answered</option>
                <option value="Answered">Answered</option>
                <option value="Restricted">Restricted</option>
                <option value="Unreachable">Unreachable</option>
                <option value="Not Exist">Not Exist</option>
                <option value="Busy">Busy</option>
                <option value="Not In Service">Not In Service</option>
              </Select>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {contactsLoading ? (
        <Loader />
      ) : contactsError ? (
        <Message type="error">{contactsError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Campaign</Th>
                <Th>Phone</Th>
                <Th>Agent</Th>
                <Th>{`${customCallTags.col_2 || "Col 2"} (Col 2)`}</Th>
                <Th>{`${customCallTags.col_3 || "Col 3"} (Col 3)`}</Th>
                <Th>{`${customCallTags.col_4 || "Col 4"} (Col 4)`}</Th>
                <Th>{`${customCallTags.col_5 || "Col 5"} (Col 5)`}</Th>
                <Th>{`${customCallTags.col_8 || "Col 8"} (Col 8)`}</Th>
                <Th>Date Uploaded</Th>
                <Th>Date Updated</Th>
                <Th>Action Status</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentContacts && currentContacts.length > 0 ? (
                currentContacts.map((contact, index) => (
                  <Tr key={index} bg="white">
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{contact.campaign_name}</Td>
                    <Td>
                      <a href={`tel:${formatPhoneNumber(contact.col_1)}`}>
                        {formatPhoneNumber(contact.col_1)}
                      </a>
                    </Td>
                    <Td>{contact.agent_name || "Agent Not Found"}</Td>
                    <Td>{contact.col_2}</Td>
                    <Td>{contact.col_3}</Td>
                    <Td>{contact.col_4}</Td>
                    <Td>{contact.col_5}</Td>
                    <Td>{contact.col_8}</Td>
                    <Td>
                      {moment(contact.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}
                    </Td>
                    <Td>
                      {moment(contact.updated_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}
                    </Td>
                    <Td>
                      <Button
                        size="sm"
                        bg="blue.500"
                        color="white"
                        _hover={{ bg: "brandBlue" }}
                        onClick={() => openStatusModal(contact)}
                        rightIcon={<GoSingleSelect />}
                      >
                        {contact.status}
                      </Button>
                    </Td>
                    <Td>{contact.description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="13" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(indexOfLastItem, filteredContacts.length)} of{" "}
              {filteredContacts.length} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {filteredContacts.length > 0 &&
                  [...Array(Math.ceil(filteredContacts.length / itemsPerPage))].map(
                    (_, index) => (
                      <React.Fragment key={index}>
                        {index + 1 === 1 ||
                        (index + 1 >= currentPage - 2 &&
                          index + 1 <= currentPage + 2) ||
                        index + 1 ===
                          Math.ceil(filteredContacts.length / itemsPerPage) ? (
                          <Button
                            key={index}
                            variant={
                              currentPage === index + 1 ? "solid" : "outline"
                            }
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Button>
                        ) : index + 1 === currentPage - 3 ||
                          index + 1 === currentPage + 3 ? (
                          <Box key={index}>...</Box>
                        ) : null}
                      </React.Fragment>
                    )
                  )}
                <Button
                  variant="outline"
                  isDisabled={
                    currentPage === Math.ceil(filteredContacts.length / itemsPerPage)
                  }
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Contact Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mt="4">
              <FormLabel>Status</FormLabel>
              <Select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                style={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  borderColor: "gray",
                }}
              >
                <option value="Not Called">Not Called</option>
                <option value="Not Answered">Not Answered</option>
                <option value="Answered">Answered</option>
                <option value="Restricted">Restricted</option>
                <option value="Unreachable">Unreachable</option>
                <option value="Not Exist">Not Exist</option>
                <option value="Busy">Busy</option>
                <option value="Not In Service">Not In Service</option>
              </Select>
            </FormControl>

            {newStatus === "Answered" && (
              <Box mt={4}>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter description..."
                    size="lg"
                    resize="vertical"
                  />
                </FormControl>
              </Box>
            )}

            {isMoreOpen && (
              <SimpleGrid columns={2} spacing={4} mt={4}>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Input
                    placeholder="Client Name"
                    value={client_name}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Customer Feedback</FormLabel>
                  <Select
                    name="customer_feedback"
                    value={customer_feedback}
                    onChange={(e) => setCustomerFeedback(e.target.value)}
                  >
                    <option value="Positive">Positive</option>
                    <option value="Negative">Negative</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Location</FormLabel>
                  <Input
                    placeholder="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_1 || "Call Tag 1"} (Call Tag 1)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_1 || "Call Tag 1"}
                    value={callTag1}
                    onChange={(e) => setCallTag1(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_2 || "Call Tag 2"} (Call Tag 2)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_2 || "Call Tag 2"}
                    value={callTag2}
                    onChange={(e) => setCallTag2(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_3 || "Call Tag 3"} (Call Tag 3)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_3 || "Call Tag 3"}
                    value={callTag3}
                    onChange={(e) => setCallTag3(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_4 || "Call Tag 4"} (Call Tag 4)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_4 || "Call Tag 4"}
                    value={callTag4}
                    onChange={(e) => setCallTag4(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_5 || "Call Tag 5"} (Call Tag 5)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_5 || "Call Tag 5"}
                    value={callTag5}
                    onChange={(e) => setCallTag5(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{`${customCallTags.call_tag_6 || "Call Tag 6"} (Call Tag 6)`}</FormLabel>
                  <Input
                    placeholder={customCallTags.call_tag_6 || "Call Tag 6"}
                    value={callTag6}
                    onChange={(e) => setCallTag6(e.target.value)}
                  />
                </FormControl>
              </SimpleGrid>
            )}
          </ModalBody>

          <ModalFooter>
            <Stack spacing={2} direction="row" align="center">
              {newStatus === "Answered" && (
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    handleEdit(selectedContact, newStatus);
                    window.location.href = "/outbound/call-logs";
                  }}
                >
                  Disposition ?
                </Button>
              )}
              {newStatus === "Answered" && (
                <Button onClick={onToggle} colorScheme="purple">
                  {isMoreOpen ? "Less" : "More"}
                </Button>
              )}
              <Button
                colorScheme="green"
                onClick={() => handleEdit(selectedContact, newStatus)}
              >
                Update
              </Button>
              <Button onClick={handleCloseModal} colorScheme="red">
                Cancel
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Spacer h="5" />
    </Box>
  );
};

export default OutboundAgentContacts;

import React, { useState } from 'react';
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

const GuideScreen = () => {
  const [guideSearch, setGuideSearch] = useState('');
  const [faqSearch, setFaqSearch] = useState('');

  const guides = [
    {
      title: 'Introduction',
      content: [
        'Purpose: This guide aims to help agents navigate and utilize Zuridesk efficiently to provide excellent customer support.',
        'Overview: Zuridesk is a customer service platform that offers ticket management and reporting features to help manage and resolve customer inquiries.',
      ],
    },
    {
      title: 'Getting Started',
      content: [
        'Visit your Zuridesk portal.',
        'Click log-in.',
        'Enter your correct credentials and log-in.',
      ],
    },
    {
      title: 'Abandoned Calls',
      content: [
        'Abandoned calls are those that are terminated by the caller before they are answered or connected to an agent. This typically happens when the caller hangs up before an agent picks up, or before the call reaches the queue or response system.',
        'Click on the Abandoned calls and a list of the calls will be displayed.',
        'Copy the Caller’s number and call it on your Microsip extension.',
        'Action the call as answered and when redirected to outbound calls disposition the call from there.',
      ],
    },
    {
      title: 'Notifications Bell',
      content: [
        'Escalated Tickets: View escalated ticket alerts by checking the “Notifications” bell.',
        'Scheduled Callbacks: Find notifications for scheduled callbacks in the “Notifications” bell.',
        'Tips: Check notifications regularly to stay updated and act promptly to handle issues efficiently.',
      ],
    },
    {
      title: 'Dashboard',
      content: [
        'Inbound Today Stats: Locate and view stats such as the number of inbound tickets answered, not answered, talk time, and average handling time.',
        'Outbound Today Stats: Locate and view stats such as the number of outbound calls answered, not answered, unreachable, or not in service.',
        'Using the Stats: Evaluate and adjust based on insights to optimize response times and resource allocation.',
      ],
    },
    {
      title: 'Products',
      content: [
        'Locate Product: Found on the side bar of the Zuridesk portal.',
        'Details Shown: Product code and date created.',
      ],
    },
    {
      title: 'Calls',
      content: [
        'Inbound Calls: View details of the most recent inbound call, search for past calls made by the customer, and access a comprehensive log of all inbound calls.',
        'Outbound Calls: Access a list of contacts for outbound calling, review outbound call logs, and monitor call activity.',
        'Using Call Logs: Disposition calls, review history, and analyze call outcomes.',
      ],
    },
    {
      title: 'Escalated Calls',
      content: [
        'Viewing Escalated Calls: View details of calls that need higher-level attention or follow-up.',
        'Managing Escalated Calls: Review call history, respond to escalations, update status, and follow up to ensure resolution.',
        'Utilizing Escalated Call Data: Track performance and improve processes based on escalated calls.',
      ],
    },
    {
      title: 'Scheduled Callbacks',
      content: [
        'Viewing Scheduled Callbacks: View a list of scheduled callbacks and details such as scheduled time, customer information, and disposition details.',
        'Managing Scheduled Callbacks: Prepare for the call, make the call at the scheduled time, and update the status accordingly.',
        'Utilizing Callback Data: Track performance and optimize scheduling practices based on callback outcomes and customer feedback.',
      ],
    },
    {
      title: 'Best Practices',
      content: [
        'Timeliness: Respond to escalated calls promptly, make scheduled callbacks on time, and update statuses regularly.',
        'Clarity: Ensure your responses are clear and concise when dispositioning.',
        'Customer Focus: Always prioritize the customer’s needs and provide solutions that address their concerns.',
      ],
    },
    {
      title: 'Troubleshooting',
      content: [
        'Common Issues: Resolve login problems by ensuring correct credentials and checking for system outages.',
        'Errors when using the portal: Refresh your browser and clear your browsing history.',
        'Getting Help: Contact your Supervisor or refer to Zuridesk’s support documentation.',
      ],
    },
  ];  

  const faqs = [
    {
      title: 'How do I login to Zuridesk?',
      content: [
        'Go to your Zuridesk portal on the top right corner you will see the log-in button click on it and enter your correct credentials i.e email and password.',
      ],
    },
    {
      title: 'At what point should I escalate a call when dispositioning an inbound call?',
      content: [
        'You should escalate a call if you, as the agent, are unable to handle the customer’s issue. In such cases, disposition the call as escalated.',
      ],
    },
    {
      title: 'Can I be reminded of a scheduled callback when I have heavy traffic?',
      content: [
        'Yes, a notification of the scheduled callback will appear on the notification bell.',
      ],
    },
    {
      title: 'What should I do if I need to escalate a call?',
      content: [
        'When dispositioning a call that needs to be escalated, mark the call as ‘escalated’ and indicate that the issue has not been fully resolved. In the description, specify the issue the customer was calling about.',
      ],
    },
    {
      title: 'How do I disposition a call?',
      content: [
        'In the call logs, for both inbound and outbound calls, click on the ‘disposition’ button. This will display a disposition modal. Enter the customer details and save the changes.',
      ],
    },
    {
      title: 'Why does my Zuridesk portal display an error of Not Authorized, Token Failed?',
      content: [
        'If your Zuridesk portal displays an error saying ‘Not Authorized, Token Failed,’ it may be because you took too long to log in or did not log out properly. To resolve this, clear your browser history for the past 7 days, then try logging in again.',
      ],
    },
    {
      title: 'At what point do I disposition a call?',
      content: [
        'In the inbound call logs, there are three sections: Latest call, Previous Customer Call History, and Call History. The ‘Latest’ section includes either the call you are currently on or the most recent call you handled. Disposition the call in the ‘Latest call’ section to prevent calls from piling up.',
      ],
    },
    {
      title: 'How do I disposition an outbound contact?',
      content: [
        'When dispositioning an outbound contact, there are 2 ways to do so:',
        '1. Directly from the Disposition Modal: After you action the contact as answered, a disposition modal will appear. This modal will show the status you selected and a blank description field. Enter brief and precise information in the description box and save the change.',
        '2. Using the Disposition Button: After actioning the contact as answered, the disposition modal will appear with the status, a blank description box, and a disposition button. Click the disposition button to be redirected to the outbound call logs. In the outbound call logs, enter the customer details and save the changes.',
      ],
    },
    {
      title: 'Can I disposition a call twice, that is if I make a mistake while dispositioning a call?',
      content: [
        'No, you cannot edit or disposition a call twice. Once you save the changes, the details are captured and cannot be undone. Please be cautious while dispositioning a call to avoid mistakes.',
      ],
    },
    {
      title: 'Why do Inbound Call Stats differ from Disposition Stats?',
      content: [
        'The discrepancy occurs because you might be answering and interacting with calls without dispositioning them. As a result, the numbers for inbound calls and dispositions may not match. Ensure all calls are properly dispositioned to align the stats.',
      ],
    },
    {
      title: 'Why are my Escalated Calls’ Escalation Step and Description blank?',
      content: [
        'The escalation step and description may be blank because the support user has not yet actioned the calls. Once the support user takes action, you will receive a notification, and the escalation step and description will be updated accordingly.',
      ],
    },
    {
      title: 'What are Abandoned calls?',
      content: [
        'Abandoned calls are those that are terminated by the caller before they are answered or connected to an agent. This typically happens when the caller hangs up before an agent picks up, or before the call reaches the queue or response system.',
      ],
    },
    {
      title: 'How do I call an Abandoned call?',
      content: [
        'Click on the "Abandoned Calls" section at the top of the Zuridesk portal. Find the caller’s number and copy it. Use your Microsip extension to dial the copied number and return the call.',
      ],
    },
    {
      title: 'Can I disposition an Abandoned call?',
      content: [
        'Yes, you can. After you call an abandoned call, action it as answered. This will redirect you to the outbound call logs, where you can disposition the call as you would with any other outbound call.',
      ],
    },
    {
      title: 'Why do I always have numbers on my notification bell?',
      content: [
        'The numbers on your notification bell will accumulate if you do not mark the notifications as read. To prevent this, make sure to review and clear the notifications regularly.',
      ],
    },
    {
      title: 'Why do my scheduled callbacks differ from my colleagues’?',
      content: [
        'The difference occurs because you may not be actioning your scheduled callbacks. If you action a scheduled callback as answered, it will disappear from your list, as it has been completed. Ensure you action your callbacks to keep your schedule up to date and aligned with your colleagues’.',
      ],
    },
    {
      title: 'How do I log-out of Zuridesk portal?',
      content: [
        'Click on the profile icon on the top right and select the "Logout" option from the dropdown menu.',
      ],
    },
  ];
  
  const filteredGuides = guides.filter(guide =>
    guide.title.toLowerCase().includes(guideSearch.toLowerCase())
  );

  const filteredFaqs = faqs.filter(faq =>
    faq.title.toLowerCase().includes(faqSearch.toLowerCase())
  );

  return (
   <Box p="4" bg="white" borderRadius="lg" boxShadow="md" m={2} width="100%">
    <Text fontSize="xl" fontWeight="bold" mb="4">
      System Guide
    </Text>
    <Tabs mt="4" isLazy>
      <TabList>
        <Tab>Guide</Tab>
        <Tab>FAQs</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Input
            type="text"
            placeholder="Search Guide"
            value={guideSearch}
            onChange={(e) => setGuideSearch(e.target.value)}
            mb="4"
          />
          <Accordion defaultIndex={[0]} allowMultiple>
            {filteredGuides.map((guide, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="semibold">
                      {guide.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <VStack align="start">
                    {guide.content.map((line, i) => (
                      <Text key={i}>{line}</Text>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </TabPanel>
        <TabPanel>
          <Input
            type="text"
            placeholder="Search FAQs"
            value={faqSearch}
            onChange={(e) => setFaqSearch(e.target.value)}
            mb="4"
          />
          <Accordion defaultIndex={[0]} allowMultiple>
            {filteredFaqs.map((faq, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left" fontWeight="semibold">
                      {faq.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <VStack align="start">
                    {faq.content.map((line, i) => (
                      <Text key={i}>{line}</Text>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>  
  );
};

export default GuideScreen;

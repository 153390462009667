import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";

import { listClientDetails } from "../../actions/clientsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const ProductScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const clientsDetails = useSelector((state) => state.clientsDetails);
  const { loading, error, client } = clientsDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  
  useEffect(() => {
    console.log('Inside useEffect - ClientViewScreen');

    if (!userInfo) {
      console.log('Redirecting to login...');
      navigate("/login");
    }

  }, [userInfo, navigate]);

  useEffect(() => {
    console.log('Dispatching listClientDetails...');
    if (userInfo && id) {
      dispatch(listClientDetails(id));
    }
  }, [dispatch, userInfo, id]);
  
  
  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={2}>
      <Heading as="h1" fontSize='xl' mb="5" ml={8} mt={4}>
        Product Details
      </Heading>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th borderBottom="1px" borderColor="gray.300">Name</Th>
                <Th borderBottom="1px" borderColor="gray.300">Facebook ID</Th>
                <Th borderBottom="1px" borderColor="gray.300">Date Created</Th>
              </Tr>
            </Thead>
            <Tbody>
            <Tr>
              {client ? (
                <>
                  <Td>{client.name}</Td>
                  <Td>{client.facebook_page_id}</Td>
                  <Td>
                    {moment(client.created_at)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>                  
                </>
              ) : (
                <Td colSpan="6">Client details not available.</Td>
              )}
            </Tr>
            </Tbody>
          </Table>
        </Box>
      )}  

      
      <Spacer h="5" />
    </Box>
  );
};

export default ProductScreen;

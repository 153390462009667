import {
  Container,
  Heading,
  Flex,
  Stack,
  Text,
  Box,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { FaCheckCircle, FaPlay } from "react-icons/fa";

const AboutUsSection = () => {
  const playVideo = () => {
    const iframe = document.getElementById("youtube-video");
    iframe.src += "?autoplay=1";
  };

  return (
    <Box mx="auto" width={{ base: "90%", md: "80%" }}>
      <Container maxWidth="xlg" mx="auto" data-aos="fade-up">
        <Stack spacing={8} align="center">
          <br />
          <Heading fontFamily="'Lato', sans-serif" mb={4} size="xl">
            About Us
          </Heading>
          <Text
            fontFamily="'Open Sans', sans-serif"
            mb={2}
            fontSize="18px"
            lineHeight="30px"
            letterSpacing="0.5px"
            align="center"
          >
            We help businesses realize excellence in Customer Service by offering
            them an easy-to-use Zuridesk platform that does what it is required
            to do.
          </Text>
          <Stack spacing={4} direction={{ base: "column", lg: "row" }}>
            <Box flex={1}>
              <Heading
                fontFamily="'Lato', sans-serif"
                fontSize="26px"
                fontWeight="bold"
                color="#1e1e1e"
                lineHeight="40px"
                marginBottom="25px"
                letterSpacing="0.5px"
              >
                Zuridesk Platform Value To Your Business
              </Heading>
              <Text
                marginBottom="30px"
                fontSize="16px"
                fontFamily="'Open Sans', sans-serif"
                lineHeight="30px"
                letterSpacing="0.5px"
              >
                Zuridesk Platform will clearly mark out pitfalls in your customer
                service delivery and provide decision support information you
                require to make decisions around better organization of your
                business processes to ensure that your business is reachable
                whenever needed by its customers.
              </Text>
              <Text
                marginBottom="30px"
                fontSize="16px"
                lineHeight="30px"
                fontFamily="'Open Sans', sans-serif"
                letterSpacing="0.5px"
              >
                Zuridesk will help you manage your customer service workforce without
                the need for physical supervision. It will also assist your human
                resource department in formulating and measuring performance through
                clearly marked out reports to aid KPI and OKR tracking in your customer
                service department.
              </Text>
              <Text
                marginBottom="30px"
                fontSize="16px"
                lineHeight="30px"
                fontFamily="'Open Sans', sans-serif"
                letterSpacing="0.5px"
              >
                Zuridesk enables your outbound team to conduct revenue-driven customer
                campaigns. It gives you visibility of your outbound team so that you can
                track their performance in real-time as they engage with customers.
              </Text>
            </Box>
            <Box flex={1} pl={{ base: 0, lg: 5 }} ml={8}>
              <Heading
                fontFamily="'Lato', sans-serif"
                fontSize="26px"
                fontWeight="bold"
                color="#1e1e1e"
                lineHeight="40px"
                mb={8}                
                letterSpacing="0.5px"
              >
                Zuridesk Platform Value to your B2C
              </Heading>
              <Text
                marginBottom="30px"
                fontSize="16px"
                lineHeight="30px"
                fontFamily="'Open Sans', sans-serif"
                color="#1e1e1e"
                letterSpacing="0.5px"
                fontStyle="italic"
              >
                Zuridesk platform empowers your business through customer information
                gathering that is key for your customer mapping and customer experience
                analysts by providing features to conduct or improve your:
              </Text>
              <UnorderedList listStyleType="none" ml="0">
                <ListItem>
                  <Flex align="center">
                    <FaCheckCircle color="blue.500" fontSize="18px" />
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      fontFamily="'Open Sans', sans-serif"
                      color="#1e1e1e"
                      marginLeft="10px"
                    >
                      KYC (Know Your Customer) Campaign.
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex align="center">
                    <FaCheckCircle color="blue.500" fontSize="18px" />
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      fontFamily="'Open Sans', sans-serif"
                      color="#1e1e1e"
                      marginLeft="10px"
                    >
                      Sales Funnel.
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex align="center">
                    <FaCheckCircle color="blue.500" fontSize="18px" />
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      fontFamily="'Open Sans', sans-serif"
                      color="#1e1e1e"
                      marginLeft="10px"
                    >
                      Customer Journey.
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex align="center">
                    <FaCheckCircle color="blue.500" fontSize="18px" />
                    <Text
                      fontSize="18px"
                      fontWeight="medium"
                      fontFamily="'Open Sans', sans-serif"
                      color="#1e1e1e"
                      marginLeft="10px"
                    >
                      Customer Retention.
                    </Text>
                  </Flex>
                </ListItem>
              </UnorderedList>
              <Text
                marginBottom="30px"
                fontSize="16px"
                lineHeight="30px"
                fontFamily="'Open Sans', sans-serif"
                letterSpacing="0.5px"
              >
                Through the Zuridesk solution, your business can seamlessly interact
                with your customers on a 24/7 basis and have access to real-time and
                historical business intelligence analytics reports of how your customers
                were served, whether on a remote basis or from the office.
              </Text>
            </Box>
          </Stack>
          <Box
            mx="auto"
            textAlign="center"
            maxW="100%"
            mt={8}
            mb={8}
            position="relative"
          >
            <Box position="relative" width="100%" height="500px">
              <iframe
                id="youtube-video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/8HgfdWOpbmo"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <Box
                as={FaPlay}
                color="blue.500"
                fontSize="48px"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                cursor="pointer"
                zIndex="1"
                onClick={playVideo}
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default AboutUsSection;


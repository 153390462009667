import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Button,
  HStack,
  VStack,
  Link,
  Text,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import { FiRadio, FiLayout, FiMessageSquare, FiLink } from "react-icons/fi";
import { AddIcon } from "@chakra-ui/icons";

import HaziWhatsappPhone from "./HaziWhatsappPhone";

const HaziWhatsappMsgTemp = () => {
  const [formData, setFormData] = useState({
    templateName: "",
    category: "",
    language: "",
    headerType: "none",
    headerContent: "",
    body: "",
    footer: "",
    buttonType: "none",
    buttons: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddButton = () => {
    if (formData.buttonType === "quick-reply") {
      setFormData({
        ...formData,
        buttons: [...formData.buttons, { type: "quick-reply", label: "" }],
      });
    } else if (formData.buttonType === "call-to-action") {
      setFormData({
        ...formData,
        buttons: [...formData.buttons, { type: "call-to-action", action: "" }],
      });
    }
  };

  const handleButtonChange = (index, field, value) => {
    const updatedButtons = [...formData.buttons];
    updatedButtons[index][field] = value;
    setFormData({ ...formData, buttons: updatedButtons });
  };

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      {/* Header/Navbar */}
      <Box bg="blue.500" color="white" py={4} px={6}>
        <Flex justify="space-between" align="center">
          <HStack spacing={8}>
            <Link href="/trial/socials-h/whatsapp" display="flex" alignItems="center">
              <FiRadio />
              <Text ml={2}>Broadcast</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-template" display="flex" alignItems="center">
              <FiLayout />
              <Text ml={2}>Message Template</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-chat" display="flex" alignItems="center">
              <FiMessageSquare />
              <Text ml={2}>Chat Box</Text>
            </Link>
          </HStack>
          <Link href="/trial/socials-h/whatsapp-accounts" display="flex" alignItems="center">
            <FiLink />
            <Text ml={2}>Connected Accounts</Text>
          </Link>
        </Flex>
      </Box>


      {/* Breadcrumb */}
      <Box px={8} py={4}>
        <Text fontSize="sm" color="gray.600">
          <Link href="/trial/socials-h/dashboard" color="blue.500">Dashboard</Link> / 
          <Link href="/trial/socials-h/whatsapp" color="blue.500"> WhatsApp</Link> / Message Templates
        </Text>
      </Box>

      {/* Main Content */}
      <Flex direction={{ base: "column", md: "row" }} p={4} gap={6}>
        {/* Left Section: Form */}
        <Box flex="1" p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Template Name</FormLabel>
              <Input
                name="templateName"
                placeholder="Enter template name"
                value={formData.templateName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                <option value="authentication">Authentication</option>
                <option value="marketing">Marketing</option>
                <option value="utility">Utility</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Language</FormLabel>
              <Select
                name="language"
                value={formData.language}
                onChange={handleChange}
              >
                <option value="">Select Language</option>
                <option value="en">English</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="sw">Swahili</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Header</FormLabel>
              <Select
                name="headerType"
                value={formData.headerType}
                onChange={handleChange}
              >
                <option value="none">None</option>
                <option value="text">Text</option>
                <option value="media">Media</option>
              </Select>
              {formData.headerType === "text" && (
                <Input
                  mt={2}
                  name="headerContent"
                  placeholder="Enter header text"
                  value={formData.headerContent}
                  onChange={handleChange}
                />
              )}
              {formData.headerType === "media" && (
                <Input
                  mt={2}
                  type="file"
                  name="headerContent"
                  onChange={(e) =>
                    setFormData({ ...formData, headerContent: e.target.files[0] })
                  }
                />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Body</FormLabel>
              <Textarea
                name="body"
                placeholder="Enter message body"
                value={formData.body}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Footer</FormLabel>
              <Input
                name="footer"
                placeholder="Enter footer text"
                value={formData.footer}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Buttons</FormLabel>
              <Select
                name="buttonType"
                value={formData.buttonType}
                onChange={handleChange}
              >
                <option value="none">None</option>
                <option value="quick-reply">Quick Reply</option>
                <option value="call-to-action">Call to Action</option>
              </Select>
              {formData.buttonType !== "none" && (
                <Button
                  mt={2}
                  onClick={handleAddButton}
                  colorScheme="blue"
                  size="sm"
                >
                  Add Button
                </Button>
              )}
              {formData.buttons.map((button, index) => (
                <HStack key={index} mt={2} spacing={2}>
                  <Input
                    placeholder={
                      button.type === "quick-reply"
                        ? "Quick Reply Label"
                        : "Call-to-Action Label"
                    }
                    value={button.label || button.action}
                    onChange={(e) =>
                      handleButtonChange(index, "label", e.target.value)
                    }
                  />
                  <IconButton
                    icon={<AddIcon />}
                    size="sm"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        buttons: formData.buttons.filter((_, i) => i !== index),
                      })
                    }
                  />
                </HStack>
              ))}
            </FormControl>
            <Button colorScheme="teal" size="lg" mt={4}>
              Save Template
            </Button>
          </VStack>
        </Box>

        {/* Right Section: Phone Preview */}
        <Box
          flex="1"
          p={4}
          borderWidth="1px"
          borderRadius="md"
          bg="gray.50"
          textAlign="center"
        >
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Preview
          </Text>
          <HaziWhatsappPhone />
         
          <Divider my={2} />
            
        </Box>
      </Flex>

  </Box>
  );
};


export default HaziWhatsappMsgTemp;

import React, { useEffect, useState } from "react";
import {  
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spacer,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton,
  ModalBody, 
  ModalFooter, 
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";

import { listqueLog } from '../../actions/queLogActions';
import { getProductCallHistory } from "../../actions/callDispositionActions";
import InboundCallDisposition from "./InboundCallDisposition";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const InboundCallLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCall, setSelectedCall] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const productInboundCallHistory = useSelector((state) => state.productInboundCallHistory);
  const { loading: callHistoryLoading, error: callHistoryError, productCallHistory } = productInboundCallHistory;

  const queLogList = useSelector((state) => state.queLogList);
  const { loading: queLogLoading, error: queLogError, queLog } = queLogList || {};

  const agentQueuelog = queLog && queLog.queuelog
    ? queLog.queuelog
        .map((log) => ({ ...log, id: log.id })) 
        .slice(indexOfFirstItem, Math.min(indexOfLastItem, queLog.queuelog.length))
    : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      const prodCode = userInfo.product_id;
      
      dispatch(getProductCallHistory(prodCode, searchInput || undefined))
        .catch((error) => {
          console.error('Error fetching call history:', error);
        });
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, searchInput, navigate]);

  useEffect(() => {
    if (userInfo) {  
      dispatch(listqueLog());  
    } else {  
      navigate("/login");  
    }  
  }, [dispatch, navigate, userInfo]);

  const [logDispositionInfo, setLogDispositionInfo] = useState({});

  useEffect(() => {  
    const fetchDispositionForLog = async (log) => {
      try {
        const response = await axios.get(`/api/queuelog/dispose/${log.id}`);
        const { dispositioned, reason, description } = response.data;
        setLogDispositionInfo((prevState) => ({
          ...prevState,
          [log.id]: { dispositioned, reason, description }
        }));
      } catch (error) {
        setError('Error fetching call disposition');
      }
    };

    const fetchDispositionForAllLogs = async () => {
      if (queLog && queLog.queuelog && queLog.queuelog.length > 0) {
        queLog.queuelog.forEach((log) => {
          fetchDispositionForLog(log);
        });
      } else {
        console.log('queLog or queLog.queuelog is missing or empty');
        setLoading(false); 
      }
    };

    fetchDispositionForAllLogs();
  }, [queLog]);

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  if (loading) {
    return <Text>Loading...</Text>;
  }

  const handleDispositionClick = (callData) => {
    setSelectedCall(callData);
    onOpen();
  };

  return (
    <Box p="4" m={2} alignItems="center" width={{ base: '100%', md: '100%' }} bg="white" borderRadius="md">
      <Box m={6}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Latest Call
        </Text>
      </Box>

      {queLogLoading ? (
        <Loader />
      ) : queLogError ? (
        <Message type="error">{queLogError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Trx Id</Th>
                <Th>Product</Th>
                <Th>MSISDN</Th>
                <Th>Date Created</Th>
                <Th>Action / Reason</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agentQueuelog.length > 0 ? (
                <Tr key={agentQueuelog[0].trx_id} bg="white">
                  <Td>1</Td>
                  <Td>{agentQueuelog[0].trx_id}</Td>
                  <Td>{agentQueuelog[0].queue_id}</Td>
                  <Td>{agentQueuelog[0].msisdn}</Td>
                  <Td>
                    {moment(agentQueuelog[0].created)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>
                  <Td>
                    {logDispositionInfo[agentQueuelog[0].id] && logDispositionInfo[agentQueuelog[0].id].dispositioned ? (
                      <Box>
                        <Text fontWeight="semibold">
                          Reason: <Text as="span" fontWeight="normal">{logDispositionInfo[agentQueuelog[0].id].reason}</Text>
                        </Text>
                        <Text fontWeight="semibold">
                          Description: <Text as="span" fontWeight="normal">{logDispositionInfo[agentQueuelog[0].id].description}</Text>
                        </Text>
                      </Box>
                    ) : (
                      <Button
                        size="sm"
                        variant="outline"
                        colorScheme="primary"
                        onClick={() => handleDispositionClick({
                          trx_id: agentQueuelog[0].trx_id,
                          agent: agentQueuelog[0].agent,
                          queue_id: agentQueuelog[0].id,
                          msisdn: agentQueuelog[0].msisdn,
                          product_id: agentQueuelog[0].queue_id,
                        })}
                        _hover={{ bg: 'primary.500', color: 'blue.500' }}
                      >
                        Disposition
                      </Button>
                    )}
                  </Td>
                </Tr>
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      )}

      <Box m={6} mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Previous Customer Call History
        </Text>
        <Flex m={4}>
          <Text fontSize="md" fontWeight="semibold" mb="4">
            Enter the number you want to search:
          </Text>
          <Input
            type="text"
            placeholder="Enter phone digits"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}          
            ml={8}
            maxWidth='300px'
            borderColor='blue.400'
            borderWidth='2px'
          />
        </Flex>
        {callHistoryLoading ? (
          <Loader />
        ) : callHistoryError ? (
          <Message type="error">{callHistoryError}</Message>
        ) : (
          <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Attended by</Th>
                  <Th>Caller Name</Th>
                  <Th>Caller No</Th>
                  <Th>Caller Location</Th>
                  <Th>Date Created</Th>
                  <Th>Reason</Th>
                  <Th>Decription</Th>
                </Tr>
              </Thead>
              <Tbody>
              {productCallHistory && productCallHistory.length > 0 ? (
                productCallHistory.map((log, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{log.Attendedby}</Td>
                    <Td>{log.ClientName}</Td>
                    <Td>{log.Caller}</Td>
                    <Td>{log.CallerLocation}</Td>
                    <Td>
                      {moment(log.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>{log.CallerReason}</Td>
                    <Td>{log.Description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="8" textAlign="center">No data available</Td>
                </Tr>
              )}
              </Tbody>
            </Table>
          </Box>
        )}        
      </Box>
      
      <Spacer h='5' bgColor="blackAlpha.100" />
      
      <Box m={6}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Call History
        </Text>
      </Box>

      {queLogLoading ? (
        <Loader />
      ) : queLogError ? (
        <Message type="error">{queLogError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Trx Id</Th>
                <Th>Product</Th>
                <Th>MSISDN</Th>
                <Th>Date Created</Th>
                <Th>Action / Reason</Th>
              </Tr>
            </Thead>
            <Tbody>
            {agentQueuelog.length > 0 ? (
                  agentQueuelog.map((log, index) => (
                <Tr key={log.trx_id} bg="white">
                  <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                  <Td>{log.trx_id}</Td>
                  <Td>{log.queue_id}</Td>
                  <Td>{log.msisdn}</Td>
                  <Td>
                    {moment(log.created)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>
                  <Td>
                      {logDispositionInfo[log.id] && logDispositionInfo[log.id].dispositioned ? (
                          <Box>
                              <Text fontWeight="semibold">
                                  Reason: <Text as="span" fontWeight="normal">{logDispositionInfo[log.id].reason}</Text>
                              </Text>
                              <Text fontWeight="semibold">
                                  Description: <Text as="span" fontWeight="normal">{logDispositionInfo[log.id].description}</Text>
                              </Text>
                          </Box>
                      ) : (
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="primary"
                          onClick={() => handleDispositionClick({
                            trx_id: log.trx_id,
                            agent: log.agent,
                            queue_id: log.id,
                            msisdn: log.msisdn,
                            product_id: log.queue_id,
                          })}
                          _hover={{ bg: 'primary.500', color: 'blue.500' }}
                        >
                          Disposition
                        </Button>
                      )}
                   </Td>
                </Tr>
              ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        
        <Flex mt="4" justify="space-between" align="center">
          <Box>
            Showing {indexOfFirstItem + 1} to {queLog.queuelog ? Math.min(indexOfLastItem, queLog.queuelog.length) : 0} of {queLog.queuelog ? queLog.queuelog.length : 0} entries
          </Box>
          <Flex>
            <Button
              variant="outline"
              isDisabled={currentPage === 1}
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </Button>
            {[...Array(Math.ceil((queLog.queuelog?.length || 0) / itemsPerPage))].map((_, index) => (
              <React.Fragment key={index}>
                {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(queLog.queuelog?.length / itemsPerPage) ? (
                  <Button
                    key={index}
                    variant={currentPage === index + 1 ? "solid" : "outline"}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Button>
                ) : (
                  index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                    <Box key={index}>...</Box>
                  ) : null
                )}
              </React.Fragment>
            ))}
            <Button
              variant="outline"
              isDisabled={currentPage === Math.ceil(queLog.queuelog?.length / itemsPerPage)}
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      </Box>
      )}

      {/* Modal for Disposition */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Disposition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InboundCallDisposition callData={selectedCall} onClose={onClose} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Spacer h="5" />
    </Box>
  );
};

export default InboundCallLogs;

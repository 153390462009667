import React, { useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Button,
    Icon,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Text,
    Image,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    useToast,
    keyframes,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import { FaRegFrown, FaRedo, FaWhatsappSquare, FaRegSadTear } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { sendDirectMessage, sendMediaFile } from '../../actions/qrActions';
import { createWhatsappOutbound } from '../../actions/whatsappOutboundActions';
import FormContainer from '../../components/FormContainer';

const moveAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const WhatsappSend = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const instance_id = userInfo?.instance_id;
  const access_token = userInfo?.access_token;
  const user_id = userInfo?.id;
  const agent = userInfo?.name;
  const product_id = userInfo?.product_id;

  const {
    isOpen: isDirectMessageOpen,
    onOpen: onDirectMessageOpen,
    onClose: onDirectMessageClose,
  } = useDisclosure();

  const {
    isOpen: isMediaFileOpen,
    onOpen: onMediaFileOpen,
    onClose: onMediaFileClose,
  } = useDisclosure();

  const [alertStatus, setAlertStatus] = useState(null);

  const [directPhone, setDirectPhone] = useState('');
  const [directMessage, setDirectMessage] = useState('');

  const [mediaPhone, setMediaPhone] = useState('');
  const [mediaMessage, setMediaMessage] = useState('');
  const [mediaUrl, setMediaUrl] = useState('')

  const validatePhoneNumber = (number) => {
    const whatsappRegex = /^\d{10,15}$/;
    return whatsappRegex.test(number);
  };

  const handleCreateWhatsappOutbound = (number, type, message, media_url = null) => {
    const data = {
      number,
      type,
      message,
      media_url,
      instance_id,
      access_token,
      user_id,
      agent,
      product_id,
    };
    dispatch(createWhatsappOutbound(data));
  };

  const handleSendDirectMessage = async (e) => {
    e.preventDefault();

    const number = directPhone;
    const message = directMessage;
    const type = 'text';

    if (!validatePhoneNumber(number.replace(/\D/g, ''))) {
      toast({
        title: "Invalid Phone Number",
        description: "Please enter a valid phone number with this format (e.g., 254XXXXXXXXX).",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await dispatch(sendDirectMessage(number, type, message, instance_id, access_token));

      if (response.success) {
        handleCreateWhatsappOutbound(number, type, message);
        setAlertStatus({
          status: "success",
          title: "Message Sent",
          description: "Your message has been sent successfully.",
        });
        onDirectMessageClose();
        setDirectPhone('');
        setDirectMessage('');
      } else {
        setAlertStatus({
          status: "error",
          title: "Message Failed",
          description: "There was an issue sending your message.",
        });
      }
    } catch (error) {
      setAlertStatus({
        status: "error",
        title: "Message Failed",
        description: "There was an issue sending your message.",
      });
    }
  };

  const handleSendMediaFile = async (e) => {
    e.preventDefault();
    const number = mediaPhone;
    const message = mediaMessage;
    const media_url = mediaUrl;
    const type = 'media';


    if (!validatePhoneNumber(number.replace(/\D/g, ''))) { 
      toast({
        title: "Invalid Phone Number",
        description: "Please enter a valid phone number with the correct format.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!media_url) {
      toast({
        title: "Missing Media URL",
        description: "Please enter a valid media URL.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await dispatch(sendMediaFile(number, type, message, media_url, instance_id, access_token));

      if (response.success) {
        handleCreateWhatsappOutbound(number, type, message, media_url);
        setAlertStatus({
          status: "success",
          title: "Media Sent",
          description: "Your media message has been sent successfully.",
        });
        onMediaFileClose();
        setMediaPhone('');
        setMediaMessage('');
        setMediaUrl('');
      } else {
        setAlertStatus({
          status: "error",
          title: "Media Send Failed",
          description: "There was an issue sending your media message.",
        });
      }
    } catch (error) {
      setAlertStatus({
        status: "error",
        title: "Media Send Failed",
        description: "There was an issue sending your media message.",
      });
    }
  };

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Flex w={{ base: '80%', md: '60%' }} alignItems="center" justifyContent="center" py="5" mx="auto">
        <FormContainer>
          <Image
            src={require('../../images/whatsapp.jpg')}
            alt=""
            display={{ base: 'block', md: 'block' }}
            w={{ base: '80%', md: '50%' }}
            objectFit="cover"
            marginTop={0}
            alignSelf="center"
            borderRadius="45px"
          />
          <Heading as="h1" m={8} fontSize="3xl" color="black" mx="auto">
            Send a Whatsapp Message
          </Heading>
          {userInfo?.isWhatsappEnabled ? (
            <VStack spacing={4} align="stretch">
              <Button colorScheme="blue" width="full" mb={4} onClick={onDirectMessageOpen}>
                Send Direct Message
              </Button>
              <Button colorScheme="blue" width="full" mb={4} onClick={onMediaFileOpen}>
                Send Media File
              </Button>
            </VStack>
          ) : (
            <>
              <Text fontSize="xl" color="gray.600" textAlign="center">
                Your WhatsApp is not integrated yet.
              </Text>
              <Box className="icon-container" display="flex" gap={4} mt={8} mx="auto">
                <Icon as={FaRegFrown} boxSize={12} animation={`${moveAnimation} 2s infinite`} color="teal.500" />
                <Icon as={FaRedo} boxSize={12} animation={`${rotateAnimation} 2s infinite linear`} color="yellow.500" />
                <Icon as={FaWhatsappSquare} boxSize={12} animation={`${moveAnimation} 2s infinite 1s`} color="blue.500" />
                <Icon as={FaRegSadTear} boxSize={12} animation={`${moveAnimation} 2s infinite 1.5s`} color="green.500" />
              </Box>
            </>
          )}
        </FormContainer>
      </Flex>

      {alertStatus && (
        <Alert status={alertStatus.status} mt={4} borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>{alertStatus.title}</AlertTitle>
            <AlertDescription>{alertStatus.description}</AlertDescription>
          </Box>
        </Alert>
      )}

      <Modal isOpen={isDirectMessageOpen} onClose={onDirectMessageClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Direct Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSendDirectMessage}>
              <FormControl id="number" isRequired mb={4}>
                <FormLabel>Phone Number</FormLabel>
                <PhoneInput
                  country={'ke'}
                  value={directPhone}
                  onChange={(phone) => setDirectPhone(phone)}
                  placeholder="Enter WhatsApp Number"
                  countryCodeEditable={true}
                />
              </FormControl>
              <FormControl id="message" isRequired mb={4}>
                <FormLabel>Message</FormLabel>
                <Textarea
                  value={directMessage}
                  onChange={(e) => setDirectMessage(e.target.value)}
                  placeholder="Enter your message"
                />
              </FormControl>
              <ModalFooter px={0}>
                <Button colorScheme="green" mr={3} type="submit">
                  Send
                </Button>
                <Button colorScheme="red" onClick={onDirectMessageClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Media File Modal */}
      <Modal isOpen={isMediaFileOpen} onClose={onMediaFileClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Message with Media</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSendMediaFile}>
              <FormControl id="number" isRequired mb={4}>
                <FormLabel>Phone Number</FormLabel>
                <PhoneInput
                  country={'ke'}
                  value={mediaPhone}
                  onChange={(phone) => setMediaPhone(phone)}
                  placeholder="Enter WhatsApp Number"
                  countryCodeEditable={true}
                />
              </FormControl>
              <FormControl id="message" isRequired mb={4}>
                <FormLabel>Message</FormLabel>
                <Textarea
                  value={mediaMessage}
                  onChange={(e) => setMediaMessage(e.target.value)}
                  placeholder="Enter your message"
                />
              </FormControl>
              <FormControl id="mediaUrl" isRequired mb={4}>
                <FormLabel>Media URL</FormLabel>
                <Input
                  type="url"
                  value={mediaUrl}
                  onChange={(e) => setMediaUrl(e.target.value)}
                  placeholder="Enter media URL"
                />
              </FormControl>
              <ModalFooter px={0}>
                <Button colorScheme="green" mr={3} type="submit">
                  Send
                </Button>
                <Button colorScheme="red" onClick={onMediaFileClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WhatsappSend;

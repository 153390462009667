import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spacer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import { GoSingleSelect } from "react-icons/go"; 
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { getProductAbandonedCalls, updateCDR } from "../../actions/cdrActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const InboundAbandonedCalls = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCall, setSelectedCall] = useState(null);
  const [newStatus, setNewStatus] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      const prodCode = userInfo.product_id;
      dispatch(getProductAbandonedCalls(prodCode));
    }
  }, [dispatch, userInfo]);

  const productAbandonedCallsList = useSelector((state) => state.productAbandonedCallsList);
  const { loading, error, calls: productAbandonedCalls } = productAbandonedCallsList;

  const allAbandonedCalls = productAbandonedCalls
    ? productAbandonedCalls.slice(indexOfFirstItem, Math.min(indexOfLastItem, productAbandonedCalls.length))
    : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStatusUpdate = async () => {
    try {
      const agent = userInfo.name;
      const uniqueId = selectedCall.uniqueid;

      await dispatch(updateCDR(uniqueId, agent, newStatus)).then(() => {
        dispatch(getProductAbandonedCalls(userInfo.product_id));
        onClose();
      });
    } catch (error) {
      console.error("Error updating CDR:", error);
    }
  };

  const openStatusModal = (call) => {
    setSelectedCall(call);
    setNewStatus(call.Status);
    onOpen();
  };

  return (
    <Box p="4" m={2} alignItems="center" width={{ base: "100%", md: "100%" }} bg="white" borderRadius="md">
      <Box m={6}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Abandoned Calls
        </Text>
      </Box>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Caller</Th>
                <Th>Duration (Sec)</Th>
                <Th>Abandoned Time</Th>
                <Th>Agent</Th>
                <Th>Action / Status</Th>
                <Th>Time Actioned</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allAbandonedCalls.length > 0 ? (
                allAbandonedCalls.map((call, index) => (
                  <Tr key={index}>
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{call.Caller}</Td>
                    <Td>{call.Duration}</Td>
                    <Td>
                      {moment(call.Time)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>{call.Agent}</Td>
                    <Td>
                      <Button 
                        size="sm" 
                        bg="blue.500" 
                        color="white" 
                        _hover={{ bg: 'brandBlue' }} 
                        onClick={() => openStatusModal(call)}
                        leftIcon={<GoSingleSelect />} 
                      >
                        {call.Status}
                      </Button>
                    </Td>
                    <Td>
                      {moment(call.ReturnCallDate)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="7" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to {productAbandonedCalls ? Math.min(indexOfLastItem, productAbandonedCalls.length) : 0} of {productAbandonedCalls ? productAbandonedCalls.length : 0} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {[...Array(Math.ceil((productAbandonedCalls?.length || 0) / itemsPerPage))].map((_, index) => (
                  <React.Fragment key={index}>
                    {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(productAbandonedCalls?.length / itemsPerPage) ? (
                      <Button
                        key={index}
                        variant={currentPage === index + 1 ? "solid" : "outline"}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : (
                      index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={currentPage === Math.ceil(productAbandonedCalls?.length / itemsPerPage)}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}

      <Spacer h="5" />

      {selectedCall && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Call Status</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
                style={{ borderWidth: "1px", borderStyle: "solid", borderRadius: "4px", borderColor: "gray" }}
              >
                <option value="Not Answered">Not Answered</option>
                <option value="Answered">Answered</option>
                <option value="Not Called">Not Called</option>
                <option value="RESTRICTED">RESTRICTED</option>
                <option value="UNREACHABLE">UNREACHABLE</option>
                <option value="NOT EXIST">NOT EXIST</option>
                <option value="BUSY">BUSY</option>
                <option value="NOT INSERVICE">NOT INSERVICE</option>
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleStatusUpdate}>
                Update
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default InboundAbandonedCalls;

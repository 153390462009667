import { Box, Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import MainComponent from "../components/MainComponent";

const TrialHome = () => {
  const mainBodyColor = useColorModeValue("white", "#1A202C");
  return (
    <MainComponent width="95.6vw">
      <Box bg={mainBodyColor} borderRadius="sm" m={4}>
        <Flex justifyContent="space-between" mt="2">
          <Heading
            as="h1"
            fontSize={{ base: "xl", md: "2xl" }}
            mb="5"
            ml={8}
            mt={4}
          >
            Webphone
          </Heading>
        </Flex>
      </Box>
    </MainComponent>
  );
};

export default TrialHome;

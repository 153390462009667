import axios from 'axios';

import {
  CALLDISPOSITION_CREATE_REQUEST,
  CALLDISPOSITION_CREATE_SUCCESS,
  CALLDISPOSITION_CREATE_FAIL,
  CALL_HISTORY_REQUEST,
  CALL_HISTORY_SUCCESS,
  CALL_HISTORY_FAIL,
  CALL_HISTORY_PRODUCT_REQUEST,
  CALL_HISTORY_PRODUCT_SUCCESS,
  CALL_HISTORY_PRODUCT_FAIL,
  CALL_HISTORY_ALL_REQUEST,
  CALL_HISTORY_ALL_SUCCESS,
  CALL_HISTORY_ALL_FAIL,
  INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST,
  INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
  INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
  INBOUND_SCHEDULED_CALLBACKS_REQUEST,
  INBOUND_SCHEDULED_CALLBACKS_SUCCESS,
  INBOUND_SCHEDULED_CALLBACKS_FAIL,
  UPDATE_CALLBACK_STATUS_REQUEST,
  UPDATE_CALLBACK_STATUS_SUCCESS,
  UPDATE_CALLBACK_STATUS_FAIL,
  GET_INBOUND_ESCALATED_CALLS_REQUEST,
  GET_INBOUND_ESCALATED_CALLS_SUCCESS,
  GET_INBOUND_ESCALATED_CALLS_FAIL,
  UPDATE_ESCALATION_DETAILS_REQUEST,
  UPDATE_ESCALATION_DETAILS_SUCCESS,
  UPDATE_ESCALATION_DETAILS_FAIL,
} from '../constants/callDispositionConstants';

export const createCallDisposition = (callDispositionData, queue_log_id, msisdn, agent, product_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CALLDISPOSITION_CREATE_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const requestData = {
      ...callDispositionData,
      queue_log_id,
      msisdn,
      agent,
      product_id,
    };

    const { data } = await axios.post(`/api/call-dispositions`, requestData, config);

    console.log('Call disposition created successfully:', data);

    dispatch({
      type: CALLDISPOSITION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error('Error creating call disposition:', error);
    console.log('Response data:', error.response.data);
    dispatch({
      type: CALLDISPOSITION_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getPreviousCallHistory = (prodCode, lastPhoneDigits) => async (dispatch, getState) => {
  try {
    dispatch({ type: CALL_HISTORY_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/callhistory/${prodCode}/${lastPhoneDigits}`, config);

    dispatch({
      type: CALL_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CALL_HISTORY_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getProductCallHistory = (prodCode, searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: CALL_HISTORY_PRODUCT_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/callhistory/${prodCode}?search=${searchInput}`, config);

    dispatch({
      type: CALL_HISTORY_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CALL_HISTORY_PRODUCT_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getAllCallHistory = (searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: CALL_HISTORY_ALL_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/callhistory?search=${searchInput}`, config);

    dispatch({
      type: CALL_HISTORY_ALL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CALL_HISTORY_ALL_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getAllInboundScheduledCallbacks = (searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/callbacks?search=${searchInput}`, config);
    console.log('Data', data)

    dispatch({
      type: INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getInboundScheduledCallbacks = (agentName, searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: INBOUND_SCHEDULED_CALLBACKS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/callbacks/${agentName}?search=${searchInput}`, config);

    dispatch({
      type: INBOUND_SCHEDULED_CALLBACKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INBOUND_SCHEDULED_CALLBACKS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const updateCallbackStatus = (callbackId, newStatus) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CALLBACK_STATUS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };    
    
    const { data } = await axios.put(`/api/callbacks/${callbackId}/status`, { status: newStatus }, config);
   
    dispatch({
      type: UPDATE_CALLBACK_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CALLBACK_STATUS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getProductInboundEscalatedCalls = (prodCode ,searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_INBOUND_ESCALATED_CALLS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/inbound-escalated-calls/${prodCode}?search=${searchInput}`, config);

    dispatch({
      type: GET_INBOUND_ESCALATED_CALLS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_INBOUND_ESCALATED_CALLS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const updateEscalationDetails = (dispositionId, escalationStep, escalationDescription) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_ESCALATION_DETAILS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/call-dispositions/${dispositionId}/escalation`, { escalation_step: escalationStep, escalation_description: escalationDescription }, config);

    dispatch({
      type: UPDATE_ESCALATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ESCALATION_DETAILS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    Link,
} from '@chakra-ui/react';

import { forgotPassword } from '../actions/userActions'; 
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';

const ForgotPasswordScreen = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');

    const userForgotPassword = useSelector((state) => state.userForgotPassword);
    const { loading, error, success } = userForgotPassword; 

    const submitHandler = async (e) => {
        e.preventDefault();
        const formattedEmail = email.trim().toLowerCase(); 
        dispatch(forgotPassword(formattedEmail));
    };
    

    return (
        <Flex w={{ base: "80%", md: "60%" }} alignItems='center' justifyContent='center' py='5' mx='auto'>
            <FormContainer>
                <Heading as='h1' mb='8' fontSize='3xl' color ='blue.500' display='flex'>
                    Forgot Password
                </Heading>

                {error && <Message type='error'>{error}</Message>}
                {success && <Message type='success'>Password reset link sent successfully</Message>}

                <form onSubmit={submitHandler}>
                    <FormControl id='email'>
                        <FormLabel htmlFor='email'>Email address</FormLabel>
                        <Input
                            id='email'
                            type='email'
                            placeholder='username@domain.com'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormControl>

                    <Button type='submit' bg="blue.500" color= "white" _hover={{ bg: 'brandBlue' }} mt='4' isLoading={loading}>
                        Send Password Reset Link
                    </Button>
                </form>

                <Flex pt='10'>
                    <Text fontWeight='semibold'>
                        <Link as={RouterLink} to='/login'>
                            Back to Login
                        </Link>
                    </Text>
                </Flex>
            </FormContainer>
        </Flex>
    );
};

export default ForgotPasswordScreen;

import axios from 'axios';

import {
  OUTBOUND_DISPOSITION_CREATE_REQUEST,
  OUTBOUND_DISPOSITION_CREATE_SUCCESS,
  OUTBOUND_DISPOSITION_CREATE_FAIL,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
  OUTBOUND_SCHEDULED_CALLBACKS_REQUEST,
  OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS,
  OUTBOUND_SCHEDULED_CALLBACKS_FAIL,
  UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST,
  UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS,
  UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL
} from '../constants/outboundDispositionConstants';

export const createOutboundDisposition = (dispositionData, log_uniqueid, agent, agent_code, msisdn, product_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: OUTBOUND_DISPOSITION_CREATE_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const requestData = {
      ...dispositionData,
      log_uniqueid,      
      msisdn,
      agent,
      agent_code,
      product_id,
    };

    const { data } = await axios.post(`/api/outbound-dispositions`, requestData, config);

    dispatch({
      type: OUTBOUND_DISPOSITION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error('Error creating outbound disposition:', error);
    console.log('Response data:', error.response.data);
    dispatch({
      type: OUTBOUND_DISPOSITION_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }    
};

export const getAllOutboundScheduledCallbacks = (searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/outbound-callbacks?search=${searchInput}`, config);

    dispatch({
      type: OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getOutboundScheduledCallbacks = (agentName, searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: OUTBOUND_SCHEDULED_CALLBACKS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/outbound-callbacks/${agentName}?search=${searchInput}`, config);

    dispatch({
      type: OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OUTBOUND_SCHEDULED_CALLBACKS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const updateOutboundCallbackStatus = (callbackId, newStatus) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/outbound-callbacks/${callbackId}/status`, { status: newStatus }, config);

    dispatch({
      type: UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getProductOutboundEscalatedCalls = (prodCode, searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/outbound-escalated-calls/${prodCode}?search=${searchInput}`, config);

    dispatch({
      type: GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS,
      payload: data,
    });
    console.log('Data', data);
  } catch (error) {
    dispatch({
      type: GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const updateOutboundEscalationDetails = (dispositionId, escalationStep, escalationDescription) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/outbound-call-dispositions/${dispositionId}/escalation`, { escalation_step: escalationStep, escalation_description: escalationDescription }, config);

    dispatch({
      type: UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getAgentOutboundEscalatedCalls = (agentName, searchInput = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/outbound-escalated-calls/agent/${agentName}?search=${searchInput}`, config);

    dispatch({
      type: GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

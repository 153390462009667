import {
    ESCALATEDCALLS_LIST_FAIL,
    ESCALATEDCALLS_LIST_REQUEST,
    ESCALATEDCALLS_LIST_SUCCESS
    
  } from '../constants/escalatedCallsConstants';
  
  export const escalatedCallsListReducer = (state = { escalatedCalls: [] }, action) => {
    switch (action.type) {
      case ESCALATEDCALLS_LIST_REQUEST:
        return { loading: true, escalatedCalls: [] };
  
      case ESCALATEDCALLS_LIST_SUCCESS:
        return { loading: false, escalatedCalls: action.payload };
      case ESCALATEDCALLS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
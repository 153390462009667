export const FETCH_INSTANCE_ID_REQUEST = 'FETCH_INSTANCE_ID_REQUEST';
export const FETCH_INSTANCE_ID_SUCCESS = 'FETCH_INSTANCE_ID_SUCCESS';
export const FETCH_INSTANCE_ID_FAILURE = 'FETCH_INSTANCE_ID_FAILURE';

export const FETCH_QR_REQUEST = 'FETCH_QR_REQUEST';
export const FETCH_QR_SUCCESS = 'FETCH_QR_SUCCESS';
export const FETCH_QR_FAILURE = 'FETCH_QR_FAILURE';

export const SET_WEBHOOK_REQUEST = 'SET_WEBHOOK_REQUEST';
export const SET_WEBHOOK_SUCCESS = 'SET_WEBHOOK_SUCCESS';
export const SET_WEBHOOK_FAILURE = 'SET_WEBHOOK_FAILURE';

export const REBOOT_INSTANCE_REQUEST = 'REBOOT_INSTANCE_REQUEST';
export const REBOOT_INSTANCE_SUCCESS = 'REBOOT_INSTANCE_SUCCESS';
export const REBOOT_INSTANCE_FAILURE = 'REBOOT_INSTANCE_FAILURE';

export const RESET_INSTANCE_REQUEST = 'RESET_INSTANCE_REQUEST';
export const RESET_INSTANCE_SUCCESS = 'RESET_INSTANCE_SUCCESS';
export const RESET_INSTANCE_FAILURE = 'RESET_INSTANCE_FAILURE';

export const RECONNECT_INSTANCE_REQUEST = 'RECONNECT_INSTANCE_REQUEST';
export const RECONNECT_INSTANCE_SUCCESS = 'RECONNECT_INSTANCE_SUCCESS';
export const RECONNECT_INSTANCE_FAILURE = 'RECONNECT_INSTANCE_FAILURE';

export const SEND_DIRECT_MESSAGE_REQUEST = 'SEND_DIRECT_MESSAGE_REQUEST';
export const SEND_DIRECT_MESSAGE_SUCCESS = 'SEND_DIRECT_MESSAGE_SUCCESS';
export const SEND_DIRECT_MESSAGE_FAILURE = 'SEND_DIRECT_MESSAGE_FAILURE';

export const SEND_MEDIA_FILE_REQUEST = 'SEND_MEDIA_FILE_REQUEST';
export const SEND_MEDIA_FILE_SUCCESS = 'SEND_MEDIA_FILE_SUCCESS';
export const SEND_MEDIA_FILE_FAILURE = 'SEND_MEDIA_FILE_FAILURE';

export const SEND_GROUP_MESSAGE_REQUEST = 'SEND_GROUP_MESSAGE_REQUEST';
export const SEND_GROUP_MESSAGE_SUCCESS = 'SEND_GROUP_MESSAGE_SUCCESS';
export const SEND_GROUP_MESSAGE_FAILURE = 'SEND_GROUP_MESSAGE_FAILURE';

export const SEND_GROUP_MEDIA_FILE_REQUEST = 'SEND_GROUP_MEDIA_FILE_REQUEST';
export const SEND_GROUP_MEDIA_FILE_SUCCESS = 'SEND_GROUP_MEDIA_FILE_SUCCESS';
export const SEND_GROUP_MEDIA_FILE_FAILURE = 'SEND_GROUP_MEDIA_FILE_FAILURE';

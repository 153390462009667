export const REASONS_LIST_REQUEST = "REASONS_LIST_REQUEST";
export const REASONS_LIST_SUCCESS = "REASONS_LIST_SUCCESS";
export const REASONS_LIST_FAIL = "REASONS_LIST_FAIL";

export const REASONS_DETAILS_SUCCESS = "REASONS_DETAILS_SUCCESS";
export const REASONS_DETAILS_REQUEST = "REASONS_DETAILS_REQUEST";
export const REASONS_DETAILS_FAIL = "REASONS_DETAILS_FAIL";

export const REASONS_DELETE_REQUEST = "REASONS_DELETE_REQUEST";
export const REASONS_DELETE_SUCCESS = "REASONS_DELETE_SUCCESS";
export const REASONS_DELETE_FAIL = "REASONS_DELETE_FAIL";

export const REASONS_CREATE_REQUEST = "REASONS_CREATE_REQUEST";
export const REASONS_CREATE_SUCCESS = "REASONS_CREATE_SUCCESS";
export const REASONS_CREATE_FAIL = "REASONS_CREATE_FAIL";
export const REASONS_CREATE_RESET = "REASONS_CREATE_RESET";

export const REASONS_UPDATE_REQUEST = "REASONS_UPDATE_REQUEST";
export const REASONS_UPDATE_SUCCESS = "REASONS_UPDATE_SUCCESS";
export const REASONS_UPDATE_FAIL = "REASONS_UPDATE_FAIL";
export const REASONS_UPDATE_RESET = "REASONS_UPDATE_RESET";

export const PRODUCT_REASONS_REQUEST = "PRODUCT_REASONS_REQUEST";
export const PRODUCT_REASONS_SUCCESS = "PRODUCT_REASONS_SUCCESS";
export const PRODUCT_REASONS_FAIL = "PRODUCT_REASONS_FAIL";

import React from "react";
import "./grid.css";
import { Container, Box, Text, Grid, GridItem } from "@chakra-ui/react";

import { BiArrowToBottom, BiPhone, BiCamera, BiAward } from "react-icons/bi";

function Gridicon() {
  return (
    <div>
      <Text>
        <Box
          className="icon-boxes position-relative"
          backgroundColor={"#00669e"}
        >
          <Container
            maxWidth="1366px"
            mx="auto"
            px="3"
            pos="relative"
            className="position-relative"
          >
            <Grid
              className="row gy-4 mt-5"
              templateColumns={{
                xl: "repeat(4, 1fr)",

                md: "repeat(2, 1fr)",
              }}
              gap={4}
            >
              <GridItem colSpan={{ xl: 1, md: 1 }}>
                <Box data-aos="fade-up" data-aos-delay="100">
                  <Box className="icon-box">
                    <Box className="icon">
                      <BiArrowToBottom />
                    </Box>
                    <Box className="title">
                      <a
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                        className="stretched-link"
                      >
                        Contact Centre
                      </a>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={{ xl: 1, md: 1 }}>
                <Box data-aos="fade-up" data-aos-delay="200">
                  <Box className="icon-box">
                    <Box className="icon">
                      <BiPhone />
                    </Box>
                    <Box className="title">
                      <a
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                        className="stretched-link"
                      >
                        IVR-ACD
                      </a>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={{ xl: 1, md: 1 }}>
                <Box data-aos="fade-up" data-aos-delay="300">
                  <Box className="icon-box">
                    <Box className="icon">
                      <BiCamera />
                    </Box>
                    <Box className="title">
                      <a
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                        className="stretched-link"
                      >
                        Business Intelligence
                      </a>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
              <GridItem colSpan={{ xl: 1, md: 1 }}>
                <Box data-aos="fade-up" data-aos-delay="500">
                  <Box className="icon-box">
                    <Box className="icon">
                      <BiAward />
                    </Box>
                    <Box className="title">
                      <a
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                        className="stretched-link"
                      >
                        Customer Service
                      </a>
                    </Box>
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Text>
    </div>
  );
}

export default Gridicon;

import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  IconButton,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { FaPaperPlane, FaSmile, FaPaperclip } from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";

import WhatsappBackground from "../../images/whatsapp-background.png";

const HaziWhatsappPhone = () => {
  const [messages, setMessages] = useState([
    { sender: "John Doe", text: "Hello there! 🤝 How can we help?", time: "11:41 AM" },
  ]);
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const newMessage = {
        sender: "You",
        text: message,
        time: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      };
      setMessages([...messages, newMessage]);
      setMessage("");
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newMessage = {
        sender: "You",
        text: `📎 Attached: ${file.name}`,
        time: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      };
      setMessages([...messages, newMessage]);
    }
  };

  return (
    <Box
      bg="gray.50"
      borderRadius="lg"
      overflow="hidden"
      maxW="sm"
      mx="auto"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      minH="200px"
      maxH="700px"
    >
      {/* Header */}
      <Flex bg="teal.600" p={3} alignItems="center" color="white">
        <Image
          src="https://via.placeholder.com/60"
          borderRadius="full"
          boxSize="50px"
          alt="avatar"
          mr={3}
        />
        <Box>
          <Text fontWeight="bold">John Doe</Text>
          <Text fontSize="sm" opacity="0.8">
            Typically replies within 1 hour
          </Text>
        </Box>
      </Flex>

      {/* Chat Body */}
      <VStack
        flex={1}
        p={4}
        spacing={4}
        bgImage={`url(${WhatsappBackground})`}
        bgRepeat="repeat"
        bgSize="cover"
        overflowY="auto"
        maxH="600px"
        minH="300px"
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            alignSelf={msg.sender === "You" ? "flex-end" : "flex-start"}
            bg={msg.sender === "You" ? "teal.500" : "gray.200"}
            color={msg.sender === "You" ? "white" : "black"}
            px={3}
            py={2}
            borderRadius="lg"
            maxW="80%"
          >
            <Text fontSize="sm" fontWeight="bold">
              {msg.sender}
            </Text>
            <Text>{msg.text}</Text>
            <Text fontSize="xs" textAlign="right" mt={1}>
              {msg.time}
            </Text>
          </Box>
        ))}
      </VStack>

      {/* Footer */}
      <Flex p={3} alignItems="center" bg="white" borderTop="1px solid gray.200" position="relative">
        {showEmojiPicker && (
          <Box
            position="absolute"
            bottom="60px"
            left="40px"
            zIndex="10"
            boxShadow="lg"
            borderRadius="md"
            overflow="hidden"
            bg="white"
          >
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Box>
        )}
        <IconButton
          icon={<FaSmile />}
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          mr={2}
          aria-label="Add Emoji"
        />
        <IconButton
          as="label"
          htmlFor="file-input"
          icon={<FaPaperclip />}
          mr={2}
          aria-label="Attach File"
        />
        <Input
          id="file-input"
          type="file"
          display="none"
          onChange={handleFileSelect}
        />
        <Input
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          mr={2}
          flex={1}
        />
        <IconButton
          icon={<FaPaperPlane />}
          colorScheme="teal"
          onClick={handleSendMessage}
          aria-label="Send Message"
        />
      </Flex>
    </Box>
  );
};

export default HaziWhatsappPhone;

import {
  AGENT_OUTBOUNDSTATS_REQUEST,
  AGENT_OUTBOUNDSTATS_SUCCESS,
  AGENT_OUTBOUNDSTATS_FAIL,
  AGENT_CALLSTATS_REQUEST,
  AGENT_CALLSTATS_SUCCESS,
  AGENT_CALLSTATS_FAIL,
  AGENT_DAILY_STATS_REQUEST,
  AGENT_DAILY_STATS_SUCCESS,
  AGENT_DAILY_STATS_FAIL,
  ADMIN_INBOUND_STATS_REQUEST,
  ADMIN_INBOUND_STATS_SUCCESS,
  ADMIN_INBOUND_STATS_FAIL,
  ADMIN_OUTBOUND_STATS_REQUEST,
  ADMIN_OUTBOUND_STATS_SUCCESS,
  ADMIN_OUTBOUND_STATS_FAIL,
  SUPERVISOR_INBOUND_STATS_REQUEST,
  SUPERVISOR_INBOUND_STATS_SUCCESS,
  SUPERVISOR_INBOUND_STATS_FAIL,
  SUPERVISOR_OUTBOUND_STATS_REQUEST,
  SUPERVISOR_OUTBOUND_STATS_SUCCESS,
  SUPERVISOR_OUTBOUND_STATS_FAIL,
  SUPPORT_INBOUND_STATS_REQUEST,
  SUPPORT_INBOUND_STATS_SUCCESS,
  SUPPORT_INBOUND_STATS_FAIL,
  SUPPORT_INBOUND_REASON_STATS_REQUEST,
  SUPPORT_INBOUND_REASON_STATS_SUCCESS,
  SUPPORT_INBOUND_REASON_STATS_FAIL,
  SUPPORT_OUTBOUND_STATS_REQUEST,
  SUPPORT_OUTBOUND_STATS_SUCCESS,
  SUPPORT_OUTBOUND_STATS_FAIL,
  SUPPORT_OUTBOUND_REASON_STATS_REQUEST,
  SUPPORT_OUTBOUND_REASON_STATS_SUCCESS,
  SUPPORT_OUTBOUND_REASON_STATS_FAIL,
} from '../constants/statsConstants';

export const agentOutboundStatsReducer = (state = {
  loading: false,
  error: null,
  answeredCount: 0,
  notCalledCount: 0,
  notAnsweredCount: 0,
  unreachableCount: 0,
  notExistCount: 0,
  busyCount: 0,
  restrictedCount: 0,
  notInServiceCount: 0, 
}, action) => {
  switch (action.type) {
    case AGENT_OUTBOUNDSTATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AGENT_OUTBOUNDSTATS_SUCCESS:
      return {
        ...state,
        loading: false,
        answeredCount: action.payload.answeredCount,
        notCalledCount: action.payload.notCalledCount,
        notAnsweredCount: action.payload.notAnsweredCount,
        unreachableCount: action.payload.unreachableCount,
        notExistCount: action.payload.notExistCount,
        busyCount: action.payload.busyCount,
        restrictedCount: action.payload.restrictedCount,
        notInServiceCount: action.payload.notInServiceCount, 
      };
    case AGENT_OUTBOUNDSTATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const agentCallStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_CALLSTATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AGENT_CALLSTATS_SUCCESS:
      return {
        ...state,
        loading: false,
        agentAnsweredCount: action.payload.agentAnsweredCount,
        agentNoAnswerCount: action.payload.agentNoAnswerCount,
        agentTalkTime: action.payload.agentTalkTime,
        agentAht: action.payload.agentAht,
      };
    case AGENT_CALLSTATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const agentDailyStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_DAILY_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AGENT_DAILY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        inboundStats: action.payload.inboundStats,
        outboundStats: action.payload.outboundStats,
      };
    case AGENT_DAILY_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminInboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_INBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMIN_INBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTimeStats: action.payload.allTimeStats,
        dailyStats: action.payload.dailyStats,
      };
    case ADMIN_INBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminOutboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_OUTBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADMIN_OUTBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTimeStats: action.payload.allTimeStats,
        dailyStats: action.payload.dailyStats,
      };
    case ADMIN_OUTBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const supervisorInboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPERVISOR_INBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPERVISOR_INBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTimeStats: action.payload.allTimeStats,
        dailyStats: action.payload.dailyStats,
      };
    case SUPERVISOR_INBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const supervisorOutboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPERVISOR_OUTBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPERVISOR_OUTBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTimeStats: action.payload.allTimeStats,
        dailyStats: action.payload.dailyStats,
      };
    case SUPERVISOR_OUTBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const supportInboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPORT_INBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPPORT_INBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        pending: action.payload.pending,
        inProgress: action.payload.in_progress,
        fullyResolved: action.payload.fully_resolved,
        onHold: action.payload.on_hold,
      };
    case SUPPORT_INBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const supportInboundReasonStatsReducer = (state = [], action) => {
  switch (action.type) {
    case SUPPORT_INBOUND_REASON_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPPORT_INBOUND_REASON_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        reasonStats: action.payload,
      };
    case SUPPORT_INBOUND_REASON_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const supportOutboundStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPORT_OUTBOUND_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPPORT_OUTBOUND_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        pending: action.payload.pending,
        in_progress: action.payload.in_progress,
        fully_resolved: action.payload.fully_resolved,
        on_hold: action.payload.on_hold,
      };
    case SUPPORT_OUTBOUND_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const supportOutboundReasonStatsReducer = (state = [], action) => {
  switch (action.type) {
    case SUPPORT_OUTBOUND_REASON_STATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUPPORT_OUTBOUND_REASON_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        reasonStats: action.payload,
      };
    case SUPPORT_OUTBOUND_REASON_STATS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

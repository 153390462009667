import { Box, Text, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import ChatPage from '../admin/ChatPage';
import WhatsappSend from '../agent/WhatsappSend';
import QRCodeScanner from '../agent/WhatsappQRScreen';
import WhatsAppProductHistory from '../agent/WhatsAppProductHistory';

const SupWhatsappHome = () => {

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Text fontSize="xl" fontWeight="bold" m={8}>WhatsApp</Text>      
      <Tabs variant='enclosed' mt={4} isLazy>
          <TabList
            overflowX="auto"
            whiteSpace="nowrap"
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Tab>
              Chat
            </Tab> 
            <Tab>
              Quick Message
            </Tab>           
            <Tab>
              Session
            </Tab>
            <Tab>
              History
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ChatPage /> 
            </TabPanel>
            <TabPanel>
              <WhatsappSend /> 
            </TabPanel>
            <TabPanel>
              <QRCodeScanner />
            </TabPanel>
            <TabPanel>
              <WhatsAppProductHistory /> 
            </TabPanel>
          </TabPanels>
        </Tabs>
      
    </Box>
  );
};

export default SupWhatsappHome;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Icon,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import moment from "moment-timezone";
import { IoEyeSharp } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";

import { getAgentProducts } from '../../actions/agentProductActions';

const Products = () => {
  
  const dispatch = useDispatch();
  const { loading: productsLoading, error: productsError, agentProducts } = useSelector((state) => state.agentProducts);
  const { userInfo } = useSelector((state) => state.userLogin);

  const [agentDetails, setAgentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgentDetails = async (user_id) => {
      try {
        const { data } = await axios.get(`/api/admin/agents/details/${user_id}`);
        setAgentDetails(data.data);
  
        dispatch(getAgentProducts(data.data.id));
  
        setLoading(false);
      } catch (error) {
        setError(error.response ? error.response.data.message : 'Internal Server Error');
        setLoading(false);
      }
    };
  
    if (userInfo && userInfo.id) {
      const { id } = userInfo;
      fetchAgentDetails(id);
    }
  }, [dispatch, userInfo]);
  
  return (
    <Box p="4" bg="white" borderRadius="md" m={2}>
      <Box m={6} mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Products
        </Text>
      </Box>

      <Flex justifyContent="space-between" mt={8}>
         {/* Render Agent Details */}
         {agentDetails && (
          <Text>Agent Code: {agentDetails.agent_code}</Text>
        )}        
      </Flex>     
      
      <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8} >
        <Table variant="striped" colorScheme="gray">
        <TableCaption>Showing {agentProducts.data ? agentProducts.data.length : 0} entries</TableCaption>
        <Thead>
          <Tr>
            <Th>No.</Th>
            <Th>Product Code</Th>
            <Th>Date Created</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
        {productsLoading || loading ? (
          <Tr>
            <Td colSpan="4">Loading...</Td>
          </Tr>
        ) : (productsError || error) ? (
          <Tr>
            <Td colSpan="4">Error: {productsError || error}</Td>
          </Tr>
        ) : (
          agentProducts.data.map((agentproduct, index) => (
            <Tr key={agentproduct.id}>
              <Td>{index + 1}</Td>
              <Td>{agentproduct.product_id}</Td>
              <Td>
                {moment(agentproduct.created_at)
                  .tz("Africa/Nairobi")
                  .format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </Td>
              <Td>                
                <Button
                  mr="4"
                  colorScheme="blue"
                  bg='blue.500'
                  size="sm"
                  as={RouterLink}
                  to={`/agent-products/${agentproduct.id}`}
                  >
                    <Icon as={IoEyeSharp} color="white" size="md" />
                  </Button>
              </Td>
            </Tr>
          ))
        )}
        </Tbody>
      </Table>
    </Box>
      
    </Box>
  );
};

export default Products;

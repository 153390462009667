import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    Icon,
    Input,
    Spacer,
  } from "@chakra-ui/react";
  import { FaDownload } from "react-icons/fa";
  
  
  const OutboundCallReports = () => {  
  
    return (
      <Box bg='white' borderRadius="sm" m={4}>
        <Flex justifyContent="space-between" mt="2">
          <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
            Outbound Call Reports
          </Heading>  
        </Flex>
        <Spacer h="2" bg='blackAlpha.50'/>
  
        <Flex
          flexWrap="wrap"
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center"
          mr={8}
          ml={8} 
          mt={8} 
          fontSize='lg'
        >  
          <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }} w={{ base:"70%", md :'40%'}}>
            <Text style={{ fontWeight: "bold" }}>From Date</Text>
            <Input 
             type="date"
            >
            </Input>
          </Flex>
         
          <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }} w={{ base:"70%", md :'40%'}}>
            <Text style={{ fontWeight: "bold" }}>To Date</Text>
            <Input 
             type="date"
            >
            </Input>
          </Flex>       
        </Flex>
  
        <Flex justifyContent="space-between" mt={8} ml={10}>
          <Button
              colorScheme="blue"
              bg="blue.500"
              color="white"        
          >
           <Icon as={FaDownload} size='sm' mr={2}/>
              Download
          </Button>
        </Flex>
  
        <Spacer h="5" />
      </Box>
    );
  };
  
  export default OutboundCallReports;
  
import React, { useEffect, useState } from "react";
import {  
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";

import { getAllOutboundCalls } from '../../actions/cdrActions';
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const OutboundAllCallLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const outboundCalls = useSelector((state) => state.allOutboundCalls.calls);
  const outboundCallsLoading = useSelector((state) => state.allOutboundCalls.loading);
  const outboundCallsError = useSelector((state) => state.allOutboundCalls.error);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(getAllOutboundCalls());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);
  
  const [callDispositionInfo, setCallDispositionInfo] = useState({});
  
  useEffect(() => {

    const fetchDispositionForCall = async (call) => {
      try {
          const response = await axios.get(`/api/dispose/${call.uniqueid}`);
          const { dispositioned, reason } = response.data;
          setCallDispositionInfo((prevState) => ({
              ...prevState,
              [call.uniqueid]: { dispositioned, reason }
          }));
      } catch (error) {
          console.error('Error fetching outbound disposition for call', error);
      }
  };  

    const fetchDispositionForAllCalls = async () => {
      if (outboundCalls && outboundCalls.length > 0) {
          outboundCalls.forEach((call) => {
              fetchDispositionForCall(call);
          });
      } else {
      }
  };
    
    fetchDispositionForAllCalls();
}, [outboundCalls]);


const paginate = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentOutboundCalls = outboundCalls && outboundCalls.slice(indexOfFirstItem, indexOfLastItem);
  

  return (
    <Box p="4" m={2} alignItems="center" width={{ base: '100%', md: '100%' }} bg="white" borderRadius="md">      
      <Box m={6}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
            Outbound Call Logs
        </Text>
      </Box>

      {outboundCallsLoading ? (
        <Loader />
      ) : outboundCallsError ? (
        <Message type="error">{outboundCallsError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8} >
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr fontWeight="bold">
                <Th>No.</Th>
                <Th>Phone</Th>
                <Th>Call Duration</Th>
                <Th>Agent</Th>
                <Th>Agent Code</Th>
                <Th>Date</Th>
                <Th>Disposition</Th>
              </Tr>
            </Thead>
            <Tbody>
            {currentOutboundCalls && currentOutboundCalls.length > 0 ? (
              currentOutboundCalls.map((call, index) => (
                <Tr key={index} bg="white">
                 <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                  <Td>{call.Destination}</Td>
                  <Td>{call.Duration}</Td>
                  <Td>{call.AgentName}</Td>
                  <Td>{call.AgentExt}</Td>
                  <Td>
                    {moment(call.calldate)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>
                  <Td>
                  {callDispositionInfo[call.uniqueid] && callDispositionInfo[call.uniqueid].dispositioned ? (
                          <Box>
                              <Text fontWeight="normal">
                                Dispositioned
                              </Text>                              
                          </Box>
                      ) : (
                        <Box>
                        <Text fontWeight="nomal">
                           Not Dispositioned
                        </Text>                              
                    </Box>                 
                      )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6" textAlign="center">
                  No data available
                </Td>
              </Tr>
            )}
            </Tbody>
          </Table>
        
          <Flex mt="4" justify="space-between" align="center">
          <Box>
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, outboundCalls ? outboundCalls.length : 0)} of {outboundCalls ? outboundCalls.length : 0} entries
          </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {outboundCalls && Array.isArray(outboundCalls) && outboundCalls.length > 0 && [...Array(Math.ceil(outboundCalls.length / itemsPerPage))].map((_, index) => (
                <React.Fragment key={index}>
                  {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(outboundCalls.length / itemsPerPage) ? (
                    <Button
                      key={index}
                      variant={currentPage === index + 1 ? "solid" : "outline"}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Button>
                  ) : (
                    index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                      <Box key={index}>...</Box>
                    ) : null
                  )}
                </React.Fragment>
              ))}
              <Button
                variant="outline"
                isDisabled={outboundCalls ? currentPage === Math.ceil(outboundCalls.length / itemsPerPage) : true}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </Button>

              </Box>
            </Flex>
          </Flex>
        </Box>
      )}

      <Spacer h="5" />
    </Box>
  );
};

export default OutboundAllCallLogs;

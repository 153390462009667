import axios from 'axios';

import {
    HAZI_PROFILE_REQUEST,
    HAZI_PROFILE_SUCCESS,
    HAZI_PROFILE_FAIL,
    HAZI_BALANCE_REQUEST,
    HAZI_BALANCE_SUCCESS,
    HAZI_BALANCE_FAIL,
    HAZI_EMAIL_VERIFY_REQUEST,
    HAZI_EMAIL_VERIFY_SUCCESS,
    HAZI_EMAIL_VERIFY_FAIL,
    HAZI_EMAIL_SEND_REQUEST,
    HAZI_EMAIL_SEND_SUCCESS,
    HAZI_EMAIL_SEND_FAIL,
    HAZI_EMAIL_BULK_SEND_REQUEST,
    HAZI_EMAIL_BULK_SEND_SUCCESS,
    HAZI_EMAIL_BULK_SEND_FAIL,
    HAZI_SMS_SEND_REQUEST,
    HAZI_SMS_SEND_SUCCESS,
    HAZI_SMS_SEND_FAIL,    
    HAZI_SMS_BULK_SEND_REQUEST,
    HAZI_SMS_BULK_SEND_SUCCESS,
    HAZI_SMS_BULK_SEND_FAIL,
    HAZI_CONTACT_GROUPS_LIST_REQUEST,
    HAZI_CONTACT_GROUPS_LIST_SUCCESS,
    HAZI_CONTACT_GROUPS_LIST_FAIL,
    HAZI_CONTACT_GROUP_CREATE_REQUEST,
    HAZI_CONTACT_GROUP_CREATE_SUCCESS,
    HAZI_CONTACT_GROUP_CREATE_FAIL,
    HAZI_CONTACT_GROUP_EDIT_REQUEST,
    HAZI_CONTACT_GROUP_EDIT_SUCCESS,
    HAZI_CONTACT_GROUP_EDIT_FAIL,
    HAZI_CONTACT_GROUP_DELETE_REQUEST,
    HAZI_CONTACT_GROUP_DELETE_SUCCESS,
    HAZI_CONTACT_GROUP_DELETE_FAIL,
    HAZI_ADD_CONTACTS_TO_GROUP_REQUEST,
    HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS,
    HAZI_ADD_CONTACTS_TO_GROUP_FAIL,
    HAZI_CONTACTS_LIST_IN_GROUP_REQUEST,
    HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS,
    HAZI_CONTACTS_LIST_IN_GROUP_FAIL,
    HAZI_CONTACT_UPDATE_REQUEST,
    HAZI_CONTACT_UPDATE_SUCCESS,
    HAZI_CONTACT_UPDATE_FAIL,
    HAZI_CONTACT_DELETE_REQUEST,
    HAZI_CONTACT_DELETE_SUCCESS,
    HAZI_CONTACT_DELETE_FAIL,
    HAZI_WHATSAPP_TEMPLATES_REQUEST,
    HAZI_WHATSAPP_TEMPLATES_SUCCESS,
    HAZI_WHATSAPP_TEMPLATES_FAIL,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL,
    HAZI_WHATSAPP_BROADCASTS_REQUEST,
    HAZI_WHATSAPP_BROADCASTS_SUCCESS,
    HAZI_WHATSAPP_BROADCASTS_FAIL,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL,
    HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST,
    HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS,
    HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL,
    HAZI_WHATSAPP_WEBHOOK_READ_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_READ_FAIL,
    HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL,
} from '../constants/haziSocialsConstants';

export const getHaziProfile = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_PROFILE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get('/api/hazi/profile', config);

        dispatch({ type: HAZI_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_PROFILE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const getHaziBalance = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_BALANCE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get('/api/hazi/balance', config);

        dispatch({ type: HAZI_BALANCE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_BALANCE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const verifyHaziEmail = (emailData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_EMAIL_VERIFY_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/email/verify', emailData, config);

        dispatch({ type: HAZI_EMAIL_VERIFY_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_EMAIL_VERIFY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const sendHaziEmail = (emailData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_EMAIL_SEND_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/email/send', emailData, config);

        dispatch({ type: HAZI_EMAIL_SEND_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_EMAIL_SEND_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const sendHaziBulkEmail = (bulkEmailData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_EMAIL_BULK_SEND_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/email/send-bulk', bulkEmailData, config);

        dispatch({ type: HAZI_EMAIL_BULK_SEND_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_EMAIL_BULK_SEND_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const sendHaziSMS = (smsData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_SMS_SEND_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/sms/send', smsData, config);

        dispatch({ type: HAZI_SMS_SEND_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_SMS_SEND_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const sendHaziBulkSMS = (bulkSMSData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_SMS_BULK_SEND_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/sms/send-bulk', bulkSMSData, config);

        dispatch({ type: HAZI_SMS_BULK_SEND_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_SMS_BULK_SEND_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const listContactGroups = (searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_GROUPS_LIST_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get(`/api/hazi/contacts?search=${searchQuery}`, config);

        dispatch({ type: HAZI_CONTACT_GROUPS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_GROUPS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const createContactGroup = (groupData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_GROUP_CREATE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/contacts/group/create', groupData, config);

        dispatch({ type: HAZI_CONTACT_GROUP_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_GROUP_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const editContactGroup = (groupId, updatedData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_GROUP_EDIT_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post(`/api/hazi/contacts/group/${groupId}`, updatedData, config);

        dispatch({ type: HAZI_CONTACT_GROUP_EDIT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_GROUP_EDIT_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const deleteContactGroup = (groupId) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_GROUP_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.post(`/api/hazi/contacts/group/${groupId}`, config);

        dispatch({ type: HAZI_CONTACT_GROUP_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_GROUP_DELETE_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const addContactsToGroup = (groupId, contacts) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_ADD_CONTACTS_TO_GROUP_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post(`/api/hazi/contacts/group/${groupId}/add`, {contacts }, config);

        dispatch({ type: HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_ADD_CONTACTS_TO_GROUP_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const listContactsInGroup = (groupId) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACTS_LIST_IN_GROUP_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get(`/api/hazi/contacts/group/${groupId}`, config);

        dispatch({ type: HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACTS_LIST_IN_GROUP_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const updateContactInGroup = (groupId, contactId, updatedContact) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post(`/api/hazi/contacts/group/${groupId}/contact/${contactId}`, updatedContact, config);

        dispatch({ type: HAZI_CONTACT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_UPDATE_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const deleteContactFromGroup = (groupId, contactId) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_CONTACT_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.delete(`/api/hazi/contacts/group/${groupId}/contact/${contactId}`, config);

        dispatch({ type: HAZI_CONTACT_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: HAZI_CONTACT_DELETE_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const listHaziWhatsappTemplates = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_TEMPLATES_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get('/api/hazi/whatsapp/templates', config);

        dispatch({ type: HAZI_WHATSAPP_TEMPLATES_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_TEMPLATES_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const createHaziWhatsappTextTemplate = (templateData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/whatsapp/create-text-template', templateData, config);

        dispatch({ type: HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const createHaziWhatsappMediaTemplate = (templateData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/whatsapp/create-media-template', templateData, config);

        dispatch({ type: HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const sendHaziWhatsappTemplateMessage = (messageData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/whatsapp/send-template', messageData, config);

        dispatch({ type: HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const sendHaziWhatsappSimpleMessage = (messageData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/whatsapp/send-simple', messageData, config);

        dispatch({ type: HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const listHaziWhatsappActiveBroadcasts = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_BROADCASTS_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get('/api/hazi/whatsapp/broadcasts', config);

        dispatch({ type: HAZI_WHATSAPP_BROADCASTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_BROADCASTS_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const listHaziWhatsAppAccounts = () => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.get('/api/hazi/whatsapp/linked-accounts', config);

        dispatch({ type: HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const onboardHaziClient = (clientData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axios.post('/api/hazi/whatsapp/onboarding-setup', clientData, config);

        dispatch({ type: HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const webhookDelivered = (webhookData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.post('/api/hazi/whatsapp/webhook/delivered', webhookData, config);

        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const webhookRead = (webhookData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_READ_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.post('/api/hazi/whatsapp/webhook/read', webhookData, config);

        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_WEBHOOK_READ_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};

export const webhookReply = (webhookData) => async (dispatch, getState) => {
    try {
        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        await axios.post('/api/hazi/whatsapp/webhook/reply', webhookData, config);

        dispatch({ type: HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS });
    } catch (error) {
        dispatch({
            type: HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL,
            payload: error.response?.data.message || error.message,
        });
    }
};


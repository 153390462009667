import axios from "axios";

import {
  AGENTPRODUCT_DETAILS_FAIL,
  AGENTPRODUCT_DETAILS_REQUEST,
  AGENTPRODUCT_DETAILS_SUCCESS,
  AGENTPRODUCTS_LIST_REQUEST,
  AGENTPRODUCTS_LIST_SUCCESS,
  AGENTPRODUCTS_LIST_FAIL,
  AGENT_PRODUCTS_REQUEST,
  AGENT_PRODUCTS_SUCCESS,
  AGENT_PRODUCTS_FAIL,
  AGENTPRODUCT_DELETE_REQUEST,
  AGENTPRODUCT_DELETE_FAIL,
  AGENTPRODUCT_DELETE_SUCCESS,
  AGENTPRODUCT_CREATE_FAIL,
  AGENTPRODUCT_CREATE_REQUEST,
  AGENTPRODUCT_CREATE_SUCCESS,
  AGENTPRODUCT_UPDATE_FAIL,
  AGENTPRODUCT_UPDATE_REQUEST,
  AGENTPRODUCT_UPDATE_SUCCESS,
} from "../constants/agentProductConstants";

export const listAgentProductDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENTPRODUCT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/agent-products/${id}`, 
      config
    );

    dispatch({
      type: AGENTPRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: AGENTPRODUCT_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAgentProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENTPRODUCTS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/agent-products`, 
      config
    );

    dispatch({ type: AGENTPRODUCTS_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENTPRODUCTS_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getAgentProducts = (agentId) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_PRODUCTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/agent-products/agent/${agentId}`, 
      config
    );

    dispatch({
      type: AGENT_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: AGENT_PRODUCTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteAgentProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENTPRODUCT_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/agent-products/${id}`, config); 

    console.log("delete is working");

    dispatch({ type: AGENTPRODUCT_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: AGENTPRODUCT_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createAgentProduct = (agentProduct) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENTPRODUCT_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/agent-products`,
      agentProduct,
      config
    );

    dispatch({ type: AGENTPRODUCT_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENTPRODUCT_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateAgentProduct =
   (id, agentProduct) => async (dispatch, getState) => {
     try {
       dispatch({ type: AGENTPRODUCT_UPDATE_REQUEST });

       const {
         userLogin: { userInfo },
       } = getState();

       const config = {
         headers: {
           Authorization: `Bearer ${userInfo.token}`,
           "Content-Type": "application/json",
         },
       };

       const { data } = await axios.put(
         `/api/agent-products/${id}`, 
         agentProduct,
         config
       );

       dispatch({ type: AGENTPRODUCT_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
         type: AGENTPRODUCT_UPDATE_FAIL,
         payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };


export const AGENT_CHECKINS_LIST_REQUEST = "AGENT_CHECKINS_LIST_REQUEST";
export const AGENT_CHECKINS_LIST_SUCCESS = "AGENT_CHECKINS_LIST_SUCCESS";
export const AGENT_CHECKINS_LIST_FAIL = "AGENT_CHECKINS_LIST_FAIL";

export const AGENT_CHECKINS_BY_PRODUCT_REQUEST = "AGENT_CHECKINS_BY_PRODUCT_REQUEST";
export const AGENT_CHECKINS_BY_PRODUCT_SUCCESS = "AGENT_CHECKINS_BY_PRODUCT_SUCCESS";
export const AGENT_CHECKINS_BY_PRODUCT_FAIL = "AGENT_CHECKINS_BY_PRODUCT_FAIL";

export const AGENT_CHECKIN_CREATE_REQUEST = "AGENT_CHECKIN_CREATE_REQUEST";
export const AGENT_CHECKIN_CREATE_SUCCESS = "AGENT_CHECKIN_CREATE_SUCCESS";
export const AGENT_CHECKIN_CREATE_FAIL = "AGENT_CHECKIN_CREATE_FAIL";

export const AGENT_CHECKIN_UPDATE_REQUEST = "AGENT_CHECKIN_UPDATE_REQUEST";
export const AGENT_CHECKIN_UPDATE_SUCCESS = "AGENT_CHECKIN_UPDATE_SUCCESS";
export const AGENT_CHECKIN_UPDATE_FAIL = "AGENT_CHECKIN_UPDATE_FAIL";

export const AGENT_LATEST_LOGOUT_CHECKIN_REQUEST = "AGENT_LATEST_LOGOUT_CHECKIN_REQUEST";
export const AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS = "AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS";
export const AGENT_LATEST_LOGOUT_CHECKIN_FAIL = "AGENT_LATEST_LOGOUT_CHECKIN_FAIL";

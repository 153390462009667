import {
    NOTIFICATIONS_LIST_REQUEST,
    NOTIFICATIONS_LIST_SUCCESS,
    NOTIFICATIONS_LIST_FAIL,
    NOTIFICATION_DETAILS_REQUEST,
    NOTIFICATION_DETAILS_SUCCESS,
    NOTIFICATION_DETAILS_FAIL,
    NOTIFICATION_DELETE_REQUEST,
    NOTIFICATION_DELETE_SUCCESS,
    NOTIFICATION_DELETE_FAIL,
    NOTIFICATION_CREATE_REQUEST,
    NOTIFICATION_CREATE_SUCCESS,
    NOTIFICATION_CREATE_FAIL,
    NOTIFICATION_CREATE_RESET,
    NOTIFICATION_UPDATE_REQUEST,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL,
    NOTIFICATION_UPDATE_RESET,
    USER_NOTIFICATIONS_REQUEST,
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAIL,
    MARK_NOTIFICATION_AS_READ_REQUEST,
    MARK_NOTIFICATION_AS_READ_SUCCESS,
    MARK_NOTIFICATION_AS_READ_FAIL,
    MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAIL,
} from '../constants/notificationConstants';

export const notificationsListReducer = (state = { notifications: [] }, action) => {
    switch (action.type) {
        case NOTIFICATIONS_LIST_REQUEST:
            return { loading: true, notifications: [] };
        case NOTIFICATIONS_LIST_SUCCESS:
            return { loading: false, notifications: action.payload };
        case NOTIFICATIONS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const notificationDetailsReducer = (state = { notification: {} }, action) => {
    switch (action.type) {
        case NOTIFICATION_DETAILS_REQUEST:
            return { ...state, loading: true };
        case NOTIFICATION_DETAILS_SUCCESS:
            return { loading: false, notification: action.payload };
        case NOTIFICATION_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const notificationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_DELETE_REQUEST:
            return { loading: true };
        case NOTIFICATION_DELETE_SUCCESS:
            return { loading: false, success: true };
        case NOTIFICATION_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const notificationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_CREATE_REQUEST:
            return { loading: true };
        case NOTIFICATION_CREATE_SUCCESS:
            return { loading: false, success: true, notification: action.payload };
        case NOTIFICATION_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case NOTIFICATION_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const notificationUpdateReducer = (state = { notification: {} }, action) => {
    switch (action.type) {
        case NOTIFICATION_UPDATE_REQUEST:
            return { loading: true };
        case NOTIFICATION_UPDATE_SUCCESS:
            return { loading: false, success: true, notification: action.payload };
        case NOTIFICATION_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case NOTIFICATION_UPDATE_RESET:
            return { notification: {} };
        default:
            return state;
    }
};

export const userNotificationsReducer = (state = { notifications: [] }, action) => {
    switch (action.type) {
        case USER_NOTIFICATIONS_REQUEST:
            return { loading: true, notifications: [] };
        case USER_NOTIFICATIONS_SUCCESS:
            return { loading: false, notifications: action.payload };
        case USER_NOTIFICATIONS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const markNotificationAsReadReducer = (state = {}, action) => {
    switch (action.type) {
        case MARK_NOTIFICATION_AS_READ_REQUEST:
            return { loading: true };
        case MARK_NOTIFICATION_AS_READ_SUCCESS:
            return { loading: false, success: true };
        case MARK_NOTIFICATION_AS_READ_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const markAllNotificationsAsReadReducer = (state = {}, action) => {
    switch (action.type) {
      case MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST:
        return { loading: true };
      case MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
        return { loading: false, success: true };
      case MARK_ALL_NOTIFICATIONS_AS_READ_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
export const CLIENT_CONTACTS_LIST_REQUEST = "CLIENT_CONTACTS_LIST_REQUEST";
export const CLIENT_CONTACTS_LIST_SUCCESS = "CLIENT_CONTACTS_LIST_SUCCESS";
export const CLIENT_CONTACTS_LIST_FAIL = "CLIENT_CONTACTS_LIST_FAIL";

export const CLIENT_CONTACTS_DETAILS_SUCCESS = "CLIENT_CONTACTS_DETAILS_SUCCESS";
export const CLIENT_CONTACTS_DETAILS_REQUEST = "CLIENT_CONTACTS_DETAILS_REQUEST";
export const CLIENT_CONTACTS_DETAILS_FAIL = "CLIENT_CONTACTS_DETAILS_FAIL";

export const CLIENT_CONTACTS_DELETE_REQUEST = "CLIENT_CONTACTS_DELETE_REQUEST";
export const CLIENT_CONTACTS_DELETE_SUCCESS = "CLIENT_CONTACTS_DELETE_SUCCESS";
export const CLIENT_CONTACTS_DELETE_FAIL = "CLIENT_CONTACTS_DELETE_FAIL";

export const CLIENT_CONTACTS_CREATE_REQUEST = "CLIENT_CONTACTS_CREATE_REQUEST";
export const CLIENT_CONTACTS_CREATE_SUCCESS = "CLIENT_CONTACTS_CREATE_SUCCESS";
export const CLIENT_CONTACTS_CREATE_FAIL = "CLIENT_CONTACTS_CREATE_FAIL";
export const CLIENT_CONTACTS_CREATE_RESET = "CLIENT_CONTACTS_CREATE_RESET";

export const CLIENT_CONTACTS_UPDATE_REQUEST = "CLIENT_CONTACTS_UPDATE_REQUEST";
export const CLIENT_CONTACTS_UPDATE_SUCCESS = "CLIENT_CONTACTS_UPDATE_SUCCESS";
export const CLIENT_CONTACTS_UPDATE_FAIL = "CLIENT_CONTACTS_UPDATE_FAIL";
export const CLIENT_CONTACTS_UPDATE_RESET = "CLIENT_CONTACTS_UPDATE_RESET";

export const PRODUCT_CLIENT_CONTACTS_REQUEST = "PRODUCT_CLIENT_CONTACTS_REQUEST";
export const PRODUCT_CLIENT_CONTACTS_SUCCESS = "PRODUCT_CLIENT_CONTACTS_SUCCESS";
export const PRODUCT_CLIENT_CONTACTS_FAIL = "PRODUCT_CLIENT_CONTACTS_FAIL";

export const AGENT_CLIENT_CONTACTS_REQUEST = "AGENT_CLIENT_CONTACTS_REQUEST";
export const AGENT_CLIENT_CONTACTS_SUCCESS = "AGENT_CLIENT_CONTACTS_SUCCESS";
export const AGENT_CLIENT_CONTACTS_FAIL = "AGENT_CLIENT_CONTACTS_FAIL";

export const BULK_CONTACTS_DELETE_REQUEST = "BULK_CONTACTS_DELETE_REQUEST";
export const BULK_CONTACTS_DELETE_SUCCESS = "BULK_CONTACTS_DELETE_SUCCESS";
export const BULK_CONTACTS_DELETE_FAIL = "BULK_CONTACTS_DELETE_FAIL";

export const AGENT_CLIENT_PREFIX_REQUEST = "AGENT_CLIENT_PREFIX_REQUEST";
export const AGENT_CLIENT_PREFIX_SUCCESS = "AGENT_CLIENT_PREFIX_SUCCESS";
export const AGENT_CLIENT_PREFIX_FAIL = "AGENT_CLIENT_PREFIX_FAIL";

export const BULK_CLIENT_CONTACTS_UPDATE_REQUEST = "BULK_CLIENT_CONTACTS_UPDATE_REQUEST";
export const BULK_CLIENT_CONTACTS_UPDATE_SUCCESS = "BULK_CLIENT_CONTACTS_UPDATE_SUCCESS";
export const BULK_CLIENT_CONTACTS_UPDATE_FAIL = "BULK_CLIENT_CONTACTS_UPDATE_FAIL";
export const BULK_CLIENT_CONTACTS_UPDATE_RESET = "BULK_CLIENT_CONTACTS_UPDATE_RESET";

export const CUSTOM_TAGS_UPDATE_REQUEST = 'CUSTOM_TAGS_UPDATE_REQUEST';
export const CUSTOM_TAGS_UPDATE_SUCCESS = 'CUSTOM_TAGS_UPDATE_SUCCESS';
export const CUSTOM_TAGS_UPDATE_FAIL = 'CUSTOM_TAGS_UPDATE_FAIL';

export const CUSTOM_TAGS_CREATE_REQUEST = 'CUSTOM_TAGS_CREATE_REQUEST';
export const CUSTOM_TAGS_CREATE_SUCCESS = 'CUSTOM_TAGS_CREATE_SUCCESS';
export const CUSTOM_TAGS_CREATE_FAIL = 'CUSTOM_TAGS_CREATE_FAIL';

export const CUSTOM_TAGS_FETCH_REQUEST = 'CUSTOM_TAGS_FETCH_REQUEST';
export const CUSTOM_TAGS_FETCH_SUCCESS = 'CUSTOM_TAGS_FETCH_SUCCESS';
export const CUSTOM_TAGS_FETCH_FAIL = 'CUSTOM_TAGS_FETCH_FAIL';

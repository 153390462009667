import React, { useState } from 'react';
import { Box, Text, Flex, Icon, HStack } from '@chakra-ui/react';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const SupervisorInbound = () => {
  const tabs = [    
    { label: 'Call Logs', icon: FiPhoneCall, path: '/supervisor-inbound/call-logs' },
    { label: 'Call Reports', icon: HiOutlineDocumentReport, path: '/supervisor-inbound/call-reports' },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].path);

  return (
    <Box p="4" m={4} alignItems="center" bg="white" borderRadius="md">
      <Box m={6} mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Inbound
        </Text>
      </Box>
      <Flex gridGap="2" flexWrap="wrap" justifyContent="center">
        {tabs.map((tab) => (
          <Link key={tab.path} to={tab.path} style={{ textDecoration: 'none' }}>
            <Box
              bg="white"
              p="4"
              borderWidth="3px"
              borderColor={activeTab === tab.path ? 'brandBlue' : 'blue.500'}
              borderRadius="lg"
              boxShadow="md"
              m={2}
              flex="1"
              minWidth={{ base: '300px',  lg: '200px' }}
              _hover={{
                borderColor: 'brandBlue',
                borderRadius: 'lg',
              }}
              onClick={() => setActiveTab(tab.path)}
            >
              <HStack spacing="2">
                <Icon as={tab.icon} />
                <Text>{tab.label}</Text>
              </HStack>
            </Box>
          </Link>
        ))}
      </Flex>
    </Box>
  );
};

export default SupervisorInbound;

export const ABANDONEDCALLS_LIST_REQUEST = "ABANDONEDCALLS_LIST_REQUEST";
export const ABANDONEDCALLS_LIST_SUCCESS = "ABANDONEDCALLS_LIST_SUCCESS";
export const ABANDONEDCALLS_LIST_FAIL = "ABANDONEDCALLS_LIST_FAIL";

export const ABANDONEDCALLS_DETAILS_SUCCESS = "ABANDONEDCALLS_DETAILS_SUCCESS";
export const ABANDONEDCALLS_DETAILS_REQUEST = "ABANDONEDCALLS_DETAILS_REQUEST";
export const ABANDONEDCALLS_DETAILS_FAIL = "ABANDONEDCALLS_DETAILS_FAIL";

export const ABANDONEDCALLS_DELETE_REQUEST = "ABANDONEDCALLS_DELETE_REQUEST";
export const ABANDONEDCALLS_DELETE_SUCCESS = "ABANDONEDCALLS_DELETE_SUCCESS";
export const ABANDONEDCALLS_DELETE_FAIL = "ABANDONEDCALLS_DELETE_FAIL";

export const ABANDONEDCALLS_CREATE_REQUEST = "ABANDONEDCALLS_CREATE_REQUEST";
export const ABANDONEDCALLS_CREATE_SUCCESS = "ABANDONEDCALLS_CREATE_SUCCESS";
export const ABANDONEDCALLS_CREATE_FAIL = "ABANDONEDCALLS_CREATE_FAIL";
export const ABANDONEDCALLS_CREATE_RESET = "ABANDONEDCALLS_CREATE_RESET";

export const ABANDONEDCALLS_UPDATE_REQUEST = "ABANDONEDCALLS_UPDATE_REQUEST";
export const ABANDONEDCALLS_UPDATE_SUCCESS = "ABANDONEDCALLS_UPDATE_SUCCESS";
export const ABANDONEDCALLS_UPDATE_FAIL = "ABANDONEDCALLS_UPDATE_FAIL";
export const ABANDONEDCALLS_UPDATE_RESET = "ABANDONEDCALLS_UPDATE_RESET";
export const AGENTPRODUCTS_LIST_REQUEST = "AGENTPRODUCTS_LIST_REQUEST";
export const AGENTPRODUCTS_LIST_SUCCESS = "AGENTPRODUCTS_LIST_SUCCESS";
export const AGENTPRODUCTS_LIST_FAIL = "AGENTPRODUCTS_LIST_FAIL";

export const AGENT_PRODUCTS_REQUEST = "AGENT_PRODUCTS_REQUEST";
export const AGENT_PRODUCTS_SUCCESS = "AGENT_PRODUCTS_SUCCESS";
export const AGENT_PRODUCTS_FAIL = "AGENT_PRODUCTS_FAIL";

export const AGENTPRODUCT_DETAILS_SUCCESS = "AGENTPRODUCT_DETAILS_SUCCESS";
export const AGENTPRODUCT_DETAILS_REQUEST = "AGENTPRODUCT_DETAILS_REQUEST";
export const AGENTPRODUCT_DETAILS_FAIL = "AGENTPRODUCT_DETAILS_FAIL";

export const AGENTPRODUCT_DELETE_REQUEST = "AGENTPRODUCT_DELETE_REQUEST";
export const AGENTPRODUCT_DELETE_SUCCESS = "AGENTPRODUCT_DELETE_SUCCESS";
export const AGENTPRODUCT_DELETE_FAIL = "AGENTPRODUCT_DELETE_FAIL";

export const AGENTPRODUCT_CREATE_REQUEST = "AGENTPRODUCT_CREATE_REQUEST";
export const AGENTPRODUCT_CREATE_SUCCESS = "AGENTPRODUCT_CREATE_SUCCESS";
export const AGENTPRODUCT_CREATE_FAIL = "AGENTPRODUCT_CREATE_FAIL";
export const AGENTPRODUCT_CREATE_RESET = "AGENTPRODUCT_CREATE_RESET";

export const AGENTPRODUCT_UPDATE_REQUEST = "AGENTPRODUCT_UPDATE_REQUEST";
export const AGENTPRODUCT_UPDATE_SUCCESS = "AGENTPRODUCT_UPDATE_SUCCESS";
export const AGENTPRODUCT_UPDATE_FAIL = "AGENTPRODUCT_UPDATE_FAIL";
export const AGENTPRODUCT_UPDATE_RESET = "AGENTPRODUCT_UPDATE_RESET";

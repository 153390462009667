export const SUPPORT_LIST_REQUEST = "SUPPORT_LIST_REQUEST";
export const SUPPORT_LIST_SUCCESS = "SUPPORT_LIST_SUCCESS";
export const SUPPORT_LIST_FAIL = "SUPPORT_LIST_FAIL";

export const SUPPORT_PRODUCT_LIST_REQUEST = "SUPPORT_PRODUCT_LIST_REQUEST";
export const SUPPORT_PRODUCT_LIST_SUCCESS = "SUPPORT_PRODUCT_LIST_SUCCESS";
export const SUPPORT_PRODUCT_LIST_FAIL = "SUPPORT_PRODUCT_LIST_FAIL";

export const SUPPORT_DETAILS_SUCCESS = "SUPPORT_DETAILS_SUCCESS";
export const SUPPORT_DETAILS_REQUEST = "SUPPORT_DETAILS_REQUEST";
export const SUPPORT_DETAILS_FAIL = "SUPPORT_DETAILS_FAIL";

export const SUPPORT_DELETE_REQUEST = "SUPPORT_DELETE_REQUEST";
export const SUPPORT_DELETE_SUCCESS = "SUPPORT_DELETE_SUCCESS";
export const SUPPORT_DELETE_FAIL = "SUPPORT_DELETE_FAIL";

export const SUPPORT_CREATE_REQUEST = "SUPPORT_CREATE_REQUEST";
export const SUPPORT_CREATE_SUCCESS = "SUPPORT_CREATE_SUCCESS";
export const SUPPORT_CREATE_FAIL = "SUPPORT_CREATE_FAIL";
export const SUPPORT_CREATE_RESET = "SUPPORT_CREATE_RESET";

export const SUPPORT_UPDATE_REQUEST = "SUPPORT_UPDATE_REQUEST";
export const SUPPORT_UPDATE_SUCCESS = "SUPPORT_UPDATE_SUCCESS";
export const SUPPORT_UPDATE_FAIL = "SUPPORT_UPDATE_FAIL";
export const SUPPORT_UPDATE_RESET = "SUPPORT_UPDATE_RESET";

import React, { useEffect , useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Text,
  Input,
  Heading,
  Stack,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import moment from "moment-timezone";

import { listAgentsWithSupervisor, updateAgent } from '../../actions/agentActions';
import { AGENT_UPDATE_RESET } from "../../constants/agentConstants";

const SupervisorAgents = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;
  const product_id = userInfo?.product_id || '';

  const { agents, loading, error } = useSelector((state) => state.agentSupervisorList);

  const { success: updateSuccess } = useSelector((state) => state.agentUpdate);

  const [editAgent, setEditAgent] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [agent, setAgent] = useState({
    name: "",
    email: "",
    phone:"",
    agent_code: "",
    product_id: "",
    password: "",
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(listAgentsWithSupervisor(product_id, searchQuery)); 
    }
  }, [dispatch, userInfo, product_id, searchQuery, updateSuccess]);

  const handleEdit = (agent) => {
    setEditAgent(agent);
    setAgent({
      name: agent.name,
      email: agent.email,
      phone: agent.phone,
      agent_code: agent.agent_code,
      product_id: agent.product_id,
      password: "", 
    });
  };

  const handleCloseModal = () => {
    setEditAgent(null);
  };

  const handleSaveEdit = () => {
    if (editAgent) {
      dispatch(updateAgent(editAgent.id, agent)); 
      dispatch({ type: AGENT_UPDATE_RESET });
      dispatch(listAgentsWithSupervisor(product_id, searchQuery)); 
      setEditAgent(null);
    }
  };
  

  return (
    <Box bg="whiteAlpha.500" borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Agents
        </Heading>

        <Box display="flex" alignItems="center" m={4}>
          <Text color="blue.500" fontSize="lg" mr={2}>
            Search:
          </Text>          
          <Input
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} 
            bgColor="white"
            borderRadius="lg"
            borderWidth="2px"
            borderColor="blue.500"
            padding="2"
            _hover={{ borderColor: 'white' }}
          />        
        </Box>
      </Flex>

      <Stack spacing={4}>
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Agent Code</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Date Created</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan="6" textAlign="center">Loading...</Td>
                </Tr>
              ) : error ? (
                <Tr>
                  <Td colSpan="6" textAlign="center">{error}</Td>
                </Tr>
              ) : !Array.isArray(agents) ? (
                <Tr>
                  <Td colSpan="6" textAlign="center">No agents available</Td>
                </Tr>
              ) : (
                agents.map((agent, index) => (                
                <Tr key={agent.id}>
                  <Td>{index + 1}</Td>
                  <Td>{agent.agent_code}</Td>
                  <Td>{agent.name}</Td>
                  <Td>{agent.email}</Td>
                  <Td>
                  {moment(agent.created_at)
                          .tz("Africa/Nairobi")
                          .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>
                  <Td>
                    <Flex>
                      <Button colorScheme="teal" bg="blue.500" mr="2" onClick={() => handleEdit(agent)}>
                        <Icon as={IoPencilSharp} />
                      </Button>
                      <Button colorScheme="red">
                        <Icon as={IoTrashBinSharp} />
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
                    ))
                  )}
              </Tbody>
            </Table>
          </Box>
        </Stack>

      <Spacer h="5" />

      {/* Modal for editing  an agent */}
      <Modal isOpen={!!editAgent} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Edit Agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <FormControl mb="4">
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={agent.name}
                onChange={(e) => setAgent({ ...agent, name: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="text"
                name="email"
                value={agent.email}
                onChange={(e) => setAgent({ ...agent, email: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                type="text"
                name="phone"
                value={agent.phone}
                onChange={(e) => setAgent({ ...agent, phone: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="agent_code">Agent Code</FormLabel>
              <Input
                type="text"
                name="agent_code"
                value={agent.agent_code}
                onChange={(e) => setAgent({ ...agent, agent_code: e.target.value })}
              />
            </FormControl>
            
            <FormControl mb="4">
              <FormLabel htmlFor="product_id">Product ID</FormLabel>
              <Input
                type="text"
                name="product_id"
                value={agent.product_id}
                readOnly  
                onChange={(e) => setAgent({ ...agent, product_id: e.target.value })}
              />
            </FormControl>
            
            <FormControl id='password'>
              <FormLabel htmlFor='password'>Password</FormLabel>
              <Input
                type='password'
                placeholder='************'
                value={agent.password}
                onChange={(e) => setAgent({ ...agent, password: e.target.value  })}
              />
					</FormControl>
            
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" bg='blue.500' mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            <Button colorScheme="blue" bg="brandGreen" onClick={handleSaveEdit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default SupervisorAgents;

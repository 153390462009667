import { MenuItem, SubMenu } from "react-pro-sidebar";
import { Flex, Icon } from "@chakra-ui/react";
import { Outlet, NavLink } from "react-router-dom";
import {
  MdContacts,
  MdOutlineHome,
  MdOutlineMail,
  MdOutlineSms,
  MdWhatsapp,
} from "react-icons/md";
import { FaChartSimple } from "react-icons/fa6";

import MainComponent from "./MainComponent";
import SubSidebar from "./SubSidebar";

const HaziMenu = () => {
  const smsTabs = [
    { label: "Quick Sms", path: "quick-sms" },
    { label: "Bulk SMS", path: "bulk-sms" },
  ];
  const emailTabs = [
    { label: "Quick Email", path: "quick-email" },
    { label: "Bulk Email", path: "bulk-email" },
    { label: "Campaigns", path: "campaigns-email" },
    { label: "Email Templates", path: "email-templates" },
    { label: "Verify Sender Email", path: "verify-emailsender" },
  ];
  const reportTabs = [
    { label: "All Messages", path: "report-allmessages" },
    { label: "Quick SMS", path: "report-quicksms" },
    { label: "Bulk SMS", path: "report-bulksms" },
    { label: "Quick SMS", path: "report-quickemail" },
    { label: "Bulk Email", path: "report-bulkemail" },
  ];

  return (
    <Flex>
      <SubSidebar>
        {/* Dashboard menuitem with no nested items */}
        <MenuItem icon={<Icon as={MdOutlineHome} />}>
          <NavLink
            to="dashboard"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#3182CE" : "black",
            })}
          >
            Dashboard
          </NavLink>
        </MenuItem>
        <MenuItem icon={<Icon as={MdContacts} />}>
          <NavLink
            to="contacts"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#3182CE" : "black",
            })}
          >
            Contacts
          </NavLink>
        </MenuItem>
        {/* sms submenu selection */}
        <SubMenu icon={<MdOutlineSms />} label="SMS">
          {smsTabs.map((tab) => (
            <MenuItem key={tab.path}>
              <NavLink
                to={tab.path}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#3182CE" : "black",
                })}
              >
                {tab.label}
              </NavLink>
            </MenuItem>
          ))}
        </SubMenu>

        {/* email submenu selection */}
        <SubMenu icon={<MdOutlineMail />} label="Email">
          {emailTabs.map((tab) => (
            <MenuItem key={tab.path}>
              <NavLink
                to={tab.path}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#3182CE" : "black",
                })}
              >
                {tab.label}
              </NavLink>
            </MenuItem>
          ))}
        </SubMenu>
        {/* Whatsapp menu item  */}
        <MenuItem icon={<Icon as={MdWhatsapp} />}>
          <NavLink
            to="whatsapp"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#3182CE" : "black",
            })}
          >
            WhatsApp
          </NavLink>
        </MenuItem>
        {/* Reports submenu selection */}
        <SubMenu icon={<FaChartSimple />} label="Reports">
          {reportTabs.map((tab) => (
            <MenuItem key={tab.path}>
              <NavLink
                to={tab.path}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#3182CE" : "black",
                })}
              >
                {tab.label}
              </NavLink>
            </MenuItem>
          ))}
        </SubMenu>
      </SubSidebar>
      <MainComponent width="81.8vw" marginLeft="14vw">
        <Outlet />
      </MainComponent>
    </Flex>
  );
};

export default HaziMenu;

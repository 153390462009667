import {
    Box,
    Flex,
    Heading,
    Icon,
    Text,
    Image,
    keyframes,
  } from '@chakra-ui/react';
  import { FaSmile, FaSpinner, FaWhatsappSquare, FaWeixin } from 'react-icons/fa';

  import FormContainer from '../../components/FormContainer';
  
  const moveAnimation = keyframes`
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
  `;
  
  const rotateAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;
  
  const WhatsappAgentSession = () => {        
    
    return (
      <Box bg="white" borderRadius="sm" m={4}>
        <Flex w={{ base: '80%', md: '60%' }} alignItems="center" justifyContent="center" py="5" mx="auto">
          <FormContainer>
            <Image
              src={require('../../images/scan.gif')}
              alt=""
              display={{ base: 'block', md: 'block' }}
              w={{ base: '80%', md: '50%' }}
              objectFit="cover"
              marginTop={0}
              alignSelf="center"
              borderRadius="45px"
            />
            <Heading as="h1" m={8} fontSize="3xl" color="black" mx="auto">
              WhatsApp Session
            </Heading>
                <Text fontSize="xl" color="gray.600" textAlign="center">
                  Contact your Supervisor for Clarification on Your WhatsApp Session.
                </Text>
                <Box className="icon-container" display="flex" gap={4} mt={8} mx="auto">
                  <Icon as={FaSmile} boxSize={12} animation={`${moveAnimation} 2s infinite`} color="teal.500" />
                  <Icon as={FaSpinner } boxSize={12} animation={`${rotateAnimation} 2s infinite linear`} color="yellow.500" />
                  <Icon as={FaWhatsappSquare} boxSize={12} animation={`${moveAnimation} 2s infinite 1s`} color="green.500" />
                  <Icon as={FaWeixin} boxSize={12} animation={`${moveAnimation} 2s infinite 1.5s`} color="blue.500" />
                </Box>
                          
          </FormContainer>
        </Flex>
  
        
      </Box>
    );
  };
  
  export default WhatsappAgentSession;
  
import {
    AGENTPRODUCTS_LIST_REQUEST,
    AGENTPRODUCTS_LIST_SUCCESS,
    AGENTPRODUCTS_LIST_FAIL,
    AGENT_PRODUCTS_REQUEST,
    AGENT_PRODUCTS_SUCCESS,
    AGENT_PRODUCTS_FAIL,
    AGENTPRODUCT_DETAILS_REQUEST,
    AGENTPRODUCT_DETAILS_SUCCESS,
    AGENTPRODUCT_DETAILS_FAIL,
    AGENTPRODUCT_DELETE_REQUEST,
    AGENTPRODUCT_DELETE_SUCCESS,
    AGENTPRODUCT_DELETE_FAIL,
    AGENTPRODUCT_CREATE_REQUEST,
    AGENTPRODUCT_CREATE_SUCCESS,
    AGENTPRODUCT_CREATE_FAIL,
    AGENTPRODUCT_CREATE_RESET,
    AGENTPRODUCT_UPDATE_REQUEST,
    AGENTPRODUCT_UPDATE_SUCCESS,
    AGENTPRODUCT_UPDATE_FAIL,
    AGENTPRODUCT_UPDATE_RESET,
  } from '../constants/agentProductConstants';
  
  export const agentProductListReducer = (state = { products: [] }, action) => {
    switch (action.type) {
      case AGENTPRODUCTS_LIST_REQUEST:
        return { ...state, loading: true };
      case AGENTPRODUCTS_LIST_SUCCESS:
        return { loading: false, products: action.payload };
      case AGENTPRODUCTS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const agentProductsReducer = (state = { agentProducts: [] }, action) => {
    switch (action.type) {
      case AGENT_PRODUCTS_REQUEST:
        return { ...state, loading: true };
      case AGENT_PRODUCTS_SUCCESS:
        return { loading: false, agentProducts: action.payload };
      case AGENT_PRODUCTS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const agentProductDetailsReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case AGENTPRODUCT_DETAILS_REQUEST:
        return { ...state, loading: true };
      case AGENTPRODUCT_DETAILS_SUCCESS:
        return { loading: false, product: action.payload };
      case AGENTPRODUCT_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const agentProductDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case AGENTPRODUCT_DELETE_REQUEST:
        return { loading: true };
      case AGENTPRODUCT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case AGENTPRODUCT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const agentProductCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case AGENTPRODUCT_CREATE_REQUEST:
        return { loading: true };
      case AGENTPRODUCT_CREATE_SUCCESS:
        return { loading: false, success: true, product: action.payload };
      case AGENTPRODUCT_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case AGENTPRODUCT_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const agentProductUpdateReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case AGENTPRODUCT_UPDATE_REQUEST:
        return { loading: true };
      case AGENTPRODUCT_UPDATE_SUCCESS:
        return { loading: false, success: true, product: action.payload };
      case AGENTPRODUCT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case AGENTPRODUCT_UPDATE_RESET:
        return { product: {} };
      default:
        return state;
    }
  };
  
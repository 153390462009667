const sampleData = [
    {
      "col_1": 712345678,
      "col_2": "Name 1",
      "col_3": "Last name",
      "col_4": "Organization name",
      "col_5": "Manager",
      "col_6": "email@example.org",
      "col_7": "Sacco Name",
      "col_8": "Merchant 1",
      "col_9": "Chanel Name",
      "col_10": ""
    },
    {
      "col_1": 787654321,
      "col_2": "Name 2",
      "col_3": "Last name",
      "col_4": "Organization name",
      "col_5": "Accountant",
      "col_6": "email@example.org",
      "col_7": "Sacco Name",
      "col_8": "Merchant 2",
      "col_9": "Chanel Name",
      "col_10": ""
    },
    {
      "col_1": 723566678,
      "col_2": "Name 3",
      "col_3": "Last name",
      "col_4": "Organization name",
      "col_5": "Driver",
      "col_6": "email@example.org",
      "col_7": "Sacco Name",
      "col_8": "Merchant 3",
      "col_9": "Chanel Name",
      "col_10": ""
    },
    {
      "col_1": 782677753,
      "col_2": "Name 4",
      "col_3": "Last name",
      "col_4": "Organization name",
      "col_5": "CEO",
      "col_6": "email@example.org",
      "col_7": "Sacco Name",
      "col_8": "Merchant 4",
      "col_9": "Chanel Name",
      "col_10": ""
    }
  ];
  
  export default sampleData;
  
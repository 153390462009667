import axios from "axios";

import {
  CLIENTS_DETAILS_FAIL,
  CLIENTS_DETAILS_REQUEST,
  CLIENTS_DETAILS_SUCCESS,
  CLIENTS_LIST_FAIL,
  CLIENTS_LIST_REQUEST,
  CLIENTS_LIST_SUCCESS,
  CLIENTS_DELETE_REQUEST,
  CLIENTS_DELETE_FAIL,
  CLIENTS_DELETE_SUCCESS,
  CLIENTS_CREATE_FAIL,
  CLIENTS_CREATE_REQUEST,
  CLIENTS_CREATE_SUCCESS,
  CLIENTS_UPDATE_FAIL,
  CLIENTS_UPDATE_REQUEST,
  CLIENTS_UPDATE_SUCCESS,  
  AGENTS_BY_PRODUCT_REQUEST,
  AGENTS_BY_PRODUCT_SUCCESS,
  AGENTS_BY_PRODUCT_FAIL,
} from '../constants/clientsConstants';

export const listClients = (searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENTS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/clients?search=${searchQuery}`,
      config
    );

    dispatch({ type: CLIENTS_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENTS_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listClientDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENTS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/clients/${id}`,
      config
    );
    
    dispatch({ type: CLIENTS_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENTS_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteClients = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENTS_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/admin/clients/${id}`,
      config
    );

    dispatch({ type: CLIENTS_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: CLIENTS_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createClient = (clients) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENTS_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/admin/clients`,
      clients,
      config
    );

    dispatch({ type: CLIENTS_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENTS_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateClient = (id, clientObj) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENTS_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/admin/clients/${id}`,
      clientObj,
      config
    );

    dispatch({ type: CLIENTS_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENTS_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAgentsByProduct = (productId, searchQuery = '') => async (dispatch, getState) => {
  try {

    dispatch({ type: AGENTS_BY_PRODUCT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/clients/agents/${productId}?search=${searchQuery}`,
      config
    );

    dispatch({ type: AGENTS_BY_PRODUCT_SUCCESS, payload: data });
  } catch (err) {
    console.error('Error fetching agents by product:', err.message);
    dispatch({
      type: AGENTS_BY_PRODUCT_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

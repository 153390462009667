import {
    AGENT_CHECKINS_LIST_FAIL,
    AGENT_CHECKINS_LIST_REQUEST,
    AGENT_CHECKINS_LIST_SUCCESS,
    AGENT_CHECKINS_BY_PRODUCT_FAIL,
    AGENT_CHECKINS_BY_PRODUCT_REQUEST,
    AGENT_CHECKINS_BY_PRODUCT_SUCCESS,
    AGENT_CHECKIN_CREATE_REQUEST,
    AGENT_CHECKIN_CREATE_SUCCESS,
    AGENT_CHECKIN_CREATE_FAIL,
    AGENT_CHECKIN_UPDATE_REQUEST,
    AGENT_CHECKIN_UPDATE_SUCCESS,
    AGENT_CHECKIN_UPDATE_FAIL,
    AGENT_LATEST_LOGOUT_CHECKIN_REQUEST,
    AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS,
    AGENT_LATEST_LOGOUT_CHECKIN_FAIL,
} from '../constants/agentCheckinsConstants';

export const agentCheckinsListReducer = (state = { agentCheckins: [] }, action) => {
    switch (action.type) {
        case AGENT_CHECKINS_LIST_REQUEST:
            return { loading: true, agentCheckins: [] };

        case AGENT_CHECKINS_LIST_SUCCESS:
            return { loading: false, agentCheckins: action.payload };

        case AGENT_CHECKINS_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const agentCheckinsByProductReducer = (state = { agentCheckins: [] }, action) => {
    switch (action.type) {
        case AGENT_CHECKINS_BY_PRODUCT_REQUEST:
            return { loading: true, agentCheckins: [] };

        case AGENT_CHECKINS_BY_PRODUCT_SUCCESS:
            return { loading: false, agentCheckins: action.payload };

        case AGENT_CHECKINS_BY_PRODUCT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const createAgentCheckinReducer = (state = {}, action) => {
  switch (action.type) {
      case AGENT_CHECKIN_CREATE_REQUEST:
          return { loading: true };

      case AGENT_CHECKIN_CREATE_SUCCESS:
          return { loading: false, success: true, agentCheckin: action.payload };

      case AGENT_CHECKIN_CREATE_FAIL:
          return { loading: false, error: action.payload };

      default:
          return state;
  }
};

export const updateAgentCheckinReducer = (state = {}, action) => {
    switch (action.type) {
      case AGENT_CHECKIN_UPDATE_REQUEST:
        return { loading: true };
      case AGENT_CHECKIN_UPDATE_SUCCESS:
        return { loading: false, success: true, agentCheckin: action.payload };
      case AGENT_CHECKIN_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const agentLatestLogoutCheckinReducer = (state = { checkins: [] }, action) => {
    switch (action.type) {
      case AGENT_LATEST_LOGOUT_CHECKIN_REQUEST:
        return { loading: true, checkins: [] };
      case AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS:
        return { loading: false, checkins: action.payload.checkins };
      case AGENT_LATEST_LOGOUT_CHECKIN_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
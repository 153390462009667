import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Text,
    Icon,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    useDisclosure,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { IoPencilSharp } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import {
    getProductInboundEscalatedCalls,
    updateEscalationDetails,
} from '../../actions/callDispositionActions';
import {
    getProductOutboundEscalatedCalls,
    updateOutboundEscalationDetails,
} from '../../actions/outboundDispositionAction';

const EscalatedCallsTable = ({ escalatedCalls, currentPage, handleEdit, paginate }) => {
    const itemsPerPage = 10;
    const totalPages = Math.ceil(escalatedCalls.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = escalatedCalls.slice(indexOfFirstItem, indexOfLastItem);

    const generatePageNumbers = () => {
        let pages = [];
        if (totalPages <= 5) {
            pages = [...Array(totalPages).keys()].map((i) => i + 1);
        } else {
            if (currentPage <= 3) {
                pages = [1, 2, 3, '...', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
            } else {
                pages = [1, '...', currentPage, '...', totalPages];
            }
        }
        return pages;
    };

    return (
        <>
            <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>No.</Th>
                            <Th>Ticket</Th>
                            <Th>Caller</Th>
                            <Th>Attended By</Th>
                            <Th>Reason</Th>
                            <Th>Description</Th>
                            <Th>Escalation Step</Th>
                            <Th>Escalation Description</Th>
                            <Th>Escalated At</Th>
                            <Th>Action</Th>
                            <Th>Location</Th>
                            <Th>Client Name</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems.map((call, index) => (
                            <Tr key={call.id}>
                                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                                <Td>{call.ticket_number}</Td>
                                <Td>{call.Caller}</Td>
                                <Td>{call.Attendedby}</Td>
                                <Td>{call.CallerReason}</Td>
                                <Td>{call.Description}</Td>
                                <Td>{call.EscalationStep}</Td>
                                <Td>{call.EscalationDescription}</Td>
                                <Td>
                                    {moment(call.created_at)
                                        .tz('Africa/Nairobi')
                                        .format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                </Td>
                                <Td>
                                    <Button
                                        colorScheme="teal"
                                        bg="blue.500"
                                        mr="2"
                                        onClick={() => handleEdit(call)}
                                    >
                                        <Icon as={IoPencilSharp} />
                                    </Button>
                                </Td>
                                <Td>{call.CallerLocation}</Td>
                                <Td>{call.ClientName}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

            <Flex justify="center" mt={4}>
                <Button
                    onClick={() => paginate(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    mr={2}
                >
                    Previous
                </Button>
                {generatePageNumbers().map((page, index) =>
                    typeof page === 'number' ? (
                        <Button
                            key={index}
                            onClick={() => paginate(page)}
                            variant={currentPage === page ? 'solid' : 'outline'}
                            mr={2}
                        >
                            {page}
                        </Button>
                    ) : (
                        <Button key={index} variant="outline" disabled>
                            {page}
                        </Button>
                    )
                )}
                <Button
                    onClick={() => paginate(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    ml={2}
                >
                    Next
                </Button>
            </Flex>
        </>
    );
};

const SupportEscalated = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [editedCall, setEditedCall] = useState(null);
    const [escalationStep, setEscalationStep] = useState('');
    const [escalationDescription, setEscalationDescription] = useState('');
    const [activeTab, setActiveTab] = useState(0); // 0 for Inbound, 1 for Outbound

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const product_id = userInfo?.product_id || '';

    const { success: updateInboundSuccess } = useSelector((state) => state.updateEscalationDetails);

    const { success: updateOutboundSuccess } = useSelector((state) => state.updateOutboundEscalationDetails);

    const handleTabChange = (index) => {
        setActiveTab(index);
        setSearchInput('');
        setCurrentPage(1);
        if (index === 0) {
            dispatch(getProductInboundEscalatedCalls(product_id, searchInput));
        } else {
            dispatch(getProductOutboundEscalatedCalls(product_id, searchInput));
        }
    };

    useEffect(() => {
        if (userInfo) {
            if (activeTab === 0) {
                dispatch(getProductInboundEscalatedCalls(product_id, searchInput));
            } else {
                dispatch(getProductOutboundEscalatedCalls(product_id, searchInput));
            }
        }
    }, [dispatch, userInfo, product_id, searchInput, activeTab, updateInboundSuccess, updateOutboundSuccess]);

    const inboundProductEscalatedCalls = useSelector((state) => state.inboundProductEscalatedCalls);
    const outboundProductEscalatedCalls = useSelector((state) => state.getOutboundEscalatedCallsProduct);

    const inboundEscalatedCalls = inboundProductEscalatedCalls?.escalatedCalls || [];
    const outboundEscalatedCalls = outboundProductEscalatedCalls?.escalatedCalls || [];

    const handleEdit = (call) => {
        setEditedCall(call);
        setEscalationStep(call.EscalationStep);
        setEscalationDescription(call.EscalationDescription);
        onOpen();
    };

    const handleCloseModal = () => {
        setEditedCall(null);
        setEscalationStep('');
        setEscalationDescription('');
        onClose();
    };

    const handleUpdate = () => {
        if (editedCall) {
            const updateAction =
                activeTab === 0 ? updateEscalationDetails : updateOutboundEscalationDetails;
            dispatch(updateAction(editedCall.id, escalationStep, escalationDescription))
                .then(() => {
                    if (activeTab === 0) {
                        dispatch(getProductInboundEscalatedCalls(product_id, searchInput));
                    } else {
                        dispatch(getProductOutboundEscalatedCalls(product_id, searchInput));
                    }
                    handleCloseModal();
                })
                .catch((error) => {
                    console.error('Error updating escalation details:', error);
                });
        }
    };

    const handleViewTicket = () => {
        navigate(`/support-escalations/${editedCall.ticket_number}`);
    };


    return (
        <Box bg="white" borderRadius="sm" m={4}>
            <Text fontSize="xl" fontWeight="bold" mb={4} mt={4} ml={5}>Escalated Calls</Text>
            <Tabs variant="enclosed" onChange={handleTabChange}>
                <TabList>
                    <Tab>Inbound Escalated Calls</Tab>
                    <Tab>Outbound Escalated Calls</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex justifyContent="space-between" mt="2">
                            <Text as="h1" fontSize='xl' mb="5" ml={8} mt={4}>
                                Escalated Inbound Calls
                            </Text>
                            <Input
                                type="text"
                                placeholder="Search..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                m={8}
                                maxWidth="500px"
                                borderColor="blue.400"
                                borderWidth="2px"
                            />
                        </Flex>
                        <EscalatedCallsTable
                            escalatedCalls={inboundEscalatedCalls}
                            currentPage={currentPage}
                            handleEdit={handleEdit}
                            paginate={setCurrentPage}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Flex justifyContent="space-between" mt="2">
                            <Text as="h1" fontSize='xl' mb="5" ml={8} mt={4}>
                                Escalated Outbound Calls
                            </Text>
                            <Input
                                type="text"
                                placeholder="Search..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                m={8}
                                maxWidth="500px"
                                borderColor="blue.400"
                                borderWidth="2px"
                            />
                        </Flex>
                        <EscalatedCallsTable
                            escalatedCalls={outboundEscalatedCalls}
                            currentPage={currentPage}
                            handleEdit={handleEdit}
                            paginate={setCurrentPage}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Escalation Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb="4">
                            <FormLabel>Escalation Step</FormLabel>
                            <Select
                                value={escalationStep}
                                onChange={(e) => setEscalationStep(e.target.value)}
                            >
                                <option value="Pending">Pending</option>
                                <option value="In Progress">In Progress</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Fully Resolved">Fully Resolved</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Escalation Description</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter description"
                                value={escalationDescription}
                                onChange={(e) => setEscalationDescription(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleUpdate}>
                            Update
                        </Button>
                        <Button colorScheme="green" mr={3} onClick={handleViewTicket}>
                            View
                        </Button>
                        <Button variant="ghost" colorScheme="red" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default SupportEscalated;

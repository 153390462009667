import {
    CLIENT_CONTACTS_LIST_FAIL,
    CLIENT_CONTACTS_LIST_REQUEST,
    CLIENT_CONTACTS_LIST_SUCCESS,
    CLIENT_CONTACTS_DETAILS_FAIL,
    CLIENT_CONTACTS_DETAILS_REQUEST,
    CLIENT_CONTACTS_DETAILS_SUCCESS,
    CLIENT_CONTACTS_DELETE_FAIL,
    CLIENT_CONTACTS_DELETE_REQUEST,
    CLIENT_CONTACTS_DELETE_SUCCESS,
    CLIENT_CONTACTS_CREATE_FAIL,
    CLIENT_CONTACTS_CREATE_REQUEST,
    CLIENT_CONTACTS_CREATE_SUCCESS,
    CLIENT_CONTACTS_CREATE_RESET,
    CLIENT_CONTACTS_UPDATE_FAIL,
    CLIENT_CONTACTS_UPDATE_REQUEST,
    CLIENT_CONTACTS_UPDATE_SUCCESS,
    CLIENT_CONTACTS_UPDATE_RESET,
    PRODUCT_CLIENT_CONTACTS_REQUEST,
    PRODUCT_CLIENT_CONTACTS_SUCCESS,
    PRODUCT_CLIENT_CONTACTS_FAIL,
    AGENT_CLIENT_CONTACTS_REQUEST,
    AGENT_CLIENT_CONTACTS_SUCCESS,
    AGENT_CLIENT_CONTACTS_FAIL,
    BULK_CONTACTS_DELETE_REQUEST,
    BULK_CONTACTS_DELETE_SUCCESS,
    BULK_CONTACTS_DELETE_FAIL,
    AGENT_CLIENT_PREFIX_REQUEST,
    AGENT_CLIENT_PREFIX_SUCCESS,
    AGENT_CLIENT_PREFIX_FAIL,
    BULK_CLIENT_CONTACTS_UPDATE_REQUEST,
    BULK_CLIENT_CONTACTS_UPDATE_SUCCESS,
    BULK_CLIENT_CONTACTS_UPDATE_FAIL,
    CUSTOM_TAGS_UPDATE_REQUEST,
    CUSTOM_TAGS_UPDATE_SUCCESS,
    CUSTOM_TAGS_UPDATE_FAIL,
    CUSTOM_TAGS_FETCH_REQUEST,
    CUSTOM_TAGS_FETCH_SUCCESS,
    CUSTOM_TAGS_FETCH_FAIL,
} from '../constants/clientContactsConstants';
  
export const clientContactsListReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case CLIENT_CONTACTS_LIST_REQUEST:
            return { loading: true, contacts: [] };
        case CLIENT_CONTACTS_LIST_SUCCESS:
            return { loading: false, contacts: action.payload.data };
        case CLIENT_CONTACTS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
  
export const clientContactsDetailsReducer = (state = { contact: {} }, action) => {
    switch (action.type) {
        case CLIENT_CONTACTS_DETAILS_REQUEST:
            return { ...state, loading: true };
        case CLIENT_CONTACTS_DETAILS_SUCCESS:
            return { loading: false, contact: action.payload };
        case CLIENT_CONTACTS_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
  
export const clientContactsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_CONTACTS_DELETE_REQUEST:
            return { loading: true };
        case CLIENT_CONTACTS_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CLIENT_CONTACTS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
  
export const clientContactsCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_CONTACTS_CREATE_REQUEST:
            return { loading: true };
        case CLIENT_CONTACTS_CREATE_SUCCESS:
            return { loading: false, success: true, contact: action.payload };
        case CLIENT_CONTACTS_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case CLIENT_CONTACTS_CREATE_RESET:
            return {};
        default:
            return state;
    }
};
  
export const clientContactsUpdateReducer = (state = { contact: {} }, action) => {
    switch (action.type) {
        case CLIENT_CONTACTS_UPDATE_REQUEST:
            return { loading: true };
        case CLIENT_CONTACTS_UPDATE_SUCCESS:
            return { loading: false, success: true, contact: action.payload };
        case CLIENT_CONTACTS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CLIENT_CONTACTS_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};  

export const productClientContactsReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case PRODUCT_CLIENT_CONTACTS_REQUEST:
            return { loading: true, contacts: [] };
        case PRODUCT_CLIENT_CONTACTS_SUCCESS:
            return { loading: false, contacts: action.payload.data };
        case PRODUCT_CLIENT_CONTACTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const agentClientContactsReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case AGENT_CLIENT_CONTACTS_REQUEST:
            return { loading: true, contacts: [] };
        case AGENT_CLIENT_CONTACTS_SUCCESS:
            return { loading: false, contacts: action.payload.data };
        case AGENT_CLIENT_CONTACTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const bulkContactsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case BULK_CONTACTS_DELETE_REQUEST:
            return { loading: true };
        case BULK_CONTACTS_DELETE_SUCCESS:
            return { loading: false, success: true };
        case BULK_CONTACTS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const clientPrefixByAgentReducer = (state = { clientsPrefix: [] }, action) => {
    switch (action.type) {
        case AGENT_CLIENT_PREFIX_REQUEST:
            return { loading: true, clientsPrefix: [] };
        case AGENT_CLIENT_PREFIX_SUCCESS:
            return { loading: false, clientsPrefix: action.payload.data };
        case AGENT_CLIENT_PREFIX_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const bulkContactsUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case BULK_CLIENT_CONTACTS_UPDATE_REQUEST:
        return { loading: true };
      case BULK_CLIENT_CONTACTS_UPDATE_SUCCESS:
        return { loading: false, success: true, data: action.payload };
      case BULK_CLIENT_CONTACTS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};

export const customTagsUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CUSTOM_TAGS_UPDATE_REQUEST:
            return { loading: true };
        case CUSTOM_TAGS_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CUSTOM_TAGS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const customTagsFetchReducer = (state = { customTags: {} }, action) => {
    switch (action.type) {
        case CUSTOM_TAGS_FETCH_REQUEST:
            return { loading: true, customTags: {} };
        case CUSTOM_TAGS_FETCH_SUCCESS:
            return { loading: false, customTags: action.payload };
        case CUSTOM_TAGS_FETCH_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

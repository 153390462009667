import {
    WHATSAPP_OUTBOUND_LIST_REQUEST,
    WHATSAPP_OUTBOUND_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_LIST_FAIL,
    WHATSAPP_OUTBOUND_DETAILS_REQUEST,
    WHATSAPP_OUTBOUND_DETAILS_SUCCESS,
    WHATSAPP_OUTBOUND_DETAILS_FAIL,
    WHATSAPP_OUTBOUND_CREATE_REQUEST,
    WHATSAPP_OUTBOUND_CREATE_SUCCESS,
    WHATSAPP_OUTBOUND_CREATE_FAIL,
    WHATSAPP_OUTBOUND_CREATE_RESET,
    WHATSAPP_OUTBOUND_DELETE_REQUEST,
    WHATSAPP_OUTBOUND_DELETE_SUCCESS,
    WHATSAPP_OUTBOUND_DELETE_FAIL,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL,
} from '../constants/whatsappOutboundConstants';

export const whatsappOutboundListReducer = (state = { whatsappOutbounds: [] }, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_LIST_REQUEST:
            return { loading: true, whatsappOutbounds: [] };
        case WHATSAPP_OUTBOUND_LIST_SUCCESS:
            return { loading: false, whatsappOutbounds: action.payload };
        case WHATSAPP_OUTBOUND_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const whatsappOutboundDetailsReducer = (state = { whatsappOutbound: {} }, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_DETAILS_REQUEST:
            return { ...state, loading: true };
        case WHATSAPP_OUTBOUND_DETAILS_SUCCESS:
            return { loading: false, whatsappOutbound: action.payload };
        case WHATSAPP_OUTBOUND_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const whatsappOutboundDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_DELETE_REQUEST:
            return { loading: true };
        case WHATSAPP_OUTBOUND_DELETE_SUCCESS:
            return { loading: false, success: true };
        case WHATSAPP_OUTBOUND_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const whatsappOutboundCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_CREATE_REQUEST:
            return { loading: true };
        case WHATSAPP_OUTBOUND_CREATE_SUCCESS:
            return { loading: false, success: true, whatsappOutbound: action.payload };
        case WHATSAPP_OUTBOUND_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case WHATSAPP_OUTBOUND_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const whatsappOutboundProductListReducer = (state = { whatsappOutbounds: [] }, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST:
            return { loading: true, whatsappOutbounds: [] };
        case WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS:
            return { loading: false, whatsappOutbounds: action.payload.data };
        case WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const whatsappOutboundProductDateRangeListReducer = (state = { whatsappOutbounds: [] }, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST:
            return { loading: true, whatsappOutbounds: [] };
        case WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS:
            return { loading: false, whatsappOutbounds: action.payload };
        case WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const whatsappOutboundDateRangeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST:
            return { loading: true };
        case WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Heading, Icon } from '@chakra-ui/react';
import { FaPhone, FaClock, FaUser, FaPhoneSlash, FaRegBookmark } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { getAgentCallStats, getAgentOutboundStats, getAgentDailyStats } from '../../actions/statsActions';

const StatCard = ({ bgColor, icon, label, value }) => (
  <Flex
    bg="white"
    p="4"
    borderWidth="3px"
    borderColor="grey.100"
    borderRadius="lg"
    boxShadow="md"
    m={2}
    width={{ base: '100%', md: '40%' }}
    align="center"
    mb={{ base: '2', md: '0' }}
    mx="auto"
  >
    <Box
      bgColor={bgColor}
      m={2}
      h="40px"
      w="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icon as={icon} color="white" fontSize="2.0em" />
    </Box>
    <Box>
      <Text>{`${label}: ${value}`}</Text>
    </Box>
  </Flex>
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  const {
    agentAnsweredCount,
    agentNoAnswerCount,
    agentTalkTime,
    agentAht,
    loading: statsLoading,
    error: statsError,
  } = useSelector((state) => state.agentCallStats);

  const {
    answeredCount: outboundAnsweredCount,
    notCalledCount: outboundNotCalledCount,
    notAnsweredCount: outboundNotAnsweredCount,
    unreachableCount: outboundUnreachableCount,
    notExistCount: outboundNotExistCount,
    busyCount: outboundBusyCount,
    restrictedCount: outboundRestrictedCount,
    notInServiceCount: outboundNotInserviceCount,
    loading: outboundStatsLoading,
    error: outboundStatsError,
  } = useSelector((state) => state.agentOutboundStats);

  const {
    inboundStats,
    outboundStats,
    loading: dailyStatsLoading,
    error: dailyStatsError,
  } = useSelector((state) => state.agentDailyStats);

  const [agentDetails, setAgentDetails] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchAgentDetails = async (user_id) => {
      try {
        const { data } = await axios.get(`/api/admin/agents/details/${user_id}`);
        setAgentDetails(data.data);

        const agentExt = data.data.agent_code;
        const agentid = data.data.id;
        const agent = userInfo && userInfo.name ? userInfo.name : "";

        dispatch(getAgentCallStats(agentExt));
        dispatch(getAgentOutboundStats(agentid));
        dispatch(getAgentDailyStats(agent));

      } catch (error) {
        setError(error.response ? error.response.data.message : 'Internal Server Error');
        setLoading(false); 
      }
    };

    if (userInfo && userInfo.id) {
      setLoading(true); 
      fetchAgentDetails(userInfo.id);
    }
  }, [dispatch, userInfo]);

  if (statsLoading || outboundStatsLoading || dailyStatsLoading) {
    return null; 
  }

  if (error || statsError || outboundStatsError || dailyStatsError) {
    return <p>Error: {error || statsError || outboundStatsError || dailyStatsError}</p>;
  }

  console.log('Loading', loading);

  return (
    <Flex direction="column" p="4" borderRadius="md" m={2}>
      <Heading as="h2" size="md" mb="4">
        Dashboard
      </Heading>

      {agentDetails && (
        <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
          <Heading as="h3" size="md" mb="2">
            Inbound Today Stats
          </Heading>
          <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
            <StatCard bgColor="teal" icon={FaPhone} label="Answered Calls" value={agentAnsweredCount || 0} />
            <StatCard
              bgColor="orange"
              icon={FaPhoneSlash}
              label="Not Answered Calls"
              value={agentNoAnswerCount || 0}
            />
            <StatCard bgColor="green" icon={FaClock} label="Talk Time (Mins)" value={agentTalkTime || 0} />
            <StatCard bgColor="red" icon={FaUser} label="AHT (Mins)" value={agentAht || 0} />
          </Flex>
        </Box>
      )}

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Outbound Today Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="teal" icon={FaPhone} label="Answered" value={outboundAnsweredCount || 0} />
          <StatCard
            bgColor="orange"
            icon={FaPhoneSlash}
            label="Not Answered Calls"
            value={outboundNotAnsweredCount || 0}
          />
          <StatCard
            bgColor="red"
            icon={FaPhone}
            label="Not Called"
            value={outboundNotCalledCount || 0}
          />
          <StatCard
            bgColor="purple"
            icon={FaPhone}
            label="Unreachable"
            value={outboundUnreachableCount || 0}
          />
          <StatCard
            bgColor="yellow"
            icon={FaPhone}
            label="Not Exist"
            value={outboundNotExistCount || 0}
          />
          <StatCard
            bgColor="blue"
            icon={FaPhone}
            label="Busy"
            value={outboundBusyCount || 0}
          />
          <StatCard
            bgColor="green"
            icon={FaPhone}
            label="Restricted"
            value={outboundRestrictedCount || 0}
          />
          <StatCard
            bgColor="gray"
            icon={FaPhone}
            label="Not Inservice"
            value={outboundNotInserviceCount || 0}
          />
        </Flex>
      </Box>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Disposition Today Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="green" icon={FaRegBookmark} label="Inbound Dispositions" value={inboundStats?.length || 0} />
          <StatCard bgColor="teal" icon={FaRegBookmark} label="Outbound Dispositions" value={outboundStats?.length || 0} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Dashboard;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Input,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuDivider,
  MenuList,
  HStack,
  VStack,
  Text,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { FiBell, FiChevronDown } from "react-icons/fi";

import { logout } from "../actions/userActions";

const HeaderNew = () => {
  const mainBoxColor = useColorModeValue("#f8f8f8", "#1A202c");
  const borderColor = useColorModeValue("#E0E0E0", "#4A5568");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  if (!userInfo) {
    return null;
  }
  return (
    <Box
      p="6px"
      border="1px solid"
      borderColor={borderColor}
      bg={mainBoxColor}
      borderRadius="6px"
    >
      <Flex gap={4} justifyContent="flex-end" alignItems="center">
        <Input placeholder="search ..." size="sm" width="300px" />
        {/* Notification Icon */}
        <NavLink to="/notifications" style={{ textDecoration: "none" }}>
          <IconButton
            size="sm"
            variant="outline"
            aria-label="open menu"
            color="blue.500"
            _hover={{ bg: "blue.100" }}
            icon={<FiBell />}
          />
        </NavLink>
        {userInfo ? (
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
              mr={4}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    userInfo?.icon
                      ? userInfo?.icon
                      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display="flex"
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{userInfo?.name}</Text>
                  <Text fontSize="xs" color="white">
                    Welcome ✨
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="brandBlue" color="black" borderColor="blue.500">
              <MenuItem>
                <NavLink to="/profile" style={{ textDecoration: "none" }}>
                  Profile
                </NavLink>
              </MenuItem>
              <MenuDivider />
              {userInfo.role === "super-admin" && (
                <>
                  <MenuItem>
                    <NavLink
                      to="/admin/userlist"
                      style={{ textDecoration: "none" }}
                    >
                      Manage Users
                    </NavLink>
                  </MenuItem>
                  <MenuDivider />
                </>
              )}
              <MenuItem onClick={logoutHandler}>
                  Logout
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Text color="white">Not logged in</Text>
        )}
      </Flex>
    </Box>
  );
};

export default HeaderNew;

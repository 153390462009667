import { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FaPlus } from 'react-icons/fa';
import { IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";

import { listClientDetails, listAgentsByProduct } from "../../actions/clientsActions";
import { createAgent, updateAgent, deleteAgent } from "../../actions/agentActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { AGENT_UPDATE_RESET } from "../../constants/agentConstants";

const ClientViewScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  const clientsDetails = useSelector((state) => state.clientsDetails);
  const { loading, error, client } = clientsDetails;

  const { success: createSuccess } = useSelector((state) => state.agentCreate);  

  const { success: updateSuccess } = useSelector((state) => state.agentUpdate);

  const agentsByProduct = useSelector((state) => state.agentsByProduct);
  const { loading: agentLoading, error: agentError, agents } = agentsByProduct;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agent, setAgent] = useState({
    name: "",
    email: "",
    phone:"",
    agent_code: "",
    product_id: "",
    password: "",
  });

  
  useEffect(() => {

    if (!userInfo) {
      console.log('Redirecting to login...');
      navigate("/login");
    }

  }, [userInfo, navigate]);

  useEffect(() => {
    if (userInfo && id) {
      dispatch(listClientDetails(id));
    }
  }, [dispatch, userInfo, id, createSuccess, updateSuccess]);

  useEffect(() => {
    if (client && client.product_id) {
      dispatch(listAgentsByProduct(client.id, searchQuery));
    }
  }, [dispatch, client, createSuccess, updateSuccess, searchQuery]);
  
  
  const openModal = () => {    
      setAgent({
        name: "",
        email: "",
        phone:"",
        agent_code: "",
        product_id: client.product_id,
        password: "",
      });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setAgent({
      name: "",
      email: "",
      phone: "",
      agent_code: "",
      product_id: "",
      password: "",
    });
    setIsModalOpen(false);
    setIsEditMode(false); 
  };
  

  const handleEdit = (agent) => {
    setAgent({
      id: agent.id,
      name: agent.name,
      email: agent.email,
      phone: agent.phone,
      agent_code: agent.agent_code,
      product_id: agent.product_id,
      password: "", 
    });
  
    setIsEditMode(true);
    setIsModalOpen(true);
  };
  

  const handleSave = async () => {
    if (isEditMode) {
      dispatch(updateAgent(agent.id, agent));
    } else {
      dispatch(createAgent(agent));
    }
    dispatch({ type: AGENT_UPDATE_RESET });    
    closeModal();

    dispatch(listAgentsByProduct(client.id, searchQuery));
    
  };

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteAgent(id));      
      dispatch(listAgentsByProduct(client.id, searchQuery));
    }
  };
 
   return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
        Client Details
      </Heading>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5" overflow="auto">
          <Table variant="striped" colorScheme="gray" size="md">
            <Thead>
              <Tr>
                <Th borderBottom="1px" borderColor="gray.300">ID</Th>
                <Th borderBottom="1px" borderColor="gray.300">Product ID</Th>
                <Th borderBottom="1px" borderColor="gray.300">Prefix</Th>
                <Th borderBottom="1px" borderColor="gray.300">Name</Th>
                <Th borderBottom="1px" borderColor="gray.300">Facebook ID</Th>
                <Th borderBottom="1px" borderColor="gray.300">Date Created</Th>
              </Tr>
            </Thead>
            <Tbody>
            <Tr>
              {client ? (
                <>
                  <Td>{client.id}</Td>
                  <Td>{client.product_id}</Td>
                  <Td>{client.prefix}</Td>
                  <Td>{client.name}</Td>
                  <Td>{client.facebook_page_id}</Td>
                  <Td>
                    {moment(client.created_at)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>                  
                </>
              ) : (
                <Td colSpan="6">Client details not available.</Td>
              )}
            </Tr>
            </Tbody>
          </Table>
        </Box>
      )}      

      <Flex
        m={4}      
        justifyContent="space-between"
        alignItems="center"
        bg='white'
        rounded='md'
      >
        <Box mt={2} ml={8} fontSize={{ base: 'xl', md: '2xl' }}>
         {client?.name} Agents
        </Box>
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          m={8}
          maxWidth='500px'
          borderColor='blue.400'
          borderWidth='2px'
        />
        <Box float="right" margin="5px" marginRight="25px">
          <Button colorScheme="blue" size="sm" variant="outline" onClick={() => openModal()}>
            <Icon as={FaPlus} mr="2" />
            Add Agent
          </Button>
        </Box>
      </Flex>

       {/* Table to list agents under the current product */}
        {agentLoading ? (
          <Loader />
        ) : agentError ? (
          <Message type="error">{agentError}</Message>
        ) : (
          <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Id</Th>
                  <Th>Agent Code</Th>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Date Created</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {agents && agents.map((agent, index) => (
                  <Tr key={agent.id}>
                    <Td>{index + 1}</Td>
                    <Td>{agent.id}</Td>
                    <Td>{agent.agent_code}</Td>
                    <Td>{agent.name}</Td>
                    <Td>{agent.email}</Td>
                    <Td>
                      {moment(agent.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>
                      <Flex alignItems="center">
                        <Button
                          colorScheme="blue"
                          bg="blue.500"
                          mr="2"
                          onClick={() => handleEdit(agent)}
                        >
                          <Icon as={IoPencilSharp} />
                        </Button>
                        <Button
                          mr="4"
                          colorScheme="red"
                          onClick={() => deleteHandler(agent.id)}
                        >
                          <Icon as={IoTrashBinSharp} color="white" size="md" />
                        </Button> 
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
          </Table>
        </Box>
        )}
      
      <Spacer h="5" />
      
      {/* Modal for creatinn or editing  an agent */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>{isEditMode ? "Edit Agent" : "Add Agent"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <FormControl mb="4">
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={agent.name}
                onChange={(e) => setAgent({ ...agent, name: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="text"
                name="email"
                value={agent.email}
                onChange={(e) => setAgent({ ...agent, email: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <Input
                type="text"
                name="phone"
                value={agent.phone}
                onChange={(e) => setAgent({ ...agent, phone: e.target.value })}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel htmlFor="agent_code">Agent Code</FormLabel>
              <Input
                type="text"
                name="agent_code"
                value={agent.agent_code}
                onChange={(e) => setAgent({ ...agent, agent_code: e.target.value })}
              />
            </FormControl>
            
            <FormControl mb="4">
              <FormLabel htmlFor="product_id">Product ID</FormLabel>
              <Input
                type="text"
                name="product_id"
                value={agent.product_id}
                readOnly  
                onChange={(e) => setAgent({ ...agent, product_id: e.target.value })}
              />
            </FormControl>
            
            <FormControl id='password'>
              <FormLabel htmlFor='password'>Password</FormLabel>
              <Input
                type='password'
                placeholder='************'
                value={agent.password}
                onChange={(e) => setAgent({ ...agent, password: e.target.value  })}
              />
					</FormControl>
            
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" bg='blue.500' mr={3} onClick={closeModal}>
              Close
            </Button>
            <Button colorScheme="green" bg='brandGreen' onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default ClientViewScreen;

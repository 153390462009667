import { 
  WEBSOCKET_CONNECT, 
  WEBSOCKET_DISCONNECT, 
  WEBSOCKET_MESSAGE_RECEIVED, 
  WEBSOCKET_ERROR, 
  SEND_MESSAGE, 
  MESSAGE_SENDING,     // Action for sending status
  MESSAGE_SENT_SUCCESS, // Action for successful message send
  MESSAGE_SENT_FAILURE,  // Action for failed message send
  TYPING_START,         // New action for typing start
  TYPING_STOP           // New action for typing stop
} from '../constants/webSocketConstants';
import { sendDirectMessage } from '../actions/qrActions'; // API action for sending message

let socket = null;  // Global WebSocket instance

const INSTANCE_ID = '';  // Constant for instance ID
const ACCESS_TOKEN = ''; // Constant for access token

const websocketMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const userInfo = state.userLogin?.userInfo;

  const instanceId = userInfo?.instance_id || INSTANCE_ID;
  const accessToken = userInfo?.access_token || ACCESS_TOKEN;

  switch (action.type) {
    case WEBSOCKET_CONNECT:
      if (socket !== null && socket.readyState === WebSocket.OPEN) {
        console.log('Reusing existing WebSocket connection...');
        return;
      }

      if (!instanceId || !accessToken) {
        console.error('Instance ID or Access Token missing. Unable to connect to WebSocket.');
        return;
      }

      const { protocol, domain, port } = action.payload;
      const socketUrl = `${protocol}://${domain}:${port}?instanceId=${instanceId}&accessToken=${accessToken}`;

      socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        console.log('WebSocket connection established.');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        store.dispatch({ type: WEBSOCKET_MESSAGE_RECEIVED, payload: data });
      };

      socket.onerror = () => {
        store.dispatch({ type: WEBSOCKET_ERROR, payload: 'WebSocket error' });
      };

      socket.onclose = (event) => {
        if (!event.wasClean) {
          console.error('WebSocket connection closed unexpectedly.');
        }
        socket = null;
      };
      break;

    case WEBSOCKET_DISCONNECT:
      if (socket !== null) {
        console.log('Closing WebSocket connection');
        socket.close();
      }
      socket = null;
      break;

    case SEND_MESSAGE:
      const tempMessageId = Date.now();

      // Optimistically dispatch message with "sending" status
      store.dispatch({
        type: MESSAGE_SENDING,
        payload: {
          id: tempMessageId,
          sender: 'You',
          content: action.payload.message,
          fromMe: true,
          timestamp: Date.now(),
          remoteJid: action.payload.number,
          status: 'sending',
        },
      });

      // Send the message via API
      store.dispatch(sendDirectMessage(action.payload.number, 'text', action.payload.message, instanceId, accessToken))
        .then((response) => {
          store.dispatch({
            type: MESSAGE_SENT_SUCCESS,
            payload: {
              id: tempMessageId,
              status: 'sent',
              serverMessageId: response.data?.messageId,
            },
          });
        })
        .catch((error) => {
          console.error('API message sending failed:', error);

          // Dispatch message failure
          store.dispatch({
            type: MESSAGE_SENT_FAILURE,
            payload: {
              id: tempMessageId,
              status: 'failed',
            },
          });
        });
      break;

    case TYPING_START:
      const typingStartData = {
        event: 'typingStart',
        agentId: action.payload.agentId,
        agentName: action.payload.agentName,
        chatId: action.payload.chatId,
      };

      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(typingStartData));
      } else {
        console.error('WebSocket is not open. Unable to send typing start event.');
      }
      break;

    case TYPING_STOP:
      const typingStopData = {
        event: 'typingStop',
        agentId: action.payload.agentId,
        agentName: action.payload.agentName,
        chatId: action.payload.chatId,
      };

      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(typingStopData));
      } else {
        console.error('WebSocket is not open. Unable to send typing stop event.');
      }
      break;

    default:
      return next(action);
  }
};

export default websocketMiddleware;

import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  listContactsInGroup,
  addContactsToGroup,
  updateContactInGroup,
  deleteContactFromGroup,
} from "../../actions/haziSocialsActions";

const HaziGroupContactsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const groupId = id;

  const [selectedContact, setSelectedContact] = useState(null);
  const [deleteContactId, setDeleteContactId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { contacts, error } = useSelector((state) => state.haziListContactsInGroup);

  const { success: addToGroupSuccess } = useSelector((state) => state.haziAddContactsToGroup);

  const { success : deleteSuccess, } = useSelector((state) =>  state.haziDeleteContact);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else if (groupId) {
      dispatch(listContactsInGroup(groupId, currentPage, itemsPerPage));
    }
  }, [dispatch, groupId, currentPage, itemsPerPage, addToGroupSuccess, deleteSuccess, userInfo, navigate]);
  

  useEffect(() => {
    if (addToGroupSuccess) {
      toast({
        title: "Success",
        description: "Contacts successfully added to the group!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    }
    if (deleteSuccess) {
      toast({
        title: "Success",
        description: "Contact successfully deleted!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    if (error) {
      toast({
        title: "Error",
        description: error.message || "An error occurred while fetching contacts.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [addToGroupSuccess, deleteSuccess, error, onClose, toast]);

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    onOpen();
  };

  const handleDeleteContact = (contactId) => {
    setDeleteContactId(contactId);
  };

  const confirmDeleteContact = () => {
    if (deleteContactId) {
      dispatch(deleteContactFromGroup(groupId, deleteContactId));
      setDeleteContactId(null);
    }
  };
 

  const handleAddContact = () => {
    setSelectedContact({ phone: "", first_name: "", last_name: "", email: "", status: ""  });
    onOpen();
  };

  const handleInputChange = (field, value) => {
    setSelectedContact({ ...selectedContact, [field]: value });
  };

  const handleSubmit = () => {
    if (selectedContact.id) {
      dispatch(updateContactInGroup(groupId, selectedContact.id, selectedContact));
    } else {
      const payload = [
        {
          phone: selectedContact.phone,
          first_name: selectedContact.first_name,
          last_name: selectedContact.last_name,
          email: selectedContact.email,
          status: selectedContact.status,
        },
      ];
      dispatch(addContactsToGroup(groupId, payload));
    }
    onClose();
  };
  

  const totalPages = contacts?.contact_count
  ? Math.ceil(contacts.contact_count / itemsPerPage)
  : 0;

  const paginatedContacts = contacts?.data
  ? contacts.data.slice(
      itemsPerPage * (currentPage - 1),
      itemsPerPage * currentPage
    )
  : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Button key={1} onClick={() => handlePageChange(1)}>
          1
        </Button>
      );
      if (startPage > 2) {
        pageNumbers.push(<Text key="start-ellipsis">...</Text>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === currentPage}
          colorScheme={i === currentPage ? 'blue' : 'gray'}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<Text key="end-ellipsis">...</Text>);
      }
      pageNumbers.push(
        <Button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Button>
      );
    }

    return (
      <Flex justify="center" align="center" mt="5" gap="2">
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {pageNumbers}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Flex>
    );
  };

  return (
    <Box bg="white" borderRadius="sm" m={4} p={4}>
      {/* Header */}
      <Flex justifyContent="space-between" mb={4}>
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }}>
          Group Contacts
        </Heading>
        <Button onClick={handleAddContact} colorScheme="green">
          Add Contact
        </Button>
      </Flex>

      {/* Breadcrumb */}
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/trial/socials-h/dashboard">
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/trial/socials-h/contacts">
            Contacts
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <Text>Group</Text>
        </BreadcrumbItem>
      </Breadcrumb>

      {/* Contacts Table */}
      <Box overflowX="auto">
        <Table variant="striped" size="md">
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Phone</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedContacts?.map((contact, index) => (
              <Tr key={contact.id}>
                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                <Td>{contact.phone}</Td>
                <Td>{contact.first_name }</Td>
                <Td>{contact.last_name}</Td>
                <Td>{contact.email}</Td>
                <Td>{contact.status}</Td>
                <Td>
                  <Button
                    size="sm"
                    colorScheme="green"
                    onClick={() => handleEditContact(contact)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleDeleteContact(contact.id)}
                    ml={2}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {renderPagination()}
      </Box>


      {/* Contact Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedContact?.id ? "Edit Contact" : "Add Contact"}</ModalHeader>
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Phone</FormLabel>
              <Input
                type="number"
                placeholder="Phone number"
                value={selectedContact?.phone}
                onChange={(e) => handleInputChange("phone", e.target.value)}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>First Name</FormLabel>
              <Input
                placeholder="First Name"
                value={selectedContact?.first_name || ""}
                onChange={(e) => handleInputChange("first_name", e.target.value)}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="Last Name"
                value={selectedContact?.last_name || ""}
                onChange={(e) => handleInputChange("last_name", e.target.value)}
              />
            </FormControl>         
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Email"
                type="email"
                value={selectedContact?.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                value={selectedContact?.status || "subscribe"}
                onChange={(e) => handleInputChange("status", e.target.value)}
              >
                <option value="subscribe">Subscribe</option>
                <option value="unsubscribe">Unsubscribe</option>
              </Select>
            </FormControl>;
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSubmit} colorScheme="green">
              {selectedContact?.id ? "Save" : "Add"}
            </Button>
            <Button onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteContactId !== null} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this contact?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={confirmDeleteContact}>
              Delete
            </Button>
            <Button onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HaziGroupContactsScreen;

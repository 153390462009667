import {
    Box,
    Flex,
    Input,
    Text,
    Heading,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
  } from '@chakra-ui/react';
  
  const SupervisorAgentProductivity = () => {
    
    return (
      <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
        <Flex justifyContent="space-between" mt="2" mb={4}>
          <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
              Agent Productivity
          </Heading>
  
          <Box display="flex" alignItems="center" m={4}>
              <Text fontSize="md" mr={2}>
                Search:
              </Text>
              <Input
                type="text"
                placeholder="search"
                bgColor="white"
                borderRadius="lg"
                borderWidth="2px"
                borderColor="blue.500"
                padding="2"
                _hover={{ borderColor: "white" }}
              />
            </Box>
        </Flex>     
          	
          <Stack spacing={4} overflowX="auto">
            <Table variant="striped" colorScheme="whiteAlpha" bg='white'>
              <Thead>
                <Tr>
                  <Th borderBottom="1px" borderColor="gray.300">No.</Th>
                  <Th borderBottom="1px" borderColor="gray.300">Agent Code</Th>                  
                  <Th borderBottom="1px" borderColor="gray.300">Agent</Th>
                  <Th borderBottom="1px" borderColor="gray.300">Hours</Th>
                </Tr>
              </Thead>
              <Tbody>              
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>                   
                  </Tr>
              </Tbody>
            </Table>
          </Stack>      
      </Box>
    );
  };
  
  export default SupervisorAgentProductivity;
  
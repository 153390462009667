import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Input,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import { listWhatsappOutbounds } from '../../actions/whatsappOutboundActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const WhatsAppHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedMessages, setExpandedMessages] = useState({});
  const itemsPerPage = 10;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { loading, error, whatsappOutbounds } = useSelector(
    (state) => state.whatsappOutboundList
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentChats = Array.isArray(whatsappOutbounds?.data)
  ? whatsappOutbounds.data.slice(
      indexOfFirstItem,
      Math.min(indexOfLastItem, whatsappOutbounds.data.length)
    )
  : [];


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleMessageView = (id) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listWhatsappOutbounds(searchQuery));
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, userInfo, searchQuery,]);

  return (
    <Box p="4" bg="white" borderRadius="md" m={4}>
      <Flex justifyContent="space-between" mt={2}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Previous Messages
        </Text>
        <Input
          type="text"
          placeholder="Search number..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          m={8}
          maxWidth="500px"
          borderColor="blue.400"
          borderWidth="2px"
        />
      </Flex>

      <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
        { loading ? (
            <Loader />
          ) : error ? (
            <Message type="error">{error}</Message>
          ) : (
            <>
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>No.</Th>
                    <Th>Customer</Th>
                    <Th>Message</Th>
                    <Th>Type</Th>
                    <Th>Sent By</Th>
                    <Th>Sent On</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentChats.length > 0 ? (
                    currentChats.map((chat, index) => (
                      <Tr key={chat.id}>
                        <Td>
                          {index + 1 + (currentPage - 1) * itemsPerPage}
                        </Td>
                        <Td>{chat.number}</Td>
                        <Td>
                          {expandedMessages[chat.id] ? (
                            <>
                              {chat.message}{' '}
                              <Button
                                size="xs"
                                color='blue.500'
                                variant="link"
                                onClick={() => toggleMessageView(chat.id)}
                              >
                                View Less
                              </Button>
                            </>
                          ) : (
                            <>
                              {chat.message.slice(0, 50)}{' '}
                              {chat.message.length > 50 && (
                                <Button
                                  size="xs"
                                  color='blue.500'
                                  variant="link"
                                  onClick={() => toggleMessageView(chat.id)}
                                >
                                  View More
                                </Button>
                              )}
                            </>
                          )}
                        </Td>
                        <Td>{chat.type}</Td>
                        <Td>{chat.agent}</Td>
                        <Td>
                          {moment(chat.created_at)
                            .tz('Africa/Nairobi')
                            .format('dddd, MMMM Do YYYY, h:mm:ss a')}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="6" textAlign="center">
                        No data available
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>

              <Flex mt="4" justify="space-between" align="center">
                <Box>
                  Showing {indexOfFirstItem + 1} to{' '}
                  {whatsappOutbounds.data
                    ? Math.min(indexOfLastItem, whatsappOutbounds.data.length)
                    : 0}{' '}
                  of{' '}
                  {whatsappOutbounds.data ? whatsappOutbounds.data.length : 0}{' '}
                  entries
                </Box>
                <Flex>
                  <Button
                    variant="outline"
                    isDisabled={currentPage === 1}
                    onClick={() => paginate(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  {[...Array(
                    Math.ceil(
                      (whatsappOutbounds.data?.length || 0) / itemsPerPage
                    )
                  )].map((_, index) => (
                    <React.Fragment key={index}>
                      {index + 1 === 1 ||
                      (index + 1 >= currentPage - 2 &&
                        index + 1 <= currentPage + 2) ||
                      index + 1 ===
                        Math.ceil(
                          whatsappOutbounds.data?.length / itemsPerPage
                        ) ? (
                        <Button
                          key={index}
                          variant={
                            currentPage === index + 1 ? 'solid' : 'outline'
                          }
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Button>
                      ) : index + 1 === currentPage - 3 ||
                        index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null}
                    </React.Fragment>
                  ))}
                  <Button
                    variant="outline"
                    isDisabled={
                      currentPage ===
                      Math.ceil(whatsappOutbounds.data?.length / itemsPerPage)
                    }
                    onClick={() => paginate(currentPage + 1)}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </>
          )}
      </Box>
    </Box>
  );
};

export default WhatsAppHistory;

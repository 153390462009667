import {
  GET_PRODUCT_ABANDONED_CALLS_REQUEST,
  GET_PRODUCT_ABANDONED_CALLS_SUCCESS,
  GET_PRODUCT_ABANDONED_CALLS_FAIL,
  GET_AGENT_OUTBOUND_CALLS_REQUEST,
  GET_AGENT_OUTBOUND_CALLS_SUCCESS,
  GET_AGENT_OUTBOUND_CALLS_FAIL,
  UPDATE_CDR_ABANDONED_REQUEST,
  UPDATE_CDR_ABANDONED_SUCCESS,
  UPDATE_CDR_ABANDONED_FAIL,
  GET_ALL_OUTBOUND_CALLS_REQUEST,
  GET_ALL_OUTBOUND_CALLS_SUCCESS,
  GET_ALL_OUTBOUND_CALLS_FAIL,
  GET_PRODUCT_OUTBOUND_CALLS_REQUEST,
  GET_PRODUCT_OUTBOUND_CALLS_SUCCESS,
  GET_PRODUCT_OUTBOUND_CALLS_FAIL,
} from '../constants/cdrConstants';

export const productAbandonedCallsReducer = (state = {}, action) => {
switch (action.type) {
  case GET_PRODUCT_ABANDONED_CALLS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case GET_PRODUCT_ABANDONED_CALLS_SUCCESS:
    return {
      ...state,
      loading: false,
      calls: action.payload,
    };
  case GET_PRODUCT_ABANDONED_CALLS_FAIL:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  default:
    return state;
}
};

export const agentOutboundCallsReducer = (state = {}, action) => {
switch (action.type) {
  case GET_AGENT_OUTBOUND_CALLS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null,
    };
  case GET_AGENT_OUTBOUND_CALLS_SUCCESS:
    return {
      ...state,
      loading: false,
      calls: action.payload,
    };
  case GET_AGENT_OUTBOUND_CALLS_FAIL:
    return {
      ...state,
      loading: false,
      error: action.payload,
    };
  default:
    return state;
}
};

export const updateCDRReducer = (state = {}, action) => {
switch (action.type) {
  case UPDATE_CDR_ABANDONED_REQUEST:
    return {
      ...state,
      updating: true,
      updateError: null,
    };
  case UPDATE_CDR_ABANDONED_SUCCESS:
    return {
      ...state,
      updating: false,
      updateSuccess: true,
    };
  case UPDATE_CDR_ABANDONED_FAIL:
    return {
      ...state,
      updating: false,
      updateError: action.payload,
    };
  default:
    return state;
}
};

export const allOutboundCallsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_OUTBOUND_CALLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_OUTBOUND_CALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        calls: action.payload,
      };
    case GET_ALL_OUTBOUND_CALLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const productOutboundCallsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_OUTBOUND_CALLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_OUTBOUND_CALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        calls: action.payload,
      };
    case GET_PRODUCT_OUTBOUND_CALLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
import {
  OUTBOUND_DISPOSITION_CREATE_REQUEST,
  OUTBOUND_DISPOSITION_CREATE_SUCCESS,
  OUTBOUND_DISPOSITION_CREATE_FAIL,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
  OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
  OUTBOUND_SCHEDULED_CALLBACKS_REQUEST,
  OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS,
  OUTBOUND_SCHEDULED_CALLBACKS_FAIL,
  UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST,
  UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS,
  UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS,
  GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS,
  UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS,
  GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL
} from '../constants/outboundDispositionConstants';

export const outboundDispositionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case OUTBOUND_DISPOSITION_CREATE_REQUEST:
      return { loading: true };
    case OUTBOUND_DISPOSITION_CREATE_SUCCESS:
      return { loading: false, outboundDisposition: action.payload };
    case OUTBOUND_DISPOSITION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const outboundAllScheduledCallbacksReducer = (state = { outboundCallbacks: [] }, action) => {
  switch (action.type) {
    case OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST:
      return { loading: true, outboundCallbacks: [] };
    case OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS:
      return { loading: false, outboundCallbacks: action.payload };
    case OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const outboundScheduledCallbacksReducer = (state = { outboundCallbacks: [] }, action) => {
  switch (action.type) {
    case OUTBOUND_SCHEDULED_CALLBACKS_REQUEST:
      return { loading: true, outboundCallbacks: [] };
    case OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS:
      return { loading: false, outboundCallbacks: action.payload };
    case OUTBOUND_SCHEDULED_CALLBACKS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateOutboundCallbackStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST:
      return { loading: true };
    case UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOutboundEscalatedCallsProductReducer = (state = { escalatedCalls: [] }, action) => {
  switch (action.type) {
    case GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST:
      return { loading: true, escalatedCalls: [] };
    case GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS:
      return { loading: false, escalatedCalls: action.payload };
    case GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateOutboundEscalationDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST:
      return { loading: true };
    case UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getOutboundEscalatedCallsAgentReducer = (state = { escalatedCalls: [] }, action) => {
  switch (action.type) {
    case GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST:
      return { loading: true, escalatedCalls: [] };
    case GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS:
      return { loading: false, escalatedCalls: action.payload };
    case GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

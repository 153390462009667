import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Stack,
  Divider,
  Text,
} from "@chakra-ui/react";

const HaziMailTemplateScreen = () => {
  const [formData, setFormData] = useState({
    salutation: "",
    body: "",
    phone: "",
    email: "",
    website: "",
    location: "",
    twitter: "",
    instagram: "",
    facebook: "",
    linkedin: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box bg="white" borderRadius="md" shadow="sm" m={4} p={6}>
      <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb={5}>
        Active Email Template
      </Heading>

      <Flex direction={{ base: "column", lg: "row" }} gap={6}>
        {/* Left Section: Form */}
        <Box flex="1" p={6} borderWidth="1px" borderRadius="md" bg="gray.50">
          <form
            action="https://hazi.co.ke/email/mail-template-builder"
            method="post"
            encType="multipart/form-data"
          >
            <Input type="hidden" name="_token" value="LmFIO8NDPjdqeow4YoyqccqHrpUxeoMFDEIl6z0R" />

            <FormControl isRequired mb={4}>
              <FormLabel htmlFor="salutation">Salutation</FormLabel>
              <Input
                id="salutation"
                name="salutation"
                placeholder="E.g., Hello, Dear"
                value={formData.salutation}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel htmlFor="body">Sample Body Message</FormLabel>
              <Textarea
                id="body"
                name="body"
                rows={5}
                placeholder="E.g., Looking forward to your availability..."
                value={formData.body}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Footer Content Details</FormLabel>
              <Stack spacing={4}>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="Phone (e.g., 0712345678)"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <Input
                  id="email"
                  name="email"
                  placeholder="Email (e.g., hello@hazi.co.ke)"
                  value={formData.email}
                  onChange={handleChange}
                />
                <Input
                  id="website"
                  name="website"
                  placeholder="Website (e.g., www.hazi.co.ke)"
                  value={formData.website}
                  onChange={handleChange}
                />
                <Input
                  id="location"
                  name="location"
                  placeholder="Location (e.g., Ngong Road, Nairobi)"
                  value={formData.location}
                  onChange={handleChange}
                />
              </Stack>
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Socials URLs</FormLabel>
              <Stack spacing={4}>
                <Input
                  id="twitter"
                  name="twitter"
                  placeholder="Twitter Profile Link"
                  value={formData.twitter}
                  onChange={handleChange}
                />
                <Input
                  id="instagram"
                  name="instagram"
                  placeholder="Instagram Profile Link"
                  value={formData.instagram}
                  onChange={handleChange}
                />
                <Input
                  id="facebook"
                  name="facebook"
                  placeholder="Facebook Profile Link"
                  value={formData.facebook}
                  onChange={handleChange}
                />
                <Input
                  id="linkedin"
                  name="linkedin"
                  placeholder="LinkedIn Profile Link"
                  value={formData.linkedin}
                  onChange={handleChange}
                />
              </Stack>
            </FormControl>

            <Button mt={4} colorScheme="blue" type="submit" w="full">
              Save Template
            </Button>
          </form>
        </Box>

        {/* Right Section: Preview */}
        <Box flex="1" p={6} borderWidth="1px" borderRadius="md" bg="gray.50">
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Preview
          </Text>
          <Box p={4} borderWidth="1px" borderRadius="md" bg="white">
            <Text fontWeight="bold" mb={2}>
              {formData.salutation || "Your salutation goes here..."}
            </Text>
            <Divider />
            <Text my={4}>{formData.body || "Your message body goes here..."}</Text>
            <Divider />
            <Text fontSize="sm" color="gray.500" mt={4}>
              Phone: {formData.phone || "N/A"} | Email: {formData.email || "N/A"}
              <br />
              Website: {formData.website || "N/A"} | Location: {formData.location || "N/A"}
            </Text>
            <Divider />
            <Text fontSize="sm" color="gray.500" mt={2}>
              Socials:
              Twitter: {formData.twitter || "N/A"}, Instagram: {formData.instagram || "N/A"}, 
              Facebook: {formData.facebook || "N/A"}, LinkedIn: {formData.linkedin || "N/A"}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default HaziMailTemplateScreen;

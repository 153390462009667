import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Link,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FiRadio, FiLayout, FiMessageSquare, FiLink } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { onboardHaziClient } from "../../actions/haziSocialsActions";

/* global FB */
const HaziWhatsappConnAccounts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "1133019201125333",
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const launchWhatsAppSignup = () => {
    setFbLoading(true);
    FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;

          FB.api(
            "/me",
            { fields: "id,name", access_token: accessToken },
            (userResponse) => {
              if (userResponse && !userResponse.error) {
                const metaUserId = userResponse.id;
                dispatch(
                  onboardHaziClient({
                    meta_accessToken: accessToken,
                    metaUserId,
                  })
                );
              } else {
                console.error("Error fetching user data:", userResponse.error);
                alert("Failed to retrieve user data from Facebook.");
              }
            }
          );
        } else {
          alert("Facebook login was unsuccessful.");
        }
        setFbLoading(false);
      },
      {
        scope:
          "business_management, whatsapp_business_management, whatsapp_business_messaging",
      }
    );
  };

  const submitSelection = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      onClose();
    }, 2000); 
  };

  return (
    <Box bg="gray.100" minH="100vh" py={6}>
      {/* Navbar */}
      <Box bg="blue.500" color="white" py={4} px={6}>
        <Flex justify="space-between" align="center">
          <HStack spacing={8}>
            <Link href="/trial/socials-h/whatsapp" display="flex" alignItems="center">
              <FiRadio />
              <Text ml={2}>Broadcast</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-template" display="flex" alignItems="center">
              <FiLayout />
              <Text ml={2}>Message Template</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-chat" display="flex" alignItems="center">
              <FiMessageSquare />
              <Text ml={2}>Chat Box</Text>
            </Link>
          </HStack>
          <Link href="/trial/socials-h/whatsapp-accounts" display="flex" alignItems="center">
            <FiLink />
            <Text ml={2}>Connected Accounts</Text>
          </Link>
        </Flex>
      </Box>

      {/* Content */}
      <Container maxW="container.md" mt={10}>
        <Heading size="lg" mb={4}>
          Connect Your WhatsApp Account
        </Heading>
        <Text>To experience messaging, please connect your WhatsApp number.</Text>
        <Text mt={4}>Few things to note before you connect:</Text>
        <UnorderedList mt={2}>
          <ListItem>You must have admin access to your Meta Business Manager</ListItem>
          <ListItem>Use a phone number that has no existing WhatsApp account</ListItem>
          <ListItem>Deregister any number previously used with WhatsApp Business</ListItem>
        </UnorderedList>
        <Center mt={8}>
          <VStack spacing={4}>
            <Button
              colorScheme="facebook"
              size="lg"
              onClick={launchWhatsAppSignup}
              isLoading={fbLoading}
              loadingText="Connecting..."
            >
              Continue With Facebook
            </Button>
            <Button
              colorScheme="blue"
              size="lg"
              onClick={onOpen}
              disabled={fbLoading}
            >
              Already Connected? 
            </Button>
          </VStack>
        </Center>
      </Container>

      {/* Business Selection Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Business, WABA, and Phone Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text fontWeight="bold">Business</Text>
              <Select placeholder="Select Business" id="businessSelect">
                {/* Populate business options here */}
              </Select>
            </Box>
            <Box mb={4}>
              <Text fontWeight="bold">WhatsApp Business Account</Text>
              <Select placeholder="Select WABA" id="wabaSelect">
                {/* Populate WABA options here */}
              </Select>
            </Box>
            <Box mb={4}>
              <Text fontWeight="bold">WhatsApp Phone Number</Text>
              <Select placeholder="Select Phone Number" id="phoneSelect">
                {/* Populate phone number options here */}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={submitSelection}>
              Submit
            </Button>
            {isLoading && <Spinner ml={3} />}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HaziWhatsappConnAccounts;

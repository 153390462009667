import React, { useEffect } from 'react';
import { Box, Text, Flex, Icon, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { IoBusiness } from "react-icons/io5";
import { MdOutlineAddBusiness } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { listClients } from "../../actions/clientsActions";
import { listWhatsappOutbounds } from '../../actions/whatsappOutboundActions';
import WhatsAppMessageClients from './WhatsAppMessageClients';
import WhatsAppHistory from './WhatsAppHistory';
import WhatsappHistoryClear from './WhatsappHistoryClear';

const StatCard = ({ bgColor, icon, label, value }) => (
  <Flex
    bg="white"
    p="4"
    borderWidth="3px"
    borderColor="grey.100"
    borderRadius="lg"
    boxShadow="md"
    m={2}
    width={{ base: '100%', md: '30%' }}
    align="center"
    mb={{ base: '2', md: '0' }}
    mx="auto"
  >
    <Box
      bgColor={bgColor}
      m={2}
      h="40px"
      w="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icon as={icon} color="white" fontSize="2.0em" />
    </Box>
    <Box>
      <Text>{`${label}: ${value}`}</Text>
    </Box>
  </Flex>
);

const WhatsappAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { clients } = useSelector((state) => state.clientsList);
  const { whatsappOutbounds } = useSelector((state) => state.whatsappOutboundList);  

  const connectedCount = clients?.data?.filter(client => client.isWhatsappEnabled).length || 0;
  const notConnectedCount = (clients?.data?.length || 0) - connectedCount;
  const messageCount = whatsappOutbounds?.count|| 0;

  useEffect(() => {
    if (userInfo) {
      dispatch(listClients());
      dispatch(listWhatsappOutbounds());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <Flex direction="column" p="4" borderRadius="md" m={4}>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Text fontSize="xl" fontWeight="bold" m={8}>WhatsApp Stats</Text>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="blue.500" icon={IoBusiness} label="Integrated Clients" value={connectedCount} />
          <StatCard bgColor="red" icon={MdOutlineAddBusiness} label="Pending Clients" value={notConnectedCount} />
          <StatCard bgColor="green" icon={TiMessages} label="Message Count" value={messageCount} />
        </Flex>
      </Box>

      <Box bg="white" borderRadius="sm">        
        <Text fontSize="xxl" fontWeight="bold" m={8}>WhatsApp</Text>
        <Tabs variant='enclosed' mt={4} isLazy>
          <TabList
            overflowX="auto"
            whiteSpace="nowrap"
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Tab>Clients</Tab>
            <Tab>History</Tab>
            <Tab>Clear Chats</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <WhatsAppMessageClients />
            </TabPanel>
            <TabPanel>
              <WhatsAppHistory />
            </TabPanel>
            <TabPanel>
              <WhatsappHistoryClear />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default WhatsappAdmin;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Spacer,
	Text,
	Image,
} from '@chakra-ui/react';
import { RxEyeClosed, RxEyeNone} from "react-icons/rx";

import { login } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';

const LoginScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, error, userInfo } = userLogin;

	useEffect(() => {
		if (userInfo) {
			let destination;
			switch (userInfo.role) {
				case 'super-admin':
					destination = '/home';
					break;
				case 'agent':
					destination = '/dashboard';
					break;
				case 'admin':
					destination = '/supervisor-home';
					break;
				case 'support':
					destination = '/support-home'; 
					break;
				default:
					destination = '/dashboard';
			}
			navigate(destination);
		}
	}, [navigate, userInfo]);	

	const submitHandler = (e) => {
		e.preventDefault();
		const formattedEmail = email.trim().toLowerCase(); 
		dispatch(login(formattedEmail, password));
	};
	

	return (
		<Flex w={{ base: "80%", md: "60%" }} alignItems='center' justifyContent='center' py='5' mx='auto'>
			<FormContainer>
				<Image
					src={require('../images/customer.gif')}
					alt=''
					display={{ base: 'block', md: 'block' }} 
					w={{ base: '80%', md: '50%' }} 
					objectFit='cover'
					marginTop={0}
					alignSelf='center'
					borderRadius="45px"
				/>
				<Heading as='h1' mb='8' fontSize='3xl' color ='blue.500' display='flex'>
					Login
				</Heading>

				{error && <Message type='error'>{error}</Message>}

				<form onSubmit={submitHandler}>
					<FormControl id='email'>
						<FormLabel htmlFor='email'>Email address</FormLabel>
						<Input
							id='email'
							type='email'
							placeholder='username@domain.com'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</FormControl>

					<Spacer h='3' />

					<FormControl id='password'>
						<FormLabel htmlFor='password'>Password</FormLabel>
						<InputGroup>
							<Input
								id='password'
								type={showPassword ? 'text' : 'password'} 
								placeholder='************'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputRightElement>
								<Button
									variant='ghost'
									size='sm'
									onClick={() => setShowPassword(!showPassword)} 
									aria-label={showPassword ? 'Hide password' : 'Show password'}
								>
									{showPassword ? <RxEyeNone /> : <RxEyeClosed />}
								</Button>
							</InputRightElement>
						</InputGroup>
					</FormControl>

					<Button type='submit' bg="blue.500" color= "white" _hover={{ bg: 'brandBlue' }} mt='4' isLoading={loading}>
						Login
					</Button>
				</form>
				
				<Flex pt='2'>
					<Text fontWeight='semibold'>						
						<Link as={RouterLink} to='/login'>
							Forgot Password ?
						</Link>
					</Text>
				</Flex>
			</FormContainer>
		</Flex>
	);
};

export default LoginScreen;

import { Link } from "react-router-dom"

import contactusImg from "./contactus.png"
import { 
    Box, 
    Flex, 
    Heading, 
    Text, 
    Icon, 
    Stack, 
    Image, 
    Link as ChakraLink, 
    Input,
    Textarea,
    FormControl,
    FormLabel,
    Button, 
} from "@chakra-ui/react";
import { FaEnvelope, FaPhoneSquareAlt, FaFacebook, FaLinkedin, FaWhatsappSquare } from 'react-icons/fa';

const Contact = () => {
 return (  
    <Flex  mx="auto"
        direction="column"
        align="center"
        mt={8}
        >
             <Heading 
                    as="h2" 
                    size="lg" 
                    fontWeight="bold"
                    color="black"
                    mb="20px"
                    >
                    Contact Us
                </Heading>
                <Text 
                    as="h4" 
                    fontSize="lg" 
                    color="primary.800" 
                    opacity="0.8"
                    textAlign={["center", "center", "left", "left"]}
                    mb={4}
                    >
                    Talk to us
                </Text>

        <Flex
            align="center"
            justify={{ base: "center", md: "space-around", xl: "space-between" }}
            direction={{ base: "column-reverse", md: "row" }}
            wrap="no-wrap"
            minH="70vh"
            px={8}
            mb={16}
        >
            <Stack mx="auto"
                spacing={4}
                w={{ base: "100%", md: "50%" }}
                align={["flex-start", "flex-start"]}
            >               
              <Box 
               w={['100%', '100%', '100%', '95%', '95%']}
               borderWidth="3px"
               borderRadius="lg"
               overflow="hidden"
               shadow="md"
               p={4}
               colorScheme='green'
               boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
               _hover={{
                 transform: 'scale(1.05)',
               }}
              >
                <Heading
                  as="h6"
                  size="xl"
                  mb={4}
                  fontWeight="normal"
                  color="blue.500"
                >
                  Drop us a Line
                </Heading>
                <form>
                  <FormControl id="name" mb={4}>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" placeholder="Your Name" />
                  </FormControl>
                  <Flex direction={{ base: "column", md: "row" }} >
                  <FormControl id="phone" mb={4}  m={1}>
                    <FormLabel>Phone</FormLabel>
                    <Input type="tel" placeholder="Your Phone Number" />
                  </FormControl>
                  <FormControl id="email" mb={4} m={1}>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" placeholder="Your Email" />
                  </FormControl>
                  </Flex>
                  <FormControl id="message" mb={4}>
                    <FormLabel>Message</FormLabel>
                    <Textarea placeholder="Your Message" />
                  </FormControl>
                  <ChakraLink
                    href="#"
                    color="blue.500"
                    fontSize="md"
                    fontWeight="bold"
                    mt={2}
                    display="block"
                  >
                    <Button 
                    color='white'
                    bg='blue.500'
                    _hover={{ bg: 'red.500'}}
                    >
                    Submit
                    </Button>
                  </ChakraLink>
               </form>
             </Box>
                
            </Stack>
            
            <Box w={{ base: "100%", md: "50%" }} mb={{ base: 12, md: 0 }}>
            <Image
                src={contactusImg} 
                size="100%"
                rounded="1rem"
                shadow="2xl"
            />
            </Box>

        </Flex>

        <Flex mx='auto'>
            {/* Cards */}
            <Box gridTemplateColumns={{ base: "repeat(2, 1fr)", sm: "repeat(4, 1fr)" }} >
                <Stack
                    direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
                    justify="center"                    
                    spacing={2}
                    wrap="wrap"
                    align="center"
                    >                

                    <Box
                        maxW="md"
                        borderWidth="2px"
                        borderRadius="lg"
                        overflow="hidden"
                        shadow="md"
                        p={4}
                        borderColor="blue.500"
                        boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                        height="100px"
                       w={{ base: "80%", md: "20%" }}
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                    >
                        <Link>                                                 
                            <Flex alignItems="center" m={4}>
                                <Icon as={FaPhoneSquareAlt} boxSize={6} color="blue.500" />
                                <Text ml={2}>254 709549000</Text>
                            </Flex>
                        </Link>
                    </Box>

                    <Box
                        maxW="md"
                        borderWidth="2px"
                        borderRadius="lg"
                        overflow="hidden"
                        shadow="md"
                        p={4}
                        borderColor="blue.500"
                        boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                        height="100px"
                       w={{ base: "80%", md: "20%" }}
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                    >
                        <Link to="">                        
                            <Flex alignItems="center" m={4}>
                                <Icon as={FaEnvelope} boxSize={6} color="blue.500" />
                                <Text ml={2}>zuridesk@gmail.com</Text>
                            </Flex>
                        </Link> 
                    </Box> 

                    <Box
                        maxW="md"
                        borderWidth="2px"
                        borderRadius="lg"
                        overflow="hidden"
                        shadow="md"
                        p={4}
                        borderColor="blue.500"
                        boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                        height="100px"
                       w={{ base: "80%", md: "20%" }}
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                    >
                        <Link to="">                        
                            <Flex alignItems="center" m={4}>
                                <Icon as={FaFacebook} boxSize={6} color="blue.500" />
                                <Text ml={2}>zuridesk kenya</Text>
                            </Flex>
                        </Link> 
                    </Box> 

                    <Box
                        maxW="md"
                        borderWidth="2px"
                        borderRadius="lg"
                        overflow="hidden"
                        shadow="md"
                        p={4}
                        borderColor="blue.500"
                        boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                        height="100px"
                       w={{ base: "80%", md: "20%" }}
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                    >
                        <Link  to="https://www.linkedin.com/company/zuridesk" target="_blank">                        
                            <Flex alignItems="center" m={4}>
                                <Icon as={FaLinkedin} boxSize={6} color="blue.500" />
                                <Text ml={2}>zuridesk</Text>
                            </Flex>
                        </Link> 
                    </Box>  

                    <Box
                        maxW="md"
                        borderWidth="2px"
                        borderRadius="lg"
                        overflow="hidden"
                        shadow="md"
                        p={4}
                        borderColor="blue.500"
                        boxShadow="0px 24px 34px rgba(80, 79, 89, 0.14)"
                        height="100px"
                       w={{ base: "80%", md: "20%" }}
                        _hover={{
                          transform: 'scale(1.05)',
                        }}
                    >
                        <Link 
                          to="https://wa.me/254768252222" 
                          target="_blank"                           
                        >                        
                            <Flex alignItems="center" m={4}>
                                <Icon as={FaWhatsappSquare} boxSize={6} color="blue.500" />
                                <Text ml={2}>zuridesk</Text>
                            </Flex>
                        </Link> 
                    </Box>                       
                </Stack>
                </Box>
         </Flex>
    </Flex>    
);
  
};

export default Contact;

import React from 'react';
import {  
  Box, 
  Container, 
  Heading, 
  Text, 
  Flex,
  Center 
} from "@chakra-ui/react";


const ServicesSection = () => {
  return (
    <Box mx="auto" width={{ base: "90%", md: "80%" }} mt={4} mb={4}>
      <Container maxWidth="xlg" mx="auto" mb={8}>
        <Center>
          <Box>
            <Heading as="h2" mt="3" fontSize='2xl'>Some Zuridesk Customers</Heading>
            <Text fontSize='lg'>Some Zuridesk customers Service industries</Text>
          </Box>          
        </Center>
        <Flex flexWrap="wrap" justifyContent="space-between" gridGap={4} mt={4} mb={2}>
          {/* Post 1 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2" size="md">
              Airline And Airline Cargo
            </Heading>
            <Flex align="center" >
              <Box>
                <Text fontSize="md" color="#3c3c3c"  ml={3} marginBottom="0">transport sector</Text>
              </Box>
            </Flex>
          </Box>

          {/* Post 2 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2" size="md">
              Ecommerce and Online Services providers
            </Heading>
            <Flex align="center">
              <Box>
                <Text fontSize="md" color="#3c3c3c"  ml={3}  marginBottom="0">ecommerce, medicine, ISPs et al</Text>
              </Box>
            </Flex>
          </Box>

          {/* Post 3 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2"  size="md">
              Hospitals and Emergency Services providers
            </Heading>
            <Flex align="center">
              <Box>
                <Text fontSize="md" color="#3c3c3c"  ml={3} marginBottom="0">hospitals, ambulance, emergency evacuation</Text>
              </Box>
            </Flex>
          </Box>

          {/* Post 4 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2"  size="md">
              Commercial Banks
            </Heading>
            <Flex align="center">
              <Box>
                <Text fontSize="md" color="#3c3c3c"  ml={3}  marginBottom="0">banking sector</Text>
              </Box>
            </Flex>
          </Box>

          {/* Post 5 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2" size="md">
              Gaming Providers
            </Heading>
            <Flex align="center">
              <Box>
                <Text fontSize="md" color="#3c3c3c"  ml={3} marginBottom="0">gaming industry</Text>
              </Box>
            </Flex>
          </Box>

          {/* Post 6 */}
          <Box 
            as="article" 
            boxShadow="0 4px 16px rgba(0, 0, 0, 0.1)"
            backgroundColor="#fff"
            padding="30px"
            borderRadius="10px"
            overflow="hidden" 
            flexBasis={{base: "100%", md: "48%", xl: "30%"}}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Heading as="h2" size="md">
              Fintechs and Saccos
            </Heading>
            <Flex align="center">
              <Box>
                <Text fontSize="md" color="#3c3c3c" ml={3}  marginBottom="0">financial services</Text>
              </Box>
            </Flex>
          </Box>          
        </Flex>
      </Container>
    </Box>
  );
}

export default ServicesSection;

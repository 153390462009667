import React, { useEffect, useState } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Badge, IconButton, useColorModeValue, keyframes } from '@chakra-ui/react';
import { FiLoader } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { websocketConnect, websocketDisconnect } from '../../actions/webSocketActions'; // Import the actions
import isEqual from 'lodash/isEqual'; // Import lodash isEqual for deep comparison

const QueueListws = () => {
    const [queues, setQueues] = useState({});
    const [dataReceived, setDataReceived] = useState(false);
    const dispatch = useDispatch(); // Initialize the Redux dispatcher
    const navigate = useNavigate(); // Initialize the navigate function

    // Access userInfo from the Redux store
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    
    const { messages } = useSelector((state) => state.websocket);

    const spin = keyframes`
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    `;

    useEffect(() => {
        if (!userInfo) {
            navigate('/login'); // Redirect to login if user is not logged in
            return;
        }

        // Dispatch action to connect to WebSocket using userInfo.id
        const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const domain = process.env.REACT_APP_QUEUE_DOMAIN || 'localhost';
        const port = protocol === 'wss' ? '8443' : '8089';

        dispatch(websocketConnect(protocol, domain, port, userInfo.id)); // Pass userInfo.id here

        return () => {
            dispatch(websocketDisconnect());
        };
    }, [dispatch, navigate, userInfo]);

    // Update queues when WebSocket messages are received, only if there is a change
    useEffect(() => {
        if (messages.length > 0) {
            const latestMessage = messages[messages.length - 1]; // Get the latest message

            // Only update queues if the new data is different from the current state
            if (!isEqual(latestMessage, queues)) {
                try {
                    const updatedQueues = { ...queues };

                    // Process the message
                    for (const queueNumber in latestMessage) {
                        if (!updatedQueues[queueNumber]) {
                            updatedQueues[queueNumber] = {};
                        }
                        latestMessage[queueNumber].members.forEach((member) => {
                            const uniqueId = `${member.name}-${member.location}`;
                            updatedQueues[queueNumber][uniqueId] = member;
                        });
                    }

                    setQueues(updatedQueues);
                    setDataReceived(true);
                } catch (err) {
                    console.error('Error parsing WebSocket data:', err);
                }
            }
        }
    }, [messages, queues]); // Dependency array ensures this effect runs when messages or queues change

    const tableHeaderBg = useColorModeValue('blue.100', 'blue.700');
    const tableHeaderText = useColorModeValue('blue.800', 'blue.200');
    const tableRowBg = useColorModeValue('gray.50', 'gray.700');
    const tableAltRowBg = useColorModeValue('white', 'gray.600');

    const getStatusBadge = (status) => {
        switch (status) {
            case 'free':
                return <Badge colorScheme="blue">{status}</Badge>;
            case 'in call-use':
                return <Badge colorScheme="green">{status}</Badge>;
            case 'ringing':
                return <Badge colorScheme="yellow">{status}</Badge>;
            case 'unavailable':
                return <Badge colorScheme="red">{status}</Badge>;
            default:
                return <Badge>{status}</Badge>;
        }
    };

    if (!dataReceived) {
        return (
            <Box bg="white" borderRadius="sm" m={4} p={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box
                    as="div"
                    borderRadius="full"
                    w={12}
                    h={12}
                    mb={4}
                    border="4px solid"
                    borderColor="blue.500"
                    borderTopColor="transparent"
                    animation={`${spin} 0.8s linear infinite`}
                />
                <Heading as="h3" size="lg" mb={4} color="blue.600">
                    Waiting for data...
                </Heading>
                <IconButton
                    variant="outline"
                    colorScheme="blue"
                    icon={<FiLoader />}
                    mb={6}
                    animation={`${spin} infinite 2s linear`}
                />
            </Box>
        );
    }

    return (
        <Box bg="white" borderRadius="sm" m={4}>
            {Object.keys(queues).length > 0 ? (
                Object.keys(queues).map((queueNumber) => (
                    <Box key={queueNumber} mb={5} p={5} bg="gray.100" borderRadius="md">
                        <Heading as="h3" size="lg" mb={4} color="blue.600">Queue Number: {queueNumber}</Heading>
                        <Table variant="simple">
                            <Thead bg={tableHeaderBg}>
                                <Tr>
                                    <Th color={tableHeaderText}>Name</Th>
                                    <Th color={tableHeaderText}>Location</Th>
                                    <Th color={tableHeaderText}>Status</Th>
                                    <Th color={tableHeaderText}>Last Call</Th>
                                    <Th color={tableHeaderText}>Calls Taken</Th>
                                    <Th color={tableHeaderText}>Event Time</Th>
                                    <Th color={tableHeaderText}>Paused</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Object.values(queues[queueNumber]).map((agent, index) => (
                                    <Tr key={index} bg={index % 2 === 0 ? tableRowBg : tableAltRowBg}>
                                        <Td>{agent.name}</Td>
                                        <Td>{agent.location}</Td>
                                        <Td>{getStatusBadge(agent.status)}</Td>
                                        <Td>{agent.lastCall}</Td>
                                        <Td>{agent.callsTaken}</Td>
                                        <Td>{agent.eventTime}</Td>
                                        <Td>{agent.paused ? 'Yes' : 'No'}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                ))
            ) : (
                <Box textAlign="center" py={10} px={6}>
                    <Heading as="h3" size="lg" mb={4} color="blue.600">
                        No Queues Available
                    </Heading>
                    <IconButton
                        variant="outline"
                        colorScheme="blue"
                        icon={<FiLoader />}
                        mb={6}
                        animation={`${spin} infinite 2s linear`}
                    />
                    <Box>Waiting for Agent Queues to be available</Box>
                </Box>
            )}
        </Box>
    );
};

export default QueueListws;

export const CAMPAIGNS_LIST_REQUEST = "CAMPAIGNS_LIST_REQUEST";
export const CAMPAIGNS_LIST_SUCCESS = "CAMPAIGNS_LIST_SUCCESS";
export const CAMPAIGNS_LIST_FAIL = "CAMPAIGNS_LIST_FAIL";
export const CAMPAIGN_LIST_RESET = "CAMPAIGN_LIST_RESET";

export const CAMPAIGNS_BY_PRODUCT_REQUEST = "CAMPAIGNS_BY_PRODUCT_REQUEST";
export const CAMPAIGNS_BY_PRODUCT_SUCCESS = "CAMPAIGNS_BY_PRODUCT_SUCCESS";
export const CAMPAIGNS_BY_PRODUCT_FAIL = "CAMPAIGNS_BY_PRODUCT_FAIL";
export const CAMPAIGNS_BY_PRODUCT_RESET = "CAMPAIGNS_BY_PRODUCT_RESET";

export const CAMPAIGNS_DELETE_REQUEST = "CAMPAIGNS_DELETE_REQUEST";
export const CAMPAIGNS_DELETE_SUCCESS = "CAMPAIGNS_DELETE_SUCCESS";
export const CAMPAIGNS_DELETE_FAIL = "CAMPAIGNS_DELETE_FAIL";

export const CAMPAIGNS_CREATE_REQUEST = "CAMPAIGNS_CREATE_REQUEST";
export const CAMPAIGNS_CREATE_SUCCESS = "CAMPAIGNS_CREATE_SUCCESS";
export const CAMPAIGNS_CREATE_FAIL = "CAMPAIGNS_CREATE_FAIL";
export const CAMPAIGNS_CREATE_RESET = "CAMPAIGNS_CREATE_RESET";

export const CAMPAIGNS_UPDATE_REQUEST = "CAMPAIGNS_UPDATE_REQUEST";
export const CAMPAIGNS_UPDATE_SUCCESS = "CAMPAIGNS_UPDATE_SUCCESS";
export const CAMPAIGNS_UPDATE_FAIL = "CAMPAIGNS_UPDATE_FAIL";
export const CAMPAIGNS_UPDATE_RESET = "CAMPAIGNS_UPDATE_RESET";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  useMediaQuery,
  useColorMode,
  Icon,
  Drawer,
  DrawerContent,
  Tooltip,
  Button,
} from "@chakra-ui/react";

import {
  MdOutlineAdminPanelSettings,
  MdShare,
  MdDarkMode,
  MdPeopleOutline,
} from "react-icons/md";
import { CiSun } from "react-icons/ci";
import { FiHome } from "react-icons/fi";

import { HiHome } from "react-icons/hi";

const adminSupervisorSupportLinkItems = [
  { name: "Admin Home", icon: MdOutlineAdminPanelSettings, to: "/trial/home" },
  { name: "Users", icon: MdPeopleOutline, to: "/trial/users" },
  { name: "Hazi Socials", icon: MdShare, to: "/trial/socials-h" },
];

const agentLinkItems = [
  { name: "Dashboard", icon: FiHome, to: "/trial/home" },
  { name: "Hazi Socials", icon: MdShare, to: "/trial/socials-h" },
];

const supervisorLinkItems = [
  { name: "Dashboard", icon: HiHome, to: "/trial/home" },
  { name: "Hazi Socials", icon: MdShare, to: "/trial/socials-h" },
];

const supportLinkItems = [
  { name: "Dashboard", icon: HiHome, to: "/trial/home" },
  { name: "Hazi Socials", icon: MdShare, to: "/trial/socials-h" },
];

const defaultLinkItems = [];

const SidebarContent = ({ onClose }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let updatedLinkItems;

  if (userInfo && userInfo.role === "super-admin") {
    updatedLinkItems = adminSupervisorSupportLinkItems;
  } else if (userInfo && userInfo.role === "admin") {
    updatedLinkItems = supervisorLinkItems;
  } else if (userInfo && userInfo.role === "agent") {
    updatedLinkItems = agentLinkItems;
  } else if (userInfo && userInfo.role === "support") {
    updatedLinkItems = supportLinkItems;
  } else {
    updatedLinkItems = defaultLinkItems;
  }
  return (
    <>
      <Button
        bgGradient="linear(to-r, blue.600, blue.500)" // Define the gradient color
        color="white"
        _hover={{ bgGradient: "linear(to-r, blue.300, blue.500)" }} // Hover effect for the button
        size="sm"
        variant="solid"
        mx={2}
        mt={3}
      >
        <Text fontSize="sm" fontWeight="bold" color="#11273f">
          ZD
        </Text>
      </Button>
      <Flex direction="column" alignItems="center">
        {updatedLinkItems.map((link) => (
          <NavItem color="white" onClose={onClose}>
            <NavLink
              to={link.to}
              style={({ isActive }) => ({
                textDecoration: "none",
                color: isActive ? "#3182CE" : "white",
              })}
            >
              <Tooltip
                label={link.name}
                placement="right"
                openDelay={200}
                bgColor="blue.500"
                borderRadius={9}
              >
                <span>
                  <Icon
                    mr="4"
                    fontSize="16"
                    _groupHover={{
                      color: "white",
                    }}
                    as={link.icon}
                  />
                </span>
              </Tooltip>
            </NavLink>
          </NavItem>
        ))}

        <Box
          mt={{ base: "20vh", md: "40vh", lg: "65vh" }}
          cursor="pointer"
          onClick={toggleColorMode}
        >
          <Tooltip
            label={colorMode === "dark" ? "light mode" : "dark mode"}
            placement="right"
            openDelay={200}
            bgColor="blue.500"
            borderRadius={9}
          >
            <span>
              <Icon
                as={colorMode === "dark" ? CiSun : MdDarkMode}
                w={12}
                h={12}
                color={colorMode === "dark" ? "white" : "gray.600"}
              />
            </span>
          </Tooltip>
        </Box>
      </Flex>
    </>
  );
};

const NavItem = ({ icon, children, onClose, ...rest }) => {
  const handleClick = () => {
    onClose();
  };
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        {...rest}
        onClick={handleClick}
      >
        {children}
      </Flex>
    </Box>
  );
};

const Sidebar = () => {
  const { isOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 48em)");

  return (
    <>
      {!isMobile && <SidebarContent onClose={onClose} />}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerContent bg="brandBlue" maxW={24}>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      {/* <MobileNav onOpen={onOpen} /> */}
    </>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Badge, Icon, useColorModeValue, IconButton, keyframes } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FiLoader } from 'react-icons/fi';

const QueueProductList = () => {
    const [queues, setQueues] = useState({});
    const [error, setError] = useState(null);
    const [dataReceived, setDataReceived] = useState(false);
    const [ws, setWs] = useState(null);
    const { userInfo } = useSelector((state) => state.userLogin);

    // Extract product_id from userInfo
    const productId = userInfo?.product_id;

    const spin = keyframes`
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    `;

    const connectWebSocket = () => {
        const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const domain = process.env.REACT_APP_QUEUE_DOMAIN || 'localhost';
        const port = protocol === 'wss' ? '8443' : '8089'; // Use port 8443 for wss, 8089 for ws
        const socketUrl = `${protocol}://${domain}:${port}`;

        const socket = new WebSocket(socketUrl);

        socket.onopen = () => {
            console.log(`WebSocket connection opened: ${socketUrl}`);
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                const updatedQueues = { ...queues };

                // Loop through the queues and update the agents
                for (const queueNumber in data) {
                    if (!updatedQueues[queueNumber]) {
                        updatedQueues[queueNumber] = {};
                    }
                    data[queueNumber].members.forEach((member) => {
                        const uniqueId = `${member.name}-${member.location}`;
                        updatedQueues[queueNumber][uniqueId] = member;
                    });
                }

                setQueues(updatedQueues);
                setDataReceived(true);
                setError(null);  // Clear any previous errors
            } catch (err) {
                setError('Failed to parse data');
                console.error('Error parsing WebSocket data:', err);
            }
        };

        socket.onerror = () => {
            setError('WebSocket error');
            console.error('WebSocket error occurred');
        };

        socket.onclose = (event) => {
            if (!event.wasClean) {
                setError('WebSocket connection closed unexpectedly. Retrying...');
                console.error('WebSocket connection closed unexpectedly. Retrying...');
                setTimeout(() => connectWebSocket(), 5000);  // Retry connection after 5 seconds
            } else {
                console.log('WebSocket connection closed cleanly');
            }
        };

        setWs(socket);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);

    const tableHeaderBg = useColorModeValue('blue.100', 'blue.700');
    const tableHeaderText = useColorModeValue('blue.800', 'blue.200');
    const tableRowBg = useColorModeValue('gray.50', 'gray.700');
    const tableAltRowBg = useColorModeValue('white', 'gray.600');

    const getStatusBadge = (status) => {
        switch (status) {
            case 'free':
                return <Badge colorScheme="blue">{status}</Badge>;
            case 'in call-use':
                return <Badge colorScheme="green">{status}</Badge>;
            case 'ringing':
                return <Badge colorScheme="yellow">{status}</Badge>;
            case 'unavailable':
                return <Badge colorScheme="red">{status}</Badge>;
            default:
                return <Badge>{status}</Badge>;
        }
    };

    if (error) {
        return (
            <Box bg="white" borderRadius="sm" m={4} p={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Icon as={FiLoader} w={10} h={10} mb={4} />
                <Heading as="h3" size="lg" mb={4}>Waiting ....</Heading>
                <IconButton
                      variant="outline"
                      colorScheme="blue"
                      icon={<FiLoader w={10} h={10} />}
                      mb={6}
                      animation={`${spin} infinite 2s linear`}
                  />
            </Box>
        );
    }

    if (!dataReceived) {
        return (
            <Box bg="white" borderRadius="sm" m={4} p={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box
                    as="div"
                    borderRadius="full"
                    w={12}
                    h={12}
                    mb={4}
                    border="4px solid"
                    borderColor="blue.500"
                    borderTopColor="transparent"
                    animation={`${spin} 0.8s linear infinite`}
                />
                <Heading as="h3" size="lg" mb={4} color="blue.600">
                    Queue Number: {productId}
                </Heading>
                <IconButton
                    variant="outline"
                    colorScheme="blue"
                    icon={<FiLoader />}
                    mb={6}
                    animation={`${spin} infinite 2s linear`}
                />
                <Box>Loading data...</Box>
            </Box>
        );
    }

    return (
        <Box bg="white" borderRadius="sm" m={4}>
            {queues[productId] ? (
                <Box mb={5} p={5} bg="gray.100" borderRadius="md">
                    <Heading as="h3" size="lg" mb={4} color="blue.600">Queue Number: {productId}</Heading>
                    <Table variant="simple">
                        <Thead bg={tableHeaderBg}>
                            <Tr>
                                <Th color={tableHeaderText}>Name</Th>
                                <Th color={tableHeaderText}>Location</Th>
                                <Th color={tableHeaderText}>Status</Th>
                                <Th color={tableHeaderText}>Last Call</Th>
                                <Th color={tableHeaderText}>Calls Taken</Th>
                                <Th color={tableHeaderText}>Event Time</Th>
                                <Th color={tableHeaderText}>Paused</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.values(queues[productId]).map((agent, index) => (
                                <Tr key={index} bg={index % 2 === 0 ? tableRowBg : tableAltRowBg}>
                                    <Td>{agent.name}</Td>
                                    <Td>{agent.location}</Td>
                                    <Td>{getStatusBadge(agent.status)}</Td>
                                    <Td>{agent.lastCall}</Td>
                                    <Td>{agent.callsTaken}</Td>
                                    <Td>{agent.eventTime}</Td>
                                    <Td>{agent.paused ? 'Yes' : 'No'}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            ) : (
                <Box textAlign="center" py={10} px={6}>
                    <Heading as="h3" size="lg" mb={4} color="blue.600">
                        Queue Number: {productId}
                    </Heading>
                    <IconButton
                      variant="outline"
                      colorScheme="blue"
                      icon={<FiLoader w={10} h={10} />}
                      mb={6}
                      animation={`${spin} infinite 2s linear`}
                  />
                    <Box>Waiting for your Agents</Box>
                </Box>
            )}
        </Box>
    );
};

export default QueueProductList;

export const AGENT_LIST_REQUEST = "AGENT_LIST_REQUEST";
export const AGENT_LIST_SUCCESS = "AGENT_LIST_SUCCESS";
export const AGENT_LIST_FAIL = "AGENT_LIST_FAIL";

export const AGENT_DETAILS_SUCCESS = "AGENT_DETAILS_SUCCESS";
export const AGENT_DETAILS_REQUEST = "AGENT_DETAILS_REQUEST";
export const AGENT_DETAILS_FAIL = "AGENT_DETAILS_FAIL";

export const AGENT_SUPERVISOR_LIST_REQUEST = "AGENT_SUPERVISOR_LIST_REQUEST";
export const AGENT_SUPERVISOR_LIST_SUCCESS = "AGENT_SUPERVISOR_LIST_SUCCESS";
export const AGENT_SUPERVISOR_LIST_FAIL = "AGENT_SUPERVISOR_LIST_FAIL";

export const AGENT_DELETE_REQUEST = "AGENT_DELETE_REQUEST";
export const AGENT_DELETE_SUCCESS = "AGENT_DELETE_SUCCESS";
export const AGENT_DELETE_FAIL = "AGENT_DELETE_FAIL";

export const AGENT_CREATE_REQUEST = "AGENT_CREATE_REQUEST";
export const AGENT_CREATE_SUCCESS = "AGENT_CREATE_SUCCESS";
export const AGENT_CREATE_FAIL = "AGENT_CREATE_FAIL";
export const AGENT_CREATE_RESET = "AGENT_CREATE_RESET";

export const AGENT_UPDATE_REQUEST = "AGENT_UPDATE_REQUEST";
export const AGENT_UPDATE_SUCCESS = "AGENT_UPDATE_SUCCESS";
export const AGENT_UPDATE_FAIL = "AGENT_UPDATE_FAIL";
export const AGENT_UPDATE_RESET = "AGENT_UPDATE_RESET";


// actions/webSocketActions.js
import { 
  WEBSOCKET_CONNECT, 
  WEBSOCKET_DISCONNECT, 
  SEND_MESSAGE, 
  MESSAGE_SENT 
} from '../constants/webSocketConstants';

// Action to connect to the WebSocket
export const websocketConnect = (protocol, domain, port) => ({
  type: WEBSOCKET_CONNECT,
  payload: { protocol, domain, port }
});

// Action to disconnect from the WebSocket
export const websocketDisconnect = () => ({
  type: WEBSOCKET_DISCONNECT
});

// Action to send a message
export const sendMessage = (number, message, instance_id, access_token) => ({
  type: SEND_MESSAGE,
  payload: {
    number,         // Recipient's phone number
    message,        // The message content
    instance_id,     // The sender's instance ID
    access_token // Access token for authentication
  }
});

// Action to indicate a message was sent (for updating the store)
export const messageSent = (messageId, status) => ({
  type: MESSAGE_SENT,
  payload: {
    id: messageId,  // ID of the sent message
    status          // Status of the message (e.g., 'sent', 'delivered')
  }
});

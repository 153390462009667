import { 
  WEBSOCKET_CONNECT, 
  WEBSOCKET_DISCONNECT, 
  WEBSOCKET_MESSAGE_RECEIVED, 
  WEBSOCKET_ERROR, 
  SEND_MESSAGE, 
  MESSAGE_SENDING,    // Constant for message sending
  MESSAGE_SENT_SUCCESS,  // Constant for successful message sent
  MESSAGE_SENT_FAILURE,   // Constant for message sending failure
  TYPING_START,          // Constant for typing start
  TYPING_STOP            // Constant for typing stop
} from '../constants/webSocketConstants';

const initialState = {
  isConnected: false,  // Track if WebSocket is connected
  messages: [],        // Store received WebSocket messages and sent messages
  error: null,         // Store WebSocket errors
  typingStatus: {},    // Store typing status for agents and chats
};

export const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case WEBSOCKET_CONNECT:
      return {
        ...state,
        isConnected: true,
        error: null,
      };

    case WEBSOCKET_DISCONNECT:
      return {
        ...state,
        isConnected: false,
      };

    case WEBSOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case WEBSOCKET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    // Handle message sending action
    case MESSAGE_SENDING:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: action.payload.id, // Temporary unique ID for optimistic update
            sender: 'You',
            content: action.payload.content, // Message content
            fromMe: true,
            timestamp: action.payload.timestamp,
            remoteJid: action.payload.remoteJid,
            status: 'sending', // Message in process of being sent
          },
        ],
      };

    // Handle successful message sent
    case MESSAGE_SENT_SUCCESS:
      return {
        ...state,
        messages: state.messages.map(msg => 
          msg.id === action.payload.id 
            ? { ...msg, status: 'sent' } // Message successfully sent
            : msg
        ),
      };

    // Handle message sending failure
    case MESSAGE_SENT_FAILURE:
      return {
        ...state,
        messages: state.messages.map(msg => 
          msg.id === action.payload.id 
            ? { ...msg, status: 'failed' } // Message failed to send
            : msg
        ),
      };

    // Handle typing start event
    case TYPING_START:
      return {
        ...state,
        typingStatus: {
          ...state.typingStatus,
          [action.payload.chatId]: {
            agentId: action.payload.agentId,
            agentName: action.payload.agentName,
            isTyping: true,  // Mark this chat as being typed in by an agent
          },
        },
      };

    // Handle typing stop event
    case TYPING_STOP:
      return {
        ...state,
        typingStatus: {
          ...state.typingStatus,
          [action.payload.chatId]: {
            agentId: action.payload.agentId,
            agentName: action.payload.agentName,
            isTyping: false,  // Mark this chat as no longer being typed in
          },
        },
      };

    default:
      return state;
  }
};

import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { createCallDisposition } from '../../actions/callDispositionActions';
import { getProductReasons } from '../../actions/reasonsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const InboundCallDisposition = ({ callData, onClose }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productId = userInfo?.product_id || '';

  const productReasons = useSelector((state) => state.productReasons);
  const { reasons } = productReasons || {};

  useEffect(() => {
    if (userInfo) {
      dispatch(getProductReasons(productId));
    }
  }, [dispatch, userInfo, productId]);

  const callDispositionCreate = useSelector((state) => state.callDispositionCreate);
  const { loading, error } = callDispositionCreate;

  const initialFormData = {
    reason: '',
    sub_reason: '',
    client_name: '',
    gender: '',
    location: '',
    callback_date: '',
    action: 'Disposition',
    fully_resolved: null,
    description: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (callData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        agent: callData.agent || '',
        queue_log_id: callData.queue_id || '',
        msisdn: callData.msisdn || '',
        product_id: callData.product_id || '',
      }));
    }
  }, [callData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCallDisposition(formData, formData.queue_log_id, formData.msisdn, formData.agent, formData.product_id));
    onClose(); 
  };


  return (
    <Box p="8" m={2} alignItems="center" width="100%" bg="white" borderRadius="md">
      <Text fontSize="xl" fontWeight="bold" mb="4">
        Inbound Call Disposition
      </Text>

      {loading && <Loader />}
      {error && <Message type="error">{error}</Message>}

      <form onSubmit={handleSubmit}>
        <FormControl mb={4}>
          <FormLabel>Call Reason</FormLabel>
          <Select
            placeholder="Select/Search Reason"
            name="reason"
            id="reason"
            onChange={handleChange}
            isRequired
            value={formData.reason}
          >
            {reasons &&
              reasons.data &&
              reasons.data
                .filter((reason) => reason.type === 'inbound')
                .map((reason) => (
                  <option key={reason.id} value={reason.reason}>
                    {reason.reason}
                  </option>
                ))}
          </Select>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Sub-Call Reason</FormLabel>
          <Input
            type="text"
            name="sub_reason"
            placeholder="Enter Sub Call Reason"
            onChange={handleChange}
            value={formData.sub_reason}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Client Name</FormLabel>
          <Input
            type="text"
            name="client_name"
            onChange={handleChange}
            value={formData.client_name}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Gender</FormLabel>
          <Select
            placeholder="Select Gender"
            name="gender"
            id="gender"
            onChange={handleChange}
            value={formData.gender}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Location</FormLabel>
          <Input
            type="text"
            name="location"
            onChange={handleChange}
            value={formData.location}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Schedule Callback</FormLabel>
          <Input
            type="datetime-local"
            name="callback_date"
            onChange={handleChange}
            value={formData.callback_date}
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Dispositioned: no Action</FormLabel>
          <Select
            name="action"
            onChange={handleChange}
            value={formData.action || 'Disposition'}
          >
            <option value="Disposition">Disposition</option>
            <option value="Escalate">Escalate</option>
          </Select>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Fully Resolved?</FormLabel>
          <Select
            name="fully_resolved"
            onChange={handleChange}
            value={formData.fully_resolved || ''}
          >
            <option value="no">No</option>
            <option value="yes">Yes</option>
          </Select>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Describe why the customer called"
            name="description"
            onChange={handleChange}
            value={formData.description}
          />
        </FormControl>

        <Button
          type="submit"
          size="md"
          variant="outline"
          colorScheme="primary"
          _hover={{ bg: 'primary.500', color: 'blue.500' }}
          isLoading={loading}
        >
          Save changes
        </Button>
      </form>
    </Box>
  );
};

export default InboundCallDisposition;

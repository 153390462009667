export const CALLDISPOSITION_CREATE_REQUEST = "CALLDISPOSITION_CREATE_REQUEST";
export const CALLDISPOSITION_CREATE_SUCCESS = "CALLDISPOSITION_CREATE_SUCCESS";
export const CALLDISPOSITION_CREATE_FAIL = "CALLDISPOSITION_CREATE_FAIL";

export const CALL_HISTORY_REQUEST = 'CALL_HISTORY_REQUEST';
export const CALL_HISTORY_SUCCESS = 'CALL_HISTORY_SUCCESS';
export const CALL_HISTORY_FAIL = 'CALL_HISTORY_FAIL';

export const CALL_HISTORY_PRODUCT_REQUEST = 'CALL_HISTORY_PRODUCT_REQUEST';
export const CALL_HISTORY_PRODUCT_SUCCESS = 'CALL_HISTORY_PRODUCT_SUCCESS';
export const CALL_HISTORY_PRODUCT_FAIL = 'CALL_HISTORY_PRODUCT_FAIL';

export const CALL_HISTORY_ALL_REQUEST = 'CALL_HISTORY_ALL_REQUEST';
export const CALL_HISTORY_ALL_SUCCESS = 'CALL_HISTORY_ALL_SUCCESS';
export const CALL_HISTORY_ALL_FAIL = 'CALL_HISTORY_ALL_FAIL';

export const INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST = 'INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST';
export const INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS = 'INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS';
export const INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL = 'INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL';

export const INBOUND_SCHEDULED_CALLBACKS_REQUEST = 'INBOUND_SCHEDULED_CALLBACKS_REQUEST';
export const INBOUND_SCHEDULED_CALLBACKS_SUCCESS = 'INBOUND_SCHEDULED_CALLBACKS_SUCCESS';
export const INBOUND_SCHEDULED_CALLBACKS_FAIL = 'INBOUND_SCHEDULED_CALLBACKS_FAIL';

export const UPDATE_CALLBACK_STATUS_REQUEST = 'UPDATE_CALLBACK_STATUS_REQUEST';
export const UPDATE_CALLBACK_STATUS_SUCCESS = 'UPDATE_CALLBACK_STATUS_SUCCESS';
export const UPDATE_CALLBACK_STATUS_FAIL = 'UPDATE_CALLBACK_STATUS_FAIL';

export const GET_INBOUND_ESCALATED_CALLS_REQUEST = 'GET_INBOUND_ESCALATED_CALLS_REQUEST';
export const GET_INBOUND_ESCALATED_CALLS_SUCCESS = 'GET_INBOUND_ESCALATED_CALLS_SUCCESS';
export const GET_INBOUND_ESCALATED_CALLS_FAIL = 'GET_INBOUND_ESCALATED_CALLS_FAIL';

export const UPDATE_ESCALATION_DETAILS_REQUEST = 'UPDATE_ESCALATION_DETAILS_REQUEST';
export const UPDATE_ESCALATION_DETAILS_SUCCESS = 'UPDATE_ESCALATION_DETAILS_SUCCESS';
export const UPDATE_ESCALATION_DETAILS_FAIL = 'UPDATE_ESCALATION_DETAILS_FAIL';

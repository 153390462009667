import { useState, useEffect } from "react";
import { 
    Box, 
    Flex, 
    Heading, 
    FormControl, 
    FormLabel, 
    Input, 
    Textarea, 
    Button,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { sendHaziEmail } from "../../actions/haziSocialsActions"; 
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziQuickMailScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [recipient, setRecipient] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [attachment, setAttachment] = useState(null);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const haziEmailSend = useSelector((state) => state.haziEmailSend);
    const { loading, success, error } = haziEmailSend;

    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        if (success) {
            setRecipient("");
            setName("");
            setSubject("");
            setMessage("");
            setAttachment(null);
        }
    }, [success]);

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("recipient", recipient);
        formData.append("name", name);
        formData.append("subject", subject);
        formData.append("message", message);
        if (attachment) {
            formData.append("attachment", attachment);
        }
        dispatch(sendHaziEmail(formData));
    };

    return (
        <Box borderRadius="sm" m={4}>
            <Flex justifyContent="space-between" mt="2">
                <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
                    Quick Email
                </Heading>
            </Flex>

            <Box p={8} bg="white" maxW="600px" rounded="lg">
                {loading && <Loader />}

                {error && (
                    <Message status="error">
                        {error}
                    </Message>
                )}

                {success && (
                    <Alert status="success" mb={4}>
                        <AlertIcon />
                        Email sent successfully!
                    </Alert>
                )}

                <form onSubmit={submitHandler}>
                    <FormControl id="recipient" isRequired mb={4}>
                        <FormLabel>Email</FormLabel>
                        <Input 
                            type="email" 
                            value={recipient} 
                            onChange={(e) => setRecipient(e.target.value)} 
                            placeholder="Recipient's Email" 
                            required 
                        />
                    </FormControl>

                    <FormControl id="name" mb={4}>
                        <FormLabel>Name</FormLabel>
                        <Input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            placeholder="Sender's Name" 
                        />
                    </FormControl>

                    <FormControl id="subject" isRequired mb={4}>
                        <FormLabel>Subject</FormLabel>
                        <Input 
                            type="text" 
                            value={subject} 
                            onChange={(e) => setSubject(e.target.value)} 
                            placeholder="Email Subject" 
                            required 
                        />
                    </FormControl>

                    <FormControl id="message" isRequired mb={4}>
                        <FormLabel>Body</FormLabel>
                        <Textarea 
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)} 
                            placeholder="Enter your message" 
                            rows={5} 
                            required 
                        />
                    </FormControl>

                    <FormControl id="attachment" mb={4}>
                        <FormLabel>Attachment</FormLabel>
                        <Input 
                            type="file" 
                            onChange={(e) => setAttachment(e.target.files[0])} 
                        />
                    </FormControl>

                    <Button 
                        type="submit" 
                        colorScheme="blue" 
                        leftIcon={<i className="feather icon-send" />} 
                        mt={4} 
                        isLoading={loading}
                    >
                        Send Email
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default HaziQuickMailScreen;

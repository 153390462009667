import { Box, Flex, Heading, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Input, Select, Text, Center } from "@chakra-ui/react";
import { FaSearch, FaPlusCircle } from "react-icons/fa";

const HaziMailCampaigns = () => {
  return (
    <Box bg="white" borderRadius="sm" m={4} p={6}>
      {/* Header */}
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }}>
          Campaigns
        </Heading>
        <Breadcrumb spacing="8px" separator=">">
          <BreadcrumbItem>
            <BreadcrumbLink href="/trial/socials-h/quick-email">Mails</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/trial/socials-h/campaigns-email">Campaigns</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Email Campaigns</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      {/* Add New Campaign Button */}
      <Box mb={4}>
        <Button
          as="a"
          href="*"
          leftIcon={<FaPlusCircle />}
          colorScheme="pink"
          variant="solid"
        >
          Add New
        </Button>
      </Box>

      {/* Filters Section */}
      <Flex gap={4} wrap="wrap" mb={6}>
        {/* Search Box */}
        <Flex align="center" bg="gray.100" borderRadius="md" p={2} w={{ base: "100%", md: "auto" }}>
          <Input placeholder="Search campaign" variant="unstyled" mr={2} />
          <FaSearch color="gray" />
        </Flex>

        {/* Sent Campaigns Filter */}
        <Select placeholder="Sent Campaigns" maxW="200px">
          <option value="activeCampaign">Active Campaigns</option>
          <option value="inactiveCampaign">Inactive Campaigns</option>
        </Select>

        {/* Campaign Type Filter */}
        <Select placeholder="Email" maxW="150px">
          <option value="sms">SMS</option>
        </Select>
      </Flex>

      {/* Empty State Message */}
      <Center mt={16}>
        <Box w={{ base: "90%", md: "50%" }} borderWidth="1px" borderRadius="lg" p={6}>
          <Text textAlign="center" fontSize="lg">
            You have not created a campaign yet
          </Text>
        </Box>
      </Center>
    </Box>
  );
};

export default HaziMailCampaigns;

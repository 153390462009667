import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { IoPencilSharp, IoTrashBinSharp, IoEyeSharp, IoClose } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import {
  deleteClients,
  listClients,
  createClient,
  updateClient,
} from "../../actions/clientsActions";
import { CLIENTS_UPDATE_RESET } from "../../constants/clientsConstants";
import Loader from "../../components/Loader";
import Message from "../../components/Message";


const ClientsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const clientsList = useSelector((state) => state.clientsList);
  const { loading, error, clients } = clientsList;

  const currentClients = 
  clients && clients.data
    ? clients.data.slice(indexOfFirstItem, Math.min(indexOfLastItem, clients.data.length))
    : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const clientsDelete = useSelector((state) => state.clientsDelete);
  const { success } = clientsDelete;

  const { success: createSuccess } = useSelector((state) => state.clientsCreate);  

  const { success: updateSuccess } = useSelector((state) => state.clientUpdate);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientData, setClientData] = useState({
    id: null,
    name: "",
    product_id: "",
    prefix: "",
    facebook_page_id: "",
    instance_id: "",
    access_token: "",
    isWhatsappEnabled: false,
  });  

  useEffect(() => {
    if (userInfo) {
      dispatch(listClients(searchQuery));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, success, createSuccess, updateSuccess, searchQuery]);
  
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you wannt to delete this record?")) {
      dispatch(deleteClients(id));
    }
    navigate("/clients");
  };

  const openModal = (client) => {
    if (client) {
      setClientData(client);
    } else {
      setClientData({
        id: null,
        name: "",
        product_id: "",
        prefix: "",
        facebook_page_id: "",
        instance_id: "",
        access_token: "",
        isWhatsappEnabled: false,
      });
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setClientData({
      id: null,
      name: "",
      product_id: "",
      prefix: "",
      facebook_page_id: "",
      instance_id: "",
      access_token: "",
      isWhatsappEnabled: false,
    });
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    if (clientData.id) {
      dispatch(updateClient(clientData.id, clientData));
    } else {
      dispatch(createClient(clientData));
      dispatch({ type: CLIENTS_UPDATE_RESET });
    }
      dispatch(listClients(searchQuery));
    setIsModalOpen(false);
  };
  
  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Clients
        </Heading>
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          m={8}
          maxWidth='500px'
          borderColor='blue.400'
          borderWidth='2px'
        />
        <Button
          onClick={() => openModal()}
          align="center"
          color="blue.500"
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          padding="4"
          textAlign="center"
          bgColor="white"
          m={4}
          _hover={{ bgColor: 'blue.500', color: 'white', borderWidth:"2px", borderColor:"white" }}
          cursor="pointer"
          variant="outline"
        >
          <Icon as={MdAdd} boxSize={6} mr={2} />
          <Text mr={2} display={{ base: "none", md: "block" }}>Add</Text>
        </Button>
      </Flex>
     
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Product ID</Th>
                <Th>Prefix</Th>
                <Th>Name</Th>
                <Th>Facebook ID</Th>
                <Th>Instance ID</Th>
                <Th>Access Token</Th>
                <Th>Whatsapp Enabled</Th>
                <Th>Date Created</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentClients.length > 0 ? (
                currentClients.map((client, index) => (
                  <Tr key={client.id}>
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{client.product_id}</Td>
                    <Td>{client.prefix}</Td>
                    <Td>{client.name}</Td>
                    <Td>{client.facebook_page_id}</Td>
                    <Td>{client.instance_id || 'N/A'}</Td>
                    <Td>{client.access_token || 'N/A'}</Td>
                    <Td>
                      {client.isWhatsappEnabled ? (
                        <Checkbox
                          isChecked={client.isWhatsappEnabled}
                          colorScheme="green"
                          isReadOnly
                        />
                      ) : (
                        <Icon as={IoClose} color="red.500" boxSize={6} />
                      )}
                    </Td>
                    <Td>
                      {moment(client.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Button
                          mr="4"
                          as={RouterLink}
                          to={`/clients/${client.id}`}
                          colorScheme="blue"
                          bg='blue.500'
                        >
                          <Icon as={IoEyeSharp} color="white" size="md" />
                        </Button> 
                        <Button
                          mr="4"
                          colorScheme="blue"
                          bg='blue.500'
                          onClick={() => openModal(client)}
                        >
                          <Icon as={IoPencilSharp} color="white" size="md" />
                        </Button>
                        <Button
                          mr="4"
                          colorScheme="red"
                          onClick={() => deleteHandler(client.id)}
                        >
                          <Icon as={IoTrashBinSharp} color="white" size="md" />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="10" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to {clients.data ? Math.min(indexOfLastItem, clients.data.length) : 0} of {clients.data ? clients.data.length : 0} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to {clients.data ? Math.min(indexOfLastItem, clients.data.length) : 0} of {clients.data ? clients.data.length : 0} entries
              </Box>
              <Flex>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {[...Array(Math.ceil((clients.data?.length || 0) / itemsPerPage))].map((_, index) => (
                  <React.Fragment key={index}>
                    {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(clients.data?.length / itemsPerPage) ? (
                      <Button
                        key={index}
                        variant={currentPage === index + 1 ? "solid" : "outline"}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : (
                      index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={currentPage === Math.ceil(clients.data?.length / itemsPerPage)}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
      <Spacer h="5" />

      {/* Modal for adding/editing a client */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{clientData.id ? "Edit Client" : "Add Client"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel htmlFor="name">Client Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={clientData.name}
                onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="product_id">Product ID</FormLabel>
              <Input
                type="text"
                name="product_id"
                value={clientData.product_id}
                onChange={(e) => setClientData({ ...clientData, product_id: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="prefix">Prefix</FormLabel>
              <Input
                type="text"
                name="prefix"
                value={clientData.prefix}
                onChange={(e) => setClientData({ ...clientData, prefix: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="facebook_page_id">Facebook Page ID</FormLabel>
              <Input
                type="text"
                name="facebook_page_id"
                value={clientData.facebook_page_id}
                onChange={(e) => setClientData({ ...clientData, facebook_page_id: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="instance_id">Instance ID</FormLabel>
              <Input
                type="text"
                name="instance_id"
                value={clientData.instance_id || ''}
                onChange={(e) => setClientData({ ...clientData, instance_id: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="access_token">Access Token</FormLabel>
              <Input
                type="text"
                name="access_token"
                value={clientData.access_token || ''}
                onChange={(e) => setClientData({ ...clientData, access_token: e.target.value })}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="isWhatsappEnabled">Whatsapp Enabled</FormLabel>
              <Checkbox
                name="isWhatsappEnabled"
                isChecked={clientData.isWhatsappEnabled}
                colorScheme="green" 
                onChange={(e) => setClientData({ ...clientData, isWhatsappEnabled: e.target.checked })}
              >
                Enabled
              </Checkbox>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" bg='blue.500' mr={3} onClick={closeModal}>
              Close
            </Button>
            <Button colorScheme="green" bg='brandGreen' onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default ClientsScreen;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import moment from "moment-timezone";

import { listqueLog } from "../../actions/queLogActions";

const InboundCallReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const queLogList = useSelector((state) => state.queLogList);
  const { queLog } = queLogList || {};

  useEffect(() => {
    if (userInfo) {
      dispatch(listqueLog());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredQueLog, setFilteredQueLog] = useState([]);

  const handleDownloadCSV = () => {
    if (filteredQueLog.length === 0) {
      console.error('No data to download');
      return;
    }
  
    const csv = Papa.unparse(filteredQueLog);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'queuelog.csv');
  };
  

  const filterQueLogByDate = () => {
    console.log("From Date:", fromDate);
    console.log("To Date:", toDate);
  
    if (typeof queLog !== "object") {
      console.error("queLog is not an object");
      return;
    }
  
    const queLogArray = Object.values(queLog);
  
    const filteredData = queLogArray.filter((log) => {
      const logDate = moment(log.created).format("YYYY-MM-DD");
      return moment(logDate).isBetween(fromDate, toDate, null, "[]");
    });
  
    console.log('Filtered data:', filteredData);
  
    setFilteredQueLog(filteredData.map((log) => ({
      "TRANS ID": log.trx_id,
      "PRODUCT ID": log.queue_id,
      "AGENT": log.agent,
      "EVENT NAME": log.event_name,
      "DELAY TIME": log.delay_time,
      "MSISDN": log.msisdn,
      "CREATED AT": moment(log.created).format("YYYY-MM-DD HH:mm:ss"),
    })));
  };
  

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
          Call Reports
        </Heading>
      </Flex>
      <Spacer h="2" bg="blackAlpha.50" />

      <Flex
        flexWrap="wrap"
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
        mr={8}
        ml={8}
        mt={8}
        fontSize="lg"
      >
        <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }} w={{ base: "70%", md: "40%" }}>
          <Text style={{ fontWeight: "bold" }}>From Date</Text>
          <Input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </Flex>

        <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 6, md: 0 }} w={{ base: "70%", md: "40%" }}>
          <Text style={{ fontWeight: "bold" }}>To Date</Text>
          <Input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" mt={8} ml={10} mr={10}>
        <Button
          colorScheme="blue"
          bg="blue.500"
          color="white"
          onClick={filterQueLogByDate}
        >
          Filter
        </Button>

        {filteredQueLog.length > 0 && (
          <Button
            colorScheme="blue"
            bg="blue.500"
            color="white"
            onClick={handleDownloadCSV}
          >
            <Icon as={FaDownload} size="sm" mr={2} />
            Download CSV
          </Button>
        )}
      </Flex>

      <Spacer h="5" />
    </Box>
  );
};

export default InboundCallReports;

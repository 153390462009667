import axios from 'axios';

import {
    WHATSAPP_OUTBOUND_LIST_REQUEST,
    WHATSAPP_OUTBOUND_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_LIST_FAIL,
    WHATSAPP_OUTBOUND_DETAILS_REQUEST,
    WHATSAPP_OUTBOUND_DETAILS_SUCCESS,
    WHATSAPP_OUTBOUND_DETAILS_FAIL,
    WHATSAPP_OUTBOUND_CREATE_REQUEST,
    WHATSAPP_OUTBOUND_CREATE_SUCCESS,
    WHATSAPP_OUTBOUND_CREATE_FAIL,
    WHATSAPP_OUTBOUND_DELETE_REQUEST,
    WHATSAPP_OUTBOUND_DELETE_SUCCESS,
    WHATSAPP_OUTBOUND_DELETE_FAIL,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS,
    WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS,
    WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL,
} from '../constants/whatsappOutboundConstants';

export const listWhatsappOutbounds = (searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_LIST_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/whatsapp-outbounds?search=${searchQuery}`, config);

        dispatch({ type: WHATSAPP_OUTBOUND_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const getWhatsappOutboundDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_DETAILS_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/whatsapp-outbounds/${id}`, config);

        dispatch({ type: WHATSAPP_OUTBOUND_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_DETAILS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const createWhatsappOutbound = (outboundData) => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_CREATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/whatsapp-outbounds`, outboundData, config);

        dispatch({ type: WHATSAPP_OUTBOUND_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const deleteWhatsappOutbound = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/whatsapp-outbounds/${id}`, config);

        dispatch({ type: WHATSAPP_OUTBOUND_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const listWhatsappOutboundsByProduct = (productId, searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/whatsapp-outbounds/product/${productId}?search=${searchQuery}`, config);

        dispatch({ type: WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const listWhatsappOutboundsByClient = (search = '', startDate = '', endDate = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: {
                search, 
                start_date: startDate,
                end_date: endDate
            },
        };

        const { data } = await axios.get('/api/whatsapp-outbounds/products/messages', config);

        dispatch({ type: WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const deleteWhatsappOutboundsByDateRange = (startDate, endDate) => async (dispatch, getState) => {
    try {
        dispatch({ type: WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        await axios.delete(`/api/whatsapp-outbounds/date-range`, {
            data: { startDate, endDate }, 
            ...config
        });

        dispatch({ type: WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};


import {
  AGENT_LIST_REQUEST,
  AGENT_LIST_SUCCESS,
  AGENT_LIST_FAIL,
  AGENT_DETAILS_SUCCESS,
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_FAIL,
  AGENT_SUPERVISOR_LIST_REQUEST,
  AGENT_SUPERVISOR_LIST_SUCCESS,
  AGENT_SUPERVISOR_LIST_FAIL,
  AGENT_DELETE_REQUEST,
  AGENT_DELETE_SUCCESS,
  AGENT_DELETE_FAIL,
  AGENT_CREATE_REQUEST,
  AGENT_CREATE_SUCCESS,
  AGENT_CREATE_FAIL,
  AGENT_CREATE_RESET,
  AGENT_UPDATE_REQUEST,
  AGENT_UPDATE_SUCCESS,
  AGENT_UPDATE_FAIL,
  AGENT_UPDATE_RESET,
} from '../constants/agentConstants';

export const agentListReducer = (state = { agent: [] }, action) => {
  switch (action.type) {
    case AGENT_LIST_REQUEST:
      return { loading: true, agent: [] };

    case AGENT_LIST_SUCCESS:
      return { loading: false, agent: action.payload, success: true };
    case AGENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const agentDetailsReducer = (state = { agent: {} }, action) => {
  switch (action.type) {
    case AGENT_DETAILS_REQUEST:
      return { ...state, loading: true };

    case AGENT_DETAILS_SUCCESS:
      return { loading: false, agent: action.payload };
    case AGENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const agentSupervisorListReducer = (state = { agents: [] }, action) => {
  switch (action.type) {
    case AGENT_SUPERVISOR_LIST_REQUEST:
      return { loading: true, agents: [] };

    case AGENT_SUPERVISOR_LIST_SUCCESS:
      return { loading: false, agents: action.payload.data, success: true };

    case AGENT_SUPERVISOR_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const agentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_DELETE_REQUEST:
      return { loading: true };
    case AGENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case AGENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const agentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_CREATE_REQUEST:
      return { loading: true };
    case AGENT_CREATE_SUCCESS:
      return { loading: false, success: true, agent: action.payload };
    case AGENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case AGENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const agentUpdateReducer = (state = { agent: {} }, action) => {
  switch (action.type) {
    case AGENT_UPDATE_REQUEST:
      return { loading: true };
    case AGENT_UPDATE_SUCCESS:
      return { loading: false, success: true, agent: action.payload };
    case AGENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case AGENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

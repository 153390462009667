import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Heading,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import { sendHaziBulkSMS, listContactGroups } from "../../actions/haziSocialsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziBulkSMSScreen = () => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [groupId, setGroupId] = useState("");
  const [message, setMessage] = useState("");

  const [originator] = useState("System Originator");
  const [smsType] = useState("plain");
  const [senderId] = useState("UJUMBEPAP");

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, error, groups } = useSelector((state) => state.haziContactGroupsList);
  const { success, error: sendError } = useSelector((state) => state.haziBulkSmsSend);

  useEffect(() => {
    if (userInfo) {
      dispatch(listContactGroups());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (success) {
      toast({
        title: "SMS Sent",
        description: "Your bulk SMS was sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setGroupId("");
      setMessage("");
    }
    if (sendError) {
      toast({
        title: "Error",
        description: "Failed to send SMS. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [success, sendError, toast]);  

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      group_id: groupId,
      originator,
      sender_id: senderId,
      message,
      sms_type: smsType,
    };
    dispatch(sendHaziBulkSMS(payload));
  };

  return (
    <Box p={6} bg="gray.50" minH="100vh">
      <Heading as="h1" fontSize="2xl" mb={6}>
        Bulk SMS
      </Heading>

      <Box
        p={8}
        bg="white"
        boxShadow="lg"
        rounded="lg"
        maxW="600px"
      >
        <Stack spacing={4}>
          {loading && <Loader />}
          {error && <Message variant="error">{error}</Message>}

          <FormControl isRequired>
            <FormLabel>Contact Group</FormLabel>
            <Select
              placeholder="Select a group"
              value={groupId}
              onChange={(e) => setGroupId(e.target.value)}
            >
              {groups?.data?.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea
              placeholder="Enter your SMS message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Sender ID</FormLabel>
            <Input value={senderId} isReadOnly />
          </FormControl>

          <Button
            colorScheme="blue"
            leftIcon={<FiSend />}
            onClick={handleSubmit}
            isDisabled={!groupId || !message}
          >
            Send SMS
          </Button>

          {success && (
            <Alert status="success" rounded="md">
              <AlertIcon />
              <AlertTitle>SMS Sent!</AlertTitle>
              <AlertDescription>
                Your SMS was sent to the selected group.
              </AlertDescription>
            </Alert>
          )}

          {sendError && (
            <Alert status="error" rounded="md">
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Failed to send the SMS. Please try again.
              </AlertDescription>
            </Alert>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default HaziBulkSMSScreen;

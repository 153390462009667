import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Input,
  Button,
  useToast,
  keyframes,
  IconButton,
} from '@chakra-ui/react';
import { FiLoader } from 'react-icons/fi';
import { BsEmojiSmile } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { websocketConnect, websocketDisconnect, sendMessage } from '../../actions/webSocketActions';
import EmojiPicker from 'emoji-picker-react';


const ChatPage = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]); // Still keeping chatMessages state for incoming messages.
  const [dataReceived, setDataReceived] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Fetch messages from the Redux store
  const { messages } = useSelector((state) => state.websocket);

  const toast = useToast();
  const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;

  // Reaction mapping for custom labels
  const reactionMap = {
    "👍": "Liked",
    "❤️": "Loved",
    "😂": "Laughed",
    "😢": "Cried",
    "👏": "Clapped",
    "🔥": "Excited",
    "😮": "Surprised",
    "🎉": "Celebrated",
    // Add more mappings as needed
  };

  const validatePhoneNumber = (number) => {
    const whatsappRegex = /^\d{10,15}$/;
    return whatsappRegex.test(number);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessageText((prevText) => prevText + emojiObject.emoji);
  };
  

  const handleSendDirectMessage = async (e) => {
    e.preventDefault();

    if (!selectedUser || !messageText) {
      toast({
        title: 'No user or message',
        description: 'Please select a user and type a message to send.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const number = selectedUser.remoteJid.split('@')[0];
    const message = messageText;

    if (!validatePhoneNumber(number)) {
      toast({
        title: 'Invalid Phone Number',
        description: 'Please enter a valid phone number.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    dispatch(sendMessage(number, message));

    // Optimistically add the sent message to the chatMessages
    setChatMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Date.now(),
        sender: 'You',
        content: message,
        fromMe: true,
        timestamp: new Date().getTime(),
        remoteJid: selectedUser.remoteJid,
        status: 'sending', // Initial status is "sending"
      },
    ]);

    setMessageText('');
  };

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const domain = process.env.REACT_APP_QUEUE_DOMAIN || 'localhost';
    const port = protocol === 'wss' ? '8443' : '8089';

    dispatch(websocketConnect(protocol, domain, port, userInfo.id));

    return () => {
      dispatch(websocketDisconnect());
    };
  }, [dispatch, userInfo]);

  const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\u200D]+/gu; // Define once

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      //console.log("Latest WebSocket message:", latestMessage);
  
      if (latestMessage.data?.event === "messages.upsert") {
        const incomingMessages = latestMessage.data.data.messages;
  
       // console.log("Incoming messages:", incomingMessages);
  
        // Process new users and update their lastMessageTimestamp
        const updatedUsers = incomingMessages.map((msg) => ({
          id: msg.key.id,
          instanceId: latestMessage.instance_id,
          name: msg.pushName || msg.key.remoteJid.split("@")[0],
          remoteJid: msg.key.remoteJid,
          participant: msg.key.participant,
          lastMessageTimestamp: msg.messageTimestamp * 1000, // Convert to ms for sorting
        }));
  
        setUniqueUsers((prevUsers) => {
          const usersMap = new Map(prevUsers.map((user) => [user.remoteJid, user]));
  
          // Update or add users with the latest message timestamp
          updatedUsers.forEach((user) => {
            usersMap.set(user.remoteJid, {
              ...usersMap.get(user.remoteJid),
              ...user,
            });
          });
  
          // Sort users by `lastMessageTimestamp` in descending order
          return Array.from(usersMap.values()).sort(
            (a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp
          );
        });
  
        // Extract message content dynamically
        const extractContent = (msg) => {
          // Handle reactions
          if (msg.message?.reactionMessage) {
            const rawReaction = msg.message.reactionMessage.text || "👍"; // Default to thumbs-up
            const reactedToMessageId = msg.message.reactionMessage.key?.id || "Unknown";
  
            // Use the mapping or fallback to raw reaction
            const interpretedReaction = reactionMap[rawReaction] || rawReaction;
  
            // Return both emoji and interpreted label
            return `Reaction: ${rawReaction} (${interpretedReaction})`;
          }
  
          // Handle text messages
          const textContent =
            msg.message?.extendedTextMessage?.text ||
            msg.message?.conversation ||
            null;
  
          if (textContent) {
            const emojis = textContent.match(emojiRegex); // Extract emojis
            return emojis
              ? `Message: ${textContent} (Includes Emojis: ${emojis.join(" ")})`
              : textContent;
          }
  
          // Handle media messages
          if (msg.message?.imageMessage) return "📷 Image Message Received";
          if (msg.message?.videoMessage) return "🎥 Video Message Received";
  
          // console.log(
          //   "Message type unsupported:",
          //   JSON.stringify(msg.message, null, 2)
          // );
          return "Unsupported message type"; // Fallback for unknown message types
        };
  
        // Process new messages
        const newMessages = incomingMessages.map((msg) => ({
          id: msg.key.id,
          sender: msg.pushName || msg.key.remoteJid.split("@")[0],
          content: extractContent(msg), // Dynamically extract content
          timestamp: msg.messageTimestamp * 1000, // Convert to milliseconds
          fromMe: msg.key.fromMe,
          remoteJid: msg.key.remoteJid,
          participant: msg.key.participant,
          groupId: msg.senderKeyDistributionMessage?.groupId,
          message: msg.message,
        }));
  
        //console.log("Newly processed messages:", newMessages);
  
        // Deduplicate messages
        setChatMessages((prevMessages) => {
          const existingIds = new Set(prevMessages.map((msg) => msg.id));
          const uniqueNewMessages = newMessages.filter(
            (msg) => !existingIds.has(msg.id)
          );
          //console.log("Unique messages being added:", uniqueNewMessages);
          return [...prevMessages, ...uniqueNewMessages];
        });
  
        setDataReceived(true);
      }
    }
  }, [messages]);
  

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const renderMessagesForSelectedUser = () => {
    if (!selectedUser) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          bg="gray.50"
          borderRadius="md"
          p={4}
        >
          <Text fontSize="lg" color="gray.500">
            Select a user to start chatting
          </Text>
        </Box>
      );
    }
  
    // Filter and sort messages for the selected user
    const allMessages = chatMessages
      .filter((msg) => msg.remoteJid === selectedUser.remoteJid)
      .sort((a, b) => a.timestamp - b.timestamp);
  
    return (
      <VStack spacing={4} align="stretch" height="100%">
        {allMessages.length > 0 ? (
          allMessages.map((message, index) => (
            <Box
              key={index}
              p={3}
              bg={message.fromMe ? "blue.50" : "gray.50"}
              alignSelf={message.fromMe ? "flex-end" : "flex-start"}
              borderRadius="lg"
              maxW="80%"
            >
              {/* Sender Information */}
              <Text
                fontWeight="bold"
                color={message.fromMe ? "blue.600" : "gray.800"}
              >
                {message.fromMe
                  ? "You"
                  : message.sender || selectedUser.name || "Unknown"}
              </Text>
  
              {/* Display the content extracted from the message */}
              <Text whiteSpace="pre-wrap" style={{ wordWrap: "break-word" }}>
                {message.content}
              </Text>
  
              {/* Display timestamp */}
              <Text fontSize="xs" color="gray.500">
                {new Date(message.timestamp).toLocaleTimeString()}
              </Text>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            bg="gray.50"
            borderRadius="md"
            p={4}
          >
            <Text fontSize="lg" color="gray.500">
              Waiting for messages...
            </Text>
          </Box>
        )}
      </VStack>
    );
  };
  

  
  if (!dataReceived) {
    return (
      <Box bg="white" borderRadius="sm" m={4} p={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          as="div"
          borderRadius="full"
          w={12}
          h={12}
          mb={4}
          border="4px solid"
          borderColor="blue.500"
          borderTopColor="transparent"
          animation={`${spin} 0.8s linear infinite`}
        />
        <Heading as="h3" size="lg" mb={4} color="blue.600">
          Waiting for chat messages...
        </Heading>
        <IconButton variant="outline" colorScheme="blue" icon={<FiLoader />} mb={6} animation={`${spin} infinite 2s linear`} />
      </Box>
    );
  }

  return (
    <HStack spacing={4} align="stretch" h="100vh" p={4}>
  {/* Sidebar with Users */}
  <Box bg="gray.100" p={4} w="25%" borderRadius="md" overflowY="auto">
    <Heading as="h4" size="md" mb={4} color="blue.600">
      Users
    </Heading>
    <VStack spacing={4} align="stretch">
  {uniqueUsers
    .filter(
      (user) =>
        user.remoteJid?.endsWith("@g.us") ||
        user.remoteJid?.endsWith("@s.whatsapp.net")
    ) // Include only group or individual chats
    .map((user, index) => (
      <Box
        key={index}
        p={3}
        bg={
          selectedUser && selectedUser.remoteJid === user.remoteJid
            ? "blue.200"
            : "white"
        }
        borderRadius="md"
        cursor="pointer"
        onClick={() => handleUserClick(user)}
      >
        {/* User Name */}
        <Text fontWeight="bold">
          {user.remoteJid?.endsWith("@g.us")
            ? `In Group: ${user.name}`
            : user.name}
        </Text>

        {/* Display Instance ID */}
  {user.instanceId && (
    <Text fontSize="xs" color="purple.500">
      Instance ID: {user.instanceId}
    </Text>
  )}

        {/* Display RemoteJid */}
        {user.remoteJid && (
          <Text fontSize="xs" color="gray.500">
            RemoteJid: {user.remoteJid}
          </Text>
        )}

        {/* Mark Chat Type */}
        {user.remoteJid?.endsWith("@g.us") ? (
          <Text fontSize="xs" color="blue.500" fontWeight="bold">
            Group Chat
          </Text>
        ) : user.remoteJid?.endsWith("@s.whatsapp.net") ? (
          <Text fontSize="xs" color="green.500" fontWeight="bold">
            Individual Chat
          </Text>
        ) : null}

        {/* Last Active Timestamp */}
        {user.lastMessageTimestamp && (
          <Text fontSize="xs" color="gray.500" fontStyle="italic">
            Last Active:{" "}
            {new Date(user.lastMessageTimestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
        )}
      </Box>
    ))}
</VStack>

  </Box>

  {/* Chat Box */}
  <Box bg="white" p={4} w="75%" borderRadius="md" overflowY="auto">
    {selectedUser ? (
      <>
        <Heading as="h4" size="md" mb={4} color="blue.600">
          Chat with {selectedUser.name}
        </Heading>

        {/* Display RemoteJid */}
        {selectedUser.remoteJid && (
          <Text fontSize="sm" color="gray.500" mb={4}>
            RemoteJid: {selectedUser.remoteJid}
          </Text>
        )}
        <VStack spacing={4} align="stretch">
          {renderMessagesForSelectedUser()}
          <Box display="flex" mt={2} alignItems="center" position="relative">
  {/* Emoji Picker Toggle Button */}
  <IconButton
    icon={<BsEmojiSmile size="1.5rem" />} // React icon for emoji picker
    aria-label="Toggle Emoji Picker"
    onClick={() => setShowEmojiPicker((prev) => !prev)}
    mr={2}
    size="sm"
    bg="gray.100"
    _hover={{ bg: "gray.200" }}
  />
  
  {/* Emoji Picker */}
  {showEmojiPicker && (
    <Box
      position="absolute"
      bottom="50px" // Adjust based on your layout
      zIndex="2"
      bg="white"
      borderRadius="md"
      boxShadow="lg"
      p={3}
      w="300px"
    >
      <EmojiPicker onEmojiClick={handleEmojiClick} />
    </Box>
  )}

  {/* Input Field */}
  <Input
    placeholder="Type a message"
    value={messageText}
    onChange={(e) => setMessageText(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        handleSendDirectMessage(e);
      }
    }}
  />
  
  {/* Send Button */}
  <Button ml={2} colorScheme="blue" onClick={handleSendDirectMessage}>
    Send
  </Button>
</Box>



        </VStack>
      </>
    ) : (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        bg="gray.50"
        borderRadius="md"
        p={4}
      >
        <Text fontSize="lg" color="gray.500">
          Select a user to start chatting
        </Text>
      </Box>
    )}
  </Box>
</HStack>

  );
};

export default ChatPage;

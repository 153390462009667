import { Box, Flex, HStack, VStack, Link, Input, Button, IconButton, Text } from "@chakra-ui/react";
import { FiRadio, FiLayout, FiMessageSquare, FiLink, FiSearch } from "react-icons/fi";
import { useState } from "react";

const HaziWhatsappChatBox = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");

  const handleSendMessage = () => {
    // Logic for sending a message goes here
    setMessage("");
  };

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      {/* Header/Navbar */}
      <Box bg="blue.500" color="white" py={4} px={6}>
        <Flex justify="space-between" align="center">
          <HStack spacing={8}>
            <Link href="/trial/socials-h/whatsapp" display="flex" alignItems="center">
              <FiRadio />
              <Text ml={2}>Broadcast</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-template" display="flex" alignItems="center">
              <FiLayout />
              <Text ml={2}>Message Template</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-chat" display="flex" alignItems="center">
              <FiMessageSquare />
              <Text ml={2}>Chat Box</Text>
            </Link>
          </HStack>
          <Link href="/trial/socials-h/whatsapp-accounts" display="flex" alignItems="center">
            <FiLink />
            <Text ml={2}>Connected Accounts</Text>
          </Link>
        </Flex>
      </Box>

      {/* Content Area */}
      <Flex mt={4} h="calc(100vh - 120px)">
        {/* Sidebar */}
        <Box w="30%" borderRight="1px solid" borderColor="gray.200" p={4}>
          <VStack spacing={4} align="stretch">
            {/* Search Box */}
            <Flex align="center">
              <Input
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg="gray.100"
                borderRadius="full"
                border="1px solid"
                borderColor="gray.300"
              />
              <IconButton
                aria-label="Search"
                icon={<FiSearch />}
                variant="ghost"
                ml={2}
                color="gray.500"
              />
            </Flex>

            {/* Users List */}
            <Box>
              <Text textAlign="center" color="gray.500">
                No chat users available.
              </Text>
            </Box>
          </VStack>
        </Box>

        {/* Chat Window */}
        <Box flex="1" p={4}>
          <VStack spacing={6} align="stretch" h="100%">
            {/* Start Chat Area */}
            <Box textAlign="center" color="gray.500">
              <FiMessageSquare size="50px" />
              <Text mt={2}>
                <Link href="https://hazi.co.ke/whatsapp/create-broadcast" color="blue.500">
                  New Conversation
                </Link>
              </Text>
            </Box>

            {/* Chat Message Area */}
            <Box flex="1" bg="gray.50" borderRadius="md" p={4} overflowY="auto">
              {/* Chat history would go here */}
              <Text textAlign="center" color="gray.500">Start chatting with your contacts</Text>
            </Box>

            {/* Chat Input Form */}
            <Flex as="form" onSubmit={(e) => e.preventDefault()} align="center">
              <Input
                placeholder="Type your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                bg="gray.100"
                flex="1"
                mr={2}
                borderRadius="full"
              />
              <Button
                onClick={handleSendMessage}
                colorScheme="blue"
                borderRadius="full"
                px={6}
              >
                Send
              </Button>
            </Flex>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default HaziWhatsappChatBox;

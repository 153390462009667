import { useEffect, useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useMediaQuery,
  keyframes,
} from '@chakra-ui/react';
import {
  FiHome,
  FiMenu,
  FiBell,
  FiChevronDown, 
} from 'react-icons/fi';
import { FaThList, FaRegCalendar, FaChartPie, FaWhatsapp } from "react-icons/fa";
import { VscCallIncoming, VscCallOutgoing, VscGitPullRequestGoToChanges,  VscGraphLine } from "react-icons/vsc";
import { MdOutlineCallSplit, MdSupportAgent, MdCalendarToday,  MdAdminPanelSettings, MdOutlineGroupAdd, MdOutlineCampaign } from "react-icons/md";
import { FaPeopleGroup, FaPeopleRoof, FaUserClock } from "react-icons/fa6";
import { BsPatchQuestionFill } from "react-icons/bs";
import { LiaClipboardListSolid } from "react-icons/lia";
import { IoIosHome, IoIosShareAlt,  } from "react-icons/io";
import { HiHome } from "react-icons/hi";
import { HiMiniQueueList } from "react-icons/hi2";
import { TbClockEdit, TbHelpSquareFilled } from "react-icons/tb";
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../actions/userActions';
import { getProductAbandonedCalls } from '../actions/cdrActions';
import { getUserNotifications } from '../actions/notificationActions';
import NotificationsModal from './NotificationsModal'; 
import AgentCheckinModal from './AgentCheckinModal';


const SubLink = ({ to, children, onClose }) => {
  const handleClick = () => {
    onClose();
  };

  return (
    <NavLink to={to} style={{ textDecoration: 'none' }}>
      <MenuItem onClick={handleClick}>{children}</MenuItem>
    </NavLink>
  );
};

const adminSupervisorSupportLinkItems = [
  { name: 'Admin Home', icon: MdAdminPanelSettings, to: '/home' },
  { name: 'Inbound', icon: VscCallIncoming, subLinks: [
      { name: 'Call Logs', to: '/inboundall-logs' },
      { name: 'Call Reports', to: '/call-reports' }
    ]
  },
  { name: 'Outbound', icon: VscCallOutgoing, subLinks: [
      { name: 'Contacts', to: '/contacts' },
      { name: 'Bulk Delete Contacts', to: '/delete-contacts' },
      { name: 'Contact Reports', to: '/contact-reports' },
      { name: 'Call Logs', to: '/outboundall-logs' },
      { name: 'Call Reports', to: '/outboundcall-reports' },
    ]
  },  
  { name: 'Clients', icon: MdOutlineGroupAdd, to: '/clients' },
  { name: 'Users', icon: FaPeopleGroup, to: '/users' },
  { name: 'Call Reasons', icon: BsPatchQuestionFill, to: '/reasons' },
  { name: 'Agent Checkins', icon: FaUserClock, to: '/agent-checkins' },
  { name: 'Scheduled Callbacks', icon: MdCalendarToday, to: '/callbacks' },
  { name: 'Demo Requests', icon: VscGitPullRequestGoToChanges, to: '/demo_reqs' },
  { name: 'Queue ', icon: HiMiniQueueList, to: '/queuemembers' },
  { name: 'WhatsApp', icon: FaWhatsapp, to: '/admin-whatsapp' },
  { name: 'Tickets', icon: VscGraphLine, to: '/alltickets' },
  { name: 'Guide', icon: TbHelpSquareFilled, to: '/guide' },
];


const agentLinkItems = [
  { name: 'Dashboard', icon: FiHome, to: '/dashboard' },
  { name: 'Products', icon: FaThList, to: '/agent-products' },
  {
    name: 'Calls',
    icon: VscCallIncoming,
    subLinks: [
      { name: 'Inbound Calls', to: '/inbound' },
      { name: 'Outbound Calls', to: '/outbound' },
    ],
  },
  //{ name : 'Calls', icon: VscCallIncoming, to: '/calls'},
  { name: 'WhatsApp', icon: FaWhatsapp, to: '/quick-whatsapp' },
  { name: 'Escalated Calls', icon: MdOutlineCallSplit,  to: '/escalated-calls' },
  { name: 'Scheduled Callbacks', icon: FaRegCalendar, to: '/scheduled-callbacks' },
  { name: 'Guide', icon: TbHelpSquareFilled, to: '/guide' },
];

const supervisorLinkItems = [
  { name: 'Dashboard', icon: HiHome, to: '/supervisor-home' },
  { name: 'Inbound Calls',icon: VscCallIncoming, to: '/supervisor-inbound' },
  { name: 'Outbound Calls',icon: VscCallOutgoing, to: '/supervisor-outbound' },
  { name: 'Agents', icon: FaPeopleRoof, to: '/supervisor-agents' },
  { name: 'Agents Queue', icon: HiMiniQueueList, to: '/queueproductmembers' },
  { name: 'WhatsApp', icon: FaWhatsapp, to: '/sup-whatsapp' }, 
  { name: 'Campaigns', icon:MdOutlineCampaign,  to: '/supervisor-campaigns' },
  { name: 'Call Reasons',  icon: LiaClipboardListSolid, to: '/supervisor-callreasons' },
  { name: 'Agent Checkins', icon:FaUserClock, to: '/supagent-checkins' },
  { name: 'Agent Productivity', icon:FaChartPie, to: '/agent-productivity' },
  { name: 'Support', icon: MdSupportAgent, to:'/supervisor-support' }, 
  { name: 'Guide', icon: TbHelpSquareFilled, to: '/guide' },
];

const supportLinkItems = [
  { name: 'Dashboard', icon:IoIosHome, to: '/support-home' },
  { name: 'Inbound Escalated',icon: VscCallIncoming, to: '/support-escalatedin' },
  { name: 'Outbound Escalated',icon: VscCallOutgoing, to: '/support-escalatedout' },
  { name: 'Escalated Calls', icon: MdOutlineCallSplit,  to: '/support-escalations' },
  { name: 'Social Escalations',icon: IoIosShareAlt, to: '/support-socials' },
  { name: 'Guide', icon: TbHelpSquareFilled, to: '/guide' },
];

const defaultLinkItems = [];

const SidebarContent = ({ onClose }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let updatedLinkItems;

  if (userInfo && userInfo.role === 'super-admin') {
    updatedLinkItems = adminSupervisorSupportLinkItems;
  } else if (userInfo && userInfo.role === 'admin') {
    updatedLinkItems = supervisorLinkItems;
  } else if (userInfo && userInfo.role === 'agent') {
    updatedLinkItems = agentLinkItems;
  } else if (userInfo && userInfo.role === 'support') {
    updatedLinkItems = supportLinkItems;
  } else {
    updatedLinkItems = defaultLinkItems;
  }

  return (
    <Box
      transition="3s ease"
      bg="brandBlue"
      borderRight="1px"
      borderRightColor="blue.500"
      w={{ base: 80, md: 60 }}
      pos="fixed"
      h="full"
    >
      <Flex h="20" alignItems="top" mx="8" my="4">
        <Text fontSize='3xl' color="whiteAlpha.800">
          zuridesk
        </Text>
        <Text fontSize="3xl" align="left" color="orange.500">
          .
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} color="white" />
      </Flex>
      {updatedLinkItems.map((link) => (
         <Box  borderRadius="lg" role="group" cursor="pointer" _hover={{ bg: 'blue.500', color: 'white' }} key={link.name}>
         {link.subLinks ? (
           <Menu key={link.name}>              
             <MenuButton mr="8" as={Text}  h="14"
              color="white"
              ml="6" 
              display="flex"
              alignItems="center"
             >
               <Icon mr="4" fontSize="16" _groupHover={{ color: 'white'}} as={link.icon} ml={2} />
               {link.name}                
             </MenuButton>
              <MenuList as={Box} color="black" bg="brandBlue" border="none">
                {link.subLinks.map((subLink) => (
                  <SubLink key={subLink.name} to={subLink.to} onClose={onClose}>
                    <Text px="4" py="2" _hover={{ bg: 'blue.500', color: 'white', borderRadius: 'lg' }}>
                      {subLink.name}
                    </Text>
                  </SubLink>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <NavItem icon={link.icon} color="white" onClose={onClose}>
              <RouterLink to={link.to} style={{ textDecoration: 'none' }}>
                {link.name}
              </RouterLink>
            </NavItem>
          )}
        </Box>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, onClose, ...rest }) => {

  const handleClick = () => {    
    onClose();
  };
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'blue.500',
          color: 'white',
        }}
        {...rest}
        onClick={handleClick}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const userNotifications = useSelector((state) => state.userNotifications);
  const { notifications } = userNotifications;

  useEffect(() => {
      if (notifications && notifications.data) {
          const unreadNotifications = notifications.data.filter(notification => notification.status === 'unread');
          setUnreadCount(unreadNotifications.length);
      }
  }, [notifications]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productAbandonedCallsList = useSelector((state) => state.productAbandonedCallsList);
  const { calls: productAbandonedCalls } = productAbandonedCallsList;

  useEffect(() => {
      if (userInfo) {
          dispatch(getUserNotifications());  
      }
  }, [dispatch, userInfo]);

  useEffect(() => {
      const fetchProductAbandonedCalls = async () => {
          try {
              const prodCode = userInfo.product_id;
              dispatch(getProductAbandonedCalls(prodCode));
          } catch (error) {
              console.error('Error fetching product abandoned calls:', error);
          }
      };

      if (userInfo && (userInfo.role === 'agent' || userInfo.role === 'admin')) {
          fetchProductAbandonedCalls();
      }
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
      dispatch(logout());
      navigate('/');
  };

  useEffect(() => {
      if (!userInfo) {
          navigate('/');
      }
  }, [navigate, userInfo]);

  if (!userInfo) {
      return null;
  }

  const rotate = keyframes`
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
  `;

  return (
      <Flex
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          bg="brandBlue"
          borderBottomWidth="1px"
          borderBottomColor="blue.500"
          color="white"
          justifyContent={{ base: 'space-between', md: 'flex-end' }}
          {...rest}
      >
          <IconButton
              display={{ base: 'flex', md: 'none' }}
              onClick={onOpen}
              variant="outline"
              bg="white"
              aria-label="open menu"
              icon={<FiMenu />}
          />

          {userInfo.role === 'agent' || userInfo.role === 'admin' ? (
              <NavLink to="/inbound/abandoned-calls" style={{ textDecoration: 'none' }}>
                  <Text
                      display='flex'
                      mr={{ base: '10', md: '300' }}
                      alignSelf='center'            
                      color="white"
                      m={2}
                  >
                      Abandoned Calls: {productAbandonedCalls !== null && productAbandonedCalls !== undefined ? productAbandonedCalls.length : 'Loading...'}
                  </Text>
              </NavLink>
          ) : null}

          <Flex alignItems="center">
              {userInfo ? (
                  <Menu>
                      <Box position="relative" mr={4}>
                          <IconButton
                              size="lg"
                              variant="outline"
                              aria-label="open notifications"
                              color="white"
                              _hover={{ bg: 'blue.500' }}
                              icon={<FiBell />}
                              onClick={() => setNotificationsOpen(true)}
                              marginRight={unreadCount > 0 ? '5px' : '0'}
                          />
                          {unreadCount > 0 && (
                              <Box position="absolute" top="-4px" right="-1px">
                                  <Box
                                      bg="red"
                                      color="white"
                                      borderRadius="50%"
                                      width="20px"
                                      height="20px"
                                      textAlign="center"
                                      lineHeight="20px"
                                  >
                                      {unreadCount}
                                  </Box>
                              </Box>
                          )}
                      </Box>
                      <NotificationsModal isOpen={notificationsOpen} onClose={() => setNotificationsOpen(false)} />
                      <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }} mr={4}>
                          <HStack>
                              <Avatar
                                  size={'sm'}
                                  src={userInfo?.icon ? userInfo?.icon :
                                      'https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                  }
                              />
                              <VStack display='flex' alignItems="flex-start" spacing="1px" ml="2">
                                  <Text fontSize="sm">{userInfo?.name}</Text>
                                  <Text fontSize="xs" color="white">
                                      Welcome ✨
                                  </Text>
                              </VStack>
                              <Box display={{ base: 'none', md: 'flex' }}>
                                  <FiChevronDown />
                              </Box>
                          </HStack>
                      </MenuButton>
                      <MenuList bg="brandBlue" color="black" borderColor="blue.500">
                          <MenuItem>
                              <NavLink to="/profile" style={{ textDecoration: 'none' }}>
                                  Profile
                              </NavLink>
                          </MenuItem>
                          <MenuDivider />
                          {(userInfo.role === 'super-admin') && (
                              <>
                                  <MenuItem>
                                      <NavLink to="/users" style={{ textDecoration: 'none' }}>
                                          Manage Users
                                      </NavLink>
                                  </MenuItem>
                                  <MenuDivider />
                              </>
                          )}
                          <MenuItem onClick={logoutHandler}>
                              Logout
                          </MenuItem>
                      </MenuList>
                  </Menu>
              ) : (
                  <Text color="white">Not logged in</Text>
              )}

              {userInfo.role === 'agent' && (
                  <IconButton
                      variant="outline"
                      color="white"
                      _hover={{ bg: 'blue.500' }}
                      onClick={handleOpenModal}
                      icon={<TbClockEdit />}
                      animation={`${rotate} infinite 2s linear`}
                  />
              )}

              {userInfo.role === 'agent' && (
                  <AgentCheckinModal isOpen={isModalOpen} onClose={handleCloseModal} />
              )}

          </Flex>
      </Flex>
  );
}

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 48em)'); 
  
  return (
    <Box bg="brandBlue">
      {!isMobile && <SidebarContent onClose={onClose} />}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        w="50%"
      >
        <DrawerContent bg="brandBlue">
        <SidebarContent onClose={onClose}  />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />      
    </Box>
  );
};

export default SidebarWithHeader;
import axios from "axios";

import {
  SUPPORT_LIST_REQUEST,
  SUPPORT_LIST_SUCCESS,
  SUPPORT_LIST_FAIL,
  SUPPORT_PRODUCT_LIST_REQUEST,
  SUPPORT_PRODUCT_LIST_SUCCESS,
  SUPPORT_PRODUCT_LIST_FAIL,
  SUPPORT_DETAILS_SUCCESS,
  SUPPORT_DETAILS_REQUEST,
  SUPPORT_DETAILS_FAIL,
  SUPPORT_DELETE_REQUEST,
  SUPPORT_DELETE_SUCCESS,
  SUPPORT_DELETE_FAIL,
  SUPPORT_CREATE_REQUEST,
  SUPPORT_CREATE_SUCCESS,
  SUPPORT_CREATE_FAIL,
  SUPPORT_UPDATE_REQUEST,
  SUPPORT_UPDATE_SUCCESS,
  SUPPORT_UPDATE_FAIL,
} from '../constants/supportConstants';

export const listSupports = (searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_LIST_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/support?search=${searchQuery}`, 
      config
    );

    dispatch({ type: SUPPORT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPORT_LIST_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

export const listSupportsByProduct = (product_id, searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_PRODUCT_LIST_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/support/product/${product_id}?search=${searchQuery}`, 
      config
    );

    dispatch({ type: SUPPORT_PRODUCT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPORT_PRODUCT_LIST_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

export const listSupportDetails = (supportId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_DETAILS_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/support/${supportId}`,
      config
    );

    dispatch({ type: SUPPORT_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPORT_DETAILS_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

export const deleteSupport = (supportId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_DELETE_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/support/${supportId}`,
      config
    );

    dispatch({ type: SUPPORT_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: SUPPORT_DELETE_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

export const createSupport = (supportData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_CREATE_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/support`,
      supportData,
      config
    );

    dispatch({ type: SUPPORT_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPORT_CREATE_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

export const updateSupport = (supportId, supportData) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_UPDATE_REQUEST });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/support/${supportId}`,
      supportData,
      config
    );

    dispatch({ type: SUPPORT_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SUPPORT_UPDATE_FAIL,
      payload:
        err.response && err.response.data.error
          ? err.response.data.error
          : err.message,
    });
  }
};

import React from "react";
import slideImg1 from "./call.png";

import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  IconButton,
} from "@chakra-ui/react";

export default function Homescreen() {
  return (
    <Container backgroundColor="#00669E" maxWidth="xlg">
      <Stack
        align={"center"}
        px={4}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 16 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "red.400",
                zIndex: -1,
              }}
            >
            
            </Text>
            <br></br>
        
            <Text as={"span"} color="#FFFFFF" fontSize={{base:"2xl",sm:"48px",lg:"48px"}} fontFamily="Montserrat, sans-serif">
              Your Customer Communication Solution
            </Text>
          </Heading>
          <Text color={"whiteAlpha.700"} fontFamily="Open Sans, sans-serif" fontSize="16px">
            <p>
              Enhance Your customer service delivery through Zuridesk Call
              centre solution
            </p>
            <br></br>
            <p>
              Whether you need your customer service team to Work-from-Home or a
              complete solution that enables your business handle concurrent
              inbound and outbound calls, Zuridesk provides you with the
              platform to achieve this by offering you a simple to use platform
              that works.
            </p>
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              rounded={"full"}
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              colorScheme={"red"}
              bg={"red.400"}
              _hover={{ bg: "red.500" }}
            >
              Get started
            </Button>
            <Button rounded={"full"} size={"lg"} fontWeight={"normal"} px={6}>
              How It Works
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          <Box
            position={"relative"}
            height={{base:"420px", xxl:"100%"}}
            rounded={"2xl"}  

            
                
            width={"full"}
            overflow={"hidden"}
          >
            <IconButton
              aria-label={"Play Button"}
              variant={"ghost"}
              _hover={{ bg: "transparent" }}
              size={"lg"}
              color={"white"}
              position={"absolute"}
              left={"50%"}
              top={"50%"}
              transform={"translateX(-50%) translateY(-50%)"}
            />
            <Image
              bo
              alt={"Hero Image"}
              align={"center"}
              fit={"cover"}
              w={"100%"}
              h={"100%"}
              src={
                slideImg1

                // "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
              }
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}

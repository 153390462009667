import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  Spinner,
  Box,
  Select,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { updateAgentCheckin, getLatestLogoutCheckin } from '../actions/agentCheckinsActions';

const AgentCheckinModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [selectedCheckinId, setSelectedCheckinId] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const agentLatestLogoutCheckin = useSelector((state) => state.agentLatestLogoutCheckin);
  const { checkins, loading, error } = agentLatestLogoutCheckin;

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getLatestLogoutCheckin(userInfo.id));
    }
  }, [dispatch, userInfo]);

  const handleUpdate = () => {
    if (reason && selectedCheckinId) {
      dispatch(updateAgentCheckin(selectedCheckinId, { reason }))
        .then(() => {
          dispatch(getLatestLogoutCheckin(userInfo.id));
          setSelectedCheckinId(null);
          onClose();
        })
        .catch((error) => {
          console.error('Error updating agent check-in:', error);
        });
    }
  };

  const handleSelectCheckin = (checkinId) => {
    setSelectedCheckinId(checkinId);
    setReason('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Agent Check-ins</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Spinner />
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : checkins?.length > 0 ? (
            <VStack spacing={4} align="stretch">
              {checkins?.map((checkin) => (
                <Box key={checkin?.id} p={4} borderWidth="1px" borderRadius="lg" bg="gray.50" boxShadow="sm">
                  <Text fontSize="sm">
                    Created At: {moment(checkin?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                  <Text fontSize="md" fontWeight="bold" color="gray.700">
                    Type: {checkin?.type}
                  </Text>
                  <Button size="sm" mt={2} colorScheme="blue" onClick={() => handleSelectCheckin(checkin?.id)}>
                    Update logout reason
                  </Button>
                </Box>
              ))}
            </VStack>
          ) : (
            <Text>No check-ins found.</Text>
          )}

          {/* Form for updating reason */}
          {selectedCheckinId && (
            <Box p={3} color="white" bg="blue.500" boxShadow="lg" mt={4} borderRadius="md">
              <Text fontWeight="bold">Break Reason:</Text>
              <Select
                defaultValue=""
                color="black"
                borderColor="blue.500"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Select Reason</option>
                <option value="Potty Break">Potty Break</option>
                <option value="Tea Break">Tea Break</option>
                <option value="Lunch Break">Lunch Break</option>
                <option value="Training">Training</option>
                <option value="Coaching">Coaching</option>
                <option value="Escalation">Escalation</option>
                <option value="Getting a drink">Getting a drink</option>
                <option value="Outbound Calls">Outbound Calls</option>
                <option value="Shift End">Shift End</option>
              </Select>
              <Button
                onClick={handleUpdate}
                size="sm"
                fontWeight="bold"
                bg="white"
                _hover={{ bg: 'gray.100' }}
                mt="2"
              >
                Save
              </Button>
              <Button
                onClick={() => setSelectedCheckinId(null)}
                size="sm"
                fontWeight="bold"
                colorScheme="red"
                _hover={{ bg: 'red.400' }}
                mt="2"
                ml="2"
              >
                Hide
              </Button>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="gray">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AgentCheckinModal;

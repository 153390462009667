import {
    DEMO_REQUESTS_LIST_FAIL,
    DEMO_REQUESTS_LIST_REQUEST,
    DEMO_REQUESTS_LIST_SUCCESS,
    DEMO_REQUEST_CREATE_FAIL,
    DEMO_REQUEST_CREATE_REQUEST,
    DEMO_REQUEST_CREATE_SUCCESS,
} from '../constants/demoRequestConstants';

export const demoRequestsListReducer = (state = { demoRequests: [] }, action) => {
    switch (action.type) {
        case DEMO_REQUESTS_LIST_REQUEST:
            return { loading: true, demoRequests: [] };

        case DEMO_REQUESTS_LIST_SUCCESS:
            return { loading: false, demoRequests: action.payload };

        case DEMO_REQUESTS_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const demoRequestCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case DEMO_REQUEST_CREATE_REQUEST:
            return { loading: true };

        case DEMO_REQUEST_CREATE_SUCCESS:
            return { loading: false, success: true, demoRequest: action.payload };

        case DEMO_REQUEST_CREATE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const NOTIFICATIONS_LIST_REQUEST = "NOTIFICATIONS_LIST_REQUEST";
export const NOTIFICATIONS_LIST_SUCCESS = "NOTIFICATIONS_LIST_SUCCESS";
export const NOTIFICATIONS_LIST_FAIL = "NOTIFICATIONS_LIST_FAIL";

export const NOTIFICATION_DETAILS_REQUEST = "NOTIFICATION_DETAILS_REQUEST";
export const NOTIFICATION_DETAILS_SUCCESS = "NOTIFICATION_DETAILS_SUCCESS";
export const NOTIFICATION_DETAILS_FAIL = "NOTIFICATION_DETAILS_FAIL";

export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_FAIL = "NOTIFICATION_DELETE_FAIL";

export const NOTIFICATION_CREATE_REQUEST = "NOTIFICATION_CREATE_REQUEST";
export const NOTIFICATION_CREATE_SUCCESS = "NOTIFICATION_CREATE_SUCCESS";
export const NOTIFICATION_CREATE_FAIL = "NOTIFICATION_CREATE_FAIL";
export const NOTIFICATION_CREATE_RESET = "NOTIFICATION_CREATE_RESET";

export const NOTIFICATION_UPDATE_REQUEST = "NOTIFICATION_UPDATE_REQUEST";
export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAIL = "NOTIFICATION_UPDATE_FAIL";
export const NOTIFICATION_UPDATE_RESET = "NOTIFICATION_UPDATE_RESET";

export const USER_NOTIFICATIONS_REQUEST = "USER_NOTIFICATIONS_REQUEST";
export const USER_NOTIFICATIONS_SUCCESS = "USER_NOTIFICATIONS_SUCCESS";
export const USER_NOTIFICATIONS_FAIL = "USER_NOTIFICATIONS_FAIL";

export const MARK_NOTIFICATION_AS_READ_REQUEST = "MARK_NOTIFICATION_AS_READ_REQUEST";
export const MARK_NOTIFICATION_AS_READ_SUCCESS = "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAIL = "MARK_NOTIFICATION_AS_READ_FAIL";

export const MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST = "MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST";
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = "MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS";
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAIL = "MARK_ALL_NOTIFICATIONS_AS_READ_FAIL";

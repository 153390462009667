import { Box, Text, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import ChatPage from '../admin/ChatPage';
import WhatsappSend from './WhatsappSend';
import WhatsappAgentSession from './WhatsappAgentSession';
import WhatsAppProductHistory from './WhatsAppProductHistory';

const WhatsappHome = () => {

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Text fontSize="xl" fontWeight="bold" m={8}>WhatsApp</Text>      
      <Tabs variant='enclosed' mt={4} isLazy>
          <TabList
            overflowX="auto"
            whiteSpace="nowrap"
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Tab>
              Chat
            </Tab> 
            <Tab>
              Quick Message
            </Tab>
            <Tab>
              History
            </Tab>         
            <Tab>
              Session
            </Tab>            
          </TabList>

          <TabPanels>
            <TabPanel>
              <ChatPage /> 
            </TabPanel>
            <TabPanel>
              <WhatsappSend /> 
            </TabPanel>
            <TabPanel>
              <WhatsAppProductHistory />
            </TabPanel>
            <TabPanel>
              <WhatsappAgentSession /> 
            </TabPanel>
          </TabPanels>
        </Tabs>
      
    </Box>
  );
};

export default WhatsappHome;

import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Link,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

const NavbarItem = ({ to, children, onClick }) => (
  <Text
    ml={{ base: 0, md: 6 }}
    mt={{ base: 4, md: 0 }}
    display="block"
    fontWeight="medium"
    letterSpacing="0.3px"
    _hover={{ transform: "translateY(-2px)" }}
  >
    <Link
      as={RouterLink}
      to={to}
      ml="4"
      color="white"
      onClick={onClick}
      _hover={{
        textDecoration: "none",
        position: "relative",
        _after: {
          content: "''",
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "-10px",
          borderBottom: "3px solid #F96F5A",
          borderRadius: "5px",
        },
        transform: "translateY(-2px)",
      }}
      href={to === "/" ? "/" : `/${to.toLowerCase().replace(" ", "-")}`}
    >
      {children}
    </Link>
  </Text>
);

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!isMobile && isOpen) {
      onClose();
    }
  }, [isMobile, isOpen, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 1, easing: "ease-in" }}
    >
      <Flex
        as="header"
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap"
        py="6"
        px="6"
        backgroundColor="#00669E"
        boxShadow="md"
        w="100%"
        pos="fixed"
        zIndex="9999"
        top="0"
        left="0"
        fontFamily="'Lato', sans-serif"
      >
        {/* Logo */}
        <Box ml={6} cursor="pointer">
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <Flex>
              <Heading
                as="h2"
                color="white"
                fontWeight="bold"
                size="xl"
                ml={4}
                letterSpacing="wide"
              >
                <Text display="inline">
                  Zuridesk
                  <Text as="span" color="red.400">.</Text>
                </Text>
              </Heading>
            </Flex>
          </motion.div>
        </Box>

        {/* Mobile Menu Icon */}
        <Box display={{ base: "block", md: "none" }}>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isOpen ? (
              <Icon as={FaTimes} color="white" w="6" h="6" onClick={onClose} />
            ) : (
              <Icon
                as={HiOutlineMenuAlt3}
                color="white"
                w="6"
                h="8"
                onClick={onOpen}
              />
            )}
            <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
              <DrawerOverlay>
                <DrawerContent
                  mt={10}
                  backgroundColor="#00669E"
                  boxShadow="md"
                >
                  <DrawerBody mt={8}>
                    <Box mt={1}>
                      <NavbarItem to="/" onClick={onClose}>
                        Home
                      </NavbarItem>
                      <NavbarItem to="/about" onClick={onClose}>
                        About Us
                      </NavbarItem>
                      <NavbarItem to="/features" onClick={onClose}>
                        Features
                      </NavbarItem>
                      <NavbarItem to="/blog" onClick={onClose}>
                        Blog
                      </NavbarItem>
                      <NavbarItem to="/contact" onClick={onClose}>
                        Contact Us
                      </NavbarItem>
                    </Box>
                    <Button
                      as={Link}
                      href="/request-demo"
                      colorScheme="red"
                      bg={"red.400"}
                      color="white"
                      mt={4}
                      w="100%"
                    >
                      Request Demo
                    </Button>
                    <Button
                      as={Link}
                      href="/login"
                      variant="outline"
                      colorScheme="whiteAlpha"
                      color="white"
                      mt={4}
                      w="100%"
                    >
                      Login
                    </Button>
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </motion.div>
        </Box>

        {/* Desktop Navbar */}
        <Box
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          mr={{ base: "none", md: 20 }}
        >
          <Flex align="center">
            <NavbarItem to="/">Home</NavbarItem>
            <NavbarItem to="/services">About Us</NavbarItem>
            <NavbarItem to="/about">Features</NavbarItem>
            <NavbarItem to="/about">Blog</NavbarItem>
            <NavbarItem to="/contact">Contact Us</NavbarItem>
            <Button
              as={Link}
              href="/request-demo"
              colorScheme="red"
              bg={"red.400"}
              color="white"
              ml={8}
              size="sm"
            >
              Request Demo
            </Button>
            <Button
              as={Link}
              href="/login"
              variant="outline"
              colorScheme="whiteAlpha"
              color="white"
              ml={8}
              size="sm"
            >
              Login
            </Button>
          </Flex>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default Header;

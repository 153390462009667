import { useState, useEffect } from 'react';
import { 
    Box, 
    Heading, 
    FormControl, 
    FormLabel, 
    Input, 
    Textarea, 
    Button, 
    Flex,
} from '@chakra-ui/react';
import { LuRefreshCcw } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { evaluate } from 'mathjs';

import { createDemoRequest } from '../actions/demoRequestActions';
import Message from '../components/Message';

const RequestDemo = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [arithmeticProblem, setArithmeticProblem] = useState('');
    const [userAnswer, setUserAnswer] = useState('');
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [message, setMessage] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false); 

    const demoRequestCreate = useSelector((state) => state.demoRequestCreate);
    const { loading, error } = demoRequestCreate;

    const generateArithmeticProblem = () => {
        const number1 = Math.floor(Math.random() * 100) + 1;
        const number2 = Math.floor(Math.random() * 10) + 1;
        const operator = Math.random() < 0.5 ? '+' : '-'; 
        const problem = `${number1} ${operator} ${number2}`;
        setArithmeticProblem(problem);
        setIsAnswerCorrect(false); 
        setUserAnswer(''); 
    };

    useEffect(() => {
        generateArithmeticProblem();
    }, []); 

    const handleSubmit = async (event) => {
        event.preventDefault();

        const correctAnswer = evaluate(arithmeticProblem);

        const submittedAnswer = parseFloat(userAnswer); 
        if (submittedAnswer === correctAnswer) {
            setIsAnswerCorrect(true); 
            setShowErrorMessage(false); 
            const demoRequestData = {
                fname: event.target.fname.value,
                lname: event.target.lname.value,
                email: event.target.email.value,
                phone: event.target.phone.value,
                company_name: event.target.company_name.value,
                business_description: event.target.business_description.value,
            };

            try {
                dispatch(createDemoRequest(demoRequestData));
                navigate('/');
            } catch (error) {
                console.error('Error submitting demo request:', error);
                setMessage(error);
            }
        } else {
            setIsAnswerCorrect(false); 
            setShowErrorMessage(true); 
        }
    };

    return (
        <Box w={{ base: "80%", md: "60%" }} alignItems='center' justifyContent='center' py='5' mx='auto'>
            <Heading as="h3" mb="4">Request a Demo</Heading>
            <Box mb="4" fontSize='lg'>
                Just answer a few questions<br />so that we can personalize the right experience for you.
            </Box>

            <Box p="3" mb="4" boxShadow='md' rounded='md' bgColor='white'>
                <Heading as="h5" mb="4" fontSize='xl'>Your Customer Communication Solution</Heading>
                {error && <Message type='error'>{error}</Message>}
                {message && <Message type='error'>{message}</Message>}

                <form onSubmit={handleSubmit}>
                    <Flex justifyContent="space-between" flexWrap="wrap">
                        {/* First Name */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="fname">First name<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Input type="text" id="fname" placeholder="Enter your first name" required />
                        </FormControl>
                        {/* Last Name */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="lname">Last name<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Input type="text" id="lname" placeholder="Enter your last name" required />
                        </FormControl>
                    </Flex>
                    <Flex justifyContent="space-between" flexWrap="wrap">
                        {/* Business Email */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="email">Business email<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Input type="email" id="email" placeholder="Enter your email" required />
                        </FormControl>
                        {/* Phone Number */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="phone">Phone number<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Input type="tel" id="phone" placeholder="Enter your phone number" required />
                        </FormControl>
                    </Flex>
                    <Flex justifyContent="space-between" flexWrap="wrap">
                        {/* Company Name */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="company_name">Business / Company Name<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Input type="text" id="company_name" placeholder="Enter your business/company name" required />
                        </FormControl>
                        {/* Security Stamp */}
                        <FormControl flexBasis="calc(50% - 8px)" mb="4">
                            <FormLabel htmlFor="arithmetic_answer">Security Stamp<span style={{ color: 'red' }}> *</span></FormLabel>
                            <Flex alignItems="center">
                                <Button colorScheme="blue" variant="outline" onClick={generateArithmeticProblem}>
                                    <LuRefreshCcw />
                                </Button>
                                <Box ml="2">
                                    <span>{arithmeticProblem}</span>
                                </Box>
                                <Input type="number" name="arithmetic_answer" value={userAnswer} onChange={(e) => setUserAnswer(e.target.value)} required />
                            </Flex>
                            {showErrorMessage && !isAnswerCorrect && <span style={{ color: 'red' }}>Incorrect answer, please try again.</span>}
                        </FormControl>
                    </Flex>
                    {/* Company Information */}
                    <FormControl mb="4">
                        <FormLabel htmlFor="business_description">More Information about your business<span style={{ color: 'red' }}> *</span></FormLabel>
                        <Textarea name="business_description" id="business_description" placeholder="Enter more information about your business" rows="5" required />
                    </FormControl>
                    {/* Submit Button */}
                    <Button type="submit" bg="blue.500" color="white" _hover={{ bg: 'red.400' }} isLoading={loading}>Request a demo</Button>
                </form>
            </Box>
        </Box>
    );
};

export default RequestDemo;

import React, { useEffect } from 'react';
import { Box, Text, Flex, Heading, Icon } from '@chakra-ui/react';
import { FaPhone, FaPhoneSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSupportInboundStats,
  getSupportOutboundStats,
} from '../../actions/statsActions';

const StatCard = ({ bgColor, icon, label, value }) => (
  <Flex
    bg="white"
    p="4"
    borderWidth="3px"
    borderColor="gray.100"
    borderRadius="lg"
    boxShadow="md"
    m={2}
    width={{ base: '100%', md: '23%' }}
    align="center"
    mb={{ base: '2', md: '0' }}
    mx="auto"
  >
    <Box
      bgColor={bgColor}
      m={2}
      h="40px"
      w="40px"
      borderRadius="md"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Icon as={icon} color="white" fontSize="2.0em" />
    </Box>
    <Box>
      <Text>{`${label}: ${value}`}</Text>
    </Box>
  </Flex>
);

const SupportHome = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    pending,
    inProgress,
    fullyResolved,
    onHold,
  } = useSelector((state) => state.supportInboundStats);

  const {
    pending: outboundPending,
    in_progress: outboundInProgress,
    fully_resolved: outboundFullyResolved,
    on_hold: outboundOnHold,
  } = useSelector((state) => state.supportOutboundStats);

  useEffect(() => {
    if (userInfo) {
      const productId = userInfo.product_id;
      dispatch(getSupportInboundStats(productId));
      dispatch(getSupportOutboundStats(productId));
    }
  }, [dispatch, userInfo]);

  return (
    <Flex direction="column" p="4" borderRadius="md" m={4}>
      <Heading as="h2" size="md" mb="4">
        Support Dashboard
      </Heading>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Inbound Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="green" icon={FaPhone} label="Pending" value={pending} />
          <StatCard bgColor="blue" icon={FaPhone} label="In Progress" value={inProgress} />
          <StatCard bgColor="orange" icon={FaPhoneSlash} label="Fully Resolved" value={fullyResolved} />
          <StatCard bgColor="purple" icon={FaPhone} label="On Hold" value={onHold} />
        </Flex>
      </Box>

      <Box bg="white" color="black" p="4" mb="4" borderRadius="md">
        <Heading as="h3" size="md" mb="2">
          Outbound Stats
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} flexWrap="wrap" justify="space-around">
          <StatCard bgColor="teal" icon={FaPhone} label="Pending" value={outboundPending} />
          <StatCard bgColor="blue" icon={FaPhone} label="In Progress" value={outboundInProgress} />
          <StatCard bgColor="orange" icon={FaPhoneSlash} label="Fully Resolved" value={outboundFullyResolved} />
          <StatCard bgColor="purple" icon={FaPhone} label="On Hold" value={outboundOnHold} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default SupportHome;

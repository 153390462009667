import { useLocation, Link } from "react-router-dom";
import {
  Grid,
  Box,
  Flex,
  GridItem,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Icon,
  useDisclosure,
  keyframes,
} from "@chakra-ui/react";
import { VscCallOutgoing } from "react-icons/vsc";

import HeaderNew from "./HeaderNew";
import FooterNew from "./FooterNew";
import CallProgress from "./CallProgress";

const MainComponent = ({ children, width, marginLeft }) => {
  const { onOpen } = useDisclosure();

  const bgColor = useColorModeValue("#F4F4F4", "#1A202C");
  const boxColor = useColorModeValue("#ffffff", "#1A202C");
  const mainBoxColor = useColorModeValue("#f8f8f8", "#1A202c");
  const borderColor = useColorModeValue("#E3E3E3", "#4A5568");

  const moveUpDown = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

  return (
    <Grid direction="row">
      <GridItem minWidth={width} p="8px" bg={bgColor} marginLeft={marginLeft}>
        <Box
          bg={boxColor}
          p="10px"
          border="2px solid"
          borderColor={borderColor}
          borderRadius="10px"
        >
          <HeaderNew />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            my="4px"
            bg={boxColor}
            ml={6}
            fontWeight="bold"
            fontSize="sm"
          >
            <DynamicPaths />
            <Button
              colorScheme="blue"
              onClick={onOpen}
              mr={4}
              leftIcon={
                <Icon
                  as={VscCallOutgoing}
                  animation={`${moveUpDown} 3s infinite`}
                />
              }
            >
              Call
            </Button>
            <CallProgress />
          </Flex>
          <Box
            border="1px solid"
            borderColor="#E0E0E0"
            bg={mainBoxColor}
            borderRadius="6px"
            height="86vh"
            overflow="auto"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {children}

            <FooterNew />
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default MainComponent;

const DynamicPaths = () => {
  const location = useLocation();

  const pathParts = location.pathname.split("/").filter((part) => part);

  return (
    <Breadcrumb fontSize="md">
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to="/home">
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>
      {pathParts.map((part, index) => {
        const path = `/${pathParts.slice(0, index + 1).join("/")}`;
        const isLast = index === pathParts.length - 1;

        return (
          <BreadcrumbItem key={path} isCurrentPage={isLast}>
            {isLast ? (
              <BreadcrumbLink>{decodeURIComponent(part)}</BreadcrumbLink>
            ) : (
              <BreadcrumbLink as={Link} to={path}>
                {decodeURIComponent(part)}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

import axios from 'axios';

import {
  TICKETS_FETCH_REQUEST,
  TICKETS_FETCH_SUCCESS,
  TICKETS_FETCH_FAIL,
  CLIENT_TICKETS_FETCH_REQUEST,
  CLIENT_TICKETS_FETCH_SUCCESS,
  CLIENT_TICKETS_FETCH_FAIL,
} from '../constants/ticketsConstants';

export const getAllTicketsProgress = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TICKETS_FETCH_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/tickets/all-tickets', config);
    console.log('Data', data);

    dispatch({
      type: TICKETS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TICKETS_FETCH_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

export const getClientTicketsProgress = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_TICKETS_FETCH_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/tickets/client-tickets/${productId}`, config);

    dispatch({
      type: CLIENT_TICKETS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_TICKETS_FETCH_FAIL,
      payload: error.response && error.response.data.error ? error.response.data.error : error.message,
    });
  }
};

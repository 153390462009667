import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import { IoPencilSharp, IoTrashBinSharp } from 'react-icons/io5';
import { MdAdd } from "react-icons/md";
import { listCampaignsByProduct, deleteCampaign, createCampaign, updateCampaign } from '../../actions/campaignActions';

const SupervisorCampaigns = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const product_id = userInfo?.product_id || '';

  const [searchQuery, setSearchQuery] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [newCampaignName, setNewCampaignName] = useState('');
  const [editData, setEditData] = useState({
    id: null,
    campaign_name: "",
    status: "",
  });

  const campaignsByProduct = useSelector((state) => state.campaignsByProduct);
  const { campaigns } = campaignsByProduct;

  const { success: createSuccess } = useSelector((state) => state.campaignsCreate);

  const { success: updateSuccess } = useSelector((state) => state.campaignUpdate);

  const { success: deleteSuccess } = useSelector((state) => state.campaignsDelete);  
  
  useEffect(() => {
    dispatch(listCampaignsByProduct(product_id, searchQuery));
  }, [dispatch, product_id, searchQuery, createSuccess, updateSuccess, deleteSuccess]);


  const handleDelete = () => {
    if (selectedCampaign) {
      dispatch(deleteCampaign(selectedCampaign.id));
      dispatch(listCampaignsByProduct(product_id, searchQuery));
      setSelectedCampaign(null);
      setShowDeleteModal(false);
    }
  };  

  const openEditModal = (campaign) => {
    if (campaign) {
      setEditData(campaign);
      setSelectedCampaign(campaign); 
    } else {
      setEditData({
        id: null,
        campaign_name: "",
        status: "",
      });
    }
    setShowEditModal(true);
  };
  
  
  const closeEditModal = () => {
    setEditData({
      id: null,
      campaign_name: "",
      status: "",
    });
    setShowEditModal(false);
  };

  const handleEditSave = async () => {
    if (selectedCampaign) {
      try {
        await dispatch(updateCampaign(selectedCampaign.id, { campaign_name: editData.campaign_name, status: editData.status }))
          .then(() => {
            setSelectedCampaign(null);
            setEditData({
              id: null,
              campaign_name: "",
              status: "",
            });
            dispatch(listCampaignsByProduct(product_id, searchQuery));
            setShowEditModal(false);
          })
          .catch((error) => {
            console.error("Error updating campaign:", error);
          });
      } catch (error) {
        console.error("Error updating campaign:", error);
      }
    }
  };
  
  
  const handleCreate = () => {
    dispatch(createCampaign({ campaign_name: newCampaignName, product_id }))
      .then(() => {
        dispatch(listCampaignsByProduct(product_id, searchQuery));
        setShowCreateModal(false);
        setNewCampaignName('');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  
  
  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
            Campaigns
        </Heading>

          <Input
            m={4}
            type="text"
            placeholder="Search ..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            maxWidth='500px'
            borderColor='blue.400'
            borderWidth='2px'
          />
          <Button
            m={4}
            onClick={() => setShowCreateModal(true)}
            align="center"
            color="blue.500"
            borderWidth="2px"
            borderColor="blue.500"
            borderRadius="lg"
            padding={{ base: "2", md: "4" }} 
            textAlign="center"
            fontSize={{ base: "sm", md: "md" }} 
            bgColor="white"
            _hover={{ bgColor: 'blue.500', color: 'white', borderWidth:"2px", borderColor:"white" }}
            cursor="pointer"
            variant="outline"
            display="flex" 
            alignItems="center" 
          >
            <Icon as={MdAdd} boxSize={{ base: 6, md: 8 }} mr={2} /> 
            <Text mr={2} display={{ base: "none", md: "block" }}>Add</Text> 
          </Button>

      </Flex>

      <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
        <Table variant="striped" colorScheme="gray"></Table>
        <Table variant="striped" colorScheme="whiteAlpha" bg='white'>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Campaign Name</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>              
            {campaigns && campaigns.map((campaign, index) => (
              <Tr key={campaign.id}>
                <Td>{index + 1}</Td>
                <Td>{campaign.campaign_name}</Td>
                <Td>
                  <Flex>
                  <Button
                    colorScheme="teal"
                    bg='blue.500'
                    mr="2"
                    onClick={() => {
                      openEditModal(campaign);
                    }}                    
                  >
                    <Icon as={IoPencilSharp} />
                  </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        setSelectedCampaign(campaign);
                        setShowDeleteModal(true);
                      }}
                    >
                      <Icon as={IoTrashBinSharp} />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Modals */}
      <Modal isOpen={showCreateModal} onClose={() => setShowCreateModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Campaign Name</FormLabel>
              <Input value={newCampaignName} onChange={(e) => setNewCampaignName(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleCreate}>Create</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={showEditModal} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <FormControl mb="4">
            <FormLabel htmlFor="name">Campaign Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={editData.campaign_name} 
              onChange={(e) => setEditData({ ...editData, campaign_name: e.target.value })}
            />
          </FormControl>
          <FormControl mb="4">
            <FormLabel htmlFor="status">Status</FormLabel>
            <Select
              name="status"
              value={editData.status} 
              onChange={(e) => setEditData({ ...editData, status: e.target.value })}
            >
              <option value="active">Active</option>
              <option value="deleted">Deleted</option>
            </Select>
          </FormControl>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeEditModal}>
              Close
            </Button>
            <Button colorScheme="green" onClick={handleEditSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete the campaign "{selectedCampaign?.campaign_name}"?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDelete}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SupervisorCampaigns;

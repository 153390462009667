import axios from "axios";
import {
  CLIENT_CONTACTS_LIST_REQUEST,
  CLIENT_CONTACTS_LIST_SUCCESS,
  CLIENT_CONTACTS_LIST_FAIL,
  CLIENT_CONTACTS_DETAILS_REQUEST,
  CLIENT_CONTACTS_DETAILS_SUCCESS,
  CLIENT_CONTACTS_DETAILS_FAIL,
  CLIENT_CONTACTS_DELETE_REQUEST,
  CLIENT_CONTACTS_DELETE_SUCCESS,
  CLIENT_CONTACTS_DELETE_FAIL,
  CLIENT_CONTACTS_CREATE_REQUEST,
  CLIENT_CONTACTS_CREATE_SUCCESS,
  CLIENT_CONTACTS_CREATE_FAIL,
  CLIENT_CONTACTS_UPDATE_REQUEST,
  CLIENT_CONTACTS_UPDATE_SUCCESS,
  CLIENT_CONTACTS_UPDATE_FAIL,
  PRODUCT_CLIENT_CONTACTS_REQUEST,
  PRODUCT_CLIENT_CONTACTS_SUCCESS,
  PRODUCT_CLIENT_CONTACTS_FAIL,
  AGENT_CLIENT_CONTACTS_REQUEST,
  AGENT_CLIENT_CONTACTS_SUCCESS,
  AGENT_CLIENT_CONTACTS_FAIL,
  BULK_CONTACTS_DELETE_REQUEST,
  BULK_CONTACTS_DELETE_SUCCESS,
  BULK_CONTACTS_DELETE_FAIL,
  AGENT_CLIENT_PREFIX_REQUEST,
  AGENT_CLIENT_PREFIX_SUCCESS,
  AGENT_CLIENT_PREFIX_FAIL,  
  BULK_CLIENT_CONTACTS_UPDATE_REQUEST,
  BULK_CLIENT_CONTACTS_UPDATE_SUCCESS,
  BULK_CLIENT_CONTACTS_UPDATE_FAIL,
  CUSTOM_TAGS_UPDATE_REQUEST,
 
  CUSTOM_TAGS_CREATE_REQUEST,
  CUSTOM_TAGS_CREATE_SUCCESS,
  CUSTOM_TAGS_CREATE_FAIL,
  CUSTOM_TAGS_UPDATE_SUCCESS,
  CUSTOM_TAGS_UPDATE_FAIL,
  CUSTOM_TAGS_FETCH_REQUEST,
  CUSTOM_TAGS_FETCH_SUCCESS,
  CUSTOM_TAGS_FETCH_FAIL,
} from '../constants/clientContactsConstants';



// Actions for client contacts
export const listClientContacts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CONTACTS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/clientContacts`,
      config
    );

    dispatch({ type: CLIENT_CONTACTS_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENT_CONTACTS_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getClientContactDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CONTACTS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/clientContacts/${id}`,
      config
    );

    dispatch({ type: CLIENT_CONTACTS_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENT_CONTACTS_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteClientContact = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CONTACTS_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/clientContacts/${id}`,
      config
    );

    dispatch({ type: CLIENT_CONTACTS_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: CLIENT_CONTACTS_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createClientContacts = (contact) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CONTACTS_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/clientContacts`,
      contact,
      config
    );

    dispatch({ type: CLIENT_CONTACTS_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENT_CONTACTS_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateClientContact = (id, contactObj) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CONTACTS_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/clientContacts/${id}`,
      contactObj,
      config
    );

    dispatch({ type: CLIENT_CONTACTS_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CLIENT_CONTACTS_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getProductClientContacts = (clientId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_CLIENT_CONTACTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/clientContacts/product/${clientId}`,
      config
    );

    dispatch({ type: PRODUCT_CLIENT_CONTACTS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_CLIENT_CONTACTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getAgentClientContacts = (agentId) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_CLIENT_CONTACTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/clientContacts/agent/${agentId}`,
      config
    );

    dispatch({ type: AGENT_CLIENT_CONTACTS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_CLIENT_CONTACTS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const bulkDeleteClientContacts = (startDate, endDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: BULK_CONTACTS_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/clientContacts/deleteInRange/${startDate}/${endDate}`,
      config
    );

    dispatch({ type: BULK_CONTACTS_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: BULK_CONTACTS_DELETE_FAIL,
      payload: err.response && err.response.data.message
        ? err.response.data.message
        : err.message,
    });
  }
};

export const getClientPrefixByAgent = (queue_id) => async (dispatch) => {
  try {
      dispatch({ type: AGENT_CLIENT_PREFIX_REQUEST });

      const { data } = await axios.get(`/api/clientContacts/prefix/agent/${queue_id}`);

      dispatch({
          type: AGENT_CLIENT_PREFIX_SUCCESS,
          payload: data,
      });
  } catch (error) {
      dispatch({
          type: AGENT_CLIENT_PREFIX_FAIL,
          payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
  }
};

export const bulkUpdateClientContacts = (startDate, endDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: BULK_CLIENT_CONTACTS_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const updateData = { product_id: userInfo.product_id };

    const { data } = await axios.put(
      `/api/clientContacts/updateInRange/${startDate}/${endDate}`,
      updateData,
      config
    );

    dispatch({ type: BULK_CLIENT_CONTACTS_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: BULK_CLIENT_CONTACTS_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// // Actions for custom call tags
// export const updateCustomTags = (productCode, campaignId, customTags) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: CUSTOM_TAGS_UPDATE_REQUEST });

//     const {
//       userLogin: { userInfo },
//     } = getState();

//     const config = {
//       headers: {
//         Authorization: `Bearer ${userInfo.token}`,
//         "Content-Type": "application/json",
//       },
//     };

//     const { data } = await axios.post(
//       `/api/clientContacts/customCallTags`,
//       { product_code: productCode, campaign_id: campaignId, ...customTags },
//       config
//     );

//     dispatch({ type: CUSTOM_TAGS_UPDATE_SUCCESS, payload: data });
//   } catch (error) {
//     dispatch({
//       type: CUSTOM_TAGS_UPDATE_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };


// Actions for creating custom call tags
export const createCustomTags = (productCode, campaignId, customTags) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_TAGS_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/clientContacts/customCallTags`,
      { product_code: productCode, campaign_id: campaignId, ...customTags },
      config
    );

    dispatch({ type: CUSTOM_TAGS_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOM_TAGS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


// Actions for updating custom call tags
export const updateCustomTags = (productCode, campaignId, customTags) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_TAGS_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    // Assuming the API supports PUT for updates and includes the ID in the URL
    const { data } = await axios.put(
      `/api/clientContacts/customCallTags/${campaignId}`,
      { product_code: productCode, ...customTags },
      config
    );

    dispatch({ type: CUSTOM_TAGS_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOM_TAGS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};




export const fetchCustomTags = (productCode, campaignId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_TAGS_FETCH_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/clientContacts/customCallTags/${productCode}/${campaignId}`, config);

    dispatch({ type: CUSTOM_TAGS_FETCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOM_TAGS_FETCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


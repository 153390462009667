export const GET_PRODUCT_ABANDONED_CALLS_REQUEST = 'GET_PRODUCT_ABANDONED_CALLS_REQUEST';
export const GET_PRODUCT_ABANDONED_CALLS_SUCCESS = 'GET_PRODUCT_ABANDONED_CALLS_SUCCESS';
export const GET_PRODUCT_ABANDONED_CALLS_FAIL = 'GET_PRODUCT_ABANDONED_CALLS_FAIL';

export const GET_AGENT_OUTBOUND_CALLS_REQUEST = 'GET_AGENT_OUTBOUND_CALLS_REQUEST';
export const GET_AGENT_OUTBOUND_CALLS_SUCCESS = 'GET_AGENT_OUTBOUND_CALLS_SUCCESS';
export const GET_AGENT_OUTBOUND_CALLS_FAIL = 'GET_AGENT_OUTBOUND_CALLS_FAIL';

export const UPDATE_CDR_ABANDONED_REQUEST = 'UPDATE_CDR_ABANDONED_REQUEST';
export const UPDATE_CDR_ABANDONED_SUCCESS = 'UPDATE_CDR_ABANDONED_SUCCESS';
export const UPDATE_CDR_ABANDONED_FAIL = 'UPDATE_CDR_ABANDONED_FAIL';

export const GET_ALL_OUTBOUND_CALLS_REQUEST = 'GET_ALL_OUTBOUND_CALLS_REQUEST';
export const GET_ALL_OUTBOUND_CALLS_SUCCESS = 'GET_ALL_OUTBOUND_CALLS_SUCCESS';
export const GET_ALL_OUTBOUND_CALLS_FAIL = 'GET_ALL_OUTBOUND_CALLS_FAIL';

export const GET_PRODUCT_OUTBOUND_CALLS_REQUEST = 'GET_PRODUCT_OUTBOUND_CALLS_REQUEST';
export const GET_PRODUCT_OUTBOUND_CALLS_SUCCESS = 'GET_PRODUCT_OUTBOUND_CALLS_SUCCESS';
export const GET_PRODUCT_OUTBOUND_CALLS_FAIL = 'GET_PRODUCT_OUTBOUND_CALLS_FAIL';
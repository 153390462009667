import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Icon,
} from "@chakra-ui/react";
import {
  FaUsers,
  FaUser,
  FaUserSlash,
  FaInbox,
  FaChartPie,
  FaInfoCircle,
} from "react-icons/fa";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const HaziDashboardScreen = () => {
  // Sample chart data
  const data = [
    { name: "Jan", uv: 400, pv: 2400, amt: 2400 },
    { name: "Feb", uv: 300, pv: 1398, amt: 2210 },
    // Add more data points as needed
  ];

  return (
    <Box bg="gray.50" p={4}>
      <Heading as="h1" fontSize="2xl" mb={6} ml={8} color="gray.700">
        Webphone Dashboard
      </Heading>

      {/* Top Row Cards */}
      <Flex wrap="wrap" gap={4} mb={8}>
        <DashboardCard
          title="Contact Groups"
          count="0"
          icon={FaUsers}
          iconColor="blue.500"
          bgColor="blue.50"
        />
        <DashboardCard
          title="Contacts"
          count="0"
          icon={FaUser}
          iconColor="green.500"
          bgColor="green.50"
        />
        <DashboardCard
          title="Blacklist"
          count="0"
          icon={FaUserSlash}
          iconColor="red.500"
          bgColor="red.50"
        />
        <DashboardCard
          title="Remaining Email Balance"
          count="10"
          icon={FaInbox}
          iconColor="yellow.500"
          bgColor="yellow.50"
        />
      </Flex>

      {/* Bottom Row Cards */}
      <Flex wrap="wrap" gap={4}>
        <Box flex="1" maxW={{ base: "100%", md: "66%" }} bg="white" p={6} borderRadius="lg" boxShadow="sm">
          <Text fontSize="xl" color="blue.500" mb={3}>
            Good morning, Orestekeikei
          </Text>
          <Text fontSize="md" mb={4}>
            Welcome back to your personalized Dashboard. Check out charts, resources, and useful Hazi solutions tailored to your account.
          </Text>
          <Button
            as="a"
            href="https://hazi.co.ke/view-charts"
            leftIcon={<FaChartPie />}
            colorScheme="blue"
            variant="solid"
            mt={3}
          >
            View Charts
          </Button>
        </Box>

        <Box flex="1" maxW={{ base: "100%", md: "33%" }} bg="white" p={6} borderRadius="lg" boxShadow="sm">
          <Text fontSize="xl" color="blue.500" mb={3}>
            Current Plan
          </Text>
          <Text fontSize="lg" color="red.500" mt={1}>
            No active subscription
          </Text>
          <Button
            as="a"
            href="https://hazi.co.ke/subscriptions"
            leftIcon={<FaInfoCircle />}
            colorScheme="blue"
            variant="solid"
            mt={3}
          >
            More info
          </Button>
        </Box>
      </Flex>

      {/* Charts Section */}
      <Flex wrap="wrap" gap={4} mt={8}>
        <ChartCard title="SMS Reports">
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </ChartCard>

        <ChartCard title="Contact Groups">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data}>
              <Bar dataKey="pv" fill="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>

        <ChartCard title="Max Contacts">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={data}>
              <Bar dataKey="amt" fill="#82ca9d" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>
      </Flex>

      {/* SMS History Section */}
      <Flex wrap="wrap" gap={4} mt={8}>
        <ChartCard title="Outgoing SMS History of Current Month">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <Line type="monotone" dataKey="uv" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </ChartCard>

        <ChartCard title="Incoming SMS History of Current Month">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </ChartCard>
      </Flex>
    </Box>
  );
};

// Card Component
const DashboardCard = ({ title, count, icon, iconColor, bgColor }) => (
  <Box
    flex="1"
    maxW="22%"
    bg="white"
    p={4}
    borderRadius="lg"
    boxShadow="sm"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
  >
    <Box>
      <Text fontSize="2xl" fontWeight="bold">
        {count}
      </Text>
      <Text>{title}</Text>
    </Box>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={3}
      borderRadius="full"
      bg={bgColor}
    >
      <Icon as={icon} w={6} h={6} color={iconColor} />
    </Box>
  </Box>
);

// ChartCard Component for wrapping charts
const ChartCard = ({ title, children }) => (
  <Box flex="1" maxW="33%" bg="white" p={6} borderRadius="lg" boxShadow="sm">
    <Text fontSize="xl" color="blue.500" mb={3}>
      {title}
    </Text>
    {children}
  </Box>
);

export default HaziDashboardScreen;

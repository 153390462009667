import { Flex, Text } from "@chakra-ui/react";

const FooterNew = () => {
  return (
    <Flex
      as="footer"
      justifyContent="center"
      alignItems="center"
      py="2"
      flexDirection="column"
      textAlign="center"
      fontFamily="'Lato', sans-serif"
    >
      {/* Copyright Text */}
      <Text mt="3" fontWeight="semibold">
        &copy; {new Date().getFullYear()}
        zuridesk
        <Text as="span" fontSize="xl" align="left" color="orange.500">
          .
        </Text>
      </Text>
    </Flex>
  );
};

export default FooterNew;

export const CLIENTS_LIST_REQUEST = "CLIENTS_LIST_REQUEST";
export const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";
export const CLIENTS_LIST_FAIL = "CLIENTS_LIST_FAIL";
export const CLIENT_LIST_RESET = "CLIENT_LIST_RESET";

export const CLIENTS_DETAILS_SUCCESS = "CLIENTS_DETAILS_SUCCESS";
export const CLIENTS_DETAILS_REQUEST = "CLIENTS_DETAILS_REQUEST";
export const CLIENTS_DETAILS_FAIL = "CLIENTS_DETAILS_FAIL";

export const CLIENTS_DELETE_REQUEST = "CLIENTS_DELETE_REQUEST";
export const CLIENTS_DELETE_SUCCESS = "CLIENTS_DELETE_SUCCESS";
export const CLIENTS_DELETE_FAIL = "CLIENTS_DELETE_FAIL";

export const CLIENTS_CREATE_REQUEST = "CLIENTS_CREATE_REQUEST";
export const CLIENTS_CREATE_SUCCESS = "CLIENTS_CREATE_SUCCESS";
export const CLIENTS_CREATE_FAIL = "CLIENTS_CREATE_FAIL";
export const CLIENTS_CREATE_RESET = "CLIENTS_CREATE_RESET";

export const CLIENTS_UPDATE_REQUEST = "CLIENTS_UPDATE_REQUEST";
export const CLIENTS_UPDATE_SUCCESS = "CLIENTS_UPDATE_SUCCESS";
export const CLIENTS_UPDATE_FAIL = "CLIENTS_UPDATE_FAIL";
export const CLIENTS_UPDATE_RESET = "CLIENTS_UPDATE_RESET";


export const AGENTS_BY_PRODUCT_REQUEST = "AGENTS_BY_PRODUCT_REQUEST";
export const AGENTS_BY_PRODUCT_SUCCESS = "AGENTS_BY_PRODUCT_SUCCESS";
export const AGENTS_BY_PRODUCT_FAIL = "AGENTS_BY_PRODUCT_FAIL";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import axios from "axios";
import moment from "moment-timezone";

import { getAgentOutboundCalls } from '../../actions/cdrActions';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import OutboundDisposition from './OutboundDisposition'; 

const OutboundCallLogs = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const outboundCalls = useSelector((state) => state.agentOutboundCalls.calls);
  const outboundCallsLoading = useSelector((state) => state.agentOutboundCalls.loading);
  const outboundCallsError = useSelector((state) => state.agentOutboundCalls.error);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchAgentDetails = async (user_id) => {
      try {
        const { data } = await axios.get(`/api/admin/agents/details/${user_id}`);
        dispatch(getAgentOutboundCalls(data.data.agent_code, searchQuery));
      } catch (error) {
        console.log(error);
      }
    };

    if (userInfo && userInfo.id) {
      const { id } = userInfo;
      fetchAgentDetails(id);
    }
  }, [dispatch, userInfo, searchQuery]);

  const [callDispositionInfo, setCallDispositionInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);

  useEffect(() => {
    const fetchDispositionForCall = async (call) => {
      try {
        const response = await axios.get(`/api/dispose/${call.uniqueid}`);
        const { dispositioned, reason } = response.data;
        setCallDispositionInfo((prevState) => ({
          ...prevState,
          [call.uniqueid]: { dispositioned, reason },
        }));
      } catch (error) {
        console.error('Error fetching outbound disposition for call', call.uniqueid, ':', error);
      }
    };

    const fetchDispositionForAllCalls = async () => {
      if (outboundCalls && outboundCalls.length > 0) {
        outboundCalls.forEach((call) => {
          fetchDispositionForCall(call);
        });
      } else {
        console.log('outboundCalls is missing or empty');
      }
    };

    fetchDispositionForAllCalls();
  }, [outboundCalls]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOutboundCalls = outboundCalls && outboundCalls.slice(indexOfFirstItem, indexOfLastItem);

  const handleOpenModal = (call) => {
    setSelectedCall({
      log_uniqueid: call.uniqueid,
      agent: call.AgentName,
      agent_code: call.AgentExt,
      msisdn: call.Destination,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCall(null);
  };

  return (
    <Box p="4" m={2} alignItems="center" width={{ base: '100%', md: '100%' }} bg="white" borderRadius="md">
       <Text fontSize="xl" fontWeight="bold" mb={{ base: 2, md: 4 }}>
          Outbound Call Logs
        </Text>
       <Flex m={6} justifyContent="space-between" flexWrap={{ base: "wrap", md: "nowrap" }}>  
          <Input
                type="text"
                placeholder="Search by Phone..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                m={8}
                maxWidth='500px'
                borderColor='blue.400'
                borderWidth='2px'
              />     
          <Box display="flex" flexDirection={{ base: "column", md: "row" }} alignItems="center">          
            <Button
              as={RouterLink}
              to='/outbound/contacts'
              align="center"
              color="blue.500"
              borderWidth="2px"
              borderColor="blue.500"
              borderRadius="lg"
              padding="4"
              textAlign="center"
              bgColor="white"
              m={2}
              _hover={{ bgColor: "blue.500", color: "white", borderWidth: "2px", borderColor: "white" }}
              cursor="pointer"
              variant="outline"
              width={{ base: "100%", md: "auto" }} 
            >
              <Text display={{ base: "none", md: "block" }}>Contacts</Text>
              <Text display={{ base: "block", md: "none" }}>Contacts</Text> 
            </Button>
            <Button
              as={RouterLink}
              to='/scheduled-callbacks'
              align="center"
              color="blue.500"
              borderWidth="2px"
              borderColor="blue.500"
              borderRadius="lg"
              padding="4"
              textAlign="center"
              bgColor="white"
              m={2}
              _hover={{ bgColor: "blue.500", color: "white", borderWidth: "2px", borderColor: "white" }}
              cursor="pointer"
              variant="outline"
              width={{ base: "100%", md: "auto" }} 
            >
              <Text display={{ base: "none", md: "block" }}>Callbacks</Text>
              <Text display={{ base: "block", md: "none" }}>Callbacks</Text> 
            </Button>
          </Box>
        </Flex>

      {outboundCallsLoading ? (
        <Loader />
      ) : outboundCallsError ? (
        <Message type="error">{outboundCallsError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr fontWeight="bold">
                <Th>No.</Th>
                <Th>Phone</Th>
                <Th>Call Duration</Th>
                <Th>Agent</Th>
                <Th>Agent Code</Th>
                <Th>Date</Th>
                <Th>Disposition</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentOutboundCalls && currentOutboundCalls.length > 0 ? (
                currentOutboundCalls.map((call, index) => (
                  <Tr key={index} bg="white">
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{call.Destination}</Td>
                    <Td>{call.Duration}</Td>
                    <Td>{call.AgentName}</Td>
                    <Td>{call.AgentExt}</Td>
                    <Td>
                      {moment(call.calldate)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>
                      {callDispositionInfo[call.uniqueid] && callDispositionInfo[call.uniqueid].dispositioned ? (
                        <Box>
                          <Text fontWeight="semibold">
                            Reason: <Text as="span" fontWeight="normal">{callDispositionInfo[call.uniqueid].reason}</Text>
                          </Text>
                        </Box>
                      ) : (
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="primary"
                          onClick={() => handleOpenModal(call)}
                          _hover={{ bg: 'primary.500', color: 'blue.500' }}
                        >
                          Disposition
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="7" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, outboundCalls ? outboundCalls.length : 0)} of {outboundCalls ? outboundCalls.length : 0} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {outboundCalls && Array.isArray(outboundCalls) && outboundCalls.length > 0 && [...Array(Math.ceil(outboundCalls.length / itemsPerPage))].map((_, index) => (
                  <React.Fragment key={index}>
                    {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(outboundCalls.length / itemsPerPage) ? (
                      <Button
                        key={index}
                        variant={currentPage === index + 1 ? "solid" : "outline"}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : (
                      index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={outboundCalls ? currentPage === Math.ceil(outboundCalls.length / itemsPerPage) : true}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}

      <Spacer h="5" />

      {/* Render the OutboundDisposition modal */}
      {selectedCall && (
        <OutboundDisposition
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          callDetails={selectedCall}
        />
      )}
    </Box>
  );
};

export default OutboundCallLogs;

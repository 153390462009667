import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Flex,
    Tabs,
    TabList,
    Tab,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Button,
    Divider,
  } from '@chakra-ui/react'; 
import { useNavigate } from 'react-router-dom';
import moment from "moment-timezone";

import { getAllInboundScheduledCallbacks } from '../../actions/callDispositionActions';
import Loader from "../../components/Loader";
import Message from "../../components/Message";
  
  const ScheduledCalls = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin

    const inboundAllScheduledCallbacks = useSelector((state) => state.inboundAllScheduledCallbacks); 
    const { loading: inboundCallbacksLoading, error: inboundCallbacksError, inboundCallbacks} = inboundAllScheduledCallbacks;
    console.log('Callbacks', inboundCallbacks)

    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    useEffect(() => {
      if (userInfo) {
        const agentName = userInfo.name; 
        console.log('Agent', agentName)       
        dispatch(getAllInboundScheduledCallbacks(searchInput))
        .catch((error) => {
          console.error('Error fetching call history:', error);
        });
  
      } else {
        navigate("/login");
      }
    }, [dispatch, userInfo, searchInput, navigate]);

    return (
      <Box p="4" bg="white"  borderRadius="md" m={2}>
           <Box m={6} mt={8}>
              <Text fontSize="xl" fontWeight="bold" mb="4">
                  Scheduled Callbacks
              </Text>
              {/* Tabs */}
                <Tabs mt="4" isLazy>
                    <TabList>
                    <Tab>Inbound</Tab>
                    <Tab>Outbound</Tab>
                    </TabList>
                </Tabs>
          </Box> 

          <Divider mt="12"  color='blackAlpha.100' borderWidth="4px"/> 

       
        <Box m={6} mt={8}>
            {/* Tabs */}
            <Tabs mt="4" isLazy>
              <TabList>
                <Tab>All Active</Tab>
              </TabList>
            </Tabs>
        </Box>     

        <Flex  flexWrap="wrap" justify="space-around">
            {/* Search input */}
            <Flex m={4}> 
              <Text fontSize="md" fontWeight="semibold" mb="4">
                search: number
              </Text>
              <Input
                type="text"
                placeholder="Enter phone digits"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}          
                ml={8}
                maxWidth='300px'
                borderColor='blue.400'
                borderWidth='2px'
              />
            </Flex>
            
        </Flex>
        {inboundCallbacksLoading ? (
          <Loader />
        ) : inboundCallbacksError ? (
          <Message type="error">{inboundCallbacksError}</Message>
        ) : (
          <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Attended by</Th>
                  <Th>Product</Th>
                  <Th>Caller Name</Th>
                  <Th>Caller No</Th>
                  <Th>Caller Location</Th>
                  <Th>Date Created</Th>
                  <Th>Status</Th>
                  <Th>Callback Date</Th>
                  <Th>Reason</Th>
                  <Th>Decription</Th>
                </Tr>
              </Thead>
              <Tbody>
                {inboundCallbacks && inboundCallbacks.length > 0 ? (
                  inboundCallbacks
                    .slice(indexOfFirstItem, indexOfLastItem)
                    .map((log, index) => (
                  <Tr key={index}>
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{log.Attendedby}</Td>
                    <Td>{log.Product}</Td>
                    <Td>{log.ClientName}</Td>
                    <Td>{log.Caller}</Td>
                    <Td>{log.CallerLocation}</Td>
                    <Td>
                      {moment(log.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>{log.Status}</Td>
                    <Td>
                      {moment(log.CallbackDate)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>{log.CallerReason}</Td>
                    <Td>{log.Description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="8" textAlign="center">No data available</Td>
                </Tr>
              )}
              </Tbody>
            </Table>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to {inboundCallbacks ? Math.min(indexOfLastItem, inboundCallbacks.length) : 0} of {inboundCallbacks ? inboundCallbacks.length : 0} entries
              </Box>
              <Flex mt="4" justify="space-between" align="center">
                <Box>
                  Showing {indexOfFirstItem + 1} to {inboundCallbacks ? Math.min(indexOfLastItem, inboundCallbacks.length) : 0} of {inboundCallbacks ? inboundCallbacks.length : 0} entries
                </Box>
                <Flex>
                  <Button
                    variant="outline"
                    isDisabled={currentPage === 1}
                    onClick={() => paginate(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  {[...Array(Math.ceil((inboundCallbacks?.length || 0) / itemsPerPage))].map((_, index) => (
                    <React.Fragment key={index}>
                      {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(inboundCallbacks?.length / itemsPerPage) ? (
                        <Button
                          key={index}
                          variant={currentPage === index + 1 ? "solid" : "outline"}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Button>
                      ) : (
                        index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                          <Box key={index}>...</Box>
                        ) : null
                      )}
                    </React.Fragment>
                  ))}
                  <Button
                    variant="outline"
                    isDisabled={currentPage === Math.ceil(inboundCallbacks?.length / itemsPerPage)}
                    onClick={() => paginate(currentPage + 1)}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        )}       

                
      </Box>
    );
  };
  
  export default ScheduledCalls;
  
import { Flex } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Landing from './landing/Landing';

import SidebarWithHeader from './components/SidebarWithHeader';
import Footer from './components/Footer';

import LoginScreen from './screens/LoginScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import RequestDemo from './screens/RequestDemo';

import Dashboard from './screens/agent/Dashboard';
//import EscalatedCalls from './screens/agent/EscalatedCalls';
import Inbound from './screens/agent/Inbound';
import InboundCallLogs from './screens/agent/InboundCallLogs';
import InboundCallDisposition from './screens/agent/InboundCallDisposition';
import InboundAbandonedCalls from './screens/agent/InboundAbandonedCalls';
import InboundCallReports from './screens/agent/InboundCallReports';
import InboundAgentEscalations from './screens/agent/InboundAgentEscalations';
import Outbound from './screens/agent/Outbound';
import OutboundCallLogs from './screens/agent/OutboundCallLogs';
import OutboundAgentContacts from './screens/agent/OutboundAgentContacts';
import OutboundDisposition from './screens/agent/OutboundDisposition';
import Products from './screens/agent/Products';
import ProductScreen from './screens/agent/ProductScreen';
import ScheduledCallbacks from './screens/agent/ScheduledCallbacks';
import SocialEscalations from './screens/agent/SocialEscalations';
import WhatsappHome from './screens/agent/WhatsappHome';
import GuideScreen from './screens/agent/GuideScreen';

import HomeAdmin from './screens/admin/HomeAdmin';
import UsersScreen from './screens/admin/UsersScreen';
import AgentCheckins from './screens/admin/AgentCheckins';
import CallReasonsScreen from './screens/admin/CallReasons';
import ClientsScreen from './screens/admin/ClientsScreen';
import ClientViewScreen from './screens/admin/ClientViewScreen';
import DemoRequests from './screens/admin/DemoRequests';
import InboundAllCallLogs from './screens/admin/InboundAllCallLogs';
import OutboundAllContacts from './screens/admin/OutboundAllContacts';
import OutboundBulkContacts from './screens/admin/OutboundBulkContacts';
import OutboundContactReports from './screens/admin/OutboundContactReports';
import OutboundAllCallLogs from './screens/admin/OutboundAllCallLogs';
import OutboundCallReports from './screens/admin/OutboundCallReports';
import QueueList from './screens/admin/QueueList';
import ScheduledCalls from './screens/admin/ScheduledCalls';
import AllTicketsScreen from './screens/admin/TicketsScreen';
import WhatsappAdmin from './screens/admin/WhatsappAdmin';
import ChatPage from './screens/admin/ChatPage';
import QueueListws from './screens/admin/QueueListws';

import SupervisorHome from './screens/supervisor/SupervisorHome';
import SupervisorInbound from './screens/supervisor/SupervisorInbound';
import SupInboundCallLogs from './screens/supervisor/SupInboundCallLogs';
import SupOutboundCalls from './screens/supervisor/SupOutboundCalls';
import SupBulkContacts from './screens/supervisor/SupBulkContacts';
import SupOutboundContacts from './screens/supervisor/SupOutboundContacts';
import SupervisorOutbound from './screens/supervisor/SupervisorOutbound';
import SupervisorCampaigns from './screens/supervisor/SupervisorCampaigns';
import SupervisorAgents from './screens/supervisor/SupervisorAgents';
import QueueProductList from './screens/supervisor/QueueProductList';
import SupervisorAgentCheckins from './screens/supervisor/SupervisorAgentCheckins';
import SupervisorAgentProductivity from './screens/supervisor/SupervisorAgentProductivity';
import SupervisorSupport from './screens/supervisor/SupervisorSupport';
import SupervisorCallReasons from './screens/supervisor/SupervisorCallReasons';
import SupWhatsappHome from './screens/supervisor/SupWhatsappHome';
import SupervisorFacebook from './screens/supervisor/SupervisorFacebook';

import SupportHome from './screens/support/SupportHome';
import SupportEscalatedIn from './screens/support/SupportEscalatedIn';
import SupportEscalatedOutbound from './screens/support/SupportEscalatedOutbound';
import SupportEscalated from './screens/support/SupportEscalated';
import TicketDetailsScreen from './screens/support/TicketDetailsScreen';

//imports for Trial New UI
import Layout from "./components/Layout";
import HaziMenu from "./components/HaziMenu";
import TrialHome from './screens/TrialHome';
import HaziDashboardScreen from "./screens/hazi/HaziDashboardScreen";
import HaziContactsScreen from "./screens/hazi/HaziContactsScreen";
import HaziGroupContactsScreen from "./screens/hazi/HaziGroupContactsScreen";
import HaziBulkSMSScreen from "./screens/hazi/HaziBulkSMSScreen";
import HaziQuickSMSScreen from "./screens/hazi/HaziQuickSMSScreen";
import HaziQuickMailScreen from "./screens/hazi/HaziQuickMailScreen";
import HaziBulkMailScreen from "./screens/hazi/HaziBulkMailScreen";
import HaziMailCampaigns from "./screens/hazi/HaziMailCampaigns";
import HaziMailTemplateScreen from "./screens/hazi/HaziMailTemplateScreen";
import HaziVerifySendMailScreen from "./screens/hazi/HaziVerifySendMailScreen";
import HaziWhatsappScreen from "./screens/hazi/HaziWhatsappScreen";
import HaziWhatsappMsgTemp from "./screens/hazi/HaziWhatsappMsgTemp";
import HaziWhatsappChatBox from "./screens/hazi/HaziWhatsappChatBox ";
import HaziWhatsappConnAccounts from "./screens/hazi/HaziWhatsappConnAccounts";

function App() {
  return (
    <BrowserRouter>
      {/* Non-dashboard routes */}
      <Routes>
      <Route path='/' element={<Landing />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
        <Route path='/request-demo' element={<RequestDemo />} />
      
      {/* Dashboard routes */}
      <Route
              path='/*'
              element={
                <DashboardRoutes                  
                />
              }
      />

      <Route path="/trial*" element={<Layout />}>
        <Route path="home" element={<TrialHome />} />
        <Route path="users" element={<UsersScreen />} />
        <Route path="socials-h" element={<HaziMenu />}>
          <Route index element={<HaziDashboardScreen to="/trial/socials-h/dashboard" replace />} />
          <Route path="dashboard" element={<HaziDashboardScreen />} />
          <Route path="contacts" element={<HaziContactsScreen />} />
          <Route path="contacts/:id/group" element={<HaziGroupContactsScreen />} />
          <Route path="quick-sms" element={<HaziQuickSMSScreen />} />
          <Route path="bulk-sms" element={<HaziBulkSMSScreen />} />
          <Route path="quick-email" element={<HaziQuickMailScreen />} />
          <Route path="bulk-email" element={<HaziBulkMailScreen />} />
          <Route path="campaigns-email" element={<HaziMailCampaigns />} />
          <Route path="email-templates" element={<HaziMailTemplateScreen />} />
          <Route path="verify-emailsender" element={<HaziVerifySendMailScreen />} />
          <Route path="whatsapp" element={<HaziWhatsappScreen />} />
          <Route path="whatsapp-template" element={<HaziWhatsappMsgTemp />} />
          <Route path="whatsapp-chat" element={<HaziWhatsappChatBox />} />
          <Route path="whatsapp-accounts" element={<HaziWhatsappConnAccounts />} />
        </Route>
      </Route>
      
      </Routes>
    </BrowserRouter>
  );

  function DashboardRoutes() {
    return ( 
      <>
        <SidebarWithHeader />
              <Flex
                  as="main"
                  mt="0"
                  direction="column"
                  bgColor="blackAlpha.100"
                  ml={{ base: 0, md: 60 }}
              >
                <Routes>

                  {/* Agent Routes */} 
                  <Route path='/dashboard' element={<Dashboard />} /> 
                  <Route path='/agent-products' element={<Products />} /> 
                  <Route path='/agent-products/:id' element={<ProductScreen />} /> 
                  <Route path='/inbound' element={<Inbound />} />
                  <Route path='/inbound/call-logs' element={<InboundCallLogs />} />
                  <Route path='/agent/disposition/:trx_id' element={<InboundCallDisposition />}  />
                  <Route path='/inbound/abandoned-calls' element={<InboundAbandonedCalls />} />
                  <Route path='/inbound/call-reports' element={<InboundCallReports />} />
                  <Route path='/escalated-calls' element={<InboundAgentEscalations />} /> 
                  <Route path='/outbound' element={<Outbound />} />
                  <Route path='/outbound/call-logs' element={<OutboundCallLogs />} />
                  <Route path='/outbound-disposition/:log_uniqueid' element={<OutboundDisposition />} /> 
                  <Route path='/outbound/contacts' element={<OutboundAgentContacts />} />
                  <Route path='/social-escalataions' element={<SocialEscalations />} />  
                   {/*<Route path='/escalated-calls' element={<EscalatedCalls />} /> */}
                  <Route path='/scheduled-callbacks' element={<ScheduledCallbacks />} />
                  <Route path='/quick-whatsapp' element={<WhatsappHome />} />
                  <Route path='/guide' element={<GuideScreen />} />


                  {/* Admin Routes */}                  
                  <Route path='/home' element={<HomeAdmin />} /> 
                  <Route path='/users' element={<UsersScreen />} /> 
                  <Route path='/agent-checkins' element={<AgentCheckins />} /> 
                  <Route path='/reasons' element={<CallReasonsScreen />} />
                  <Route path='/clients' element={<ClientsScreen />} />
                  <Route path='/clients/:id' element={<ClientViewScreen />} />
                  <Route path='/demo_reqs' element={<DemoRequests/>} />
                  <Route path='/inboundall-logs' element={<InboundAllCallLogs />} />
                  <Route path='/contacts' element={<OutboundAllContacts />} />
                  <Route path='/delete-contacts' element={<OutboundBulkContacts />} />
                  <Route path='/contact-reports' element={<OutboundContactReports />} /> 
                  <Route path='/outboundall-logs' element={<OutboundAllCallLogs />} /> 
                  <Route path='/outboundcall-reports' element={<OutboundCallReports />} /> 
                  <Route path='/callbacks' element={<ScheduledCalls />} />
                  <Route path='/alltickets' element={<AllTicketsScreen />} />
                  <Route path='/queuemembers' element={<QueueList />} /> 
                  <Route path='/admin-whatsapp' element={<WhatsappAdmin />} />
                  <Route path='/queuemembersws' element={<QueueListws />} /> 
                  <Route path='/chatpage' element={<ChatPage />} />


                   {/* Supervisor Routes */}
                   <Route path='/supervisor-home' element={<SupervisorHome />} />
                   <Route path='/supervisor-inbound' element={<SupervisorInbound />} />
                   <Route path='/supervisor-inbound/call-logs' element={<SupInboundCallLogs />} />
                   <Route path='/supervisor-outbound' element={<SupervisorOutbound />} />
                   <Route path='/supervisor-campaigns' element= {<SupervisorCampaigns />} />
                   <Route path='/supervisor-outbound/call-logs' element= {<SupOutboundCalls />} />
                   <Route path='/supervisor-outbound/contacts' element= {<SupOutboundContacts />} />
                   <Route path='/supervisor-outbound/bulk-delete' element= {<SupBulkContacts />} />
                   <Route path='/supervisor-agents' element= {<SupervisorAgents />} />
                   <Route path='/queueproductmembers' element={<QueueProductList />} /> 
                   <Route path='/supagent-checkins' element= {<SupervisorAgentCheckins />} />
                   <Route path='/agent-productivity' element= {<SupervisorAgentProductivity />} />
                   <Route path='/supervisor-support' element= {<SupervisorSupport />} />
                   <Route path='/supervisor-callreasons' element= {<SupervisorCallReasons />} />\
                   <Route path='/sup-whatsapp' element= {<SupWhatsappHome />} />
                   <Route path='/facebook' element= {<SupervisorFacebook />} />
                   
                  {/* Support Routes */}
                   <Route path='/support-home' element={<SupportHome />} />
                   <Route path='/support-escalatedin' element={<SupportEscalatedIn />} />
                   <Route path='/support-escalatedout' element={<SupportEscalatedOutbound />} />
                   <Route path='/support-escalations' element={<SupportEscalated />} />
                   <Route path='/support-escalations/:ticket_number' element={<TicketDetailsScreen />} />


                </Routes>
                <Footer />
              </Flex>
        </>
      );
    }
}

export default App;
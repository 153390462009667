import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";

const theme = extendTheme({
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    mono: "IBM Plex Mono, monospace",
  },
  colors: {
    brandBlue: "#11273F",
    brandGreen: "#18D26E",
  },
  styles: {
    global: {
      body: {
        fontFamily: "Open Sans, sans-serif",
      },
    },
  },
  components: {
    Table: {
      variants: {
        custom: {
          bg: "white",
          borderRadius: "md",
          boxShadow: "lg",
          overflowX: "auto",
        },
      },
      baseStyle: {
        fontFamily: "Source Sans Pro",
        borderWidth: "1px",
        borderColor: "gray.200",
      },
    },
    Td: {
      baseStyle: {
        fontSize: "sm",
        py: "2",
        lineHeight: "1.2",
      },
    },
    Th: {
      baseStyle: {
        fontFamily: "Source Sans Pro",
        fontSize: "13px",
        fontWeight: "bold",
        py: "2",
        lineHeight: "1.2",
        border: "1px solid",
        borderColor: "gray.200",
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

export const WHATSAPP_OUTBOUND_LIST_REQUEST = "WHATSAPP_OUTBOUND_LIST_REQUEST";
export const WHATSAPP_OUTBOUND_LIST_SUCCESS = "WHATSAPP_OUTBOUND_LIST_SUCCESS";
export const WHATSAPP_OUTBOUND_LIST_FAIL = "WHATSAPP_OUTBOUND_LIST_FAIL";

export const WHATSAPP_OUTBOUND_DETAILS_REQUEST = "WHATSAPP_OUTBOUND_DETAILS_REQUEST";
export const WHATSAPP_OUTBOUND_DETAILS_SUCCESS = "WHATSAPP_OUTBOUND_DETAILS_SUCCESS";
export const WHATSAPP_OUTBOUND_DETAILS_FAIL = "WHATSAPP_OUTBOUND_DETAILS_FAIL";

export const WHATSAPP_OUTBOUND_CREATE_REQUEST = "WHATSAPP_OUTBOUND_CREATE_REQUEST";
export const WHATSAPP_OUTBOUND_CREATE_SUCCESS = "WHATSAPP_OUTBOUND_CREATE_SUCCESS";
export const WHATSAPP_OUTBOUND_CREATE_FAIL = "WHATSAPP_OUTBOUND_CREATE_FAIL";
export const WHATSAPP_OUTBOUND_CREATE_RESET = "WHATSAPP_OUTBOUND_CREATE_RESET";

export const WHATSAPP_OUTBOUND_DELETE_REQUEST = "WHATSAPP_OUTBOUND_DELETE_REQUEST";
export const WHATSAPP_OUTBOUND_DELETE_SUCCESS = "WHATSAPP_OUTBOUND_DELETE_SUCCESS";
export const WHATSAPP_OUTBOUND_DELETE_FAIL = "WHATSAPP_OUTBOUND_DELETE_FAIL";

export const WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST = "WHATSAPP_OUTBOUND_PRODUCT_LIST_REQUEST";
export const WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS = "WHATSAPP_OUTBOUND_PRODUCT_LIST_SUCCESS";
export const WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL = "WHATSAPP_OUTBOUND_PRODUCT_LIST_FAIL";

export const WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST = "WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_REQUEST";
export const WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS = "WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_SUCCESS";
export const WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL = "WHATSAPP_OUTBOUND_PRODUCT_DATE_RANGE_LIST_FAIL";

export const WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST = "WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_REQUEST";
export const WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS = "WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_SUCCESS";
export const WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL = "WHATSAPP_OUTBOUND_DATE_RANGE_DELETE_FAIL";

import axios from 'axios';

import {
  GET_PRODUCT_ABANDONED_CALLS_REQUEST,
  GET_PRODUCT_ABANDONED_CALLS_SUCCESS,
  GET_PRODUCT_ABANDONED_CALLS_FAIL,
  GET_AGENT_OUTBOUND_CALLS_REQUEST,
  GET_AGENT_OUTBOUND_CALLS_SUCCESS,
  GET_AGENT_OUTBOUND_CALLS_FAIL,
  UPDATE_CDR_ABANDONED_REQUEST,
  UPDATE_CDR_ABANDONED_SUCCESS,
  UPDATE_CDR_ABANDONED_FAIL,
  GET_ALL_OUTBOUND_CALLS_REQUEST,
  GET_ALL_OUTBOUND_CALLS_SUCCESS,
  GET_ALL_OUTBOUND_CALLS_FAIL,
  GET_PRODUCT_OUTBOUND_CALLS_REQUEST,
  GET_PRODUCT_OUTBOUND_CALLS_SUCCESS,
  GET_PRODUCT_OUTBOUND_CALLS_FAIL,
} from '../constants/cdrConstants';

export const getProductAbandonedCalls = (prodCode) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PRODUCT_ABANDONED_CALLS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/prodabandoned/${prodCode}`, config);

    dispatch({
      type: GET_PRODUCT_ABANDONED_CALLS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_ABANDONED_CALLS_FAIL,
      payload: error.response ? error.response.data.message : 'Internal Server Error',
    });
    throw error;
  }
};

export const getAgentOutboundCalls = (agentExt, searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_AGENT_OUTBOUND_CALLS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fetchOutboundCallsData = async () => {
      try {
        const { data } = await axios.get(`/api/outbound/${agentExt}?search=${searchQuery}`, config);
        dispatch({
          type: GET_AGENT_OUTBOUND_CALLS_SUCCESS,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: GET_AGENT_OUTBOUND_CALLS_FAIL,
          payload: error.response ? error.response.data.message : "Internal Server Error",
        });
      }
    };

    await fetchOutboundCallsData();

    const pollingInterval = 20000;
    const intervalId = setInterval(fetchOutboundCallsData, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (error) {
    dispatch({
      type: GET_AGENT_OUTBOUND_CALLS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCDR = (uniqueId, agent, status) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CDR_ABANDONED_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const updatedFields = { agent, status };
    const { data } = await axios.put(`/api/cdr/update/${uniqueId}`, updatedFields, config);
    console.log('abandoned calls update', data);

    dispatch({
      type: UPDATE_CDR_ABANDONED_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: UPDATE_CDR_ABANDONED_FAIL,
      payload: error.response ? error.response.data.message : 'Internal Server Error',
    });
    throw error;
  }
};

export const getAllOutboundCalls = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_OUTBOUND_CALLS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fetchOutboundCallsData = async () => {
      try {
        const { data } = await axios.get(`/api/outbound`, config);
        dispatch({
          type: GET_ALL_OUTBOUND_CALLS_SUCCESS,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_OUTBOUND_CALLS_FAIL,
          payload: error.response ? error.response.data.message : "Internal Server Error",
        });
      }
    };

    await fetchOutboundCallsData();

    const pollingInterval = 10000;
    const intervalId = setInterval(fetchOutboundCallsData, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (error) {
    dispatch({
      type: GET_ALL_OUTBOUND_CALLS_FAIL,
      payload: error.response ? error.response.data.message : "Internal Server Error",
    });
  }
};

export const getProductOutboundCalls = (prodCode) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PRODUCT_OUTBOUND_CALLS_REQUEST });

    const { userInfo } = getState().userLogin;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fetchOutboundCallsData = async () => {
      try {
        const { data } = await axios.get(`/api/outbound/product/${prodCode}`, config);
        dispatch({
          type: GET_PRODUCT_OUTBOUND_CALLS_SUCCESS,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: GET_PRODUCT_OUTBOUND_CALLS_FAIL,
          payload: error.response ? error.response.data.message : "Internal Server Error",
        });
      }
    };

    await fetchOutboundCallsData();

    const pollingInterval = 10000;
    const intervalId = setInterval(fetchOutboundCallsData, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_OUTBOUND_CALLS_FAIL,
      payload: error.response ? error.response.data.message : "Internal Server Error",
    });
  }
};

import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { ChevronDownIcon, CheckIcon, MinusIcon, DeleteIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  listContactGroups,
  createContactGroup,
  editContactGroup,
  deleteContactGroup,
} from "../../actions/haziSocialsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziContactsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const cancelRef = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [groupName, setGroupName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { loading, error, groups } = useSelector((state) => state.haziContactGroupsList);
  const { success: createSuccess } = useSelector((state) => state.haziCreateContactGroup);
  const { success: editSuccess } = useSelector((state) => state.haziEditContactGroup);
  const { success: deleteSuccess } = useSelector((state) => state.haziDeleteContactGroup);

  useEffect(() => {
    if (userInfo) {
      dispatch(listContactGroups(searchQuery));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, createSuccess, editSuccess, deleteSuccess, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleGroupCreateOrEdit = () => {
    if (groupName) {
      if (editMode) {
        dispatch(editContactGroup(currentGroupId, { name: groupName }));
      } else {
        dispatch(createContactGroup({ name: groupName }));
      }
      resetModal();
    }
  };

  const handleDeleteConfirm = () => {
    if (deleteGroupId) {
      dispatch(deleteContactGroup(deleteGroupId));
      onDeleteClose();
    }
  };

  const resetModal = () => {
    setGroupName("");
    setEditMode(false);
    setCurrentGroupId(null);
    onClose();
  };

  const openEditModal = (group) => {
    setGroupName(group.name);
    setEditMode(true);
    setCurrentGroupId(group.id);
    onOpen();
  };

  const openDeleteConfirmation = (groupId) => {
    setDeleteGroupId(groupId);
    onDeleteOpen();
  };


  const totalPages = groups && Array.isArray(groups) ? Math.ceil(groups.length / itemsPerPage) : 0;
  const paginatedGroups = groups?.data 
    ? groups.data.slice(
        itemsPerPage * (currentPage - 1),
        itemsPerPage * currentPage
      )
    : [];   

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <Button key={1} onClick={() => handlePageChange(1)}>
          1
        </Button>
      );
      if (startPage > 2) {
        pageNumbers.push(<Text key="start-ellipsis">...</Text>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === currentPage}
          colorScheme={i === currentPage ? 'blue' : 'gray'}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<Text key="end-ellipsis">...</Text>);
      }
      pageNumbers.push(
        <Button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Button>
      );
    }

    return (
      <Flex justify="center" align="center" mt="5" gap="2">
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        {pageNumbers}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Flex>
    );
  };


  return (
    <Box bg="white" borderRadius="sm" m={4} p={4}>
      {/* Header */}
      <Flex justifyContent="space-between" mb={4}>
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }}>
          Contacts
        </Heading>
      </Flex>

      {/* Breadcrumb */}
      <Breadcrumb fontWeight="medium" fontSize="sm" mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/trial/socials-h/dashboard">
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/trial/socials-h/contacts">
            Contacts
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <Text>Contact Groups</Text>
        </BreadcrumbItem>
      </Breadcrumb>

      {/* Actions */}
      <Flex justify="space-between" align="center" mb={4}>

        <Stack direction="row" spacing={4}>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Actions
            </MenuButton>
            <MenuList>
              <MenuItem icon={<CheckIcon />}>Enable</MenuItem>
              <MenuItem icon={<MinusIcon />}>Disable</MenuItem>
              <MenuItem icon={<DeleteIcon />}>Delete</MenuItem>
            </MenuList>
          </Menu>
          <Button colorScheme="green" onClick={onOpen} leftIcon={<ChevronDownIcon />}>
            Add New
          </Button>
          <Button colorScheme="blue" leftIcon={<ChevronDownIcon />}>
            Export
          </Button>
        </Stack>
        
        
        <Stack direction="row" align="center" spacing={4}>
          <Select
            placeholder="Show"
            size="sm"
            w="100px"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Select>
          <Input
            placeholder="Search groups"
            size="sm"
            w="200px"
            value={searchQuery}
            onChange={handleSearch}
          />
        </Stack>
      </Flex>

      {/* Table */}
      <Box overflowX="auto">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <>
            <Table variant="striped" size="md">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Name</Th>
                  <Th>Total Contacts</Th>
                  <Th>Created At</Th>
                  <Th>Status</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedGroups?.length > 0 ? (
                  paginatedGroups.map((group, index) => (
                    <Tr key={group.id}>
                      <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                      <Td>{group.name}</Td>
                      <Td>{group.total_contacts}</Td>
                      <Td>{new Date(group.date).toLocaleDateString()}</Td>
                      <Td>{group.status}</Td>
                      <Td>
                        <Button size="sm" colorScheme="green"  as={Link}  to={`/trial/socials-h/contacts/${group.id}/group`} >
                          View
                        </Button>{" "}
                        <Button size="sm" colorScheme="blue" onClick={() => openEditModal(group)}>
                          Edit
                        </Button>{" "}
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => openDeleteConfirmation(group.id)}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="3" textAlign="center">
                      No results found
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {renderPagination()}
          </>
        )}
      </Box>

      {/* Create/Edit Modal */}
      <Modal isOpen={isOpen} onClose={resetModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editMode ? "Edit Group" : "Create New Group"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!groupName}>
              <FormLabel>Group Name</FormLabel>
              <Input
                placeholder="Enter group name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              {!groupName && <FormErrorMessage>Group name is required</FormErrorMessage>}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleGroupCreateOrEdit}>
              {editMode ? "Save Changes" : "Create"}
            </Button>
            <Button variant="ghost" onClick={resetModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Confirm Deletion</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this group? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default HaziContactsScreen;

import {
  FETCH_INSTANCE_ID_REQUEST,
  FETCH_INSTANCE_ID_SUCCESS,
  FETCH_INSTANCE_ID_FAILURE,
  FETCH_QR_REQUEST,
  FETCH_QR_SUCCESS,
  FETCH_QR_FAILURE,
  SET_WEBHOOK_REQUEST,
  SET_WEBHOOK_SUCCESS,
  SET_WEBHOOK_FAILURE,
  REBOOT_INSTANCE_REQUEST,
  REBOOT_INSTANCE_SUCCESS,
  REBOOT_INSTANCE_FAILURE,
  RESET_INSTANCE_REQUEST,
  RESET_INSTANCE_SUCCESS,
  RESET_INSTANCE_FAILURE,
  RECONNECT_INSTANCE_REQUEST,
  RECONNECT_INSTANCE_SUCCESS,
  RECONNECT_INSTANCE_FAILURE,
  SEND_DIRECT_MESSAGE_REQUEST,
  SEND_DIRECT_MESSAGE_SUCCESS,
  SEND_DIRECT_MESSAGE_FAILURE,
  SEND_MEDIA_FILE_REQUEST,
  SEND_MEDIA_FILE_SUCCESS,
  SEND_MEDIA_FILE_FAILURE,
  SEND_GROUP_MESSAGE_REQUEST,
  SEND_GROUP_MESSAGE_SUCCESS,
  SEND_GROUP_MESSAGE_FAILURE,
  SEND_GROUP_MEDIA_FILE_REQUEST,
  SEND_GROUP_MEDIA_FILE_SUCCESS,
  SEND_GROUP_MEDIA_FILE_FAILURE,
} from '../constants/qrConstants';

export const instanceIdReducer = (state = { loading: false, instanceId: '', error: '' }, action) => {
switch (action.type) {
    case FETCH_INSTANCE_ID_REQUEST:
        return { ...state, loading: true };
    case FETCH_INSTANCE_ID_SUCCESS:
        return { ...state, loading: false, instanceId: action.payload };
    case FETCH_INSTANCE_ID_FAILURE:
        return { ...state, loading: false, error: action.payload };
    default:
        return state;
}
};

export const qrReducer = (state = {  loading: false, qrCode: '', error: ''}, action) => {
switch (action.type) {
  case FETCH_QR_REQUEST:
    return { ...state, loading: true };
  case FETCH_QR_SUCCESS:
    return { ...state, loading: false, qrCode: action.payload };
  case FETCH_QR_FAILURE:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
}
};

export const setWebhookReducer = (state = { loading: false, success: false, error: '' }, action) => {
  switch (action.type) {
    case SET_WEBHOOK_REQUEST:
      return { ...state, loading: true };
    case SET_WEBHOOK_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_WEBHOOK_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rebootInstanceReducer = (state = { loading: false, success: false, error: '' }, action) => {
  switch (action.type) {
    case REBOOT_INSTANCE_REQUEST:
      return { ...state, loading: true };
    case REBOOT_INSTANCE_SUCCESS:
      return { ...state, loading: false, success: true };
    case REBOOT_INSTANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resetInstanceReducer = (state = { loading: false, success: false, error: '' }, action) => {
  switch (action.type) {
    case RESET_INSTANCE_REQUEST:
      return { ...state, loading: true };
    case RESET_INSTANCE_SUCCESS:
      return { ...state, loading: false, success: true };
    case RESET_INSTANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reconnectInstanceReducer = (state = { loading: false, success: false, error: '' }, action) => {
  switch (action.type) {
    case RECONNECT_INSTANCE_REQUEST:
      return { ...state, loading: true };
    case RECONNECT_INSTANCE_SUCCESS:
      return { ...state, loading: false, success: true };
    case RECONNECT_INSTANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const sendDirectMessageReducer = (state = { loading: false, success: false, error: '' }, action) => {
switch (action.type) {
  case SEND_DIRECT_MESSAGE_REQUEST:
    return { ...state, loading: true };
  case SEND_DIRECT_MESSAGE_SUCCESS:
    return { ...state, loading: false, success: true };
  case SEND_DIRECT_MESSAGE_FAILURE:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
}
};

export const sendMediaFileReducer = (state = { loading: false, success: false, error: '' }, action) => {
switch (action.type) {
  case SEND_MEDIA_FILE_REQUEST:
    return { ...state, loading: true };
  case SEND_MEDIA_FILE_SUCCESS:
    return { ...state, loading: false, success: true };
  case SEND_MEDIA_FILE_FAILURE:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
}
};

export const sendGroupMessageReducer = (state = { loading: false, success: false, error: '' }, action) => {
switch (action.type) {
  case SEND_GROUP_MESSAGE_REQUEST:
    return { ...state, loading: true };
  case SEND_GROUP_MESSAGE_SUCCESS:
    return { ...state, loading: false, success: true };
  case SEND_GROUP_MESSAGE_FAILURE:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
}
};

export const sendGroupMediaFileReducer = (state = { loading: false, success: false, error: '' }, action) => {
switch (action.type) {
  case SEND_GROUP_MEDIA_FILE_REQUEST:
    return { ...state, loading: true };
  case SEND_GROUP_MEDIA_FILE_SUCCESS:
    return { ...state, loading: false, success: true };
  case SEND_GROUP_MEDIA_FILE_FAILURE:
    return { ...state, loading: false, error: action.payload };
  default:
    return state;
}
};

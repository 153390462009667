import React from 'react';
import { Box, Text, Flex, Grid, Button } from '@chakra-ui/react';
import {
  FaFacebookF,
  FaTwitter,
  FaGoogle,
  FaLinkedin,
  FaYoutube,
  FaWhatsapp,
} from 'react-icons/fa'
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <Box
        backgroundColor="#00669E"    
        fontFamily="'Lato', sans-serif"
        color="white"        
        flexDirection='column'
        py={2}
    >
        <Grid as="footer" templateColumns="repeat(6, 1fr)" mt="16" mb="16">
            <Flex
                flexDirection="column"
                alignItems={{ base: "center", md: "flex-start" }}
                gridColumn={['1 / 7', '1 / 7', '1 / 7', '1 / 3', '1 / 3']}
                pl={['8', '8', '8', '24', '24']}
            >
                <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="8"
                >
                <Box as={FaFacebookF} size="22px" color="brandPurple" mr="12px" />
                <Box as={FaTwitter} size="22px" color="brandPurple" mr="12px" />
                <Box as={FaGoogle} size="22px" color="brandPurple" mr="12px" />
                <Box as={FaLinkedin} size="22px" color="brandPurple" mr="12px" />
                <Box as={FaYoutube} size="22px" color="brandPurple" />
                </Flex>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems={{ base: "center", md: "flex-start" }}
                gridColumn={['1 / 7', '1 / 7', '1 / 7', '3 / 4', '3 / 4']}
                paddingX="8"
                mt={['12', '12', '12', '0', '0']}
            >
                <Text color="brandPurple" fontWeight="bold" fontSize="20px" mb="8px">
                Useful Links
                </Text>            
                <Text mb="6px">Home</Text>
                <Text mb="6px">About Us</Text>
                <Text mb="6px">Contact Us</Text>
                <Text>Features</Text>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems={{ base: "center", md: "flex-start" }}
                gridColumn={['1 / 7', '1 / 7', '1 / 7', '4 / 5', '4 / 5']}
                paddingX={['8', '8', '8', '2', '2']}
                mt={['12', '12', '12', '0', '0']}
            >
                <Text color="brandPurple" fontWeight="bold" fontSize="20px" mb="8px">
                Our Services
                </Text>
                <Text mb="6px">Contact Centre</Text>
                <Text mb="6px">IVR & ACD</Text>
                <Text mb="6px">Business intelligence</Text>
                <Text mb="6px">Customer Service</Text>
                <Text mb="6px">Outbound and Telesales</Text>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems={{ base: "center", md: "flex-start" }}
                gridColumn={['1 / 7', '1 / 7', '1 / 7', '5 / 6', '5 / 6']}
                paddingX={['8', '8', '8', '2', '2']}
                mt={['12', '12', '12', '0', '0']}
            >
                <Text color="brandPurple" fontWeight="bold" fontSize="20px" mb="8px">
                    Contact Us
                </Text>
                <Text mb="6px">
                    Email: zuridesk@gmail.com
                </Text>
                <Flex>
                    <Link 
                        to="https://wa.me/254768252222" 
                        target="_blank" 
                        display="flex" 
                        alignItems="center"
                    >
                        <Button 
                            rightIcon={<FaWhatsapp />} 
                            variant="outline" 
                            borderColor="blue.500"
                            color="white" 
                            _hover={{
                                transform: 'scale(1.05)',
                                textDecoration: 'none',
                                backgroundColor: 'transparent', 
                            }}
                            p={4} 
                            fontSize="18px"
                            iconSpacing="5px" 
                        >
                            Chat with Us
                        </Button>
                    </Link>
                </Flex>
            </Flex>

        </Grid>
        <Flex
            justifyContent='center'
            alignItems='center'
            textAlign='center'
        >
            {/* Copyright Text */}
            <Text mt='3' fontWeight='semibold'>
                &copy; {new Date().getFullYear()}{'   '} 
                Zuridesk
                <Text as="span" color="red.400">. </Text>                  
            </Text>
        </Flex>
    </Box>
  )
}

export default Footer;
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk'; 
import websocketMiddleware from './middlewares/websocketMiddleware'; // Import your WebSocket middleware

// Import all the reducers
import {
	userDeleteReducer,
	userDetailsReducer,
	userListReducer,
	userLoginReducer,
	userRegisterReducer,
	userUpdateProfileReducer,
	userUpdateReducer,
	userForgotPasswordReducer,
} from './reducers/userReducer';
import {
	abandonedCallsListReducer,
	abandonedCallsDetailsReducer,
	abandonedCallsDeleteReducer,
	abandonedCallsCreateReducer,
	abandonedCallsUpdateReducer,
} from './reducers/abandonedCallsReducer';
import { 
	agentCheckinsListReducer,
	agentCheckinsByProductReducer,
	createAgentCheckinReducer,
	updateAgentCheckinReducer,
	agentLatestLogoutCheckinReducer,
 } from './reducers/agentCheckinsReducer';
import {
	agentListReducer,
	agentDetailsReducer,
	agentSupervisorListReducer,
	agentDeleteReducer,
	agentCreateReducer,
	agentUpdateReducer,
} from './reducers/agentReducer';
import {
	agentProductListReducer,
	agentProductsReducer,
	agentProductDetailsReducer,
	agentProductDeleteReducer,
	agentProductCreateReducer,
	agentProductUpdateReducer,
} from './reducers/agentProductsReducer';
import { 
	callDispositionCreateReducer,
	callHistoryReducer,
	productInboundCallHistoryReducer,
	allInboundCallHistoryReducer,
	inboundAllScheduledCallbacksReducer,
	inboundScheduledCallbacksReducer,
	updateCallbackStatusReducer,
	inboundProductEscalatedCallsReducer,
	updateEscalationDetailsReducer,
 } from './reducers/callDispositionReducer';
 import {
    campaignsListReducer,
    campaignsByProductReducer,
    campaignsDeleteReducer,
    campaignsCreateReducer,
    campaignUpdateReducer,
} from './reducers/campaignReducer';
import { 
	productAbandonedCallsReducer,
	agentOutboundCallsReducer,
	updateCDRReducer,
	allOutboundCallsReducer,
	productOutboundCallsReducer,
 } from './reducers/cdrReducer';
import {
	clientsCreateReducer,
	clientsDeleteReducer,
	clientsDetailsReducer,
	clientsListReducer,
	clientUpdateReducer,	
	agentsByProductReducer,
} from './reducers/clientsReducer';
import {
	clientContactsListReducer,
	clientContactsDetailsReducer,
	clientContactsDeleteReducer,
	clientContactsCreateReducer,
	clientContactsUpdateReducer,
	productClientContactsReducer,
	agentClientContactsReducer,
	bulkContactsDeleteReducer,
	clientPrefixByAgentReducer,
	bulkContactsUpdateReducer,
	customTagsUpdateReducer, 
	customTagsFetchReducer,
} from './reducers/clientContactsReducer'; 
import {
    demoRequestsListReducer,
    demoRequestCreateReducer,
} from './reducers/demoRequestReducer';
import { escalatedCallsListReducer } from './reducers/escalatedCallsReducer';
import {
    haziProfileReducer,
    haziBalanceReducer,
	haziEmailVerifyReducer,
    haziEmailSendReducer,
    haziBulkSmsSendReducer,
    haziSmsSendReducer,
    haziBulkEmailSendReducer,
    haziContactGroupsListReducer,
    haziCreateContactGroupReducer,
    haziEditContactGroupReducer,
    haziDeleteContactGroupReducer,
    haziAddContactsToGroupReducer,
    haziListContactsInGroupReducer,
    haziUpdateContactReducer,
    haziDeleteContactReducer,
    haziWhatsappTemplatesReducer,
    haziWhatsappCreateTextTemplateReducer,
    haziWhatsappCreateMediaTemplateReducer,
    haziWhatsappSendTemplateMessageReducer,
    haziWhatsappSendSimpleMessageReducer,
    haziWhatsappBroadcastsReducer,
    haziWhatsAppAccountsReducer,
    haziOnboardClientReducer,
    haziWhatsAppWebhookDeliveredReducer,
    haziWhatsAppWebhookReadReducer,
    haziWhatsAppWebhookReplyReducer,
} from './reducers/haziSocialsReducer';
import {
    notificationsListReducer,
    notificationDetailsReducer,
    notificationDeleteReducer,
    notificationCreateReducer,
    notificationUpdateReducer,
    userNotificationsReducer,
    markNotificationAsReadReducer,
	markAllNotificationsAsReadReducer,
} from './reducers/notificationReducer';
import { 
	outboundDispositionCreateReducer,
	outboundAllScheduledCallbacksReducer,
    outboundScheduledCallbacksReducer,
    updateOutboundCallbackStatusReducer,
    getOutboundEscalatedCallsProductReducer,
    updateOutboundEscalationDetailsReducer,
    getOutboundEscalatedCallsAgentReducer,
 } from './reducers/outboundDispositionReducer';
 import {
    instanceIdReducer,
    qrReducer,
	setWebhookReducer,
    rebootInstanceReducer,
	resetInstanceReducer,
    reconnectInstanceReducer,
    sendDirectMessageReducer,
    sendMediaFileReducer,
    sendGroupMessageReducer,
    sendGroupMediaFileReducer,
} from './reducers/qrReducer';
import { 
	queLogListReducer,
	queueLogProductListReducer,
	queLogAllReducer,
 } from './reducers/queLogReducer';
import {
	reasonsListReducer,
	reasonsDetailsReducer,
	reasonsDeleteReducer,
	reasonsCreateReducer,
	reasonsUpdateReducer,
	productReasonsReducer,
} from './reducers/reasonsReducer'; 
import {
	agentOutboundStatsReducer,
	agentCallStatsReducer,
	agentDailyStatsReducer,
	adminInboundStatsReducer,
	adminOutboundStatsReducer,
	supervisorInboundStatsReducer,
	supervisorOutboundStatsReducer,
	supportInboundStatsReducer,
	supportInboundReasonStatsReducer,
	supportOutboundStatsReducer,
	supportOutboundReasonStatsReducer,
} from './reducers/statsReducer';
import {
	supportListReducer,
	supportProductListReducer,
	supportDetailsReducer,
	supportDeleteReducer,
	supportCreateReducer,
	supportUpdateReducer,
  } from './reducers/supportReducer';
  import { 
	allTicketsReducer, 
	clientTicketsReducer, 
} from './reducers/ticketsReducer';
import {
    whatsappOutboundListReducer,
    whatsappOutboundDetailsReducer,
    whatsappOutboundDeleteReducer,
    whatsappOutboundCreateReducer,
    whatsappOutboundProductListReducer,
    whatsappOutboundProductDateRangeListReducer,
    whatsappOutboundDateRangeDeleteReducer,
} from './reducers/whatsappOutboundReducer';

import { 
	websocketReducer, 
} from './reducers/webSocketReducer';

// Combine all reducers
const reducer = combineReducers({
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userList: userListReducer,
	userUpdate: userUpdateReducer,
	userDelete: userDeleteReducer,
	userForgotPassword: userForgotPasswordReducer,

	abandonedCallsList: abandonedCallsListReducer,
	abandonedCallsDetails: abandonedCallsDetailsReducer,
	abandonedCallsDelete: abandonedCallsDeleteReducer,
	abandonedCallsCreate: abandonedCallsCreateReducer,
	abandonedCallsUpdate: abandonedCallsUpdateReducer,

	agentCheckinsList: agentCheckinsListReducer,
	agentCheckinsByProduct: agentCheckinsByProductReducer,
	createAgentCheckin: createAgentCheckinReducer,
	agentCheckinUpdate: updateAgentCheckinReducer,
	agentLatestLogoutCheckin: agentLatestLogoutCheckinReducer,

	agentList: agentListReducer,
	agentDetails: agentDetailsReducer,
	agentSupervisorList: agentSupervisorListReducer,
	agentDelete: agentDeleteReducer,
	agentCreate: agentCreateReducer,
	agentUpdate: agentUpdateReducer,

	agentProductList: agentProductListReducer,
	agentProducts: agentProductsReducer,
	agentProductDetails: agentProductDetailsReducer,
	agentProductDelete: agentProductDeleteReducer,
	agentProductCreate: agentProductCreateReducer,
	agentProductUpdate: agentProductUpdateReducer,

	callDispositionCreate: callDispositionCreateReducer,
	callHistoryList: callHistoryReducer,
	productInboundCallHistory: productInboundCallHistoryReducer,
	allInboundCallHistory: allInboundCallHistoryReducer,
	inboundAllScheduledCallbacks: inboundAllScheduledCallbacksReducer,
	inboundScheduledCallbacks: inboundScheduledCallbacksReducer,
	updateCallbackStatus:updateCallbackStatusReducer,
	inboundProductEscalatedCalls: inboundProductEscalatedCallsReducer,
	updateEscalationDetails: updateEscalationDetailsReducer,

	campaignsList: campaignsListReducer,
    campaignsByProduct: campaignsByProductReducer,
    campaignsDelete: campaignsDeleteReducer,
    campaignsCreate: campaignsCreateReducer,
    campaignUpdate: campaignUpdateReducer,

	productAbandonedCallsList: productAbandonedCallsReducer,
	agentOutboundCalls: agentOutboundCallsReducer,
	updateCDR: updateCDRReducer,
	allOutboundCalls: allOutboundCallsReducer,
	productOutboundCalls:productOutboundCallsReducer,

	clientsCreate: clientsCreateReducer,
	clientsDelete: clientsDeleteReducer,
	clientsDetails: clientsDetailsReducer,
	clientsList: clientsListReducer,
	clientUpdate: clientUpdateReducer,	
	agentsByProduct: agentsByProductReducer,

	clientContactsList: clientContactsListReducer,
	clientContactsDetails: clientContactsDetailsReducer,
	clientContactsDelete: clientContactsDeleteReducer,
	clientContactsCreate: clientContactsCreateReducer,
	clientContactsUpdate: clientContactsUpdateReducer,
	productClientContacts: productClientContactsReducer,
	agentClientContacts: agentClientContactsReducer,
	bulkContactsDelete: bulkContactsDeleteReducer,
	clientPrefixByAgent: clientPrefixByAgentReducer,
	bulkContactsUpdate: bulkContactsUpdateReducer,
	customTagsUpdate: customTagsUpdateReducer, 
	customTagsFetch: customTagsFetchReducer,

    demoRequestsList: demoRequestsListReducer,
    demoRequestCreate: demoRequestCreateReducer,    

	escalatedCallsList: escalatedCallsListReducer,

	
    haziProfile: haziProfileReducer,
    haziBalance: haziBalanceReducer,
	haziEmailVerify: haziEmailVerifyReducer,
    haziEmailSend: haziEmailSendReducer,
    haziBulkEmailSend: haziBulkEmailSendReducer,
    haziSmsSend: haziSmsSendReducer,
    haziBulkSmsSend: haziBulkSmsSendReducer,
    haziContactGroupsList: haziContactGroupsListReducer,
    haziCreateContactGroup: haziCreateContactGroupReducer,
    haziEditContactGroup: haziEditContactGroupReducer,
    haziDeleteContactGroup: haziDeleteContactGroupReducer,
    haziAddContactsToGroup: haziAddContactsToGroupReducer,
    haziListContactsInGroup: haziListContactsInGroupReducer,
    haziUpdateContact: haziUpdateContactReducer,
    haziDeleteContact: haziDeleteContactReducer,
    haziWhatsappTemplates: haziWhatsappTemplatesReducer,
    haziWhatsappCreateTextTemplate: haziWhatsappCreateTextTemplateReducer,
    haziWhatsappCreateMediaTemplate: haziWhatsappCreateMediaTemplateReducer,
    haziWhatsappSendTemplateMessage: haziWhatsappSendTemplateMessageReducer,
    haziWhatsappSendSimpleMessage: haziWhatsappSendSimpleMessageReducer,
    haziWhatsappBroadcasts: haziWhatsappBroadcastsReducer,
    haziWhatsAppAccounts: haziWhatsAppAccountsReducer,
    haziOnboardClient: haziOnboardClientReducer,
    haziWhatsAppWebhookDelivered: haziWhatsAppWebhookDeliveredReducer,
    haziWhatsAppWebhookRead: haziWhatsAppWebhookReadReducer,
    haziWhatsAppWebhookReply: haziWhatsAppWebhookReplyReducer,    
	

	notificationsList: notificationsListReducer,
	notificationDetails: notificationDetailsReducer,
	notificationDelete: notificationDeleteReducer,
	notificationCreate: notificationCreateReducer,
	notificationUpdate: notificationUpdateReducer,
	userNotifications: userNotificationsReducer,
	markNotificationAsRead: markNotificationAsReadReducer,
	markAllNotificationsAsRead: markAllNotificationsAsReadReducer, 

	outboundDispositionCreate: outboundDispositionCreateReducer,
    outboundAllScheduledCallbacks: outboundAllScheduledCallbacksReducer,
    outboundScheduledCallbacks: outboundScheduledCallbacksReducer,
    updateOutboundCallbackStatus: updateOutboundCallbackStatusReducer,
    getOutboundEscalatedCallsProduct: getOutboundEscalatedCallsProductReducer,
    updateOutboundEscalationDetails: updateOutboundEscalationDetailsReducer,
    getOutboundEscalatedCallsAgent: getOutboundEscalatedCallsAgentReducer,

	instanceId: instanceIdReducer,
    qr: qrReducer,
	setWebhook: setWebhookReducer,
	rebootInstance: rebootInstanceReducer,
	resetInstance: resetInstanceReducer,
	reconnectInstance: reconnectInstanceReducer,
    sendDirectMessage: sendDirectMessageReducer,
    sendMediaFile: sendMediaFileReducer,
    sendGroupMessage: sendGroupMessageReducer,
    sendGroupMediaFile: sendGroupMediaFileReducer,

	queLogList: queLogListReducer,
	queueLogProductList: queueLogProductListReducer,
	queLogAll: queLogAllReducer,

	reasonsList: reasonsListReducer,
	reasonsDetails: reasonsDetailsReducer,
	reasonsDelete: reasonsDeleteReducer,
	reasonsCreate: reasonsCreateReducer,
	reasonsUpdate: reasonsUpdateReducer,
	productReasons: productReasonsReducer,

	agentOutboundStats: agentOutboundStatsReducer,
	agentCallStats: agentCallStatsReducer,
	agentDailyStats: agentDailyStatsReducer,
	adminInboundStats: adminInboundStatsReducer,
	adminOutboundStats:  adminOutboundStatsReducer,
	supervisorInboundStats: supervisorInboundStatsReducer,
	supervisorOutboundStats: supervisorOutboundStatsReducer,
	supportInboundStats: supportInboundStatsReducer,
	supportInboundReasonStats: supportInboundReasonStatsReducer,
	supportOutboundStats: supportOutboundStatsReducer,
	supportOutboundReasonStats: supportOutboundReasonStatsReducer,

	supportList: supportListReducer,
	supportProductList: supportProductListReducer,
	supportDetails: supportDetailsReducer,
	supportDelete: supportDeleteReducer,
	supportCreate: supportCreateReducer,
	supportUpdate: supportUpdateReducer,

	allTickets: allTicketsReducer,
  	clientTickets: clientTicketsReducer,
	websocket:websocketReducer,

	whatsappOutboundList: whatsappOutboundListReducer,
	whatsappOutboundDetails: whatsappOutboundDetailsReducer,
	whatsappOutboundDelete: whatsappOutboundDeleteReducer,
	whatsappOutboundCreate: whatsappOutboundCreateReducer,
	whatsappOutboundProductList: whatsappOutboundProductListReducer,
	whatsappOutboundProductDateRangeList: whatsappOutboundProductDateRangeListReducer,
	whatsappOutboundDateRangeDelete: whatsappOutboundDateRangeDeleteReducer,
});

// Initial state
const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
};

// Apply middlewares, including WebSocket middleware
const middlewares = [thunk, websocketMiddleware]; // Add WebSocket middleware here

// Create store
const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;

import axios from "axios";

import {
  REASONS_LIST_REQUEST,
  REASONS_LIST_SUCCESS,
  REASONS_LIST_FAIL,
  REASONS_DETAILS_REQUEST,
  REASONS_DETAILS_SUCCESS,
  REASONS_DETAILS_FAIL,
  REASONS_DELETE_REQUEST,
  REASONS_DELETE_SUCCESS,
  REASONS_DELETE_FAIL,
  REASONS_CREATE_REQUEST,
  REASONS_CREATE_SUCCESS,
  REASONS_CREATE_FAIL,
  REASONS_UPDATE_REQUEST,
  REASONS_UPDATE_SUCCESS,
  REASONS_UPDATE_FAIL,
  PRODUCT_REASONS_REQUEST,
  PRODUCT_REASONS_SUCCESS,
  PRODUCT_REASONS_FAIL,
} from '../constants/reasonsConstants';

export const listReasons = (searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: REASONS_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/reasons?search=${searchQuery}`,
      config
    );

    dispatch({ type: REASONS_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REASONS_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listReasonDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REASONS_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/reasons/${id}`,
      config
    );
    console.log({ data });

    dispatch({ type: REASONS_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REASONS_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteReason = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REASONS_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/reasons/${id}`,
      config
    );

    dispatch({ type: REASONS_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: REASONS_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createReason = (reason) => async (dispatch, getState) => {
  try {
    dispatch({ type: REASONS_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/reasons`,
      reason,
      config
    );

    dispatch({ type: REASONS_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REASONS_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateReason = (id, reasonObj) => async (dispatch, getState) => {
  try {
    dispatch({ type: REASONS_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/reasons/${id}`,
      reasonObj,
      config
    );

    dispatch({ type: REASONS_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REASONS_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getProductReasons = (productId, searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_REASONS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/reasons/client/${productId}?search=${searchQuery}`,
      config
    );

    dispatch({ type: PRODUCT_REASONS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_REASONS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

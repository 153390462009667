export const HAZI_PROFILE_REQUEST = 'HAZI_PROFILE_REQUEST';
export const HAZI_PROFILE_SUCCESS = 'HAZI_PROFILE_SUCCESS';
export const HAZI_PROFILE_FAIL = 'HAZI_PROFILE_FAIL';

export const HAZI_BALANCE_REQUEST = 'HAZI_BALANCE_REQUEST';
export const HAZI_BALANCE_SUCCESS = 'HAZI_BALANCE_SUCCESS';
export const HAZI_BALANCE_FAIL = 'HAZI_BALANCE_FAIL';

export const HAZI_EMAIL_VERIFY_REQUEST = 'HAZI_EMAIL_VERIFY_REQUEST';
export const HAZI_EMAIL_VERIFY_SUCCESS = 'HAZI_EMAIL_VERIFY_SUCCESS';
export const HAZI_EMAIL_VERIFY_FAIL = 'HAZI_EMAIL_VERIFY_FAIL';

export const HAZI_EMAIL_SEND_REQUEST = 'HAZI_EMAIL_SEND_REQUEST';
export const HAZI_EMAIL_SEND_SUCCESS = 'HAZI_EMAIL_SEND_SUCCESS';
export const HAZI_EMAIL_SEND_FAIL = 'HAZI_EMAIL_SEND_FAIL';

export const HAZI_EMAIL_BULK_SEND_REQUEST = 'HAZI_EMAIL_BULK_SEND_REQUEST';
export const HAZI_EMAIL_BULK_SEND_SUCCESS = 'HAZI_EMAIL_BULK_SEND_SUCCESS';
export const HAZI_EMAIL_BULK_SEND_FAIL = 'HAZI_EMAIL_BULK_SEND_FAIL';

export const HAZI_SMS_SEND_REQUEST = 'HAZI_SMS_SEND_REQUEST';
export const HAZI_SMS_SEND_SUCCESS = 'HAZI_SMS_SEND_SUCCESS';
export const HAZI_SMS_SEND_FAIL = 'HAZI_SMS_SEND_FAIL'; 

export const HAZI_SMS_BULK_SEND_REQUEST = 'HAZI_SMS_BULK_SEND_REQUEST';
export const HAZI_SMS_BULK_SEND_SUCCESS = 'HAZI_SMS_BULK_SEND_SUCCESS';
export const HAZI_SMS_BULK_SEND_FAIL = 'HAZI_SMS_BULK_SEND_FAIL';

export const HAZI_CONTACT_GROUPS_LIST_REQUEST = 'HAZI_CONTACT_GROUPS_LIST_REQUEST';
export const HAZI_CONTACT_GROUPS_LIST_SUCCESS = 'HAZI_CONTACT_GROUPS_LIST_SUCCESS';
export const HAZI_CONTACT_GROUPS_LIST_FAIL = 'HAZI_CONTACT_GROUPS_LIST_FAIL';

export const HAZI_CONTACT_GROUP_CREATE_REQUEST = 'HAZI_CONTACT_GROUP_CREATE_REQUEST';
export const HAZI_CONTACT_GROUP_CREATE_SUCCESS = 'HAZI_CONTACT_GROUP_CREATE_SUCCESS';
export const HAZI_CONTACT_GROUP_CREATE_FAIL = 'HAZI_CONTACT_GROUP_CREATE_FAIL';

export const HAZI_CONTACT_GROUP_EDIT_REQUEST = 'HAZI_CONTACT_GROUP_EDIT_REQUEST';
export const HAZI_CONTACT_GROUP_EDIT_SUCCESS = 'HAZI_CONTACT_GROUP_EDIT_SUCCESS';
export const HAZI_CONTACT_GROUP_EDIT_FAIL = 'HAZI_CONTACT_GROUP_EDIT_FAIL';

export const HAZI_CONTACT_GROUP_DELETE_REQUEST = 'HAZI_CONTACT_GROUP_DELETE_REQUEST';
export const HAZI_CONTACT_GROUP_DELETE_SUCCESS = 'HAZI_CONTACT_GROUP_DELETE_SUCCESS';
export const HAZI_CONTACT_GROUP_DELETE_FAIL = 'HAZI_CONTACT_GROUP_DELETE_FAIL';

export const HAZI_ADD_CONTACTS_TO_GROUP_REQUEST = 'HAZI_ADD_CONTACTS_TO_GROUP_REQUEST';
export const HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS = 'HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS';
export const HAZI_ADD_CONTACTS_TO_GROUP_FAIL = 'HAZI_ADD_CONTACTS_TO_GROUP_FAIL';

export const HAZI_CONTACTS_LIST_IN_GROUP_REQUEST = 'HAZI_CONTACTS_LIST_IN_GROUP_REQUEST';
export const HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS = 'HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS';
export const HAZI_CONTACTS_LIST_IN_GROUP_FAIL = 'HAZI_CONTACTS_LIST_IN_GROUP_FAIL';

export const HAZI_CONTACT_UPDATE_REQUEST = 'HAZI_CONTACT_UPDATE_REQUEST';
export const HAZI_CONTACT_UPDATE_SUCCESS = 'HAZI_CONTACT_UPDATE_SUCCESS';
export const HAZI_CONTACT_UPDATE_FAIL = 'HAZI_CONTACT_UPDATE_FAIL';

export const HAZI_CONTACT_DELETE_REQUEST = 'HAZI_CONTACT_DELETE_REQUEST';
export const HAZI_CONTACT_DELETE_SUCCESS = 'HAZI_CONTACT_DELETE_SUCCESS';
export const HAZI_CONTACT_DELETE_FAIL = 'HAZI_CONTACT_DELETE_FAIL';

export const HAZI_WHATSAPP_TEMPLATES_REQUEST = 'HAZI_WHATSAPP_TEMPLATES_REQUEST';
export const HAZI_WHATSAPP_TEMPLATES_SUCCESS = 'HAZI_WHATSAPP_TEMPLATES_SUCCESS';
export const HAZI_WHATSAPP_TEMPLATES_FAIL = 'HAZI_WHATSAPP_TEMPLATES_FAIL';

export const HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST = 'HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST';
export const HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS = 'HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS';
export const HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL = 'HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL';

export const HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST = 'HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST';
export const HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS = 'HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS';
export const HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL = 'HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL';

export const HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST = 'HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST';
export const HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS = 'HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS';
export const HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL = 'HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL';

export const HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST = 'HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST';
export const HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS = 'HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS';
export const HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL = 'HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL';

export const HAZI_WHATSAPP_BROADCASTS_REQUEST = 'HAZI_WHATSAPP_BROADCASTS_REQUEST';
export const HAZI_WHATSAPP_BROADCASTS_SUCCESS = 'HAZI_WHATSAPP_BROADCASTS_SUCCESS';
export const HAZI_WHATSAPP_BROADCASTS_FAIL = 'HAZI_WHATSAPP_BROADCASTS_FAIL';

export const HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST = 'HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST';
export const HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS = 'HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS';
export const HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL = 'HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL';

export const HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST = 'HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST';
export const HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS = 'HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS';
export const HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL = 'HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL';

export const HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST = 'HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST';
export const HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS = 'HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS';
export const HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL = 'HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL';

export const HAZI_WHATSAPP_WEBHOOK_READ_REQUEST = 'HAZI_WHATSAPP_WEBHOOK_READ_REQUEST';
export const HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS = 'HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS';
export const HAZI_WHATSAPP_WEBHOOK_READ_FAIL = 'HAZI_WHATSAPP_WEBHOOK_READ_FAIL';

export const HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST = 'HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST';
export const HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS = 'HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS';
export const HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL = 'HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL';

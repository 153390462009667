import {
    CLIENTS_DETAILS_FAIL,
    CLIENTS_DETAILS_REQUEST,
    CLIENTS_DETAILS_SUCCESS,
    CLIENTS_LIST_FAIL,
    CLIENTS_LIST_REQUEST,
    CLIENTS_LIST_SUCCESS,
    CLIENT_LIST_RESET,
    CLIENTS_DELETE_FAIL,
    CLIENTS_DELETE_REQUEST,
    CLIENTS_DELETE_SUCCESS,
    CLIENTS_CREATE_FAIL,
    CLIENTS_CREATE_REQUEST,
    CLIENTS_CREATE_SUCCESS,
    CLIENTS_CREATE_RESET,
    CLIENTS_UPDATE_FAIL,
    CLIENTS_UPDATE_REQUEST,
    CLIENTS_UPDATE_SUCCESS,
    CLIENTS_UPDATE_RESET,    
    AGENTS_BY_PRODUCT_REQUEST,
    AGENTS_BY_PRODUCT_SUCCESS,
    AGENTS_BY_PRODUCT_FAIL,
  } from '../constants/clientsConstants';
  
  export const clientsListReducer = (state = { clients: [] }, action) => {
    switch (action.type) {
      case CLIENTS_LIST_REQUEST:
        return { loading: true, clients: [] };
  
      case CLIENTS_LIST_SUCCESS:
        return { loading: false, clients: action.payload };
      case CLIENTS_LIST_FAIL:
        return { loading: false, error: action.payload };
      case CLIENT_LIST_RESET:
		    return { clients: [] }; 
      default:
        return state;
    }
  };
  
  export const clientsDetailsReducer = (state = { client: {} }, action) => {
    switch (action.type) {
      case CLIENTS_DETAILS_REQUEST:
        return { ...state, loading: true };
      case CLIENTS_DETAILS_SUCCESS:
        return { loading: false, client: action.payload.data }; 
      case CLIENTS_DETAILS_FAIL:
        return { loading: false, error: action.payload };  
      default:
        return state;
    }
  }; 
  
  export const clientsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case CLIENTS_DELETE_REQUEST:
        return { loading: true };
      case CLIENTS_DELETE_SUCCESS:
        return { loading: false, success: true };
      case CLIENTS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const clientsCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case CLIENTS_CREATE_REQUEST:
        return { loading: true };
      case CLIENTS_CREATE_SUCCESS:
        return { loading: false, success: true, client: action.payload };
      case CLIENTS_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case CLIENTS_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const clientUpdateReducer = (state = { client: {} }, action) => {
    switch (action.type) {
      case CLIENTS_UPDATE_REQUEST:
        return { loading: true };
      case CLIENTS_UPDATE_SUCCESS:
        return { loading: false, success: true, client: action.payload };
      case CLIENTS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case CLIENTS_UPDATE_RESET:
        return { client: {} };
      default:
        return state;
    }
  };

  export const agentsByProductReducer = (state = { agents: [] }, action) => {
    switch (action.type) {
      case AGENTS_BY_PRODUCT_REQUEST:
        return { ...state, loading: true, agents: [] };
      case AGENTS_BY_PRODUCT_SUCCESS:
        return { ...state, loading: false, agents: action.payload.data, success: true };
      case AGENTS_BY_PRODUCT_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  }; 
  
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { getProductInboundEscalatedCalls } from '../../actions/callDispositionActions';

const InboundAgentEscalations = () => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const product_id = userInfo?.product_id || '';

    useEffect(() => {
        if (userInfo) {
            dispatch(getProductInboundEscalatedCalls(product_id, searchInput));
        }
    }, [dispatch, userInfo, product_id, searchInput]);

    const inboundProductEscalatedCalls = useSelector((state) => state.inboundProductEscalatedCalls);
    const { escalatedCalls } = inboundProductEscalatedCalls || {};

    const itemsPerPage = 10;
    const totalPages = Math.ceil(escalatedCalls.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = escalatedCalls.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Box bg='white' borderRadius="sm" m={4}>
            <Flex justifyContent="space-between" mt="2">
                <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
                    Escalated Calls
                </Heading>
                <Input
                    type="text"
                    placeholder="Search..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    m={8}
                    maxWidth='500px'
                    borderColor='blue.400'
                    borderWidth='2px'
                />
            </Flex>

            {/* Escalated calls table */}
            <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>No.</Th>
                            <Th>Caller</Th>
                            <Th>Attended By</Th>
                            <Th>Reason</Th>
                            <Th>Description</Th>
                            <Th>Location</Th>
                            <Th>Client Name</Th>
                            <Th>Status</Th>
                            <Th>Callback Date</Th>
                            <Th>Escalation Step</Th>
                            <Th>Escalation Description</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems.map((call, index) => (
                            <Tr key={call.id}>
                                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                                <Td>{call.Caller}</Td>
                                <Td>{call.Attendedby}</Td>
                                <Td>{call.CallerReason}</Td>
                                <Td>{call.Description}</Td>
                                <Td>{call.CallerLocation}</Td>
                                <Td>{call.ClientName}</Td>
                                <Td>{call.Status}</Td>
                                <Td>{moment(call.CallbackDate).tz('Africa/Nairobi').format('dddd, MMMM Do YYYY, h:mm:ss a')}</Td>
                                <Td>{call.EscalationStep}</Td>
                                <Td>{call.EscalationDescription}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

            {/* Pagination */}
            <Flex justify="center" mt={4}>
                <Button
                    onClick={() => paginate(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    mr={2}
                >
                    Previous
                </Button>
                {[...Array(totalPages)].map((_, index) => (
                    <Button
                        key={index}
                        onClick={() => paginate(index + 1)}
                        variant={currentPage === index + 1 ? 'solid' : 'outline'}
                        mr={2}
                    >
                        {index + 1}
                    </Button>
                ))}
                <Button
                    onClick={() => paginate(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    ml={2}
                >
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default InboundAgentEscalations;

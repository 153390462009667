import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Heading,
    Icon,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    useDisclosure,
} from '@chakra-ui/react';
import { IoPencilSharp } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { getProductOutboundEscalatedCalls, updateOutboundEscalationDetails } from '../../actions/outboundDispositionAction';

const SupportEscalatedOutbound = () => {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [editedCall, setEditedCall] = useState(null);
    const [escalationStep, setEscalationStep] = useState('');
    const [escalationDescription, setEscalationDescription] = useState('');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const product_id = userInfo?.product_id || '';

    const getOutboundEscalatedCallsProduct = useSelector((state) => state.getOutboundEscalatedCallsProduct);
    const { escalatedCalls = [] } = getOutboundEscalatedCallsProduct || {};
   
    const { success: updateOutboundSuccess } = useSelector((state) => state.updateOutboundEscalationDetails);

    
    useEffect(() => {
        if (userInfo) {
            dispatch(getProductOutboundEscalatedCalls(product_id, searchInput));
        }
    }, [dispatch, userInfo, product_id, searchInput, updateOutboundSuccess]);

    const itemsPerPage = 10;
    const totalPages = Math.ceil(escalatedCalls.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = escalatedCalls.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEdit = (call) => {
        setEditedCall(call);
        setEscalationStep(call.EscalationStep);
        setEscalationDescription(call.EscalationDescription);
        onOpen();
    };

    const handleCloseModal = () => {
        setEditedCall(null);
        setEscalationStep('');
        setEscalationDescription('');
        onClose();
    };

    const handleUpdate = () => {
        if (editedCall) {
            dispatch(updateOutboundEscalationDetails(editedCall.id, escalationStep, escalationDescription))
                .then(() => {
                    dispatch(getProductOutboundEscalatedCalls(product_id, searchInput));
                    handleCloseModal();
                })
                .catch((error) => {
                    console.error('Error updating escalation details:', error);
                });
        }
    };

    const generatePageNumbers = () => {
        let pages = [];
        if (totalPages <= 5) {
            pages = [...Array(totalPages).keys()].map(i => i + 1);
        } else {
            if (currentPage <= 3) {
                pages = [1, 2, 3, '...', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
            } else {
                pages = [1, '...', currentPage, '...', totalPages];
            }
        }
        return pages;
    };

    return (
        <Box bg='white' borderRadius="sm" m={4}>
            <Flex justifyContent="space-between" mt="2">
                <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
                    Escalated Outbound Calls
                </Heading>
                <Input
                    type="text"
                    placeholder="Search..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    m={8}
                    maxWidth='500px'
                    borderColor='blue.400'
                    borderWidth='2px'
                />
            </Flex>

            {/* Escalated calls table */}
            <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
                <Table variant="striped" colorScheme="gray">
                    <Thead>
                        <Tr>
                            <Th>No.</Th>
                            <Th>Ticket</Th>                           
                            <Th>Caller</Th>
                            <Th>Attended By</Th>
                            <Th>Reason</Th>
                            <Th>Description</Th>
                            <Th>Escalation Step</Th>
                            <Th>Escalation Description</Th>
                            <Th>Escalated At</Th>                          
                            <Th>Action</Th> 
                            <Th>Location</Th>
                            <Th>Client Name</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems.map((call, index) => (
                            <Tr key={call.id}>
                                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                                <Td>{call.ticket_number}</Td>             
                                <Td>{call.Caller}</Td>
                                <Td>{call.Attendedby}</Td>
                                <Td>{call.CallerReason}</Td>
                                <Td>{call.Description}</Td>
                                <Td>{call.EscalationStep}</Td>
                                <Td>{call.EscalationDescription}</Td>
                                <Td>{moment(call.created_at).tz('Africa/Nairobi').format('dddd, MMMM Do YYYY, h:mm:ss a')}</Td>                                
                                <Td>
                                    <Button 
                                        colorScheme="teal"
                                        bg='blue.500'
                                        mr="2"
                                        onClick={() => handleEdit(call)}
                                    >
                                        <Icon as={IoPencilSharp} />
                                    </Button>
                                </Td>                   
                                <Td>{call.CallerLocation}</Td>
                                <Td>{call.ClientName}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

            {/* Pagination */}
            <Flex justify="center" mt={4}>
                <Button
                    onClick={() => paginate(currentPage - 1)}
                    isDisabled={currentPage === 1}
                    mr={2}
                >
                    Previous
                </Button>
                {generatePageNumbers().map((page, index) =>
                    typeof page === 'number' ? (
                        <Button
                            key={index}
                            onClick={() => paginate(page)}
                            variant={currentPage === page ? 'solid' : 'outline'}
                            mr={2}
                        >
                            {page}
                        </Button>
                    ) : (
                        <Button key={index} variant="outline" disabled>
                            {page}
                        </Button>
                    )
                )}
                <Button
                    onClick={() => paginate(currentPage + 1)}
                    isDisabled={currentPage === totalPages}
                    ml={2}
                >
                    Next
                </Button>
            </Flex>

            {/* Edit modal */}
            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Escalated Call</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb="4">
                            <FormLabel>Escalation Step</FormLabel>
                            <Select
                                value={escalationStep}
                                onChange={(e) => setEscalationStep(e.target.value)}
                            >
                                <option value="Pending">Pending</option>
                                <option value="In Progress">In Progress</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Fully Resolved">Fully Resolved</option>
                            </Select>
                        </FormControl>

                        <FormControl mb="4">
                            <FormLabel>Escalation Description</FormLabel>
                            <Input
                                type="text"
                                value={escalationDescription}
                                onChange={(e) => setEscalationDescription(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button colorScheme="green" onClick={handleUpdate}>
                            Update
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default SupportEscalatedOutbound;

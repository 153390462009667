import {
  Box,
  Container,
  Heading,
  Image,
  Grid,
  GridItem,
  Link,
} from "@chakra-ui/react";

import "./main.css";

const Features = () => {
  return (
    <Box id="portfolio" className="portfolio sections-bg" zIndex="1">
      <Container maxWidth="1366px" mx="auto" data-aos="fade-up">
        <Box className="section-header">
          <Heading mt={3}>Some Zuridesk Platform Features</Heading>
        </Box>

        <Box zIndex="1"
          className="portfolio-isotope"
          data-portfolio-filter="*"
          data-portfolio-layout="masonry"
          data-portfolio-sort="original-order"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Box>
            <ul className="portfolio-flters">
              <li data-filter="*" className="filter-active">
                ACD
              </li>
              <li data-filter=".filter-app">CS</li>
              <li data-filter=".filter-product">BI</li>
              <li data-filter=".filter-branding">AI</li>
              <li data-filter=".filter-books">CX</li>
            </ul>
          </Box>

          <Grid
            zIndex="1"
            m={2}
            p={4}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={4}
            className="portfolio-container"
          >
            <GridItem zIndex="1" colSpan={1} overflow="hidden" className="portfolio-item  filter-active">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box  className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Concurrent inbound, outbound
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-active">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Team SLAs KPIs OKRs
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-product">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Telesales
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-product">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Customer Service CRM
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-product">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Interactive Voice Response
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-product">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Callibration & Training
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-brandin">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Business Calls Management
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>
            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-brandin">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Business Intelligent Reports
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
            <GridItem zIndex="1" colSpan={1} className="portfolio-item filter-brandin">
              <Box className="portfolio-wrap">
                <Link
                  href="#"
                  data-gallery="portfolio-gallery-app"
                  className="glightbox"
                >
                  <Image src="assets/img/portfolio/product-1.jpg" alt="" />
                </Link>
                <Box className="portfolio-info">
                  <Heading as="h4">
                    <Link href="#" onClick={() => false} title="More Details">
                      Tagging & Disposition
                    </Link>
                  </Heading>
                </Box>
              </Box>
            </GridItem>

            {/* Add more portfolio items using the same structure */}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;

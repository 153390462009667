export const AGENT_OUTBOUNDSTATS_REQUEST = 'AGENT_OUTBOUNDSTATS_REQUEST';
export const AGENT_OUTBOUNDSTATS_SUCCESS = 'AGENT_OUTBOUNDSTATS_SUCCESS';
export const AGENT_OUTBOUNDSTATS_FAIL = 'AGENT_OUTBOUNDSTATS_FAIL';

export const AGENT_CALLSTATS_REQUEST = 'AGENT_CALLSTATS_REQUEST';
export const AGENT_CALLSTATS_SUCCESS = 'AGENT_CALLSTATS_SUCCESS';
export const AGENT_CALLSTATS_FAIL = 'AGENT_CALLSTATS_FAIL';

export const AGENT_DAILY_STATS_REQUEST = 'AGENT_DAILY_STATS_REQUEST';
export const AGENT_DAILY_STATS_SUCCESS = 'AGENT_DAILY_STATS_SUCCESS';
export const AGENT_DAILY_STATS_FAIL = 'AGENT_DAILY_STATS_FAIL';

export const ADMIN_INBOUND_STATS_REQUEST = 'ADMIN_INBOUND_STATS_REQUEST';
export const ADMIN_INBOUND_STATS_SUCCESS = 'ADMIN_INBOUND_STATS_SUCCESS';
export const ADMIN_INBOUND_STATS_FAIL = 'ADMIN_INBOUND_STATS_FAIL';

export const ADMIN_OUTBOUND_STATS_REQUEST = 'ADMIN_OUTBOUND_STATS_REQUEST';
export const ADMIN_OUTBOUND_STATS_SUCCESS = 'ADMIN_OUTBOUND_STATS_SUCCESS';
export const ADMIN_OUTBOUND_STATS_FAIL = 'ADMIN_OUTBOUND_STATS_FAIL';

export const SUPERVISOR_INBOUND_STATS_REQUEST = 'SUPERVISOR_INBOUND_STATS_REQUEST';
export const SUPERVISOR_INBOUND_STATS_SUCCESS = 'SUPERVISOR_INBOUND_STATS_SUCCESS';
export const SUPERVISOR_INBOUND_STATS_FAIL = 'SUPERVISOR_INBOUND_STATS_FAIL';

export const SUPERVISOR_OUTBOUND_STATS_REQUEST = 'SUPERVISOR_OUTBOUND_STATS_REQUEST';
export const SUPERVISOR_OUTBOUND_STATS_SUCCESS = 'SUPERVISOR_OUTBOUND_STATS_SUCCESS';
export const SUPERVISOR_OUTBOUND_STATS_FAIL = 'SUPERVISOR_OUTBOUND_STATS_FAIL';

export const SUPPORT_INBOUND_STATS_REQUEST = 'SUPPORT_INBOUND_STATS_REQUEST';
export const SUPPORT_INBOUND_STATS_SUCCESS = 'SUPPORT_INBOUND_STATS_SUCCESS';
export const SUPPORT_INBOUND_STATS_FAIL = 'SUPPORT_INBOUND_STATS_FAIL';

export const SUPPORT_INBOUND_REASON_STATS_REQUEST = 'SUPPORT_INBOUND_REASON_STATS_REQUEST';
export const SUPPORT_INBOUND_REASON_STATS_SUCCESS = 'SUPPORT_INBOUND_REASON_STATS_SUCCESS';
export const SUPPORT_INBOUND_REASON_STATS_FAIL = 'SUPPORT_INBOUND_REASON_STATS_FAIL';

export const SUPPORT_OUTBOUND_STATS_REQUEST = 'SUPPORT_OUTBOUND_STATS_REQUEST';
export const SUPPORT_OUTBOUND_STATS_SUCCESS = 'SUPPORT_OUTBOUND_STATS_SUCCESS';
export const SUPPORT_OUTBOUND_STATS_FAIL = 'SUPPORT_OUTBOUND_STATS_FAIL';

export const SUPPORT_OUTBOUND_REASON_STATS_REQUEST = 'SUPPORT_OUTBOUND_REASON_STATS_REQUEST';
export const SUPPORT_OUTBOUND_REASON_STATS_SUCCESS = 'SUPPORT_OUTBOUND_REASON_STATS_SUCCESS';
export const SUPPORT_OUTBOUND_REASON_STATS_FAIL = 'SUPPORT_OUTBOUND_REASON_STATS_FAIL';

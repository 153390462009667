import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Text,
    Stack,
    Spinner,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Select,
    useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import {
    getProductInboundEscalatedCalls,
    updateEscalationDetails,
} from '../../actions/callDispositionActions';
import {
    getProductOutboundEscalatedCalls,
    updateOutboundEscalationDetails,
} from '../../actions/outboundDispositionAction';

const TicketDetailsScreen = () => {
    const { ticket_number } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [editedCall, setEditedCall] = useState(null);
    const [escalationStep, setEscalationStep] = useState('');
    const [escalationDescription, setEscalationDescription] = useState('');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const product_id = userInfo?.product_id || '';

    const inboundProductEscalatedCalls = useSelector((state) => state.inboundProductEscalatedCalls || {});
    const outboundProductEscalatedCalls = useSelector((state) => state.outboundProductEscalatedCalls || {});

    const { loading: loadingInbound, escalatedCalls: inboundCalls } = inboundProductEscalatedCalls;
    const { loading: loadingOutbound, escalatedCalls: outboundCalls } = outboundProductEscalatedCalls;

    useEffect(() => {
        dispatch(getProductInboundEscalatedCalls(product_id));
        dispatch(getProductOutboundEscalatedCalls(product_id));
    }, [dispatch, product_id]);

    const ticketDetails = inboundCalls?.find(call => call.ticket_number === ticket_number)
        || outboundCalls?.find(call => call.ticket_number === ticket_number);

    const handleEdit = () => {
        setEditedCall(ticketDetails);
        setEscalationStep(ticketDetails?.EscalationStep || '');
        setEscalationDescription(ticketDetails?.EscalationDescription || '');
        onOpen();
    };

    const handleUpdate = () => {
        if (editedCall) {
            const updateAction =
                inboundCalls.includes(editedCall) ? updateEscalationDetails : updateOutboundEscalationDetails;
            dispatch(updateAction(editedCall.id, escalationStep, escalationDescription))
                .then(() => {
                    dispatch(getProductInboundEscalatedCalls(product_id));
                    dispatch(getProductOutboundEscalatedCalls(product_id));
                    onClose();
                })
                .catch((error) => {
                    console.error('Error updating escalation details:', error);
                });
        }
    };

    if (loadingInbound || loadingOutbound) {
        return <Spinner size="xl" />;
    }

    return (
        <Box p={6} maxW="1200px" bg="white" borderRadius="sm" m={8}>
            <Button onClick={() => navigate('/support-escalations')} mb={4}>
                Back
            </Button>
            {ticketDetails ? (
                <Box w={{ base: "80%", md: "60%" }} alignItems='center' justifyContent='center' py='5' mx='auto'>
                    <Text fontSize="xl" fontWeight="bold" mb={4} mt={4} ml={5}>
                        Ticket Details
                    </Text>
                    <Stack spacing={4} maxW="500px">
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Ticket:</Text>
                            <Text>{ticketDetails.ticket_number}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Caller:</Text>
                            <Text>{ticketDetails.Caller || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Attended By:</Text>
                            <Text>{ticketDetails.Attendedby || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Reason:</Text>
                            <Text>{ticketDetails.CallerReason || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Description:</Text>
                            <Text>{ticketDetails.Description || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Escalation Step:</Text>
                            <Text>{ticketDetails.EscalationStep || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Escalation Description:</Text>
                            <Text>{ticketDetails.EscalationDescription || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Escalated At:</Text>
                            <Text>
                                {ticketDetails.created_at 
                                    ? moment(ticketDetails.created_at).tz('Africa/Nairobi').format('dddd, MMMM Do YYYY, h:mm:ss a') 
                                    : 'N/A'}
                            </Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Location:</Text>
                            <Text>{ticketDetails.CallerLocation || 'N/A'}</Text>
                        </Flex>
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold">Client Name:</Text>
                            <Text>{ticketDetails.ClientName || 'N/A'}</Text>
                        </Flex>
                        <Button mt={4} mx='auto' w={{ base: "40%", md: "30%" }} colorScheme="blue" onClick={handleEdit}>
                            Update
                        </Button>
                    </Stack>
                </Box>
            ) : (
                <Text>Ticket not found.</Text>
            )}

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Escalation Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <FormControl mb="4">
                            <FormLabel>Escalation Step</FormLabel>
                            <Select
                                value={escalationStep}
                                onChange={(e) => setEscalationStep(e.target.value)}
                            >
                                <option value="Pending">Pending</option>
                                <option value="In Progress">In Progress</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Fully Resolved">Fully Resolved</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Escalation Description</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter description"
                                value={escalationDescription}
                                onChange={(e) => setEscalationDescription(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleUpdate}>
                            Update
                        </Button>
                        <Button variant="ghost" colorScheme="red" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default TicketDetailsScreen;

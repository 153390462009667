import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  Container,
  Card,
  CardBody,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { verifyHaziEmail } from "../../actions/haziSocialsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziVerifySendMailScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const haziEmailVerify = useSelector((state) => state.haziEmailVerify);
  const { loading, success, error } = haziEmailVerify;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (success) {
      setEmail("");
    }
  }, [success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(verifyHaziEmail({ email }));
  };

  return (
    <Box borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
          Mail Verification
        </Heading>
      </Flex>

      <Container bg="white" maxW="xl" mt={5}>
        <Card shadow="sm" border="0">
          <CardBody>
            <Heading as="h3" size="lg" textAlign="center" mb={4}>
              Verify Your Email
            </Heading>
            <Text textAlign="center" mb={4}>
              You will receive a confirmation email from AWS services. Click the link to activate your email.
            </Text>

            {loading && <Loader />}
            {error && <Message status="error">{error}</Message>}
            {success && (
              <Alert status="success" mb={4}>
                <AlertIcon />
                Verification email sent successfully!
              </Alert>
            )}

            <form onSubmit={submitHandler}>
              <FormControl mb={4} isRequired>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </FormControl>

              <Button type="submit" colorScheme="teal" width="full" isLoading={loading}>
                Verify Email
              </Button>
            </form>
          </CardBody>
        </Card>
      </Container>
    </Box>
  );
};

export default HaziVerifySendMailScreen;

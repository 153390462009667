import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Icon,
  Button,
  Spacer,
} from '@chakra-ui/react';
import { FaFacebook, FaArrowRight, FaFacebookMessenger } from 'react-icons/fa'; 

const SupervisorFacebook = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      senderName: 'John Doe',
      timestamp: '2023-01-01 12:00 PM',
      message: 'Hello! How can I help you?',
    },
    {
      id: 2,
      senderName: 'Jane Doe',
      timestamp: '2023-01-01 12:15 PM',
      message: 'Hi John! I have a question.',
    },
  ]);

  const handleSendMessage = () => {
    const newMessage = {
      id: messages.length + 1,
      senderName: 'Supervisor',
      timestamp: new Date().toLocaleString(),
      message: 'This is a simulated message from the supervisor.',
    };

    setMessages([...messages, newMessage]);
  };

  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading as="h2" size="md">
          Facebook Chats
        </Heading>
        <Flex alignItems="center">
          <Button
            colorScheme="blue"
            leftIcon={<Icon as={FaArrowRight} />}
            onClick={handleSendMessage}
            m={2}
          >
            Send Message
          </Button>
          <Spacer />
          <Button
            colorScheme="blue"
            leftIcon={<Icon as={FaFacebookMessenger} />}
            onClick={() => alert('Open Facebook Messenger')}
            m={2}
          >
            Open Messenger
          </Button>
        </Flex>
      </Flex>

      <Box>
        {messages.map((message) => (
          <Box
            key={message.id}
            border="1px"
            borderRadius="lg"
            borderColor="gray.300"
            p={4}
            mb={4}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Heading as="h3" size="sm">
                  {message.senderName}
                </Heading>
                <Text fontSize="sm" color="gray.500">
                  {message.timestamp}
                </Text>
              </Box>
              <Icon as={FaFacebook} boxSize={6} color="blue.500" />
            </Flex>
            <Text mt={2}>{message.message}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SupervisorFacebook;

import axios from 'axios';

import {
    FETCH_INSTANCE_ID_REQUEST,
    FETCH_INSTANCE_ID_SUCCESS,
    FETCH_INSTANCE_ID_FAILURE,
    FETCH_QR_REQUEST,
    FETCH_QR_SUCCESS,
    FETCH_QR_FAILURE,    
    SET_WEBHOOK_REQUEST,
    SET_WEBHOOK_SUCCESS,
    SET_WEBHOOK_FAILURE,
    REBOOT_INSTANCE_REQUEST,
    REBOOT_INSTANCE_SUCCESS,
    REBOOT_INSTANCE_FAILURE,
    RESET_INSTANCE_REQUEST,
    RESET_INSTANCE_SUCCESS,
    RESET_INSTANCE_FAILURE,
    RECONNECT_INSTANCE_REQUEST,
    RECONNECT_INSTANCE_SUCCESS,
    RECONNECT_INSTANCE_FAILURE,
    SEND_DIRECT_MESSAGE_REQUEST,
    SEND_DIRECT_MESSAGE_SUCCESS,
    SEND_DIRECT_MESSAGE_FAILURE,
    SEND_MEDIA_FILE_REQUEST,
    SEND_MEDIA_FILE_SUCCESS,
    SEND_MEDIA_FILE_FAILURE,
    SEND_GROUP_MESSAGE_REQUEST,
    SEND_GROUP_MESSAGE_SUCCESS,
    SEND_GROUP_MESSAGE_FAILURE,
    SEND_GROUP_MEDIA_FILE_REQUEST,
    SEND_GROUP_MEDIA_FILE_SUCCESS,
    SEND_GROUP_MEDIA_FILE_FAILURE,
} from '../constants/qrConstants';

export const fetchInstanceId = () => async (dispatch) => {
    dispatch({ type: FETCH_INSTANCE_ID_REQUEST });

    try {
        const { data } = await axios.get(`/api/whatsapp/get_instance_id`);

        dispatch({ type: FETCH_INSTANCE_ID_SUCCESS, payload: data.instance_id });
    } catch (error) {
        dispatch({
            type: FETCH_INSTANCE_ID_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const fetchQRCode = (instance_id, access_token) => async (dispatch) => {
    dispatch({ type: FETCH_QR_REQUEST });

    try {
        const { data } = await axios.get(`/api/whatsapp/get_qrcode`, {
            params: { instance_id, access_token },
        });

        dispatch({ type: FETCH_QR_SUCCESS, payload: data.base64 });
    } catch (error) {
        dispatch({
            type: FETCH_QR_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
    }
};

export const setWebhook = (webhook_url, instance_id, access_token) => async (dispatch) => {
    dispatch({ type: SET_WEBHOOK_REQUEST });

    try {
        const { data } = await axios.get(`/api/whatsapp/set_webhook`, {
            webhook_url,
            instance_id,
            access_token,
        });
        dispatch({ type: SET_WEBHOOK_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: SET_WEBHOOK_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const rebootInstance = (instance_id, access_token) => async (dispatch) => {
    dispatch({ type: REBOOT_INSTANCE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/reboot_instance`, {
            instance_id,
            access_token,
        });
        dispatch({ type: REBOOT_INSTANCE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: REBOOT_INSTANCE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const resetInstance = (instance_id, access_token) => async (dispatch) => {
    dispatch({ type: RESET_INSTANCE_REQUEST });
  
    try {
        const { data } = await axios.post(`/api/whatsapp/reset_instance`, {
            instance_id,
            access_token,
        });

        dispatch({ type: RESET_INSTANCE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: RESET_INSTANCE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const reconnectInstance = (instance_id, access_token) => async (dispatch) => {
    dispatch({ type: RECONNECT_INSTANCE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/reconnect_instance`, {
            instance_id,
            access_token,
        });
        dispatch({ type: RECONNECT_INSTANCE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: RECONNECT_INSTANCE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const sendDirectMessage = (number, type, message, instance_id, access_token) => async (dispatch) => {
    dispatch({ type: SEND_DIRECT_MESSAGE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/send_message`, {
            number,
            type,
            message,
            instance_id,   
            access_token, 
        });

        dispatch({ type: SEND_DIRECT_MESSAGE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: SEND_DIRECT_MESSAGE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const sendMediaFile = (number, type, message, media_url, instance_id, access_token) => async (dispatch) => {
    dispatch({ type: SEND_MEDIA_FILE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/send_media`, {
            number,
            type,
            message,
            media_url,
            instance_id,
            access_token,
        });

        dispatch({ type: SEND_MEDIA_FILE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: SEND_MEDIA_FILE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const sendGroupMessage = (group_id, type, message, instance_id, access_token) => async (dispatch) => {
    dispatch({ type: SEND_GROUP_MESSAGE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/send_group_message`, {
            group_id,
            type,
            message,
            instance_id,
            access_token,
        });

        dispatch({ type: SEND_GROUP_MESSAGE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: SEND_GROUP_MESSAGE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

export const sendGroupMediaFile = (group_id, type, message, media_url, instance_id, access_token) => async (dispatch) => {
    dispatch({ type: SEND_GROUP_MEDIA_FILE_REQUEST });

    try {
        const { data } = await axios.post(`/api/whatsapp/send_group_media`, {
            group_id,
            type,
            message,
            media_url,
            instance_id,
            access_token,
        });

        dispatch({ type: SEND_GROUP_MEDIA_FILE_SUCCESS, payload: data });
        return { success: true };
    } catch (error) {
        dispatch({
            type: SEND_GROUP_MEDIA_FILE_FAILURE,
            payload: error.response?.data?.message || error.message,
        });
        return { success: false };
    }
};

import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    Box,
    Spinner,
    Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserNotifications, markNotificationAsRead, markAllNotificationsAsRead } from '../actions/notificationActions';

const NotificationsModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    const userNotifications = useSelector((state) => state.userNotifications);
    const { loading, error, notifications } = userNotifications;

    useEffect(() => {
        if (isOpen) {
            dispatch(getUserNotifications());
        }
    }, [dispatch, isOpen]);

    const handleMarkAsRead = (id) => {
        dispatch(markNotificationAsRead(id)).then(() => {
            dispatch(getUserNotifications());
        }).catch(error => {
            console.error('Error marking notification as read:', error);
        });
    };

    const handleMarkAllAsRead = () => {
        dispatch(markAllNotificationsAsRead()).then(() => {
            dispatch(getUserNotifications());
        }).catch(error => {
            console.error('Error marking all notifications as read:', error);
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInLeft">
            <ModalOverlay />
            <ModalContent minWidth="300px" maxWidth={{ base: "60%", lg: "25%" }}>
                <ModalHeader>
                    Notifications
                    <Flex justifyContent="flex-end">
                        <Button size="sm" onClick={handleMarkAllAsRead}>
                            Mark All as Read
                        </Button>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody maxHeight="500px" overflowY="auto">
                    {loading ? (
                        <Spinner />
                    ) : error ? (
                        <Text>{error}</Text>
                    ) : (
                        <VStack spacing={4} align="stretch">
                            {Array.isArray(notifications.data) && notifications.data.map((notification) => (
                                <Box
                                    key={notification.id}
                                    p={4}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    bg={notification.status === 'read' ? 'teal.50' : 'white'}
                                    borderColor={notification.status === 'read' ? 'green.400' : 'red.400'}
                                >
                                    <Text fontWeight={notification.status === 'read' ? 'normal' : 'bold'} color='#130138'>{notification.subject}</Text>
                                    {notification.ticket_number && (
                                        <Text fontWeight="bold" color="gray.500">
                                            Ticket Number: {notification.ticket_number}
                                        </Text>
                                    )}
                                    <Text>{notification.message}</Text>
                                    {!notification.read && (
                                        <Button
                                            size="sm"
                                            mt={2}
                                            onClick={() => handleMarkAsRead(notification.id)}
                                            display={notification.status === 'unread' ? 'block' : 'none'}
                                        >
                                            Mark as read
                                        </Button>
                                    )}
                                </Box>                                                   
                            ))}
                        </VStack>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NotificationsModal;

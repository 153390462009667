import { Box, Flex, Heading, HStack, Link, Input, VStack, IconButton, Text, Table, Thead, Tr, Th, Tbody, Td, Button } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";

const HaziWhatsappScreen = () => {
  return (
    <Box bg="white" borderRadius="sm" m={4}>
      {/* Navbar */}
      <Box bg="blue.500" color="white" p={4}>
        <Flex justify="space-between" align="center">
          <HStack spacing={8}>
            <Link href="/trial/socials-h/whatsapp" display="flex" alignItems="center">
              <Text ml={2}>Broadcast</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-template" display="flex" alignItems="center">
              <Text ml={2}>Message Template</Text>
            </Link>
            <Link href="/trial/socials-h/whatsapp-chat" display="flex" alignItems="center">
              <Text ml={2}>Chat Box</Text>
            </Link>
          </HStack>
          <Link href="/trial/socials-h/whatsapp-accounts" display="flex" alignItems="center">
            <Text ml={2}>Connected Accounts</Text>
          </Link>
        </Flex>
      </Box>

      {/* Breadcrumb */}
      <Box px={8} py={4}>
        <Text fontSize="sm" color="gray.600">
          <Link href="https://hazi.co.ke/dashboard" color="blue.500">Dashboard</Link> / 
          <Link href="https://hazi.co.ke/whatsapp" color="blue.500"> WhatsApp</Link> / Broadcast
        </Text>
      </Box>

      {/* Main Content */}
      <VStack spacing={6} align="stretch" px={8}>
        {/* Search and Create Broadcast */}
        <Flex justify="space-between" align="center">
          <Flex align="center">
            <IconButton icon={<FaSearch />} aria-label="Search" />
            <Input placeholder="Search" ml={2} />
          </Flex>
          <Link href="https://hazi.co.ke/whatsapp/create-broadcast" bg="blue.500" color="white" p={2} borderRadius="md">
            Create Broadcast
          </Link>
        </Flex>

        {/* Card Section */}
        <Heading as="h6" fontSize="lg" mb={4}>Overview</Heading>
        <Flex wrap="wrap" gap={4}>
          {/* Sent Card */}
          <Box p={4} borderWidth={1} borderRadius="md" flex="1" textAlign="center">
            <Text fontSize="lg">Sent</Text>
            <Flex align="center" justify="space-between" mt={2}>
              <Text fontSize="2xl" fontWeight="bold">0</Text>
              <AiOutlineCheckCircle color="green.500" size={24} />
            </Flex>
          </Box>

          {/* Delivered Card */}
          <Box p={4} borderWidth={1} borderRadius="md" flex="1" textAlign="center">
            <Text fontSize="lg">Delivered</Text>
            <Flex align="center" justify="space-between" mt={2}>
              <Text fontSize="2xl" fontWeight="bold">0</Text>
              <AiOutlineCheckCircle color="green.500" size={24} />
            </Flex>
          </Box>

          {/* Read Card */}
          <Box p={4} borderWidth={1} borderRadius="md" flex="1" textAlign="center">
            <Text fontSize="lg">Read</Text>
            <Flex align="center" justify="space-between" mt={2}>
              <Text fontSize="2xl" fontWeight="bold">0</Text>
              <AiOutlineCheckCircle color="green.500" size={24} />
            </Flex>
          </Box>

          {/* Replied Card */}
          <Box p={4} borderWidth={1} borderRadius="md" flex="1" textAlign="center">
            <Text fontSize="lg">Replied</Text>
            <Flex align="center" justify="space-between" mt={2}>
              <Text fontSize="2xl" fontWeight="bold">0</Text>
              <AiOutlineCheckCircle color="green.500" size={24} />
            </Flex>
          </Box>
        </Flex>

        {/* Broadcast List Table Section */}
        <Box className="table-section" mt={8}>
          <Flex justify="space-between" align="center" mb={4}>
            <Heading as="h6" fontSize="lg">Broadcast List</Heading>
            <Flex align="center">
              <Button variant="outline" mr={2}>Sort by</Button>
              <Input placeholder="Search" />
            </Flex>
          </Flex>

          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Broadcast Name</Th>
                <Th>Date Sent</Th>
                <Th>Successful</Th>
                <Th>Read</Th>
                <Th>Replied</Th>
                <Th>Recipients</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {/* Example row */}
              <Tr>
                <Td>1</Td>
                <Td>Campaign Launch</Td>
                <Td>2024-11-13</Td>
                <Td>50</Td>
                <Td>30</Td>
                <Td>15</Td>
                <Td>100</Td>
                <Td>Completed</Td>
              </Tr>
              {/* Additional rows can be added here */}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Box>
  );
};

export default HaziWhatsappScreen;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaDownload, FaUpload } from "react-icons/fa";
import moment from "moment-timezone";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { listClientContacts, deleteClientContact } from "../../actions/clientContactsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import sampleData from "../../data/sampleFile";


const OutboundAllContacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const clientContactsList = useSelector((state) => state.clientContactsList);
  const { loading: contactsLoading, error: contactsError, contacts } = clientContactsList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(sampleData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'sample.csv');
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listClientContacts());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  const confirmDelete = (contact) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this contact?");
    if (isConfirmed) {
      handleDelete(contact);
    }
  };

  const handleDelete = async (contact) => {
    try {
      dispatch(deleteClientContact(contact.id, { deleted: 'yes' }));     
      dispatch(listClientContacts());
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentContacts = contacts && contacts.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Upload CSV
        </Heading>  
      </Flex>
      <Spacer h="2"  bg='blackAlpha.50'/>

        <Flex
            flexWrap="wrap"
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems="center"
            mr={8}
            ml={8}
            mt={8}
            >
           <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 4, md: 0 }}>
              <Text fontWeight="bold" mb={2}>Sample CSV file</Text>
              <Button onClick={handleDownloadCSV} colorScheme="blue">
                  <Icon as={FaDownload} size='sm' mr={2}/>Download
              </Button>
           </Flex>        

            
            <Flex flexDirection="column">
                <Button type="submit" backgroundColor="blue.500" color="white" colorScheme="blue">
                <Icon as={FaUpload} size='sm'/> Upload
                </Button>
            </Flex>
        </Flex>

      <Spacer h="2"  bg='blackAlpha.50'/>

      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize='lg' mb="5" ml={8} mt={4}>
          Available Contacts
        </Heading>  
      </Flex>
      {contactsLoading ? (
        <Loader />
      ) : contactsError? (
        <Message type="error">{contactsError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Campaign</Th>
                <Th>Phone</Th>
                <Th>Col 2</Th>
                <Th>Col 3</Th>
                <Th>Col 4</Th>
                <Th>Col 5</Th>
                <Th>Col 8</Th>
                <Th>Status</Th>                
                <Th>Date Uploaded</Th>
                <Th>Date Updated</Th>
                <Th>Action</Th>
              </Tr>              
            </Thead>           
            <Tbody>
            {currentContacts && currentContacts.length > 0 ? (
              currentContacts.map((contact, index) => (
                <Tr key={index} bg="white">
                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                <Td>{contact.campaign_id}</Td>
                <Td>{contact.col_1}</Td>
                <Td>{contact.col_2}</Td>
                <Td>{contact.col_3}</Td>
                <Td>{contact.col_4}</Td>
                <Td>{contact.col_5}</Td>
                <Td>{contact.col_8}</Td>
                <Td>{contact.status}</Td>
                <Td>
                  {moment(contact.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}
                  </Td>
                <Td>
                {moment(contact.updated_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}                  
                </Td>                
                <Td>
                  <Flex alignItems="center">
                    <Button                      
                      colorScheme="white"
                      bg='white'
                      onClick={() => confirmDelete(contact)}
                    >
                      <Icon as={FaTrash} outline={true} color="red"  size="md" />
                    </Button> 
                  </Flex>
                </Td>                    
              </Tr>
            
            ))
            ) : (
              <Tr>
                <Td colSpan="6" textAlign="center">
                  No data available
                </Td>
              </Tr>
            )}
            </Tbody>
          </Table>
          <Flex mt="4" justify="space-between" align="center">
          <Box>
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, contacts? contacts.length : 0)} of {contacts ? contacts.length : 0} entries
          </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {contacts && Array.isArray(contacts) && contacts.length > 0 && [...Array(Math.ceil(contacts.length / itemsPerPage))].map((_, index) => (
                <React.Fragment key={index}>
                  {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(contacts.length / itemsPerPage) ? (
                    <Button
                      key={index}
                      variant={currentPage === index + 1 ? "solid" : "outline"}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Button>
                  ) : (
                    index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                      <Box key={index}>...</Box>
                    ) : null
                  )}
                </React.Fragment>
              ))}
              <Button
                variant="outline"
                isDisabled={contacts ? currentPage === Math.ceil(contacts.length / itemsPerPage) : true}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </Button>
  
              </Box>
            </Flex>
          </Flex> 
          </Box>
      )}       
      <Spacer h="5" />
    </Box>
  );
};

export default OutboundAllContacts;

import { Grid, GridItem } from "@chakra-ui/react";

import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";

const Layout = () => {
  return (
    // <Grid px={10} spacing={2}>
    <Grid>
      <GridItem bg="blue.800" minHeight="100vh" position="fixed" w="3.3vw">
        <Sidebar />
      </GridItem>

      <GridItem minHeight="100vh" ml="3.3vw">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Input,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import {
  listDemoRequests
} from "../../actions/demoRequestActions"; 
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const DemoRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  

  const demoRequestsList = useSelector((state) => state.demoRequestsList);
  const { loading, error, demoRequests } = demoRequestsList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  
  
  const currentRequests = 
  demoRequests?.demoRequests?.slice(indexOfFirstItem, Math.min(indexOfLastItem, demoRequests?.demoRequests?.length)) || [];


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 
  useEffect(() => {
    if (userInfo) {     
      dispatch(listDemoRequests( searchQuery|| undefined))
      .catch((error) => {
        console.error('Error fetching demo requests:', error);
      });

    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, searchQuery, navigate]);

  return (
    <Box bg="whiteAlpha.500" borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
          Demo Requests
        </Heading>

        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          m={8}
          maxWidth='500px'
          borderColor='blue.400'
          borderWidth='2px'
        />        
      </Flex>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Company Name</Th>
                <Th>Phone</Th>
                <Th>Email</Th>
                <Th>Company Information</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentRequests.length > 0 ? (
                currentRequests.map((request, index) => (
                  <Tr key={request.id}>
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                    <Td>{request.fname}</Td>
                    <Td>{request.lname}</Td>
                    <Td>{request.company_name}</Td>
                    <Td>{request.phone}</Td>
                    <Td>{request.email}</Td>
                    <Td>{request.business_description}</Td>                   
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to {demoRequests.data ? Math.min(indexOfLastItem, demoRequests.data.length) : 0} of {demoRequests.data ? demoRequests.data.length : 0} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to {demoRequests.data ? Math.min(indexOfLastItem, demoRequests.data.length) : 0} of {demoRequests.data ? demoRequests.data.length : 0} entries
              </Box>
              <Flex>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {[...Array(Math.ceil((demoRequests.data?.length || 0) / itemsPerPage))].map((_, index) => (
                  <React.Fragment key={index}>
                    {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(demoRequests.data?.length / itemsPerPage) ? (
                      <Button
                        key={index}
                        variant={currentPage === index + 1 ? "solid" : "outline"}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : (
                      index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={currentPage === Math.ceil(demoRequests.data?.length / itemsPerPage)}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}

      <Spacer h="5" />      

    </Box>
  );
};

export default DemoRequests;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Input,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { listWhatsappOutboundsByClient } from '../../actions/whatsappOutboundActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const WhatsAppMessageClients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const whatsappOutboundProductDateRangeList = useSelector(
    (state) => state.whatsappOutboundProductDateRangeList
  );
  const { loading, error, whatsappOutbounds } = whatsappOutboundProductDateRangeList;

  useEffect(() => {
    if (userInfo) {
      dispatch(listWhatsappOutboundsByClient({ searchQuery, startDate, endDate }));
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, userInfo, searchQuery, startDate, endDate]);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentMessages = Array.isArray(whatsappOutbounds)
    ? whatsappOutbounds.slice(
        indexOfFirstItem,
        Math.min(indexOfLastItem, whatsappOutbounds.length)
      )
    : [];

  const totalPages = Math.ceil((whatsappOutbounds?.length || 0) / itemsPerPage);

  return (
    <Box p="4" bg="white" borderRadius="md" m={4}>
      <Flex justifyContent="space-between" mt={2} align="center">
        <Text fontSize="xl" fontWeight="bold" mb="4">
          WhatsApp Messages
        </Text>
        <Flex>
          <Input
            type="text"
            placeholder="Search product..."
            value={searchQuery}
            onChange={handleSearchChange}
            m={2}
            maxWidth="300px"
            borderColor="blue.400"
            borderWidth="2px"
          />
          <Input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            m={2}
            maxWidth="150px"
            borderColor="blue.400"
            borderWidth="2px"
          />
          <Input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            m={2}
            maxWidth="150px"
            borderColor="blue.400"
            borderWidth="2px"
          />
        </Flex>
      </Flex>

      <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Client</Th>
                  <Th>Product ID</Th>
                  <Th>Message Count</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentMessages.length > 0 ? (
                  currentMessages.map((message, index) => (
                    <Tr key={index}>
                      <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                      <Td>{message.client_name}</Td>
                      <Td>{message.product_id}</Td>
                      <Td>{message.message_count}</Td>                      
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="6" textAlign="center">
                      No messages found
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>

            <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to{' '}
                {whatsappOutbounds
                  ? Math.min(indexOfLastItem, whatsappOutbounds.length)
                  : 0}{' '}
                of{' '}
                {whatsappOutbounds ? whatsappOutbounds.length : 0}{' '}
                entries
              </Box>
              <Flex>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {[...Array(totalPages)].map((_, index) => (
                  <React.Fragment key={index}>
                    {(index + 1 === 1 ||
                    (index + 1 >= currentPage - 2 &&
                      index + 1 <= currentPage + 2) ||
                    index + 1 === totalPages) ? (
                      <Button
                        variant={
                          currentPage === index + 1 ? 'solid' : 'outline'
                        }
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : index + 1 === currentPage - 3 ||
                      index + 1 === currentPage + 3 ? (
                      <Box>...</Box>
                    ) : null}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={currentPage === totalPages}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Flex>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WhatsAppMessageClients;

import { useState, useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  VStack,
  Text,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  keyframes,
} from '@chakra-ui/react';
import { FaRegFrown, FaRedo, FaWhatsappSquare, FaRegSadTear } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchInstanceId,
  fetchQRCode,
  setWebhook,
  rebootInstance,
  resetInstance,
  reconnectInstance,
} from '../../actions/qrActions';
import { listClientDetails, updateClient } from '../../actions/clientsActions';
import { getUserDetails } from '../../actions/userActions';
import { CLIENTS_UPDATE_RESET } from "../../constants/clientsConstants";
import FormContainer from '../../components/FormContainer';

const moveAnimation = keyframes`
0% { transform: translateY(0); }
50% { transform: translateY(-20px); }
100% { transform: translateY(0); }
`;

const rotateAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;


const QRCodeScanner = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const access_token = userInfo?.access_token;
  const instance_id = userInfo?.instance_id
  const clientId = userInfo?.clientId;

  const [clientData, setClientData] = useState({
    id: clientId,
    instance_id: "",
  });

  const {  instanceId: fetchedInstanceId, error: instanceIdError, loading: instanceIdLoading } = useSelector((state) => state.instanceId);
  const { qrCode, error: qrError, loading: qrLoading } = useSelector(state => state.qr);
  const { success: webhookSuccess, error: webhookError, loading: webhookLoading } = useSelector(state => state.setWebhook);
  const { success: rebootSuccess, error: rebootError, loading: rebootLoading } = useSelector(state => state.rebootInstance);
  const { success: resetSuccess, error: resetError, loading: resetLoading } = useSelector(state => state.resetInstance);
  const { success: reconnectSuccess, error: reconnectError, loading: reconnectLoading } = useSelector(state => state.reconnectInstance);

  const [webhook_url, setWebhookUrl] = useState('https://zuridesk.com/api/webhook/whatsapp-notify');
  const [showQRCodeFields, setShowQRCodeFields] = useState(false);
  const [showWebhookField, setShowWebhookField] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const handleClientInstanceSave = async () => {
    if (clientData.id) {
      dispatch(updateClient(clientData.id, clientData));
      dispatch(getUserDetails()); 
      dispatch(listClientDetails(clientId));
    } else {
      dispatch({ type: CLIENTS_UPDATE_RESET });
    }
  };

  const handleGetInstanceId = async () => {
    dispatch(fetchInstanceId());
    if (fetchedInstanceId) {
      setClientData((prevData) => ({ ...prevData, instance_id: fetchedInstanceId }));
      handleClientInstanceSave();
    }
  };

  const handleGetQRCode = (e) => {
    e.preventDefault();
    if (fetchedInstanceId && access_token) {
      dispatch(fetchQRCode(fetchedInstanceId, access_token));
    }
  };

  const handleConfirmAction = (action) => {
    setConfirmAction(action);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setConfirmAction(null);
  };

  const handleActionConfirmed = () => {
    if (confirmAction === 'setWebhook') {
      dispatch(setWebhook(webhook_url, instance_id, access_token));
    } else if (confirmAction === 'rebootInstance') {
      dispatch(rebootInstance(instance_id, access_token));
    } else if (confirmAction === 'resetInstance') {
      dispatch(resetInstance(instance_id, access_token));
    } else if (confirmAction === 'reconnectInstance') {
      dispatch(reconnectInstance(instance_id, access_token));
    }
    onClose();
  };

  return (
    <Box bg="white" borderRadius="sm" m={4}>
      <Flex w={{ base: "80%", md: "60%" }} alignItems='center' justifyContent='center' py='5' mx='auto'>
        <FormContainer>
          <Image
            src={require('../../images/scan.gif')}
            alt=''
            display={{ base: 'block', md: 'block' }}
            w={{ base: '80%', md: '50%' }}
            objectFit='cover'
            marginTop={0}
            alignSelf='center'
            borderRadius="45px"
          />
          <Heading as='h1' mb='8' fontSize='3xl' color='blue.500' display='flex'>
            WhatsApp Scanner
          </Heading>
          {userInfo?.isWhatsappEnabled ? (
            <><VStack spacing={4} align="stretch">
              <Button
                onClick={handleGetInstanceId} 
                colorScheme="blue"
                width="full"
                isLoading={instanceIdLoading}
                mb={4}
              >
                Get Instance ID
              </Button>
              {showQRCodeFields && (
                <>
                  <FormControl id="instance-id" isRequired>
                    <FormLabel>Instance ID</FormLabel>
                    <Input type="text" value={fetchedInstanceId ||  instance_id || ''} isReadOnly />
                  </FormControl>
                  <FormControl id="access-token" isRequired>
                    <FormLabel>Access Token</FormLabel>
                    <Input
                      type="text"
                      value={access_token}
                    />
                  </FormControl>
                  <Button
                    onClick={handleGetQRCode}
                    colorScheme="green"
                    width="full"
                    isLoading={qrLoading}
                  >
                    Get QR Code
                  </Button>
                </>
              )}
              {!showQRCodeFields && (
                <Button
                  onClick={() => setShowQRCodeFields(true)}
                  colorScheme="blue"
                  width="full"
                >
                  Show QR Code Inputs
                </Button>
              )}
              {showWebhookField && (
                <>
                  <FormControl id="webhook-url">
                    <FormLabel>Webhook URL</FormLabel>
                    <Input
                      type="text"
                      value={webhook_url}
                      isReadOnly 
                      onChange={(e) => setWebhookUrl(e.target.value)} />
                  </FormControl>
                </>
              )}
              {userInfo && (userInfo.role === 'super-admin' || userInfo.role === 'admin') && (
                <Button
                  onClick={() => {
                    setShowWebhookField(true);
                    handleConfirmAction('setWebhook');
                  }}
                  colorScheme="pink"
                  width="full"
                  isLoading={webhookLoading}
                  mt={4}
                >
                  Set Webhook
                </Button>
              )}
              <Button
                onClick={() => handleConfirmAction('rebootInstance')}
                colorScheme="orange"
                width="full"
                isLoading={rebootLoading}
                mt={4}
              >
                Reboot Instance
              </Button>
              <Button
                onClick={() => handleConfirmAction('resetInstance')}
                colorScheme="cyan"
                color="white"
                width="full"
                isLoading={resetLoading}
                mt={4}
              >
                Reset Instance
              </Button>
              <Button
                onClick={() => handleConfirmAction('reconnectInstance')}
                colorScheme="purple"
                width="full"
                isLoading={reconnectLoading}
                mt={4}
              >
                Reconnect Instance
              </Button>
            </VStack>
            <Box mt={4} textAlign="center" mx="auto">
              {instanceIdError && <Box color="red.500" mt={4}>{instanceIdError}</Box>}
              {qrError && <Box color="red.500" mt={4}>{qrError}</Box>}
              {webhookError && <Box color="red.500" mt={4}>{webhookError}</Box>}
              {rebootError && <Box color="red.500" mt={4}>{rebootError}</Box>}
              {resetError && <Box color="red.500" mt={4}>{resetError}</Box>}
              {reconnectError && <Box color="red.500" mt={4}>{reconnectError}</Box>}
              {qrCode && <Image src={qrCode} alt="QR Code" mt={4} />}
              {webhookSuccess && <Text color="green.500" mt={4}>Webhook set successfully!</Text>}
              {rebootSuccess && <Text color="green.500" mt={4}>Instance rebooted successfully!</Text>}
              {resetSuccess && <Text color="green.500" mt={4}>Instance reset successfully!</Text>}
              {reconnectSuccess && <Text color="green.500" mt={4}>Instance reconnected successfully!</Text>}
            </Box></>
          ) : (
            <>
              <Text fontSize="xl" color="gray.600" textAlign="center">
                Your WhatsApp is not integrated yet.
              </Text>
              <Box className="icon-container" display="flex" gap={4} mt={8} mx="auto">
                <Icon as={FaRegFrown} boxSize={12} animation={`${moveAnimation} 2s infinite`} color="teal.500" />
                <Icon as={FaRedo} boxSize={12} animation={`${rotateAnimation} 2s infinite linear`} color="yellow.500" />
                <Icon as={FaWhatsappSquare} boxSize={12} animation={`${moveAnimation} 2s infinite 1s`} color="blue.500" />
                <Icon as={FaRegSadTear} boxSize={12} animation={`${moveAnimation} 2s infinite 1.5s`} color="green.500" />
              </Box>
            </>
          )}
        </FormContainer>
      </Flex>
      
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Confirm Action
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to proceed with this action?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleActionConfirmed} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default QRCodeScanner;

// constants/webSocketConstants.js



export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WEBSOCKET_MESSAGE_RECEIVED = 'WEBSOCKET_MESSAGE_RECEIVED';
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGE_SENT = 'MESSAGE_SENT';

export const MESSAGE_SENDING = 'MESSAGE_SENDING';  // Message is in the process of being sent
export const MESSAGE_SENT_SUCCESS = 'MESSAGE_SENT_SUCCESS';  // Message was successfully sent
export const MESSAGE_SENT_FAILURE = 'MESSAGE_SENT_FAILURE';

export const TYPING_START = 'TYPING_START';  // When an agent starts typing
export const TYPING_STOP = 'TYPING_STOP';    // When an agent stops typing

import {
    ABANDONEDCALLS_DETAILS_FAIL,
    ABANDONEDCALLS_DETAILS_REQUEST,
    ABANDONEDCALLS_DETAILS_SUCCESS,
    ABANDONEDCALLS_LIST_FAIL,
    ABANDONEDCALLS_LIST_REQUEST,
    ABANDONEDCALLS_LIST_SUCCESS,
    ABANDONEDCALLS_DELETE_FAIL,
    ABANDONEDCALLS_DELETE_REQUEST,
    ABANDONEDCALLS_DELETE_SUCCESS,
    ABANDONEDCALLS_CREATE_FAIL,
    ABANDONEDCALLS_CREATE_REQUEST,
    ABANDONEDCALLS_CREATE_SUCCESS,
    ABANDONEDCALLS_CREATE_RESET,
    ABANDONEDCALLS_UPDATE_FAIL,
    ABANDONEDCALLS_UPDATE_REQUEST,
    ABANDONEDCALLS_UPDATE_SUCCESS,
    ABANDONEDCALLS_UPDATE_RESET,
  } from '../constants/abandonedCallsConstants';
  
  export const abandonedCallsListReducer = (
    state = { ABANDONEDcalls: [] },
    action
  ) => {
    switch (action.type) {
        case ABANDONEDCALLS_LIST_REQUEST:
            return { loading: true, abandonedcalls: [] };
    
        case ABANDONEDCALLS_LIST_SUCCESS:
            return { loading: false, abandonedcalls: action.payload };
        case ABANDONEDCALLS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
  };
  
export const abandonedCallsDetailsReducer = (
    state = { permission: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
        case ABANDONEDCALLS_DETAILS_REQUEST:
            return { ...state, loading: true };
        case ABANDONEDCALLS_DETAILS_SUCCESS:
           return { loading: false, permission: action.payload };
       case ABANDONEDCALLS_DETAILS_FAIL:
         return { loading: false, error: action.payload };
  
       default:
         return state;
     }
};
  
export const abandonedCallsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case ABANDONEDCALLS_DELETE_REQUEST:
        return { loading: true };
      case ABANDONEDCALLS_DELETE_SUCCESS:
        return { loading: false, success: true };
      case ABANDONEDCALLS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};
  
export const abandonedCallsCreateReducer = (state = {}, action) => {
     switch (action.type) {
       case ABANDONEDCALLS_CREATE_REQUEST:
         return { loading: true };
       case ABANDONEDCALLS_CREATE_SUCCESS:
         return { loading: false, success: true, permission: action.payload };
       case ABANDONEDCALLS_CREATE_FAIL:
         return { loading: false, error: action.payload };
       case ABANDONEDCALLS_CREATE_RESET:
         return {};
       default:
         return state;
     }
};
  
export const abandonedCallsUpdateReducer = (state = { permission: {} }, action) => {
   switch (action.type) {
       case ABANDONEDCALLS_UPDATE_REQUEST:
         return { loading: true };
       case ABANDONEDCALLS_UPDATE_SUCCESS:
         return { loading: false, success: true, permission: action.payload };
       case ABANDONEDCALLS_UPDATE_FAIL:
         return { loading: false, error: action.payload };
       case ABANDONEDCALLS_UPDATE_RESET:
         return {};
       default:
         return state;
     }
};
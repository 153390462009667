import { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Grid,
  GridItem,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createOutboundDisposition } from '../../actions/outboundDispositionAction';
import { getProductReasons } from '../../actions/reasonsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const OutboundDisposition = ({ isOpen, onClose, callDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productId = userInfo?.product_id || '';

  const productReasons = useSelector((state) => state.productReasons);
  const { reasons } = productReasons || {};

  useEffect(() => {
    if (userInfo) {
      dispatch(getProductReasons(productId));
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, userInfo, productId]);

  const { log_uniqueid, agent, agent_code, msisdn } = callDetails || {};

  const outboundDispositionCreate = useSelector((state) => state.outboundDispositionCreate);
  const { loading, error, outboundDisposition } = outboundDispositionCreate;

  const initialFormData = {
    reason: '',
    sub_reason: '',
    customer_feedback: 'Positive',
    client_name: '',
    location: '',
    callback_date: '',
    description: '',
    action: 'Disposition',
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      log_uniqueid: log_uniqueid || '',
      agent: agent || '',
      agent_code: agent_code || '',
      msisdn: msisdn || '',
    }));
  }, [log_uniqueid, agent, agent_code, msisdn]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      log_uniqueid,
      agent,
      agent_code,
      msisdn,
      product_id: productId,
    };

    dispatch(createOutboundDisposition(updatedFormData, log_uniqueid, agent_code, msisdn, agent, productId));
  };

  useEffect(() => {
    if (outboundDisposition) {
      console.log('Outbound Call disposition created successfully!');
      onClose();
    }
  }, [outboundDisposition, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Outbound Call Disposition</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading && <Loader />}
          {error && <Message type="error">{error}</Message>}
          <form onSubmit={handleSubmit}>
            <Grid templateColumns="repeat(1, 1fr)" gap={6}>
              <GridItem>
                <FormControl>
                  <FormLabel>Reason</FormLabel>
                  <Select
                    placeholder="Select/Search Reason"
                    name="reason"
                    onChange={handleChange}
                    isRequired
                    value={formData.reason}
                  >
                    {reasons &&
                      reasons.data &&
                      reasons.data
                        .filter((reason) => reason.type === 'outbound')
                        .map((reason) => (
                          <option key={reason.id} value={reason.reason}>
                            {reason.reason}
                          </option>
                        ))}
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Call Tag</FormLabel>
                  <Input type="text" name="sub_reason" onChange={handleChange} value={formData.sub_reason} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Client Name</FormLabel>
                  <Input type="text" name="client_name" onChange={handleChange} value={formData.client_name} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Location</FormLabel>
                  <Input type="text" name="location" onChange={handleChange} value={formData.location} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Schedule Callback</FormLabel>
                  <Input type="datetime-local" name="callback_date" onChange={handleChange} value={formData.callback_date} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Dispositioned: no Action</FormLabel>
                  <Select
                    name="action"
                    onChange={handleChange}
                    value={formData.action || 'Disposition'}
                  >
                    <option value="Disposition">Disposition</option>
                    <option value="Escalate">Escalate</option>
                  </Select>
                </FormControl>
              </GridItem>
              
              <GridItem>
                <FormControl>
                  <FormLabel>Customer Feedback</FormLabel>
                  <Select
                    name="customer_feedback"
                    onChange={handleChange}
                    value={formData.customer_feedback}
                  >
                    <option value="Positive">Positive</option>
                    <option value="Negative">Negative</option>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder="Describe why the customer called"
                    name="description"
                    onChange={handleChange}
                    value={formData.description}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </form>
        </ModalBody>
        <ModalFooter>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button
              type="submit"
              size="md"
              variant="outline"
              colorScheme="primary"
              _hover={{ bg: 'primary.500', color: 'blue.500' }}
              isLoading={loading}
              onClick={handleSubmit}
            >
              Save changes
            </Button>

            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OutboundDisposition;

import axios from 'axios';

import {
    DEMO_REQUESTS_LIST_REQUEST,
    DEMO_REQUESTS_LIST_SUCCESS,
    DEMO_REQUESTS_LIST_FAIL,
    DEMO_REQUEST_CREATE_REQUEST,
    DEMO_REQUEST_CREATE_SUCCESS,
    DEMO_REQUEST_CREATE_FAIL,
} from '../constants/demoRequestConstants';


export const listDemoRequests = (searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: DEMO_REQUESTS_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/demo_requests?search=${searchQuery}`, config);

        dispatch({ type: DEMO_REQUESTS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DEMO_REQUESTS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const createDemoRequest = (demoRequestData) => async (dispatch) => {
    try {
        dispatch({ type: DEMO_REQUEST_CREATE_REQUEST });

        const { data } = await axios.post('/api/demo_requests', demoRequestData);

        dispatch({ type: DEMO_REQUEST_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: DEMO_REQUEST_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

import {
    HAZI_PROFILE_REQUEST,
    HAZI_PROFILE_SUCCESS,
    HAZI_PROFILE_FAIL,
    HAZI_BALANCE_REQUEST,
    HAZI_BALANCE_SUCCESS,
    HAZI_BALANCE_FAIL,    
    HAZI_EMAIL_VERIFY_REQUEST,
    HAZI_EMAIL_VERIFY_SUCCESS,
    HAZI_EMAIL_VERIFY_FAIL,
    HAZI_EMAIL_SEND_REQUEST,
    HAZI_EMAIL_SEND_SUCCESS,
    HAZI_EMAIL_SEND_FAIL,
    HAZI_EMAIL_BULK_SEND_REQUEST,
    HAZI_EMAIL_BULK_SEND_SUCCESS,
    HAZI_EMAIL_BULK_SEND_FAIL,
    HAZI_SMS_SEND_REQUEST,
    HAZI_SMS_SEND_SUCCESS,
    HAZI_SMS_SEND_FAIL,    
    HAZI_SMS_BULK_SEND_REQUEST,
    HAZI_SMS_BULK_SEND_SUCCESS,
    HAZI_SMS_BULK_SEND_FAIL,
    HAZI_CONTACT_GROUPS_LIST_REQUEST,
    HAZI_CONTACT_GROUPS_LIST_SUCCESS,
    HAZI_CONTACT_GROUPS_LIST_FAIL,
    HAZI_CONTACT_GROUP_CREATE_REQUEST,
    HAZI_CONTACT_GROUP_CREATE_SUCCESS,
    HAZI_CONTACT_GROUP_CREATE_FAIL,
    HAZI_CONTACT_GROUP_EDIT_REQUEST,
    HAZI_CONTACT_GROUP_EDIT_SUCCESS,
    HAZI_CONTACT_GROUP_EDIT_FAIL,
    HAZI_CONTACT_GROUP_DELETE_REQUEST,
    HAZI_CONTACT_GROUP_DELETE_SUCCESS,
    HAZI_CONTACT_GROUP_DELETE_FAIL,
    HAZI_ADD_CONTACTS_TO_GROUP_REQUEST,
    HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS,
    HAZI_ADD_CONTACTS_TO_GROUP_FAIL,
    HAZI_CONTACTS_LIST_IN_GROUP_REQUEST,
    HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS,
    HAZI_CONTACTS_LIST_IN_GROUP_FAIL,
    HAZI_CONTACT_UPDATE_REQUEST,
    HAZI_CONTACT_UPDATE_SUCCESS,
    HAZI_CONTACT_UPDATE_FAIL,
    HAZI_CONTACT_DELETE_REQUEST,
    HAZI_CONTACT_DELETE_SUCCESS,
    HAZI_CONTACT_DELETE_FAIL,
    HAZI_WHATSAPP_TEMPLATES_REQUEST,
    HAZI_WHATSAPP_TEMPLATES_SUCCESS,
    HAZI_WHATSAPP_TEMPLATES_FAIL,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS,
    HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS,
    HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS,
    HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS,
    HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL,
    HAZI_WHATSAPP_BROADCASTS_REQUEST,
    HAZI_WHATSAPP_BROADCASTS_SUCCESS,
    HAZI_WHATSAPP_BROADCASTS_FAIL,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS,
    HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL,
    HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST,
    HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS,
    HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL,
    HAZI_WHATSAPP_WEBHOOK_READ_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_READ_FAIL,
    HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST,
    HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS,
    HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL,
} from '../constants/haziSocialsConstants';

export const haziProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_PROFILE_REQUEST:
            return { loading: true };
        case HAZI_PROFILE_SUCCESS:
            return { loading: false, profile: action.payload };
        case HAZI_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziBalanceReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_BALANCE_REQUEST:
            return { loading: true };
        case HAZI_BALANCE_SUCCESS:
            return { loading: false, balance: action.payload };
        case HAZI_BALANCE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziEmailVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_EMAIL_VERIFY_REQUEST:
            return { loading: true };
        case HAZI_EMAIL_VERIFY_SUCCESS:
            return { loading: false, success: true, data: action.payload };
        case HAZI_EMAIL_VERIFY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziEmailSendReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_EMAIL_SEND_REQUEST:
            return { loading: true };
        case HAZI_EMAIL_SEND_SUCCESS:
            return { loading: false, success: true };
        case HAZI_EMAIL_SEND_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziBulkEmailSendReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_EMAIL_BULK_SEND_REQUEST:
            return { loading: true };
        case HAZI_EMAIL_BULK_SEND_SUCCESS:
            return { loading: false, success: true, data: action.payload };
        case HAZI_EMAIL_BULK_SEND_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziSmsSendReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_SMS_SEND_REQUEST:
            return { loading: true };
        case HAZI_SMS_SEND_SUCCESS:
            return { loading: false, success: true };
        case HAZI_SMS_SEND_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziBulkSmsSendReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_SMS_BULK_SEND_REQUEST:
            return { loading: true };
        case HAZI_SMS_BULK_SEND_SUCCESS:
            return { loading: false, success: true, data: action.payload };
        case HAZI_SMS_BULK_SEND_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziContactGroupsListReducer = (state = { groups: [] }, action) => {
    switch (action.type) {
        case HAZI_CONTACT_GROUPS_LIST_REQUEST:
            return { loading: true, groups: [] };
        case HAZI_CONTACT_GROUPS_LIST_SUCCESS:
            return { loading: false, groups: action.payload };
        case HAZI_CONTACT_GROUPS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziCreateContactGroupReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_CONTACT_GROUP_CREATE_REQUEST:
            return { loading: true };
        case HAZI_CONTACT_GROUP_CREATE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_CONTACT_GROUP_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziEditContactGroupReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_CONTACT_GROUP_EDIT_REQUEST:
            return { loading: true };
        case HAZI_CONTACT_GROUP_EDIT_SUCCESS:
            return { loading: false, success: true };
        case HAZI_CONTACT_GROUP_EDIT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziDeleteContactGroupReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_CONTACT_GROUP_DELETE_REQUEST:
            return { loading: true };
        case HAZI_CONTACT_GROUP_DELETE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_CONTACT_GROUP_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziAddContactsToGroupReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_ADD_CONTACTS_TO_GROUP_REQUEST:
            return { loading: true };
        case HAZI_ADD_CONTACTS_TO_GROUP_SUCCESS:
            return { loading: false, success: true };
        case HAZI_ADD_CONTACTS_TO_GROUP_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziListContactsInGroupReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case HAZI_CONTACTS_LIST_IN_GROUP_REQUEST:
            return { loading: true, contacts: [] };
        case HAZI_CONTACTS_LIST_IN_GROUP_SUCCESS:
            return { loading: false, contacts: action.payload };
        case HAZI_CONTACTS_LIST_IN_GROUP_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziUpdateContactReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_CONTACT_UPDATE_REQUEST:
            return { loading: true };
        case HAZI_CONTACT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_CONTACT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziDeleteContactReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_CONTACT_DELETE_REQUEST:
            return { loading: true };
        case HAZI_CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappTemplatesReducer = (state = { templates: [] }, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_TEMPLATES_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_TEMPLATES_SUCCESS:
            return { loading: false, templates: action.payload };
        case HAZI_WHATSAPP_TEMPLATES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappCreateTextTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_CREATE_TEXT_TEMPLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappCreateMediaTemplateReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_CREATE_MEDIA_TEMPLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappSendTemplateMessageReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_SEND_TEMPLATE_MESSAGE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappSendSimpleMessageReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_SEND_SIMPLE_MESSAGE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsappBroadcastsReducer = (state = { broadcasts: [] }, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_BROADCASTS_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_BROADCASTS_SUCCESS:
            return { loading: false, broadcasts: action.payload };
        case HAZI_WHATSAPP_BROADCASTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsAppAccountsReducer = (state = { accounts: [] }, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_LINKED_ACCOUNTS_REQUEST:
            return { loading: true, accounts: [] };
        case HAZI_WHATSAPP_LINKED_ACCOUNTS_SUCCESS:
            return { loading: false, accounts: action.payload };
        case HAZI_WHATSAPP_LINKED_ACCOUNTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziOnboardClientReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_ONBOARD_CLIENT_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_ONBOARD_CLIENT_SUCCESS:
            return { loading: false, success: true, clientData: action.payload };
        case HAZI_WHATSAPP_ONBOARD_CLIENT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsAppWebhookDeliveredReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_WEBHOOK_DELIVERED_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_WEBHOOK_DELIVERED_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_WEBHOOK_DELIVERED_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsAppWebhookReadReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_WEBHOOK_READ_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_WEBHOOK_READ_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_WEBHOOK_READ_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const haziWhatsAppWebhookReplyReducer = (state = {}, action) => {
    switch (action.type) {
        case HAZI_WHATSAPP_WEBHOOK_REPLY_REQUEST:
            return { loading: true };
        case HAZI_WHATSAPP_WEBHOOK_REPLY_SUCCESS:
            return { loading: false, success: true };
        case HAZI_WHATSAPP_WEBHOOK_REPLY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

import axios from 'axios';
import {
  AGENT_OUTBOUNDSTATS_REQUEST,
  AGENT_OUTBOUNDSTATS_SUCCESS,
  AGENT_OUTBOUNDSTATS_FAIL,
  AGENT_CALLSTATS_REQUEST,
  AGENT_CALLSTATS_SUCCESS,
  AGENT_CALLSTATS_FAIL,
  AGENT_DAILY_STATS_REQUEST,
  AGENT_DAILY_STATS_SUCCESS,
  AGENT_DAILY_STATS_FAIL,
  ADMIN_INBOUND_STATS_REQUEST,
  ADMIN_INBOUND_STATS_SUCCESS,
  ADMIN_INBOUND_STATS_FAIL,
  ADMIN_OUTBOUND_STATS_REQUEST,
  ADMIN_OUTBOUND_STATS_SUCCESS,
  ADMIN_OUTBOUND_STATS_FAIL,
  SUPERVISOR_INBOUND_STATS_REQUEST,
  SUPERVISOR_INBOUND_STATS_SUCCESS,
  SUPERVISOR_INBOUND_STATS_FAIL,
  SUPERVISOR_OUTBOUND_STATS_REQUEST,
  SUPERVISOR_OUTBOUND_STATS_SUCCESS,
  SUPERVISOR_OUTBOUND_STATS_FAIL,
  SUPPORT_INBOUND_STATS_REQUEST,
  SUPPORT_INBOUND_STATS_SUCCESS,
  SUPPORT_INBOUND_STATS_FAIL,
  SUPPORT_INBOUND_REASON_STATS_REQUEST,
  SUPPORT_INBOUND_REASON_STATS_SUCCESS,
  SUPPORT_INBOUND_REASON_STATS_FAIL,
  SUPPORT_OUTBOUND_STATS_REQUEST,
  SUPPORT_OUTBOUND_STATS_SUCCESS,
  SUPPORT_OUTBOUND_STATS_FAIL,
  SUPPORT_OUTBOUND_REASON_STATS_REQUEST,
  SUPPORT_OUTBOUND_REASON_STATS_SUCCESS,
  SUPPORT_OUTBOUND_REASON_STATS_FAIL,
} from '../constants/statsConstants';

export const getAgentOutboundStats = (agentid) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_OUTBOUNDSTATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/agentlog/outbound/${agentid}`, config);

    dispatch({
      type: AGENT_OUTBOUNDSTATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGENT_OUTBOUNDSTATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAgentCallStats = (agentExt) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_CALLSTATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/agentlog/${agentExt}`, config);

    dispatch({
      type: AGENT_CALLSTATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGENT_CALLSTATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAgentDailyStats = (agent) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_DAILY_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/dailydisposition/${agent}`, config);

    dispatch({
      type: AGENT_DAILY_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGENT_DAILY_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAdminInboundStats = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_INBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/admin/inbound/stats', config);
    
    dispatch({
      type: ADMIN_INBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_INBOUND_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAdminOutboundStats = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMIN_OUTBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/admin/outbound/stats', config);

    dispatch({
      type: ADMIN_OUTBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_OUTBOUND_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSupervisorInboundStats = (queue_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPERVISOR_INBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/supervisor/inbound/stats/${queue_id}`, config);

    dispatch({
      type: SUPERVISOR_INBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPERVISOR_INBOUND_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSupervisorOutboundStats = (queue_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPERVISOR_OUTBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/supervisor/outbound/stats/${queue_id}`, config);

    dispatch({
      type: SUPERVISOR_OUTBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPERVISOR_OUTBOUND_STATS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getSupportInboundStats = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_INBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/support/inbound/stats/${productId}`, config);

    dispatch({
      type: SUPPORT_INBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPORT_INBOUND_STATS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getSupportInboundReasonStats = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_INBOUND_REASON_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/support/inbound/reasonstats/${productId}`, config);

    dispatch({
      type: SUPPORT_INBOUND_REASON_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPORT_INBOUND_REASON_STATS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getSupportOutboundStats = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_OUTBOUND_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/support/outbound/stats/${productId}`, config);

    dispatch({
      type: SUPPORT_OUTBOUND_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPORT_OUTBOUND_STATS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const getSupportOutboundReasonStats = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUPPORT_OUTBOUND_REASON_STATS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/support/outbound/reasonstats/${productId}`, config);

    dispatch({
      type: SUPPORT_OUTBOUND_REASON_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUPPORT_OUTBOUND_REASON_STATS_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};


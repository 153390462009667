import axios from "axios";
import {
  QUELOG_LIST_FAIL,
  QUELOG_LIST_REQUEST,
  QUELOG_LIST_SUCCESS,
  QUELOG_PRODUCT_LIST_FAIL,
  QUELOG_PRODUCT_LIST_REQUEST,
  QUELOG_PRODUCT_LIST_SUCCESS,
  QUELOG_ALL_LIST_FAIL,
  QUELOG_ALL_LIST_REQUEST,
  QUELOG_ALL_LIST_SUCCESS,
} from '../constants/queLogConstants';

export const listqueLog = () => async (dispatch, getState) => {
  try {
    dispatch({ type: QUELOG_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const agentName = userInfo && userInfo.name ? userInfo.name : "";

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fetchQueueLogData = async () => {
      try {
        const { data } = await axios.get(`/api/queuelog/${agentName}`, config);
        dispatch({ type: QUELOG_LIST_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: QUELOG_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

    await fetchQueueLogData();

    const pollingInterval = 10000; 
    const intervalId = setInterval(fetchQueueLogData, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (error) {
    dispatch({
      type: QUELOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listProductQueuelog = (productId) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUELOG_PRODUCT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/queuelog/product/${productId}`,
      config
    );

    dispatch({ type: QUELOG_PRODUCT_LIST_SUCCESS, payload: data });

    const pollingInterval = 10000; 
    const intervalId = setInterval(async () => {
      try {
        const { data } = await axios.get(
          `/api/queuelog/product/${productId}`,
          config
        );
        dispatch({ type: QUELOG_PRODUCT_LIST_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: QUELOG_PRODUCT_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (err) {
    dispatch({
      type: QUELOG_PRODUCT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAllQueuelog = () => async (dispatch, getState) => {
  try {
    dispatch({ type: QUELOG_ALL_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const fetchAllQueueLogData = async () => {
      try {
        const { data } = await axios.get(`/api/queuelog`, config);
        dispatch({ type: QUELOG_ALL_LIST_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: QUELOG_ALL_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

    await fetchAllQueueLogData();

    const pollingInterval = 10000; 
    const intervalId = setInterval(fetchAllQueueLogData, pollingInterval);

    return () => clearInterval(intervalId);
  } catch (error) {
    dispatch({
      type: QUELOG_ALL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

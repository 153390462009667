import axios from 'axios';

import {
    NOTIFICATIONS_LIST_REQUEST,
    NOTIFICATIONS_LIST_SUCCESS,
    NOTIFICATIONS_LIST_FAIL,
    NOTIFICATION_DETAILS_REQUEST,
    NOTIFICATION_DETAILS_SUCCESS,
    NOTIFICATION_DETAILS_FAIL,
    NOTIFICATION_DELETE_REQUEST,
    NOTIFICATION_DELETE_SUCCESS,
    NOTIFICATION_DELETE_FAIL,
    NOTIFICATION_CREATE_REQUEST,
    NOTIFICATION_CREATE_SUCCESS,
    NOTIFICATION_CREATE_FAIL,
    NOTIFICATION_UPDATE_REQUEST,
    NOTIFICATION_UPDATE_SUCCESS,
    NOTIFICATION_UPDATE_FAIL,
    USER_NOTIFICATIONS_REQUEST,
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAIL,
    MARK_NOTIFICATION_AS_READ_REQUEST,
    MARK_NOTIFICATION_AS_READ_SUCCESS,
    MARK_NOTIFICATION_AS_READ_FAIL,
    MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAIL,
} from '../constants/notificationConstants';

export const listNotifications = () => async (dispatch, getState) => {
    try {
        dispatch({ type: NOTIFICATIONS_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/notifications`, config);

        dispatch({ type: NOTIFICATIONS_LIST_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: NOTIFICATIONS_LIST_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const getNotificationDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: NOTIFICATION_DETAILS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/notifications/${id}`, config);

        dispatch({ type: NOTIFICATION_DETAILS_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: NOTIFICATION_DETAILS_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const deleteNotification = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: NOTIFICATION_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/notifications/${id}`, config);

        dispatch({ type: NOTIFICATION_DELETE_SUCCESS });
    } catch (err) {
        dispatch({
            type: NOTIFICATION_DELETE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const createNotification = (notification) => async (dispatch, getState) => {
    try {
        dispatch({ type: NOTIFICATION_CREATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/notifications`, notification, config);

        dispatch({ type: NOTIFICATION_CREATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: NOTIFICATION_CREATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const updateNotification = (id, notification) => async (dispatch, getState) => {
    try {
        dispatch({ type: NOTIFICATION_UPDATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`/api/notifications/${id}`, notification, config);

        dispatch({ type: NOTIFICATION_UPDATE_SUCCESS, payload: data });
    } catch (err) {
        dispatch({
            type: NOTIFICATION_UPDATE_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const getUserNotifications = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_NOTIFICATIONS_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/notifications/user/${userInfo.id}`, config);

        dispatch({ type: USER_NOTIFICATIONS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: USER_NOTIFICATIONS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const markNotificationAsRead = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MARK_NOTIFICATION_AS_READ_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.put(`/api/notifications/${id}/read`, {}, config);

        dispatch({ type: MARK_NOTIFICATION_AS_READ_SUCCESS });
    } catch (err) {
        dispatch({
            type: MARK_NOTIFICATION_AS_READ_FAIL,
            payload:
                err.response && err.response.data.message
                    ? err.response.data.message
                    : err.message,
        });
    }
};

export const markAllNotificationsAsRead = () => async (dispatch, getState) => {
    try {
      dispatch({ type: MARK_ALL_NOTIFICATIONS_AS_READ_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      await axios.put(`/api/notifications/mark-all-as-read/${userInfo.id}`, {}, config);
  
      dispatch({ type: MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS });
    } catch (err) {
      dispatch({
        type: MARK_ALL_NOTIFICATIONS_AS_READ_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };
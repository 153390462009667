import React, { useEffect, useState } from "react";
import {  
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";

import { listAllQueuelog } from '../../actions/queLogActions';
import { getAllCallHistory } from "../../actions/callDispositionActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const InboundAllCallLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const allInboundCallHistory  = useSelector((state) => state.allInboundCallHistory) ;
  const { loading: callHistoryLoading, error: callHistoryError, allCallHistory } = allInboundCallHistory ;


  const queLogAll = useSelector((state) => state.queLogAll);
  const { loading: queLogLoading, error: queLogError, allQueLog } = queLogAll || {};

  const allQueuelog = allQueLog && allQueLog.queuelog
  ? allQueLog.queuelog
      .map((log) => ({ ...log, id: log.id })) 
      .slice(indexOfFirstItem, Math.min(indexOfLastItem, allQueLog.queuelog.length))
  : [];
  

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {      
        dispatch(getAllCallHistory(searchInput))
        .catch((error) => {
          console.error('Error fetching call history:', error);
        });
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate, searchInput]);  
  
  useEffect(() => {

    if (userInfo) {  
     dispatch(listAllQueuelog());  
    } else {  
     navigate("/login");  
    }
  
   }, [dispatch, navigate, userInfo]);


  const [logDispositionInfo, setLogDispositionInfo] = useState({});

  useEffect(() => {  
      const fetchDispositionForLog = async (log) => {
          try {
              const response = await axios.get(`/api/queuelog/dispose/${log.id}`);
              const { dispositioned, reason, description } = response.data;
              setLogDispositionInfo((prevState) => ({
                  ...prevState,
                  [log.id]: { dispositioned, reason, description }
              }));
          } catch (error) {
              setError('Error fetching call disposition');
          }
      };
  
      const fetchDispositionForAllLogs = async () => {
          if (allQueLog && allQueLog.queuelog && allQueLog.queuelog.length > 0) {
                allQueLog.queuelog.forEach((log) => {
                  fetchDispositionForLog(log);
              });
          } else {
              console.log('queLog or queLog.queuelog is missing or empty');
              setLoading(false); 
          }
      };
  
      fetchDispositionForAllLogs();
  }, [allQueLog]);
  
  
  if (error) {
    return <Text>Error: {error}</Text>;
  }

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box p="4" m={2} alignItems="center" width={{ base: '100%', md: '100%' }} bg="white" borderRadius="md">
      <Box m={6} mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Previous Call History
        </Text>
        <Flex m={4}> 
          <Text fontSize="md" fontWeight="semibold" mb="4">
            Enter the number you want to search:
          </Text>
          <Input
            type="text"
            placeholder="Enter phone digits"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}          
            ml={8}
            maxWidth='300px'
            borderColor='blue.400'
            borderWidth='2px'
          />
        </Flex>
        {callHistoryLoading ? (
          <Loader />
        ) : callHistoryError ? (
          <Message type="error">{callHistoryError}</Message>
        ) : (
          <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Product</Th>
                  <Th>Attended by</Th>
                  <Th>Caller Name</Th>
                  <Th>Caller No</Th>
                  <Th>Caller Location</Th>
                  <Th>Date Created</Th>
                  <Th>Reason</Th>
                  <Th>Decription</Th>
                </Tr>
              </Thead>
              <Tbody>
              {allCallHistory && allCallHistory.length > 0 ? (
                allCallHistory.map((log, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{log.Product}</Td>
                    <Td>{log.Attendedby}</Td>
                    <Td>{log.ClientName}</Td>
                    <Td>{log.Caller}</Td>
                    <Td>{log.CallerLocation}</Td>
                    <Td>
                      {moment(log.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>{log.CallerReason}</Td>
                    <Td>{log.Description}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="8" textAlign="center">No data available</Td>
                </Tr>
              )}
              </Tbody>
            </Table>
          </Box>
        )}        
      </Box>
      
      <Spacer h='5' bgColor="blackAlpha.100" />
      
      <Box m={6}>
        <Text fontSize="xl" fontWeight="bold" mb="4">
          Call Logs
        </Text>
      </Box>

      {queLogLoading ? (
        <Loader />
      ) : queLogError ? (
        <Message type="error">{queLogError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Trx Id</Th>
                <Th>Queue Id</Th>
                <Th>Agent</Th>
                <Th>MSISDN</Th>
                <Th>Date Created</Th>
                <Th>Action / Reason</Th>
              </Tr>
            </Thead>
            <Tbody>
            {allQueuelog.length > 0 ? (
                  allQueuelog.map((log, index) => (
                <Tr key={log.trx_id} bg="white">
                  <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                  <Td>{log.trx_id}</Td>
                  <Td>{log.queue_id}</Td>
                  <Td>{log.agent}</Td>
                  <Td>{log.msisdn}</Td>
                  <Td>
                    {moment(log.created)
                      .tz("Africa/Nairobi")
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </Td>
                  <Td>
                      {logDispositionInfo[log.id] && logDispositionInfo[log.id].dispositioned ? (
                          <Box>
                              <Text fontWeight="semibold">
                                  Reason: <Text as="span" fontWeight="normal">{logDispositionInfo[log.id].reason}</Text>
                              </Text>
                              <Text fontWeight="semibold">
                                  Description: <Text as="span" fontWeight="normal">{logDispositionInfo[log.id].description}</Text>
                              </Text>
                          </Box>
                      ) : (
                        <Box>
                        <Text fontWeight="semibold">
                            Not Dispositioned 
                        </Text>
                    </Box>
                      )}
                   </Td>
                </Tr>
              ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        
        <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to {allQueLog.queuelog ? Math.min(indexOfLastItem, allQueLog.queuelog.length) : 0} of {allQueLog.queuelog ? allQueLog.queuelog.length : 0} entries
              </Box>
              <Flex mt="4" justify="space-between" align="center">
                <Box>
                  Showing {indexOfFirstItem + 1} to {allQueLog.queuelog ? Math.min(indexOfLastItem, allQueLog.queuelog.length) : 0} of {allQueLog.queuelog ? allQueLog.queuelog.length : 0} entries
                </Box>
                <Flex>
                  <Button
                    variant="outline"
                    isDisabled={currentPage === 1}
                    onClick={() => paginate(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  {[...Array(Math.ceil((allQueLog.queuelog?.length || 0) / itemsPerPage))].map((_, index) => (
                    <React.Fragment key={index}>
                      {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(allQueLog.queuelog?.length / itemsPerPage) ? (
                        <Button
                          key={index}
                          variant={currentPage === index + 1 ? "solid" : "outline"}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Button>
                      ) : (
                        index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                          <Box key={index}>...</Box>
                        ) : null
                      )}
                    </React.Fragment>
                  ))}
                  <Button
                    variant="outline"
                    isDisabled={currentPage === Math.ceil(allQueLog.queuelog?.length / itemsPerPage)}
                    onClick={() => paginate(currentPage + 1)}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          )}

          <Spacer h="5" />
    </Box>
  );
};

export default InboundAllCallLogs;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { getAllTicketsProgress } from "../../actions/ticketsActions";

const AllTicketsScreen = () => {
  const dispatch = useDispatch();

  const { loading, allTickets, error } = useSelector((state) => ({
    allTickets: state.allTickets.allTickets,
    loading: state.allTickets.loading,
    error: state.allTickets.error,
  }));

  useEffect(() => {
    dispatch(getAllTicketsProgress());
  }, [dispatch]);

  return (
    <Box bg="white" borderRadius="sm" m={4}>        
        <Flex justifyContent="space-between" mt="2">
            <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
            All Tickets
            </Heading>
        </Flex>

      {loading ? (
        <Flex justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Client</Th>
              <Th>Status</Th>
              <Th>Count</Th>
              <Th>Tickets</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(allTickets?.inbound || {}).map(([client, statuses]) =>
              Object.entries(statuses).map(([status, { count, tickets }], index) => (
                <Tr key={`${client}-${status}-${index}`}>
                  <Td>{client}</Td>
                  <Td>{status || "Unknown"}</Td>
                  <Td>{count}</Td>
                  <Td>{tickets.join(", ")}</Td>
                </Tr>
              ))
            )}
            {Object.entries(allTickets?.outbound || {}).map(([client, statuses]) =>
              Object.entries(statuses).map(([status, { count, tickets }], index) => (
                <Tr key={`${client}-${status}-${index}`}>
                  <Td>{client}</Td>
                  <Td>{status || "Unknown"}</Td>
                  <Td>{count}</Td>
                  <Td>{tickets.join(", ")}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default AllTicketsScreen;

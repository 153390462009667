import {
    CALLDISPOSITION_CREATE_REQUEST,
    CALLDISPOSITION_CREATE_SUCCESS,
    CALLDISPOSITION_CREATE_FAIL,
    CALL_HISTORY_REQUEST,
    CALL_HISTORY_SUCCESS,
    CALL_HISTORY_FAIL,
    CALL_HISTORY_PRODUCT_REQUEST,
    CALL_HISTORY_PRODUCT_SUCCESS,
    CALL_HISTORY_PRODUCT_FAIL,
    CALL_HISTORY_ALL_REQUEST,
    CALL_HISTORY_ALL_SUCCESS,
    CALL_HISTORY_ALL_FAIL,
    INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST,
    INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS,
    INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL,
    INBOUND_SCHEDULED_CALLBACKS_REQUEST,
    INBOUND_SCHEDULED_CALLBACKS_SUCCESS,
    INBOUND_SCHEDULED_CALLBACKS_FAIL,
    UPDATE_CALLBACK_STATUS_REQUEST,
    UPDATE_CALLBACK_STATUS_SUCCESS,
    UPDATE_CALLBACK_STATUS_FAIL,
    GET_INBOUND_ESCALATED_CALLS_REQUEST,
    GET_INBOUND_ESCALATED_CALLS_SUCCESS,
    GET_INBOUND_ESCALATED_CALLS_FAIL,
    UPDATE_ESCALATION_DETAILS_REQUEST,
    UPDATE_ESCALATION_DETAILS_SUCCESS,
    UPDATE_ESCALATION_DETAILS_FAIL,
} from '../constants/callDispositionConstants';

export const callDispositionCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CALLDISPOSITION_CREATE_REQUEST:
            return { loading: true };
        case CALLDISPOSITION_CREATE_SUCCESS:
            return { loading: false, callDisposition: action.payload };
        case CALLDISPOSITION_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const callHistoryReducer = (state = { callHistory: [] }, action) => {
    switch (action.type) {
        case CALL_HISTORY_REQUEST:
            return { loading: true, calls: [] };
        case CALL_HISTORY_SUCCESS:
            return { loading: false, calls: action.payload };
        case CALL_HISTORY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productInboundCallHistoryReducer = (state = { productCallHistory: [] }, action) => {
    switch (action.type) {
        case CALL_HISTORY_PRODUCT_REQUEST:
            return { loading: true, productCallHistory: [] };
        case CALL_HISTORY_PRODUCT_SUCCESS:
            return { loading: false, productCallHistory: action.payload };
        case CALL_HISTORY_PRODUCT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const allInboundCallHistoryReducer = (state = { allCallHistory: [] }, action) => {
    switch (action.type) {
        case CALL_HISTORY_ALL_REQUEST:
            return { loading: true, allCallHistory: [] };
        case CALL_HISTORY_ALL_SUCCESS:
            return { loading: false, allCallHistory: action.payload };
        case CALL_HISTORY_ALL_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const inboundAllScheduledCallbacksReducer = (state = { inboundCallbacks: [] }, action) => {
    switch (action.type) {
        case INBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST:
            return { loading: true, inboundCallbacks: [] };
        case INBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS:
            return { loading: false, inboundCallbacks: action.payload };
        case INBOUND_ALL_SCHEDULED_CALLBACKS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const inboundScheduledCallbacksReducer = (state = { inboundCallbacks: [] }, action) => {
    switch (action.type) {
        case INBOUND_SCHEDULED_CALLBACKS_REQUEST:
            return { loading: true, inboundCallbacks: [] };
        case INBOUND_SCHEDULED_CALLBACKS_SUCCESS:
            return { loading: false, inboundCallbacks: action.payload };
        case INBOUND_SCHEDULED_CALLBACKS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const updateCallbackStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CALLBACK_STATUS_REQUEST:
            return { loading: true };
        case UPDATE_CALLBACK_STATUS_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_CALLBACK_STATUS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const inboundProductEscalatedCallsReducer = (state = { escalatedCalls: [] }, action) => {
    switch (action.type) {
        case GET_INBOUND_ESCALATED_CALLS_REQUEST:
            return { loading: true, escalatedCalls: [] };
        case GET_INBOUND_ESCALATED_CALLS_SUCCESS:
            return { loading: false, escalatedCalls: action.payload };
        case GET_INBOUND_ESCALATED_CALLS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const updateEscalationDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_ESCALATION_DETAILS_REQUEST:
            return { loading: true };
        case UPDATE_ESCALATION_DETAILS_SUCCESS:
            return { loading: false, success: true };
        case UPDATE_ESCALATION_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

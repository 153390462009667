
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { IoPencilSharp, IoTrashBinSharp } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import {
  deleteReason,
  listReasons,
  createReason,
  updateReason,
} from "../../actions/reasonsActions";
import {  listClients } from "../../actions/clientsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const CallReasonsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);  
  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();

  const [newReason, setNewReason] = useState({
    product_id: "",
    type: "",
    reason: "",
  });
  const [editReason, setEditReason] = useState({
    id: null,
    product_id: "",
    type: "",
    reason: "",
  });

  const reasonsList = useSelector((state) => state.reasonsList);
  const { loading, error, reasons } = reasonsList || {};

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reasonsDelete = useSelector((state) => state.reasonsDelete);
  const { success } = reasonsDelete;

  const clientsList = useSelector((state) => state.clientsList);
  const { loading: clientsLoading, error: clientsError, clients } = clientsList;

  useEffect(() => {
    if (userInfo) {
      dispatch(listReasons(searchQuery));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo, success, currentPage, searchQuery]);

  useEffect(() => {
    if (isCreateModalOpen || isEditModalOpen) {
      dispatch(listClients());
    }
  }, [dispatch, isCreateModalOpen, isEditModalOpen]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      dispatch(deleteReason(id));
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    reasons && reasons.data
      ? reasons.data.slice(indexOfFirstItem, Math.min(indexOfLastItem, reasons.data.length))
      : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openCreateModal = () => {
    setNewReason({
      product_id: "",
      type: "",
      reason: "",
    });
    onOpenCreateModal();
  };
  
  
  const closeCreateModal = () => {
    onCloseCreateModal();
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReason((prevReason) => ({
      ...prevReason,
      [name]: value,
    }));
  };
    
  const handleSave = () => {
    if (!newReason.product_id || !newReason.type || !newReason.reason) {
      return;
    }
  
    dispatch(createReason(newReason))
      .then(() => {
        dispatch(listReasons(searchQuery));
      });
  
    closeCreateModal();
  };  
  

  const openEditModal = (reason) => {
    setEditReason(reason);
    onEditModalOpen();
  };
  
  const closeEditModal = () => {
    setEditReason({
      id: null,
      product_id: "",
      type: "",
      reason: "",
    });
    onEditModalClose();
  };

  const handleUpdate = () => {
    dispatch(updateReason(editReason.id, editReason))
    .then(() => {
      dispatch(listReasons(searchQuery));
    });
    closeEditModal();
  };
  

  return (
    <Box bg='whiteAlpha.500' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize={{ base: 'xl', md: '2xl' }} mb="5" ml={8} mt={4}>
          Call Reasons
        </Heading>
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          m={8}
          maxWidth='500px'
          borderColor='blue.400'
          borderWidth='2px'
        />
        <Button
          onClick={openCreateModal}
          align="center"
          color="blue.500"
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          padding="4"
          textAlign="center"
          bgColor="white"
          m={4}
          _hover={{ bgColor: 'blue.500', color: 'white', borderWidth:"2px", borderColor:"white" }}
          cursor="pointer"
          variant="outline"
        >
          <Icon as={MdAdd} boxSize={6} mr={2} />
          <Text mr={2} display={{ base: "none", md: "block" }}>Add</Text>
        </Button>
      </Flex>      
      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Reason</Th>
                <Th>Product ID</Th>
                <Th>Type</Th>
                <Th>Date Created</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
            {currentItems.length > 0 ? (
              currentItems.map((reason, index) => (
                  <Tr key={reason.id}>
                    <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td> 
                    <Td>{reason.reason}</Td>
                    <Td>{reason.product_id}</Td>
                    <Td>{reason.type}</Td>
                    <Td>
                      {moment(reason.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </Td>
                    <Td>
                      <Flex alignItems="center">                        
                        <Button
                          mr="4"
                          colorScheme="blue"
                          bg='blue.500'
                          onClick={() => openEditModal(reason)}
                        >
                          <Icon as={IoPencilSharp} color="white" size="md" />
                        </Button>
                        <Button
                          mr="4"
                          colorScheme="red"
                          onClick={() => deleteHandler(reason.id)}
                        >
                          <Icon as={IoTrashBinSharp} color="white" size="md" />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="6" textAlign="center">
                    No data available
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Flex mt="4" justify="space-between" align="center">
            <Box>
              Showing {indexOfFirstItem + 1} to {reasons.data ? Math.min(indexOfLastItem, reasons.data.length) : 0} of {reasons.data ? reasons.data.length : 0} entries
            </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                Showing {indexOfFirstItem + 1} to {reasons.data ? Math.min(indexOfLastItem, reasons.data.length) : 0} of {reasons.data ? reasons.data.length : 0} entries
              </Box>
              <Flex>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {[...Array(Math.ceil((reasons.data?.length || 0) / itemsPerPage))].map((_, index) => (
                  <React.Fragment key={index}>
                    {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(reasons.data?.length / itemsPerPage) ? (
                      <Button
                        key={index}
                        variant={currentPage === index + 1 ? "solid" : "outline"}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Button>
                    ) : (
                      index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                        <Box key={index}>...</Box>
                      ) : null
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant="outline"
                  isDisabled={currentPage === Math.ceil(reasons.data?.length / itemsPerPage)}
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </Button>
              </Flex>
            </Flex>            
          </Flex>
        </Box>
      )}
      <Spacer h="5" />

      {/* Modal for adding a new call reason */}
      <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Call Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel htmlFor="client">Client</FormLabel>
              <Select
                placeholder="Select client"
                name="product_id"
                value={newReason.product_id}
                onChange={handleInputChange}
              >
                {clientsLoading ? (
                    <option value="" disabled>Loading clients...</option>
                  ) : clientsError ? (
                    <option value="" disabled>Error loading clients</option>
                  ) : Array.isArray(clients.data) ? (
                    clients.data.map((client) => (
                      <option key={client.id} value={client.product_id}>
                        {client.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>No clients available</option>
                  )}
              </Select>
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="type">Type</FormLabel>
              <Select
                placeholder="Select type"
                name="type"
                value={newReason.type}
                onChange={handleInputChange}
              >
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </Select>
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="reason">Reason</FormLabel>
              <Input
                type="text"
                name="reason"
                value={newReason.reason}
                onChange={handleInputChange}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" bg='blue.500' mr={3} onClick={closeCreateModal}>
              Close
            </Button>
            <Button colorScheme="green" bg='brandGreen' onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

       {/* Edit Modal */}
       <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Call Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel htmlFor="client">Client</FormLabel>
              <Select
                name="client"
                value={editReason.product_id}
                onChange={(e) => setEditReason({ ...editReason, client: e.target.value })}
              >
                {clientsLoading ? (
                    <option value="" disabled>Loading clients...</option>
                  ) : clientsError ? (
                    <option value="" disabled>Error loading clients</option>
                  ) : Array.isArray(clients.data) ? (
                    clients.data.map((client) => (
                      <option key={client.id} value={client.product_id}>
                        {client.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>No clients available</option>
                  )}
              </Select>
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="type">Type</FormLabel>
              <Select
                name="type"
                value={editReason.type}
                onChange={(e) => setEditReason({ ...editReason, type: e.target.value })}
              >
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </Select>
            </FormControl>
            <FormControl mb="4">
              <FormLabel htmlFor="reason">Reason</FormLabel>
              <Input
                type="text"
                name="reason"
                value={editReason.reason}
                onChange={(e) => setEditReason({ ...editReason, reason: e.target.value })}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" bg='blue.500' mr={3} onClick={closeEditModal}>
              Close
            </Button>
            <Button colorScheme="green" bg='brandGreen' onClick={handleUpdate}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Box>
  );
};

export default CallReasonsScreen;

export const OUTBOUND_DISPOSITION_CREATE_REQUEST = "OUTBOUND_DISPOSITION_CREATE_REQUEST";
export const OUTBOUND_DISPOSITION_CREATE_SUCCESS = "OUTBOUND_DISPOSITION_CREATE_SUCCESS";
export const OUTBOUND_DISPOSITION_CREATE_FAIL = "OUTBOUND_DISPOSITION_CREATE_FAIL";

export const OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST = 'OUTBOUND_ALL_SCHEDULED_CALLBACKS_REQUEST';
export const OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS = 'OUTBOUND_ALL_SCHEDULED_CALLBACKS_SUCCESS';
export const OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL = 'OUTBOUND_ALL_SCHEDULED_CALLBACKS_FAIL';

export const OUTBOUND_SCHEDULED_CALLBACKS_REQUEST = 'OUTBOUND_SCHEDULED_CALLBACKS_REQUEST';
export const OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS = 'OUTBOUND_SCHEDULED_CALLBACKS_SUCCESS';
export const OUTBOUND_SCHEDULED_CALLBACKS_FAIL = 'OUTBOUND_SCHEDULED_CALLBACKS_FAIL';

export const UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST = 'UPDATE_OUTBOUND_CALLBACK_STATUS_REQUEST';
export const UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS = 'UPDATE_OUTBOUND_CALLBACK_STATUS_SUCCESS';
export const UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL = 'UPDATE_OUTBOUND_CALLBACK_STATUS_FAIL';

export const GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST = 'GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_REQUEST';
export const GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS = 'GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_SUCCESS';
export const GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL = 'GET_OUTBOUND_ESCALATED_CALLS_PRODUCT_FAIL';

export const UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST = 'UPDATE_OUTBOUND_ESCALATION_DETAILS_REQUEST';
export const UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS = 'UPDATE_OUTBOUND_ESCALATION_DETAILS_SUCCESS';
export const UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL = 'UPDATE_OUTBOUND_ESCALATION_DETAILS_FAIL';

export const GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST = 'GET_OUTBOUND_ESCALATED_CALLS_AGENT_REQUEST';
export const GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS = 'GET_OUTBOUND_ESCALATED_CALLS_AGENT_SUCCESS';
export const GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL = 'GET_OUTBOUND_ESCALATED_CALLS_AGENT_FAIL';

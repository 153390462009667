import {
    REASONS_LIST_FAIL,
    REASONS_LIST_REQUEST,
    REASONS_LIST_SUCCESS,
    REASONS_DETAILS_FAIL,
    REASONS_DETAILS_REQUEST,
    REASONS_DETAILS_SUCCESS,
    REASONS_DELETE_FAIL,
    REASONS_DELETE_REQUEST,
    REASONS_DELETE_SUCCESS,
    REASONS_CREATE_FAIL,
    REASONS_CREATE_REQUEST,
    REASONS_CREATE_SUCCESS,
    REASONS_CREATE_RESET,
    REASONS_UPDATE_FAIL,
    REASONS_UPDATE_REQUEST,
    REASONS_UPDATE_SUCCESS,
    REASONS_UPDATE_RESET,
    PRODUCT_REASONS_REQUEST,
    PRODUCT_REASONS_SUCCESS,
    PRODUCT_REASONS_FAIL,
  } from '../constants/reasonsConstants';
  
  export const reasonsListReducer = (state = { reasons: [] }, action) => {
    switch (action.type) {
      case REASONS_LIST_REQUEST:
        return { loading: true, reasons: [] };
  
      case REASONS_LIST_SUCCESS:
        return { loading: false, reasons: action.payload };
      case REASONS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const reasonsDetailsReducer = (state = { reason: {} }, action) => {
    switch (action.type) {
      case REASONS_DETAILS_REQUEST:
        return { ...state, loading: true };
      case REASONS_DETAILS_SUCCESS:
        return { loading: false, reason: action.payload };
      case REASONS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export const reasonsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case REASONS_DELETE_REQUEST:
        return { loading: true };
      case REASONS_DELETE_SUCCESS:
        return { loading: false, success: true };
      case REASONS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const reasonsCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case REASONS_CREATE_REQUEST:
        return { loading: true };
      case REASONS_CREATE_SUCCESS:
        return { loading: false, success: true, reason: action.payload };
      case REASONS_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case REASONS_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const reasonsUpdateReducer = (state = { reason: {} }, action) => {
    switch (action.type) {
      case REASONS_UPDATE_REQUEST:
        return { loading: true };
      case REASONS_UPDATE_SUCCESS:
        return { loading: false, success: true, reason: action.payload };
      case REASONS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case REASONS_UPDATE_RESET:
        return {};
      default:
        return state;
    }
  };  

  export const productReasonsReducer = (state = { reasons: [] }, action) => {
    switch (action.type) {
        case PRODUCT_REASONS_REQUEST:
            return { loading: true, reasons: [] };
        case PRODUCT_REASONS_SUCCESS:
            return { loading: false, reasons: action.payload };
        case PRODUCT_REASONS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Select,
  Button,
  Divider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import {
  getInboundScheduledCallbacks,
  updateCallbackStatus,
} from '../../actions/callDispositionActions';
import {
  getOutboundScheduledCallbacks,
  updateOutboundCallbackStatus,
} from '../../actions/outboundDispositionAction';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const ScheduledCallbacks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [searchInputOverdue, setSearchInputOverdue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const inboundScheduledCallbacks = useSelector((state) => state.inboundScheduledCallbacks);
  const { loading: inboundCallbacksLoading, error: inboundCallbacksError, inboundCallbacks } = inboundScheduledCallbacks;

  const outboundScheduledCallbacks = useSelector((state) => state.outboundScheduledCallbacks);
  const { loading: outboundCallbacksLoading, error: outboundCallbacksError, outboundCallbacks } = outboundScheduledCallbacks;

  const overdueInboundCallbacks = inboundCallbacks?.filter(callback => new Date(callback.CallbackDate) < new Date());
  const overdueOutboundCallbacks = outboundCallbacks?.filter(callback => new Date(callback.CallbackDate) < new Date());

  const filteredOverdueInboundCallbacks = overdueInboundCallbacks?.filter(callback => callback.Caller && callback.Caller.includes(searchInputOverdue));
  const filteredOverdueOutboundCallbacks = overdueOutboundCallbacks?.filter(callback => callback.Caller && callback.Caller.includes(searchInputOverdue));

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (userInfo) {
      const agentName = userInfo.name;
      dispatch(getInboundScheduledCallbacks(agentName, searchInput || undefined)).catch((error) => {
        console.error('Error fetching inbound callbacks:', error);
      });
      dispatch(getOutboundScheduledCallbacks(agentName, searchInput || undefined)).catch((error) => {
        console.error('Error fetching outbound callbacks:', error);
      });
    } else {
      navigate('/login');
    }
  }, [dispatch, userInfo, searchInput, navigate]);

  const handleInboundStatusChange = async (callbackId, newStatus) => {
    try {
      await dispatch(updateCallbackStatus(callbackId, newStatus)).then(() => {
        dispatch(getInboundScheduledCallbacks(userInfo.name, searchInput || undefined));
        if (newStatus === 'Answered') {
          navigate('/outbound/call-logs');
        }
      });
    } catch (error) {
      console.error('Error updating inbound callback status:', error);
    }
  };

  const handleOutboundStatusChange = async (callbackId, newStatus) => {
    try {
      await dispatch(updateOutboundCallbackStatus(callbackId, newStatus)).then(() => {
        dispatch(getOutboundScheduledCallbacks(userInfo.name, searchInput || undefined));
        if (newStatus === 'Answered') {
          navigate('/outbound/call-logs');
        }
      });
    } catch (error) {
      console.error('Error updating outbound callback status:', error);
    }
  };

  const renderCallbacksTable = (callbacks, handleStatusChange) => (
    <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>No.</Th>
            <Th>Attended by</Th>
            <Th>Caller Name</Th>
            <Th>Caller No</Th>
            <Th>Caller Location</Th>
            <Th>Date Created</Th>
            <Th>Status</Th>
            <Th>Callback Date</Th>
            <Th>Reason</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {callbacks && callbacks.length > 0 ? (
            callbacks.slice(indexOfFirstItem, indexOfLastItem).map((callback, index) => (
              <Tr key={index}>
                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                <Td>{callback.Attendedby}</Td>
                <Td>{callback.ClientName}</Td>
                <Td>{callback.Caller}</Td>
                <Td>{callback.CallerLocation}</Td>
                <Td>
                  {moment(callback.created_at).tz('Africa/Nairobi').format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </Td>
                <Td>
                  <Select
                    value={callback.Status}
                    onChange={(e) => handleStatusChange(callback.id, e.target.value)}
                    ml={2}
                    w="130px"
                  >
                    <option value="Not Called">Not Called</option>
                    <option value="Not Answered">Not Answered</option>
                    <option value="Answered">Answered</option>
                  </Select>
                </Td>
                <Td>
                  {moment(callback.CallbackDate).tz('Africa/Nairobi').format('dddd, MMMM Do YYYY, h:mm:ss a')}
                </Td>
                <Td>{callback.CallerReason}</Td>
                <Td>{callback.Description}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="10" textAlign="center">No data available</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex mt="4" justify="space-between" align="center">
        <Box>
          Showing {indexOfFirstItem + 1} to {callbacks ? Math.min(indexOfLastItem, callbacks.length) : 0} of {callbacks ? callbacks.length : 0} entries
        </Box>
        <Flex>
          <Button variant="outline" isDisabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>
            Previous
          </Button>
          {[...Array(Math.ceil((callbacks?.length || 0) / itemsPerPage))].map((_, index) => (
            <React.Fragment key={index}>
              {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(callbacks?.length / itemsPerPage) ? (
                <Button key={index} variant={currentPage === index + 1 ? 'solid' : 'outline'} onClick={() => paginate(index + 1)}>
                  {index + 1}
                </Button>
              ) : (
                index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                  <Box key={index}>...</Box>
                ) : null
              )}
            </React.Fragment>
          ))}
          <Button variant="outline" isDisabled={currentPage === Math.ceil(callbacks?.length / itemsPerPage)} onClick={() => paginate(currentPage + 1)}>
            Next
          </Button>
        </Flex>
      </Flex>
    </Box>
  );

  return (
    <Box p="4" bg="white" borderRadius="md" m={2}>
      <Box m={6} mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb="4">Scheduled Callbacks</Text>
        <Tabs mt="4" isLazy>
          <TabList>
            <Tab>Inbound</Tab>
            <Tab>Outbound</Tab>
            <Tab>Overdue</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Divider mt="4" color='blackAlpha.100' borderWidth="2px" />
              <Flex flexWrap="wrap" justify="space-around" mt="4">
                <Flex m={4}>
                  <Text fontSize="md" fontWeight="semibold" mb="4">Search: number</Text>
                  <Input
                    type="text"
                    placeholder="Enter phone digits"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    ml={8}
                    maxWidth='300px'
                    borderColor='blue.400'
                    borderWidth='2px'
                  />
                </Flex>
              </Flex>
              {inboundCallbacksLoading ? (
                <Loader />
              ) : inboundCallbacksError ? (
                <Message type="error">{inboundCallbacksError}</Message>
              ) : (
                renderCallbacksTable(inboundCallbacks, handleInboundStatusChange)
              )}
            </TabPanel>
            <TabPanel>
              <Divider mt="4" color='blackAlpha.100' borderWidth="2px" />
              <Flex flexWrap="wrap" justify="space-around" mt="4">
                <Flex m={4}>
                  <Text fontSize="md" fontWeight="semibold" mb="4">Search: number</Text>
                  <Input
                    type="text"
                    placeholder="Enter phone digits"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    ml={8}
                    maxWidth='300px'
                    borderColor='blue.400'
                    borderWidth='2px'
                  />
                </Flex>
              </Flex>
              {outboundCallbacksLoading ? (
                <Loader />
              ) : outboundCallbacksError ? (
                <Message type="error">{outboundCallbacksError}</Message>
              ) : (
                renderCallbacksTable(outboundCallbacks, handleOutboundStatusChange)
              )}
            </TabPanel>
            <TabPanel>
              <Tabs mt="4" isLazy>
                <TabList>
                  <Tab>Inbound</Tab>
                  <Tab>Outbound</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Divider mt="4" color='blackAlpha.100' borderWidth="2px" />
                    <Flex flexWrap="wrap" justify="space-around" mt="4">
                      <Flex m={4}>
                        <Text fontSize="md" fontWeight="semibold" mb="4">Search: number</Text>
                        <Input
                          type="text"
                          placeholder="Enter phone digits"
                          value={searchInputOverdue}
                          onChange={(e) => setSearchInputOverdue(e.target.value)}
                          ml={8}
                          maxWidth='300px'
                          borderColor='blue.400'
                          borderWidth='2px'
                        />
                      </Flex>
                    </Flex>
                    {inboundCallbacksLoading ? (
                      <Loader />
                    ) : inboundCallbacksError ? (
                      <Message type="error">{inboundCallbacksError}</Message>
                    ) : (
                      renderCallbacksTable(filteredOverdueInboundCallbacks, handleInboundStatusChange)
                    )}
                  </TabPanel>
                  <TabPanel>
                    <Divider mt="4" color='blackAlpha.100' borderWidth="2px" />
                    <Flex flexWrap="wrap" justify="space-around" mt="4">
                      <Flex m={4}>
                        <Text fontSize="md" fontWeight="semibold" mb="4">Search: number</Text>
                        <Input
                          type="text"
                          placeholder="Enter phone digits"
                          value={searchInputOverdue}
                          onChange={(e) => setSearchInputOverdue(e.target.value)}
                          ml={8}
                          maxWidth='300px'
                          borderColor='blue.400'
                          borderWidth='2px'
                        />
                      </Flex>
                    </Flex>
                    {outboundCallbacksLoading ? (
                      <Loader />
                    ) : outboundCallbacksError ? (
                      <Message type="error">{outboundCallbacksError}</Message>
                    ) : (
                      renderCallbacksTable(filteredOverdueOutboundCallbacks, handleOutboundStatusChange)
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default ScheduledCallbacks;

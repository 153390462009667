import { useState } from "react";
import {
  Divider,
  Box,
  Flex,
  useMediaQuery,
  useColorModeValue,
} from "@chakra-ui/react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { GiHamburgerMenu } from "react-icons/gi";

const SubSidebar = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile] = useMediaQuery("(max-width:768px");
  const bgColor = useColorModeValue("#F4F4F4", "#1A202C");
  return (
    <Flex
      direction="column"
      minHeight="100vh"
      width="14vw"
      position="fixed"
      bgColor={bgColor}
    >
      <Flex direction={isMobile ? "column" : "row"}>
        <Sidebar
          collapsed={isMobile ? false : collapsed}
          width="100%"
          backgroundColor={bgColor}
        >
          <Menu iconShape="circle">
            {isMobile && (
              <MenuItem
                icon={<GiHamburgerMenu />}
                onClick={() => setCollapsed(!collapsed)}
              >
                ToggleMenu
              </MenuItem>
            )}
            <Flex mt={4}>
              <Box pl={2} fontSize="sm" fontWeight="bold">
                zuridesk
              </Box>
              <Box fontSize="sm" fontWeight="bold" color="orange.400">
                .
              </Box>
            </Flex>
            <Divider borderColor="#E0E0E0" borderWidth="1px" my={8} />

            {children}
          </Menu>
        </Sidebar>
      </Flex>
    </Flex>
  );
};

export default SubSidebar;

import axios from 'axios';
import {
    AGENT_CHECKINS_LIST_REQUEST,
    AGENT_CHECKINS_LIST_SUCCESS,
    AGENT_CHECKINS_LIST_FAIL,
    AGENT_CHECKINS_BY_PRODUCT_REQUEST,
    AGENT_CHECKINS_BY_PRODUCT_SUCCESS,
    AGENT_CHECKINS_BY_PRODUCT_FAIL,
    AGENT_CHECKIN_CREATE_REQUEST,
    AGENT_CHECKIN_CREATE_SUCCESS,
    AGENT_CHECKIN_CREATE_FAIL,
    AGENT_CHECKIN_UPDATE_REQUEST,
    AGENT_CHECKIN_UPDATE_SUCCESS,
    AGENT_CHECKIN_UPDATE_FAIL,    
    AGENT_LATEST_LOGOUT_CHECKIN_REQUEST,
    AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS,
    AGENT_LATEST_LOGOUT_CHECKIN_FAIL,
} from '../constants/agentCheckinsConstants';

export const listAgentCheckins = (searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: AGENT_CHECKINS_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/agent_checkins?search=${searchQuery}`, config);

        dispatch({ type: AGENT_CHECKINS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: AGENT_CHECKINS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const listAgentCheckinsByProduct = (productId, searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: AGENT_CHECKINS_BY_PRODUCT_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/agent_checkins/${productId}?search=${searchQuery}`, config);

        dispatch({ type: AGENT_CHECKINS_BY_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: AGENT_CHECKINS_BY_PRODUCT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const createAgentCheckin = (agentCheckinData) => async (dispatch, getState) => {
  try {
      dispatch({ type: AGENT_CHECKIN_CREATE_REQUEST });

      const {
          userLogin: { userInfo },
      } = getState();

      const config = {
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
          },
      };

      const { data } = await axios.post('/api/agent_checkins/manual', agentCheckinData, config);

      dispatch({ type: AGENT_CHECKIN_CREATE_SUCCESS, payload: data });
  } catch (error) {
      dispatch({
          type: AGENT_CHECKIN_CREATE_FAIL,
          payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
  }
};

export const updateAgentCheckin = (checkinId, updateData) => async (dispatch, getState) => {
    try {
      dispatch({ type: AGENT_CHECKIN_UPDATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`/api/agent_checkins/update/${checkinId}`, updateData, config);
  
      dispatch({ type: AGENT_CHECKIN_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: AGENT_CHECKIN_UPDATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };

  export const getLatestLogoutCheckin = (userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: AGENT_LATEST_LOGOUT_CHECKIN_REQUEST });
  
      const { userLogin: { userInfo } } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/agent_checkins/latest_logout/${userId}`, config);
  
      dispatch({
        type: AGENT_LATEST_LOGOUT_CHECKIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: AGENT_LATEST_LOGOUT_CHECKIN_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };
  
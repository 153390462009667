import axios from "axios";

import {
  AGENT_DETAILS_FAIL,
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_SUCCESS,
  AGENT_LIST_FAIL,
  AGENT_LIST_REQUEST,
  AGENT_LIST_SUCCESS,
  AGENT_SUPERVISOR_LIST_REQUEST,
  AGENT_SUPERVISOR_LIST_SUCCESS,
  AGENT_SUPERVISOR_LIST_FAIL,
  AGENT_DELETE_FAIL,
  AGENT_DELETE_REQUEST,
  AGENT_DELETE_SUCCESS,
  AGENT_CREATE_FAIL,
  AGENT_CREATE_REQUEST,
  AGENT_CREATE_SUCCESS,
  AGENT_UPDATE_FAIL,
  AGENT_UPDATE_REQUEST,
  AGENT_UPDATE_SUCCESS,
} from '../constants/agentConstants';

export const listAgents = () => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/agents`,
      config
    );

    dispatch({ type: AGENT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAgentDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/agents/${id}`,
      config
    );

    dispatch({ type: AGENT_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAgentsWithSupervisor = (product_id, searchQuery = '') => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_SUPERVISOR_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/admin/agents/${product_id}?search=${searchQuery}`, 
      config
    );

    dispatch({ type: AGENT_SUPERVISOR_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_SUPERVISOR_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const deleteAgent = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(
      `/api/admin/agents/${id}`,
      config
    );

    dispatch({ type: AGENT_DELETE_SUCCESS });
  } catch (err) {
    dispatch({
      type: AGENT_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createAgent = (agent) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/admin/agents`,
      agent,
      config
    );

    dispatch({ type: AGENT_CREATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_CREATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const updateAgent = (id, agentObj) => async (dispatch, getState) => {
  try {
    dispatch({ type: AGENT_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/admin/agents/${id}`,
      agentObj,
      config
    );

    dispatch({ type: AGENT_UPDATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: AGENT_UPDATE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};


import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Select,
  Icon,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaDownload, FaUpload, FaSave, FaPlus } from "react-icons/fa";
import moment from "moment-timezone";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import axios from "axios";

import {
  listClientDetails,
  listAgentsByProduct,
} from "../../actions/clientsActions";
import { listCampaignsByProduct } from '../../actions/campaignActions';
import { createCustomTags } from "../../actions/clientContactsActions";
import { 
  getProductClientContacts, 
  createClientContacts, 
  updateClientContact,
  fetchCustomTags,
  updateCustomTags 
} from "../../actions/clientContactsActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import sampleData from "../../data/sampleFile";

const SupOutboundContacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [id, setId] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [uploadedContacts, setUploadedContacts] = useState([]);
  const [customCallTags, setCustomCallTags] = useState({
    call_tag_1: 'Call Tag 1',
    call_tag_2: 'Call Tag 2',
    call_tag_3: 'Call Tag 3',
    call_tag_4: 'Call Tag 4',
    call_tag_5: 'Call Tag 5',
    call_tag_6: 'Call Tag 6',
    col_1: 'Column 1',
    col_2: 'Column 2',
    col_3: 'Column 3',
    col_4: 'Column 4',
    col_5: 'Column 5',
    col_6: 'Column 6',
    col_7: 'Column 7',
    col_8: 'Column 8',
    col_9: 'Column 9',
  });
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [notFound, setNotFound] = useState(false);

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(sampleData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'sample.csv');
  };

  const clientsDetails = useSelector((state) => state.clientsDetails);
  const { client } = clientsDetails;

  const agentsByProduct = useSelector((state) => state.agentsByProduct);
  const { loading: agentsLoading, error: agentsError, agents } = agentsByProduct;

  const campaignsByProduct = useSelector((state) => state.campaignsByProduct);
  const { loading: campaignsLoading, error: campaignsError, campaigns } = campaignsByProduct;

  const productClientContacts = useSelector((state) => state.productClientContacts);
  const { loading: contactsLoading, error: contactsError, contacts } = productClientContacts;

  const customTagsState = useSelector((state) => state.customTagsFetch);
  const { loading: customTagsLoading, error: customTagsError, customTags } = customTagsState;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const product_id = userInfo?.product_id || '';

  useEffect(() => {
    const fetchClientId = async () => {    
      const prodCode = userInfo.product_id; 
      try {
        const response = await axios.get(`/api/admin/clients/product/${prodCode}`);
        const { client_id } = response.data;
        setId(client_id);
      } catch {
        console.log('Error fetching client ID');
      }
    };
  
    const fetchData = async () => {
      if (userInfo) {
        dispatch(listClientDetails(id));
        dispatch(listCampaignsByProduct(product_id));
        await fetchClientId(); 
      } else {
        navigate("/login");
      }
    };
  
    fetchData();
  }, [dispatch, navigate, userInfo, id, product_id]);  
  

  useEffect(() => {
    if (client && client.product_id) {
      dispatch(listAgentsByProduct(client.id));
      const clientId = client.id;
      dispatch(getProductClientContacts(clientId));
    }
  }, [dispatch, client]);
  
  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleCampaignChange = async (event) => {
    const campaignId = event.target.value;
    setSelectedCampaign(campaignId);
    setNotFound(false); // Reset not found state
    setSaveError('');   // Reset save error
    try {
      await dispatch(fetchCustomTags(userInfo.product_id, campaignId));
    } catch (error) {
      setNotFound(true); // Set not found state on any error
      console.error('Error fetching custom tags:', error);
    }
  };

  useEffect(() => {
    if (customTags && customTags.data && customTags.data.length > 0) {
      setCustomCallTags(prevTags => ({
        ...prevTags,
        ...customTags.data[0],
      }));
      setNotFound(false); // Reset not found state if tags are successfully fetched
    } else {
      setNotFound(true); // Set not found state if no custom tags are found
    }
  }, [customTags]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const text = await file.text();
      const parsedData = Papa.parse(text, { header: true }).data;   
      const parsedContacts = parsedData.map(data => ({
        col_1: data['col_1'],
        col_2: data['col_2'],
        col_3: data['col_3'],
        col_4: data['col_4'],
        col_5: data['col_5'],
        col_6: data['col_6'],
        col_7: data['col_7'],
        col_8: data['col_8'],
        col_9: data['col_9'],
      }));
      setUploadedContacts(parsedContacts);
    }
  };  

  const handleUpload = async () => {
    if (uploadedContacts.length === 0 || !selectedAgent || !selectedCampaign) {
      console.log('Incomplete data for uploading contacts');
      return;
    }
  
    try {
      const updatedContacts = uploadedContacts.map(contact => ({
        ...contact,
        client_id: client.id,
        agent_id: selectedAgent,
        campaign_id: selectedCampaign,
      }));
      
      for (const contact of updatedContacts) {
        const clientId = client.id;
        dispatch(createClientContacts(contact))
        .then(() => {
          dispatch(getProductClientContacts(clientId));
        });
      }
  
      setSelectedAgent("");
      setSelectedCampaign("");
      setUploadedContacts([]);
  
      console.log('Contacts uploaded successfully');
    } catch (error) {
      console.error('Error uploading contacts:', error);
    }
  };
  
  const confirmDelete = (contact) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this contact?");
    if (isConfirmed) {
      handleDelete(contact);
    }
  };

  const handleDelete = async (contact) => {
    try {
      dispatch(updateClientContact(contact.id, { deleted: 'yes' }));
      const clientId = client.id;
      dispatch(getProductClientContacts(clientId));
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };
  const handleCreateCustomTags = async () => {
    setSaving(true);
    setSaveError('');
    const dataToCreate = {
      product_code: userInfo.product_id,
      campaign_id: selectedCampaign,
      ...customCallTags,
    };
  
    console.log("Creating with data: ", dataToCreate);
  
    try {
      await dispatch(createCustomTags(userInfo.product_id, selectedCampaign, customCallTags));
      setNotFound(false); // Reset not found state after creating
      console.log('Custom tags created successfully.');
      dispatch(fetchCustomTags(userInfo.product_id, selectedCampaign));
    } catch (error) {
      setSaveError(`Failed to create custom tags: ${error.message}`);
      console.error('Failed to create custom tags:', error);
    } finally {
      setSaving(false);
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentContacts = contacts && contacts.slice(indexOfFirstItem, indexOfLastItem);

  const handleInputChange = (e) => {
    setCustomCallTags({ ...customCallTags, [e.target.name]: e.target.value });
  };

  const handleSaveCustomTags = async () => {
    setSaving(true);
    setSaveError('');
    try {
      await dispatch(updateCustomTags(userInfo.product_id, selectedCampaign, customCallTags));
      console.log('Custom tags saved successfully.');
      dispatch(fetchCustomTags(userInfo.product_id, selectedCampaign));
    } catch (error) {
      setSaveError(`Failed to save custom tags: ${error.message}`);
      console.error('Failed to save custom tags:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box bg='white' borderRadius="sm" m={4}>
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h3" fontSize='xl' mb="5" ml={8} mt={4}>
          Upload CSV
        </Heading>  
      </Flex>
      <Spacer h="2" bg='blackAlpha.50'/>
  
      <Flex
          flexWrap="wrap"
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center"
          m={8}          
        >
          <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 4, md: 0 }}>
              <Text fontWeight="bold" mb={2}>Sample CSV file</Text>
              <Button onClick={handleDownloadCSV} colorScheme="blue">
                  <Icon as={FaDownload} size='sm' mr={2}/>Download
              </Button>
          </Flex>        
  
          <Flex flexDirection="column" mb={{ base: 6, md: 0 }} mr={{ base: 4, md: 0 }}>
              <Text style={{ fontWeight: "bold" }}>Agent</Text>
              <Select 
                  name="agent_id" 
                  required 
                  value={selectedAgent} 
                  onChange={handleAgentChange}
              >
                  <option value="">Select Agent</option>
                  {agentsLoading ? (
                  <option value="" disabled>Loading agents...</option>
                  ) : agentsError ? (
                  <option value="" disabled>Error loading agents</option>
                  ) : (
                  agents.map((agent) => (
                      <option key={agent.id} value={agent.id}>
                      {agent.name}
                      </option>
                  ))
                  )}
              </Select>
          </Flex>
         
          <Flex flexDirection="column" mb={{ base: 6, md: 0 }} mr={{ base: 4, md: 0 }}>
            <Text style={{ fontWeight: "bold" }}>Campaign</Text>
            <Select 
                  name="campaign_id" 
                  required 
                  value={selectedCampaign} 
                  onChange={handleCampaignChange}
              >
                  <option value="">Select Campaign</option>
                  {campaignsLoading ? (
                      <option value="" disabled>Loading campaigns...</option>
                  ) : campaignsError ? (
                      <option value="" disabled>Error loading campaigns</option>
                  ) : (
                      campaigns.map((campaign) => (
                          <option key={campaign.id} value={campaign.id}>
                              {campaign.campaign_name} 
                          </option>
                      ))
                  )}
              </Select>           
           </Flex>
          
           <Flex flexDirection="column" mb={{ base: 4, md: 0 }} mr={{ base: 4, md: 0 }}>
              <Text style={{ fontWeight: "bold" }}>Upload CSV file</Text>
              <Input type="file" name="file" required accept=".csv" onChange={handleFileChange} />
            </Flex>
  
          <Flex flexDirection="column">
            <Button onClick={handleUpload} backgroundColor="blue.500" color="white" colorScheme="blue">
              <Icon as={FaUpload} size='sm'/> Upload
            </Button>
          </Flex>
      </Flex>
  
      <Spacer h="2" bg='blackAlpha.50'/>

      <Box p={8}>
        <Heading as="h4" fontSize='lg' mb={4}>
          Define Custom Call Tags and Columns
        </Heading>

        <Flex mb={4}>
          <Text fontWeight="bold" mr={4}>Select Campaign:</Text>
          <Select
            name="campaign_id"
            required
            value={selectedCampaign}
            onChange={handleCampaignChange}
            placeholder="Select Campaign"
          >
            {campaignsLoading ? (
              <option value="" disabled>Loading campaigns...</option>
            ) : campaignsError ? (
              <option value="" disabled>Error loading campaigns</option>
            ) : (
              campaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.campaign_name}
                </option>
              ))
            )}
          </Select>
        </Flex>

        <Flex mb={4}>
          {notFound ? (
            <Button 
              colorScheme="red" 
              onClick={handleCreateCustomTags} 
              leftIcon={<FaPlus />}
              isLoading={saving}
            >
              Create Default Custom Tags
            </Button>
          ) : (
            <Button
              colorScheme='blue'
              onClick={handleSaveCustomTags}
              isLoading={saving || customTagsLoading}
              leftIcon={<FaSave />}
            >
              Save Custom Tags
            </Button>
          )}
        </Flex>

        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {Object.keys(customCallTags).map((key, index) => (
            key !== "product_code" && key !== "campaign_id" && (
              <GridItem key={index}>
                 <Text fontWeight="bold" mb={2}>{key.replace(/_/g, ' ').toUpperCase()}</Text>
                <Input
                  placeholder={`Custom ${key.replace(/_/g, ' ').toUpperCase()}`}
                  name={key}
                  value={customCallTags[key]}
                  onChange={handleInputChange}
                  mb={4}
                />
              </GridItem>
            )
          ))}
        </Grid>

        {saveError && <Message type='error' mt={4}>{saveError}</Message>}
        {customTagsError && <Message type='error' mt={4}>{customTagsError}</Message>}
      </Box>
  
      <Spacer h="2" bg='blackAlpha.50'/>
  
      <Flex justifyContent="space-between" mt="2">
        <Heading as="h1" fontSize='lg' mb="5" ml={8} mt={4}>
          Available Contacts
        </Heading>  
      </Flex>
  
      {contactsLoading ? (
        <Loader />
      ) : contactsError ? (
        <Message type="error">{contactsError}</Message>
      ) : (
        <Box shadow="lg" p={6} overflowX="auto" borderRadius="lg" ml={8} mr={8}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Campaign</Th>
                <Th>Phone</Th>
                <Th>Agent</Th>
                <Th>{`${customCallTags.col_2 || 'Col 2'} (Col 2)`}</Th>
    <Th>{`${customCallTags.col_3 || 'Col 3'} (Col 3)`}</Th>
    <Th>{`${customCallTags.col_4 || 'Col 4'} (Col 4)`}</Th>
    <Th>{`${customCallTags.col_5 || 'Col 5'} (Col 5)`}</Th>
    <Th>{`${customCallTags.col_8 || 'Col 8'} (Col 8)`}</Th>
                <Th>Status</Th>                
                <Th>Date Uploaded</Th>
                <Th>Date Updated</Th>
                <Th>Action</Th>
                <Th>Description</Th>
                {/* New columns from agent's view */}
                <Th>Client Name</Th>
                <Th>Customer Feedback</Th>
                <Th>Location</Th>
                <Th>{`${customCallTags.call_tag_1 || 'Call Tag 1'} (Call Tag 1)`}</Th>
    <Th>{`${customCallTags.call_tag_2 || 'Call Tag 2'} (Call Tag 2)`}</Th>
    <Th>{`${customCallTags.call_tag_3 || 'Call Tag 3'} (Call Tag 3)`}</Th>
    <Th>{`${customCallTags.call_tag_4 || 'Call Tag 4'} (Call Tag 4)`}</Th>
    <Th>{`${customCallTags.call_tag_5 || 'Call Tag 5'} (Call Tag 5)`}</Th>
    <Th>{`${customCallTags.call_tag_6 || 'Call Tag 6'} (Call Tag 6)`}</Th>
              </Tr>              
            </Thead>           
            <Tbody>
            {currentContacts && currentContacts.length > 0 ? (
              currentContacts.map((contact, index) => (
                <Tr key={index} bg="white">
                <Td>{index + 1 + (currentPage - 1) * itemsPerPage}</Td>
                <Td>{contact.campaign_name}</Td>
                <Td>{contact.col_1}</Td>
                <Td>
                    {agents.find((agent) => agent.id === contact.agent_id)?.name || "Agent Not Found"}
                </Td>          
                <Td>{contact.col_2}</Td>
                <Td>{contact.col_3}</Td>
                <Td>{contact.col_4}</Td>
                <Td>{contact.col_5}</Td>
                <Td>{contact.col_8}</Td>
                <Td>{contact.status}</Td>
                
                <Td>
                  {moment(contact.created_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}
                  </Td>
                <Td>
                {moment(contact.updated_at)
                        .tz("Africa/Nairobi")
                        .format("dddd, MMMM Do YYYY")}                  
                </Td>                
                <Td>
                  <Flex alignItems="center">
                    <Button                      
                      colorScheme="white"
                      bg='white'
                      onClick={() => confirmDelete(contact)}
                    >
                      <Icon as={FaTrash} outline={true} color="red"  size="md" />
                    </Button> 
                  </Flex>
                </Td> 
                <Td>{contact.description}</Td>
                {/* New columns from agent's view */}
                <Td>{contact.client_name}</Td>
                <Td>{contact.customer_feedback}</Td>
                <Td>{contact.location}</Td>
                <Td>{contact.callTag1}</Td>
                <Td>{contact.callTag2}</Td>
                <Td>{contact.callTag3}</Td>
                <Td>{contact.callTag4}</Td>
                <Td>{contact.callTag5}</Td>
                <Td>{contact.callTag6}</Td>                   
              </Tr>
            
            ))
            ) : (
              <Tr>
                <Td colSpan="20" textAlign="center">
                  No data available
                </Td>
              </Tr>
            )}
            </Tbody>
          </Table>
          <Flex mt="4" justify="space-between" align="center">
          <Box>
            Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, contacts? contacts.length : 0)} of {contacts ? contacts.length : 0} entries
          </Box>
            <Flex mt="4" justify="space-between" align="center">
              <Box>
                <Button
                  variant="outline"
                  isDisabled={currentPage === 1}
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </Button>
                {contacts && Array.isArray(contacts) && contacts.length > 0 && [...Array(Math.ceil(contacts.length / itemsPerPage))].map((_, index) => (
                <React.Fragment key={index}>
                  {index + 1 === 1 || (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) || index + 1 === Math.ceil(contacts.length / itemsPerPage) ? (
                    <Button
                      key={index}
                      variant={currentPage === index + 1 ? "solid" : "outline"}
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </Button>
                  ) : (
                    index + 1 === currentPage - 3 || index + 1 === currentPage + 3 ? (
                      <Box key={index}>...</Box>
                    ) : null
                  )}
                </React.Fragment>
              ))}
              <Button
                variant="outline"
                isDisabled={contacts ? currentPage === Math.ceil(contacts.length / itemsPerPage) : true}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </Button>
  
              </Box>
            </Flex>
          </Flex> 
          </Box>
      )} 
      <Spacer h="5" />
    </Box>
  );
};

export default SupOutboundContacts;

import axios from 'axios';

import {
    CAMPAIGNS_LIST_REQUEST,
    CAMPAIGNS_LIST_SUCCESS,
    CAMPAIGNS_LIST_FAIL,
    CAMPAIGNS_BY_PRODUCT_REQUEST,
    CAMPAIGNS_BY_PRODUCT_SUCCESS,
    CAMPAIGNS_BY_PRODUCT_FAIL,
    CAMPAIGNS_DELETE_REQUEST,
    CAMPAIGNS_DELETE_SUCCESS,
    CAMPAIGNS_DELETE_FAIL,
    CAMPAIGNS_CREATE_REQUEST,
    CAMPAIGNS_CREATE_SUCCESS,
    CAMPAIGNS_CREATE_FAIL,
    CAMPAIGNS_UPDATE_REQUEST,
    CAMPAIGNS_UPDATE_SUCCESS,
    CAMPAIGNS_UPDATE_FAIL,
} from '../constants/campaignConstants';

export const listCampaigns = (searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: CAMPAIGNS_LIST_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.get(`/api/campaigns?name=${searchQuery}`, config);

        dispatch({ type: CAMPAIGNS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CAMPAIGNS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const listCampaignsByProduct = (productId, searchQuery = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: CAMPAIGNS_BY_PRODUCT_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };
        
        const { data } = await axios.get(`/api/campaigns/${productId}?name=${searchQuery}`, config);
        
        dispatch({ type: CAMPAIGNS_BY_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CAMPAIGNS_BY_PRODUCT_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteCampaign = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CAMPAIGNS_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/campaigns/${id}`, config);

        dispatch({ type: CAMPAIGNS_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: CAMPAIGNS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const createCampaign = (campaignData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CAMPAIGNS_CREATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post('/api/campaigns', campaignData, config);

        dispatch({ type: CAMPAIGNS_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CAMPAIGNS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const updateCampaign = (id, campaignData) => async (dispatch, getState) => {
    try {     
      dispatch({ type: CAMPAIGNS_UPDATE_REQUEST });
  
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { campaign_name, status } = campaignData;
      const updatedData = { campaign_name, status };
  
      const { data } = await axios.put(`/api/campaigns/${id}`, updatedData, config);
  
      dispatch({ type: CAMPAIGNS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      console.error("Error updating campaign:", error);
      dispatch({
        type: CAMPAIGNS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
import { useState, useEffect } from "react";
import { 
    Box, 
    Flex, 
    Heading, 
    FormControl, 
    FormLabel, 
    Input, 
    Textarea, 
    Button, 
    Text,
    Alert,
    AlertIcon,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { sendHaziSMS } from "../../actions/haziSocialsActions"; 
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const HaziQuickSMSScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [recipient, setRecipient] = useState("");
    const [name, setName] = useState("");

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const haziSmsSend = useSelector((state) => state.haziSmsSend); 
    const { loading, success, error } = haziSmsSend;

    useEffect(() => {
        if (!userInfo) {
            navigate('/login');
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        if (success) {
            setMessage("");
            setRecipient("");
            setName("");
        }
    }, [success]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(sendHaziSMS({ message, recipient, name })); 
    };

    return (
        <Box borderRadius="sm" m={4}>
            <Flex justifyContent="space-between" mt="2">
                <Heading as="h1" fontSize={{ base: "xl", md: "2xl" }} mb="5" ml={8} mt={4}>
                    Quick SMS
                </Heading>
            </Flex>

            <Box p={8} maxW="600px" bg="white" rounded="md">
                {loading && <Loader />}

                {error && (
                    <Message status="error">
                        {error}
                    </Message>
                )}

                {success && (
                    <Alert status="success" mb={4}>
                        <AlertIcon />
                        Message sent successfully!
                    </Alert>
                )}

                <form onSubmit={submitHandler}>
                    <FormControl id="recipient" isRequired mb={4}>
                        <FormLabel>Recipient</FormLabel>
                        <Input 
                            value={recipient} 
                            onChange={(e) => setRecipient(e.target.value)} 
                            placeholder="Required" 
                            required 
                        />
                    </FormControl>

                    <FormControl id="name" mb={4}>
                        <FormLabel>Name</FormLabel>
                        <Input 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            placeholder="Optional" 
                        />
                    </FormControl>

                    <FormControl id="message" isRequired mb={4}>
                        <FormLabel>Message</FormLabel>
                        <Textarea 
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)} 
                            rows="5" 
                            required 
                        />
                        <Text fontSize="sm" color="blue.600" mt={2}>
                            {160 - message.length} characters remaining
                        </Text>
                    </FormControl>

                    <Button 
                        type="submit" 
                        colorScheme="blue" 
                        leftIcon={<i className="feather icon-send" />} 
                        mt={4} 
                        isLoading={loading}
                    >
                        Send
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default HaziQuickSMSScreen;

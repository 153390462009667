import {
    CAMPAIGNS_LIST_FAIL,
    CAMPAIGNS_LIST_REQUEST,
    CAMPAIGNS_LIST_SUCCESS,
    CAMPAIGN_LIST_RESET,
    CAMPAIGNS_BY_PRODUCT_REQUEST,
    CAMPAIGNS_BY_PRODUCT_SUCCESS,
    CAMPAIGNS_BY_PRODUCT_FAIL,
    CAMPAIGNS_BY_PRODUCT_RESET,
    CAMPAIGNS_DELETE_FAIL,
    CAMPAIGNS_DELETE_REQUEST,
    CAMPAIGNS_DELETE_SUCCESS,
    CAMPAIGNS_CREATE_FAIL,
    CAMPAIGNS_CREATE_REQUEST,
    CAMPAIGNS_CREATE_SUCCESS,
    CAMPAIGNS_CREATE_RESET,
    CAMPAIGNS_UPDATE_FAIL,
    CAMPAIGNS_UPDATE_REQUEST,
    CAMPAIGNS_UPDATE_SUCCESS,
    CAMPAIGNS_UPDATE_RESET,
  } from '../constants/campaignConstants';
  
  export const campaignsListReducer = (state = { campaigns: [] }, action) => {
    switch (action.type) {
      case CAMPAIGNS_LIST_REQUEST:
        return { loading: true, campaigns: [] };
      case CAMPAIGNS_LIST_SUCCESS:
        return { loading: false, campaigns: action.payload };
      case CAMPAIGNS_LIST_FAIL:
        return { loading: false, error: action.payload };
      case CAMPAIGN_LIST_RESET:
        return { campaigns: [] };
      default:
        return state;
    }
  };
  
  export const campaignsByProductReducer = (state = { campaigns: [] }, action) => {
    switch (action.type) {
      case CAMPAIGNS_BY_PRODUCT_REQUEST:
        return { ...state, loading: true, campaigns: [] };
      case CAMPAIGNS_BY_PRODUCT_SUCCESS:
        return { ...state, loading: false, campaigns: action.payload, success: true };
      case CAMPAIGNS_BY_PRODUCT_FAIL:
        return { ...state, loading: false, error: action.payload };
      case CAMPAIGNS_BY_PRODUCT_RESET:
      default:
        return state;
    }
  };
  
  export const campaignsDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case CAMPAIGNS_DELETE_REQUEST:
        return { loading: true };
      case CAMPAIGNS_DELETE_SUCCESS:
        return { loading: false, success: true };
      case CAMPAIGNS_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const campaignsCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case CAMPAIGNS_CREATE_REQUEST:
        return { loading: true };
      case CAMPAIGNS_CREATE_SUCCESS:
        return { loading: false, success: true, campaign: action.payload };
      case CAMPAIGNS_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case CAMPAIGNS_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const campaignUpdateReducer = (state = { campaign: {}, loading: false, error: null }, action) => {
    switch (action.type) {
      case CAMPAIGNS_UPDATE_REQUEST:
        return { loading: true };
      case CAMPAIGNS_UPDATE_SUCCESS:
        return { loading: false, success: true, campaign: action.payload };
      case CAMPAIGNS_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case CAMPAIGNS_UPDATE_RESET:
        return { ...state, loading: false, success: false, error: null };
      default:
        return state;
    }
  };
  
export const QUELOG_LIST_REQUEST = "QUELOG_LIST_REQUEST";
export const QUELOG_LIST_SUCCESS = "QUELOG_LIST_SUCCESS";
export const QUELOG_LIST_FAIL = "QUELOG_LIST_FAIL";

export const QUELOG_PRODUCT_LIST_REQUEST = "QUELOG_PRODUCT_LIST_REQUEST";
export const QUELOG_PRODUCT_LIST_SUCCESS = "QUELOG_PRODUCT_LIST_SUCCESS";
export const QUELOG_PRODUCT_LIST_FAIL = "QUELOG_PRODUCT_LIST_FAIL";

export const QUELOG_ALL_LIST_REQUEST = "QUELOG_ALL_LIST_REQUEST";
export const QUELOG_ALL_LIST_SUCCESS = "QUELOG_ALL_LIST_SUCCESS";
export const QUELOG_ALL_LIST_FAIL = "QUELOG_ALL_LIST_FAIL";
